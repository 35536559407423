import React, { Children, cloneElement, forwardRef, ReactElement, useCallback } from 'react'

import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
} from '@noriginmedia/norigin-spatial-navigation'

export type OnChildFocus<T = Any> = (
  layout: FocusableComponentLayout,
  props: object,
  details: FocusDetails,
  scrollIndex?: number,
  child?: T
) => void

export type OnChildPress = (props: object, details: KeyPressDetails) => void

export interface DefaultScrollingWrapperProps {
  children: ReactElement[]
  className?: string
  contentWrapperClassName?: string

  onChildPress?: OnChildPress
  onChildFocus?: OnChildFocus
}

const DefaultScrollingWrapper = forwardRef<HTMLDivElement, DefaultScrollingWrapperProps>(
  ({ children, className, contentWrapperClassName, onChildPress, onChildFocus }, ref) => {
    const onChildFocusLocal: OnChildFocus = useCallback(
      (layout, props, details, scrollIndex) => {
        onChildFocus?.(layout, props, details, scrollIndex)
      },
      [onChildFocus],
    )

    const onChildLocalPress: OnChildPress = useCallback(
      (props, details) => {
        onChildPress?.(props, details)
      },
      [onChildPress],
    )

    return (
      <div
        ref={(node) => {
          if (typeof ref === 'function') ref(node)
          else if (ref) ref.current = node
        }}
        className={className}
      >
        <div className={contentWrapperClassName}>
          {Children.map(children, (child, index) => {
            return cloneElement(child, {
              ...child.props,
              focusProps: {
                ...child.props.focusProps,
                onFocus: onChildFocusLocal,
                onPress: onChildLocalPress,
              },
              scrollIndex: child.key !== undefined ? child.key : index,
            })
          })}
        </div>
      </div>
    )
  },
)

DefaultScrollingWrapper.displayName = 'DefaultScrollingWrapper'

export default DefaultScrollingWrapper
