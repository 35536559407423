import React, { FC, memo } from 'react'
import DefaultGenreSlider, {
  DefaultGenreSliderProps,
} from '@/modules/genre/components/DefaultGenreSlider'
import { FeedsResourceBaseModel } from '@/models/feeds.resource.model'

import styles from './styles.module.scss'
import { GenreModel } from '@/models/genre.model'

interface Props extends DefaultGenreSliderProps {
  resource: FeedsResourceBaseModel
  genres: GenreModel[]
}

const GenresFromResource: FC<Props> = ({ resource, genres, ...props }) => {
  if (!genres || !genres.length) {
    return null
  }

  return (
    <DefaultGenreSlider
      className={styles.HeightWrapper}
      data={genres}
      // isLoading={loading}
      rowProps={{ title: resource.title }}
      {...props}
    />
  )
}

export default memo(GenresFromResource)
