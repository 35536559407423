import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import {
  FocusContext,
  useFocusable,
  UseFocusableConfig,
} from '@noriginmedia/norigin-spatial-navigation'

import PlayerLoader from '@/modules/player/components/controls/PlayerLoader'
import OverlayHeader from '@/modules/player/components/controls/OverlayHeader'
import OverlayFooter from '@/modules/player/components/controls/OverlayFooter'
import OverlayLeftSlot from '@/modules/player/components/controls/OverlayLeftSlot'
import OverlayRightSlot from '@/modules/player/components/controls/OverlayRightSlot'
import AnimatedPlayPause from '@/modules/player/components/controls/AnimatedPlayPause'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'
import { FullscreenState } from '@/modules/player/Player/PlayerFullscreenManager'
import { NotificationModalEvents } from '@/core/customEvents/notifications.custom.events'

export type LeftSlotRenderFn = (
  isPaused: boolean,
  togglePlayPause?: () => Promise<void>,
  changeProgress?: (value: number | string) => void,
  isLive?: boolean,
  setUserActivity?: (active: boolean) => void,
  changeDisableOverlay?: (value: boolean) => void,
) => React.ReactNode

export interface OverlaySlotsProps {
  headerSlotRenderFn?: () => React.ReactNode
  leftSlotRenderFn?: LeftSlotRenderFn
  rightSlotRenderFn?: (
    setUserActivity: (active: boolean) => void,
    changeDisableOverlay: (value: boolean) => void,
  ) => React.ReactNode
}

export interface OverlayProps extends OverlaySlotsProps {
  className?: string
  togglePlayPause?: () => Promise<void>
  fullscreenState: FullscreenState
  changeProgress?: (value: string | number) => void
  setUserActivity: (active: boolean) => void
}

const focusConfig: UseFocusableConfig = {
  focusKey: 'player_overlay',
  isFocusBoundary: true,
  preferredChildFocusKey: 'timeline-thumb',
}

const Overlay: FC<OverlayProps> = ({
  className,
  fullscreenState,
  changeProgress,
  togglePlayPause,
  headerSlotRenderFn,
  leftSlotRenderFn,
  rightSlotRenderFn,
  setUserActivity,
}) => {
  const { ref, focusKey, focusSelf } = useFocusable(focusConfig)

  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isPaused = usePlayerStateSelector((state) => state.video.isPaused)

  const isUserActive = usePlayerStateSelector((state) => state.userActivity.isUserActive)

  const handleChangeProgress = useCallback((value: string | number) => {
    changeProgress?.(value)
  }, [])

  useEffect(() => {
    focusSelf()

    // custom fullscreen flag
    const root = document.getElementById('root')
    if (!root) return
    root.dataset.fullscreen = 'true'

    return () => {
      root.dataset.fullscreen = 'false'
    }
  }, [])

  const handleTogglePlayPause = useCallback(async () => {
    setUserActivity(true)
    togglePlayPause?.()
  }, [togglePlayPause])

  const { 0: isOverlayDisabled, 1: handleChangeDisableOverlay } = useState(false)

  const animate = useMemo(() => {
    if (isOverlayDisabled) return 'close'
    return !isPaused && !isUserActive ? 'close' : 'open'
  }, [isPaused, isUserActive, isOverlayDisabled])

  const handleSetUserActiveWithDisablingOverlay = useCallback((status: boolean) => {
    setUserActivity(status)
    handleChangeDisableOverlay(!status)
  }, [])

  const handlePreventClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const handleBodyClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (e.currentTarget !== e.target) return
      if (!togglePlayPause) return
      togglePlayPause()

      handlePreventClick(e)
    },
    [togglePlayPause],
  )

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        id={focusKey}
        ref={ref}
        onClick={handlePreventClick}
        className={classNames(className, styles.Overlay)}
      >
        <OverlayHeader
          overlay={ref}
          animate={animate}
          setUserActivity={setUserActivity}
          toggleFullscreen={fullscreenState.toggleFullscreen}
          headerSlotRenderFn={headerSlotRenderFn}
          className={styles.Overlay__Header}
        />

        <div className={styles.Overlay__Body} onClick={handleBodyClick}>
          <OverlayLeftSlot
            animate={animate}
            className={classNames(styles.Overlay__LeftSlot)}
            leftSlotRenderFn={leftSlotRenderFn}
            changeProgress={changeProgress}
            togglePlayPause={handleTogglePlayPause}
            setUserActivity={handleSetUserActiveWithDisablingOverlay}
            changeDisableOverlay={handleChangeDisableOverlay}
          />

          <AnimatedPlayPause isPaused={isPaused} />
          <PlayerLoader className={styles.Overlay__Control} />

          <OverlayRightSlot
            animate={animate}
            rightSlotRenderFn={rightSlotRenderFn}
            className={classNames(
              styles.Overlay__RightSlot,
              !rightSlotRenderFn && styles.Overlay__RightSlot__Empty,
            )}
            setUserActivity={handleSetUserActiveWithDisablingOverlay}
            changeDisableOverlay={handleChangeDisableOverlay}
          />
        </div>

        <OverlayFooter
          animate={animate}
          className={styles.Overlay__Footer}
          handleTogglePlayPause={handleTogglePlayPause}
          handleChangeProgress={handleChangeProgress}
        />
      </div>
      <NotificationAndUserActivityResolver
        setUserActivity={handleSetUserActiveWithDisablingOverlay}
        changeDisableOverlay={handleChangeDisableOverlay}
      />
    </FocusContext.Provider>
  )
}

interface ResolverProps {
  setUserActivity: (status: boolean) => void
  changeDisableOverlay: (value: boolean) => void
}

const NotificationAndUserActivityResolver = ({
  setUserActivity,
  changeDisableOverlay,
}: ResolverProps) => {
  useEffect(() => {
    const handleNotificationOpened = () => {
      setUserActivity(false)
      changeDisableOverlay(true)
    }
    const handleNotificationClosed = () => {
      changeDisableOverlay(false)
      setUserActivity(true)
    }

    document.addEventListener(NotificationModalEvents.OPENED, handleNotificationOpened)
    document.addEventListener(NotificationModalEvents.CLOSED, handleNotificationClosed)

    return () => {
      document.removeEventListener(NotificationModalEvents.OPENED, handleNotificationOpened)
      document.removeEventListener(NotificationModalEvents.CLOSED, handleNotificationClosed)
    }
  }, [])

  return null
}

export default Overlay
