import React, { useEffect } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'
import DefaultFocusWrapper from '@/components/Slider/DefaultFocusWrapper'
import ActorsAndGenres from '@/modules/content/components/ActorsAndGenres'
import PlayChosenMovieAction from '@/modules/films/components/PlayChosenMovieAction'
import FavoritesAction from '@/modules/favorites/components/FavoritesAction'
import TrailerAction from '@/modules/content/components/TrailerAction'
import ChosenContentSkeleton from '@/modules/content/components/ChosenContentSkeleton'

import { useCheckRights } from '@/modules/content/hooks/useCheckRights'
import { useChosenContent } from '@/modules/content/hooks/useChosenContent'

import { MovieModel } from '@/models/movie.model'
import { ContentType } from '@/models/content.model'

const Movie = () => {
  const { rightsLoading, rightsError } = useCheckRights('movie')

  const { loading, shouldShowSkeleton, sliders } = useChosenContent<MovieModel>('movie')

  useEffect(() => {
    const desc = document.getElementById('content-description')
    if (desc) {
      desc.dataset.full = 'true'
    }
    return () => {
      if (desc) {
        desc.dataset.full = 'false'
      }
      // dispatch(resetChosenCard())
    }
  }, [])

  return (
    <div id={'movie-page'} className={styles.Movie}>
      <div className={styles.Movie__Footer}>
        {shouldShowSkeleton && <ChosenContentSkeleton />}
        {!shouldShowSkeleton && (
          <Slider className={styles.FocusWrapper}>
            <Slider.Vertical>
              {[
                <DefaultFocusWrapper
                  key={0}
                  withoutChildProps
                  className={styles.ActionsFocusWrapper}
                >
                  <ActorsAndGenres className={styles.AdditionalInfo} />

                  <div className={styles.ActionsContainer}>
                    <PlayChosenMovieAction
                      disabled={loading}
                      className={styles.ActionButton}
                      rightsLoading={rightsLoading}
                      rightsError={rightsError}
                    />
                    <TrailerAction disabled={loading} buttonClassName={styles.ActionButton} />
                    <FavoritesAction
                      disabled={loading}
                      className={styles.ActionButton}
                      favoritesContentType={ContentType.MOVIE}
                    />
                  </div>
                </DefaultFocusWrapper>,
                ...sliders,
              ]}
            </Slider.Vertical>
          </Slider>
        )}
      </div>
      <div id={'trailer'} />
      <div id={'movies-player'} />
    </div>
  )
}

export default Movie
