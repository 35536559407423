export enum ClientNotificationSocketEvent {
  CONNECT = 'client:notification:connect',
  CONNECT_ERROR = 'client:notification:connect-error',

  DISCONNECT = 'client:notification:disconnect',
  DISCONNECT_ERROR = 'client:notification:disconnect-error',

  CREATE = 'client:notification:create',
  DELETE_ONE = 'client:notification:delete-one',
  DELETE_ALL = 'client:notification:delete-all',
  READ_ALL = 'client:notification:read-all',
  READ_ONE = 'client:notification:read-one',
}

export enum AuthSessionsSocketsEvents {
  CONNECT = 'client:session:connect',
  CONNECT_ERROR = 'client:session:connect-error',
  DELETE_SESSION = 'client:session:delete-one',
}

export enum TvEventSessionsSocketsEvents {
  CONNECT = 'client:tv-event:connect',
  CONNECT_ERROR = 'client:tv-event:connect-error',
}

export enum MediaEventSessionsSocketsEvents {
  CONNECT = 'client:media-event:connect',
  CONNECT_ERROR = 'client:media-event:connect-error',
}
