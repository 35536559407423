import React, { FC, useCallback, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import KeyboardButton from '@/components/BigKeyboardButton'
import { mainRussianKeys, numpadKeys } from '@/components/BigKeyboard/keys'

interface BigKeyboardProps {
  className?: string
  symbolsKeysListClassName?: string
  numpadKeysListClassName?: string
  onKeyPress?: (key: string) => void
  onResetPress?: () => void
  onDeletePress?: () => void
  disabled?: boolean
}

const BigKeyboard: FC<BigKeyboardProps> = ({
  className,
  symbolsKeysListClassName,
  numpadKeysListClassName,
  onResetPress,
  onDeletePress,
  onKeyPress,
  disabled,
}) => {
  const { 0: isUpperCase, 1: setIsUpperCase } = useState(true)

  const switchUppercaseState = useCallback(() => {
    setIsUpperCase((state) => !state)
  }, [])

  return (
    <div className={classNames(className, styles.Keyboard)}>
      <div className={classNames(symbolsKeysListClassName, styles.Keys)}>
        {mainRussianKeys.map((key, index) => {
          const handleButtonPress = () => {
            if (key === 'up') {
              switchUppercaseState()
              return
            }
            if (key === 'Очистить') {
              onResetPress && onResetPress()
              return
            }
            if (key === 'del') {
              onDeletePress && onDeletePress()
              return
            }
            if (isUpperCase) {
              onKeyPress && onKeyPress(key.toUpperCase())
              return
            }
            onKeyPress && onKeyPress(key)
          }
          return (
            <KeyboardButton
              key={index}
              disabled={key === 'eng' || disabled}
              doubleSize={key === 'Очистить' || key === 'eng'}
              sixSize={key === ' '}
              onButtonClick={handleButtonPress}
              onEnterPress={handleButtonPress}
              buttonClassName={classNames({
                [styles.ClearButton]: key === 'Очистить',
              })}
            >
              {key === 'del' ? (
                <i className={'icon-fill-back'} />
              ) : key === 'up' ? (
                isUpperCase ? (
                  <i className={'icon-fill-shift'} />
                ) : (
                  <i className={'icon-line-shift'} />
                )
              ) : isUpperCase ? (
                key === 'Очистить' || key === 'eng' ? (
                  key
                ) : (
                  key.toUpperCase()
                )
              ) : (
                key
              )}
            </KeyboardButton>
          )
        })}
      </div>
      <div className={classNames(numpadKeysListClassName, styles.NumpadKeys)}>
        {numpadKeys.map((key, index) => {
          const handleButtonPress = () => {
            onKeyPress && onKeyPress(`${key}`)
          }
          return (
            <KeyboardButton
              disabled={disabled}
              onButtonClick={handleButtonPress}
              onEnterPress={handleButtonPress}
              key={index + `${key}`}
            >{`${key}`}</KeyboardButton>
          )
        })}
      </div>
    </div>
  )
}

export default BigKeyboard
