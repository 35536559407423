import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import ModalPortal, { ModalPortalProps } from '@/components/Modal/ModalPortal'

export interface ModalProps extends Omit<ModalPortalProps, 'onClose'> {
  isOpen?: boolean
  trigger: (onTrigger: () => void) => React.ReactElement
  appendToId?: string
  onClose?: () => void
}

const Modal = ({ trigger, isOpen, appendToId, onClose, ...rest }: ModalProps) => {
  const [open, setOpen] = useState(isOpen || false)

  const handleTrigger = () => {
    setOpen(true)
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose?.()
  }

  useEffect(() => {
    if (isOpen === undefined) return
    setOpen(isOpen)
  }, [isOpen])
  return (
    <>
      {trigger(handleTrigger)}
      {open &&
        createPortal(
          <ModalPortal {...rest} onClose={handleOnClose} />,
          document.getElementById(appendToId || 'modal')!,
        )}
    </>
  )
}

export default Modal
