import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

interface PartnerLogoProps {
  logo: string
  className?: string
}

const PartnerLogo = ({ logo, className }: PartnerLogoProps) => {
  return (
    <div className={classNames(styles.PartnerLogo, className)}>
      <img src={logo} />
    </div>
  )
}

export default PartnerLogo
