import React, { FC } from 'react'

import Loader from '@/components/Loader'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

interface PlayerLoaderProps {
  className: string
}

const PlayerLoader: FC<PlayerLoaderProps> = ({ className }) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isBuffering = usePlayerStateSelector((state) => state.video.isBuffering)
  const isLoading = usePlayerStateSelector((state) => state.screen.isLoading)

  return (
    <div className={className}>
      {(isLoading || isBuffering) && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
    </div>
  )
}

export default PlayerLoader
