import Svg from '@/components/Notifications/components/Svg'
import React from 'react'
import { TypeOptions, Theme } from '@/components/Notifications/types'
import { Callback, ContainerType, EventManager, Events } from '@/components/Notifications/index'

export const getIcon = (type: TypeOptions = 'default', theme: Theme = 'light') => {
  switch (type) {
    case 'info':
      return <Svg.Info type={type} theme={theme} />
    case 'success':
      return <Svg.Success type={type} theme={theme} />
    case 'warning':
      return <Svg.Warning type={type} theme={theme} />
    case 'error':
      return <Svg.Error type={type} theme={theme} />
    default:
      return null
  }
}

export const containerStartUp = (
  id: string,
  type: ContainerType,
  on: { event: Events; cb: Callback }[] = [],
  emit: Events[] = [],
  cleanUpEmit: Events[] = [],
) => {
  EventManager?.setContainer(id, type)
  on.forEach(({ event, cb }) => {
    EventManager?.on(event, cb)
  })
  emit.forEach((event) => {
    EventManager?.emit(event)
  })

  return () => {
    EventManager?.removeContainer(id, type)
    on.forEach(({ event, cb }) => {
      EventManager?.off(event, cb)
    })
    cleanUpEmit.forEach((event) => {
      EventManager?.emit(event)
    })
  }
}
