import React, { FC, useEffect } from 'react'

import { useFocusable, FocusContext, setThrottle } from '@noriginmedia/norigin-spatial-navigation'

interface RedirectProps {
  focusKey?: string
}

const Redirect: FC<RedirectProps> = ({ focusKey: key }) => {
  const { ref, focusKey, setFocus, focused } = useFocusable({
    focusKey: key || 'Redirect',
  })

  useEffect(() => {
    if (!focused) return

    if (focusKey !== 'Redirect') {
      setFocus('CONTENT')
      return
    }
    const root = document.getElementById('root')

    if (!root) return

    const prevLocation = root.dataset.location

    if (!prevLocation) {
      root.dataset.location = 'CONTENT'

      setThrottle({ throttle: 300, throttleKeypresses: true })
      const id = setTimeout(() => {
        setFocus('CONTENT')
        clearTimeout(id)
      }, 10)
      return
    }

    if (prevLocation === 'MENU') {
      root.dataset.location = 'CONTENT'
      setThrottle({ throttle: 300, throttleKeypresses: true })
      const id = setTimeout(() => {
        setFocus('CONTENT')
        clearTimeout(id)
      }, 10)
    } else {
      setThrottle({ throttle: 300, throttleKeypresses: false })
      root.dataset.location = 'MENU'
      setFocus('MENU')
    }
  }, [focused])

  return (
    <FocusContext.Provider value={focusKey}>
      <div id={focusKey} ref={ref} style={{ width: '0', height: '1080px' }} />
    </FocusContext.Provider>
  )
}

export default Redirect
