import React, { FC } from 'react'

import { motion } from 'framer-motion'

import PlayerManager from '@/modules/player/player.manager'

import { leftVariants } from '@/modules/player/components/player/Overlay/overlay.slots.transitions'

import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import { LeftSlotRenderFn } from '@/modules/player/components/player/Overlay'
import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

interface OverlayLeftSlotProps {
  animate: string
  className: string
  leftSlotRenderFn?: LeftSlotRenderFn
  changeProgress?: (value: string | number) => void
  togglePlayPause?: () => Promise<void>
  setUserActivity?: (active: boolean) => void
  changeDisableOverlay?: (value: boolean) => void
}

const OverlayLeftSlot: FC<OverlayLeftSlotProps> = ({
  animate,
  className,
  leftSlotRenderFn,
  changeProgress,
  togglePlayPause,
  setUserActivity,
  changeDisableOverlay,
}) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isPaused = usePlayerStateSelector((state) => state.video.isPaused)
  const isLive = usePlayerStateSelector((state) => state.video.isLive)

  return (
    <motion.div initial={'open'} animate={animate} variants={leftVariants} className={className}>
      {leftSlotRenderFn?.(
        isPaused,
        togglePlayPause,
        changeProgress,
        isLive,
        setUserActivity,
        changeDisableOverlay,
      )}
    </motion.div>
  )
}

export default OverlayLeftSlot
