import { DrawFunction } from '@/core/animations/animate'

export const drawOpenedMenu =
  (
    menuTitleFocusBgElements: NodeListOf<HTMLElement>,
    menuTitleElements: NodeListOf<HTMLElement>,
    menuBgCover: HTMLElement | null,
  ): DrawFunction =>
  (progress) => {
    menuTitleFocusBgElements.forEach((focusBg, index) => {
      // -250% - left position while closed
      // 275 - all path from (-250%) to 25%
      focusBg.style.left = `${-250 + 275 * progress}%`

      const title = menuTitleElements[index]
      // -200% - left position while closed
      // 280 - all path from (-250%) to 80%
      title.style.left = `${-200 + 280 * progress}%`
      // -860px - left position while closed
      // 860 - all path from (-860px) to 0px
      menuBgCover && (menuBgCover.style.left = `${-860 + 860 * progress}px`)
    })
  }

export const drawClosedMenu =
  (
    menuTitleFocusBgElements: NodeListOf<HTMLElement>,
    menuTitleElements: NodeListOf<HTMLElement>,
    menuBgCover: HTMLElement | null,
  ): DrawFunction =>
  (progress) => {
    menuTitleFocusBgElements.forEach((focusBg, index) => {
      // 25% - left position while opened
      // 275 - all path from (-250%) to 25%
      focusBg.style.left = `${25 - 275 * progress}%`
      const title = menuTitleElements[index]
      // 80% - left position while opened
      // 280 - all path from (-250%) to 80%
      title.style.left = `${80 - 280 * progress}%`
      // 0px - left position while opened
      // 860 - all path from (-860px) to 0px
      menuBgCover && (menuBgCover.style.left = `${0 - 860 * progress}px`)
    })
  }
