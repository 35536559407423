import { useCallback, useRef } from 'react'

export const useCommonStatisticCounterLogic = () => {
  const totalCountRef = useRef<number>(0)
  const counterRef = useRef<number>(0)
  const isViewPointAchievedRef = useRef<boolean>(false)

  const intervalIdRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const resetCounter = useCallback(() => {
    counterRef.current = 0
  }, [])

  const resetTotalCount = useCallback(() => {
    totalCountRef.current = 0
  }, [])

  const resetIsViewPointAchieved = useCallback(() => {
    isViewPointAchievedRef.current = false
  }, [])

  const resetCounterInterval = useCallback(() => {
    if (!intervalIdRef.current) return
    clearInterval(intervalIdRef.current)
    intervalIdRef.current = null
  }, [])

  return {
    intervalIdRef,
    totalCountRef,
    counterRef,
    isViewPointAchievedRef,
    resetCounter,
    resetCounterInterval,
    resetIsViewPointAchieved,
    resetTotalCount,
  }
}
