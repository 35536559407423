import React, { ChangeEvent, forwardRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

interface InputProps
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'className' | 'value' | 'onChange' | 'placeholder' | 'disabled'
  > {
  className?: string
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  focused?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ value, onChange, placeholder, className, focused, ...rest }: InputProps, ref) => {
    return (
      <input
        ref={ref}
        disabled
        placeholder={placeholder}
        className={classNames(className, styles.Input, {
          [styles.Focused]: focused,
        })}
        value={value}
        onChange={onChange}
        {...rest}
      />
    )
  },
)

Input.displayName = 'Input'

export default Input
