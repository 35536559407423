import { CategoryModel } from '@/models/category.model'

export enum ChannelStatuses {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum ArchiveStatuses {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum ChannelSleepStatuses {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export interface ChannelBaseModel {
  id: string
  createdAt: string
  updatedAt: string
  title: string
  alias: string
  slug: string
  status: ChannelStatuses
  archiveStatus: ArchiveStatuses
  sleepUrl: string | null
  sleepStatus: ChannelSleepStatuses
  partnerChannelId: string
  quality: number
  isFromInternet: boolean
  order: number | null
}

export interface ChannelLogo {
  id: string
  createdAt: string
  updatedAt: string
  hash: string
  folder: string
  sourceBaseUrl: string
  sourcePath: string
}

export interface ChannelsCategoriesModel {
  id: string
  createdAt: string
  updatedAt: string
  channelId: string
  categoryId: string
  order: number | null
}

export interface ChannelModel extends ChannelBaseModel {
  logo?: ChannelLogo
  categories?: CategoryModel[] | null
  channelsCategories?: ChannelsCategoriesModel[] | null
}

export type ChannelRelationsKeys = 'logo' | 'categories' | 'channelsCategories'

export interface ChannelRelations {
  logo: boolean
  categories: boolean
}
