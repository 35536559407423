import React, { FC, useEffect } from 'react'

import Modal from '@/components/Notifications/components/Modals/Modal'

import { useRenderListAndQueue } from '@/components/Notifications/notifications.hooks'
import { containerStartUp } from '@/components/Notifications/notifications.helpers'

import type { NotificationsModalProps } from '@/components/Notifications/components/Modals/Modal'
import { Callback, ContainerType, Events } from '@/components/Notifications'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

export interface ModalsContainerProps {
  id?: string
  limit?: number
  returnFocusTo?: string
}

const ModalsContainer: FC<ModalsContainerProps> = ({ limit, id, returnFocusTo }) => {
  const { render, handleAdd, handleDelete } = useRenderListAndQueue<NotificationsModalProps>({
    limit: limit || 5,
  })

  useEffect(() => {
    const containerId = id ? id : `${Date.now()}`

    const subs: { event: Events; cb: Callback }[] = [
      { event: Events.ADD_MODAL, cb: handleAdd },
      { event: Events.DELETE_MODAL, cb: handleDelete },
    ]

    const emit: Events[] = [Events.MODAL_CONTAINER_MOUNTED]
    const cleanUpEmit: Events[] = [Events.MODAL_CONTAINER_UNMOUNTED]

    const cleanUp = containerStartUp(
      containerId,
      ContainerType.MODAL_CONTAINER,
      subs,
      emit,
      cleanUpEmit,
    )
    return () => {
      cleanUp()
    }
  }, [])
  const { ref, focusKey, focused, setFocus } = useFocusable()

  useEffect(() => {
    if (!(focused && !render.length)) return
    if (!returnFocusTo) return

    setFocus(returnFocusTo)
  }, [focused])

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref}>
        {render?.map((el) => (
          <Modal key={el.notification.id} notification={el.notification} id={el.id} />
        ))}
      </div>
    </FocusContext.Provider>
  )
}

export default ModalsContainer
