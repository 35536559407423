import React, { useEffect, useMemo, useState } from 'react'

import styles from './styles.module.scss'

import dayjs from 'dayjs'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { GET_LIVE_POLLING_INTERVAL } from '@/core/config'

import {
  useLazyGetTodayProgramsByChannelIdQuery,
  useLazyGetLiveProgramByChannelIdQuery,
} from '@/modules/tv/tv.api'
import ShortProgramListItem from '@/modules/tv/components/ShortProgramListItem'
import { setCurrentProgram, setLiveProgram } from '@/modules/tv/tv.actions'
import { isNotFoundError } from '@/core/api/api'
import { useShouldUpdateCurrentProgram } from '@/modules/tv/hooks/useShouldUpdateCurrentProgram'

const SHORT_PROGRAM_ELEMENTS_COUNT = 3

const ShortProgramList = () => {
  const dispatch = useAppDispatch()

  const focusedChannel = useAppSelector((state) => state.tv.focusedChannel)
  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)
  const [interval, setInterval] = useState<number | undefined>(+GET_LIVE_POLLING_INTERVAL)
  const [getTodayPrograms, { data, isLoading }] = useLazyGetTodayProgramsByChannelIdQuery({})

  // запрос за лайвом для канала в фокусе
  const [getLiveProgram, { data: liveProgram, error }] = useLazyGetLiveProgramByChannelIdQuery({
    pollingInterval: interval,
  })

  // if current program and current live program in state is equal boolean flag
  const shouldUpdateCurrentProgram = useShouldUpdateCurrentProgram()

  useEffect(() => {
    if (error && isNotFoundError(error)) {
      setInterval(undefined)
      return
    }

    setInterval(+GET_LIVE_POLLING_INTERVAL)
  }, [error])

  useEffect(() => {
    if (!focusedChannel) return

    getTodayPrograms({ channelId: focusedChannel.id }, true)

    getLiveProgram({ channelId: focusedChannel.id })
  }, [focusedChannel])

  useEffect(() => {
    if (!liveProgram) return
    if (!chosenChannel) return
    if (liveProgram.channelId !== chosenChannel.id) return

    dispatch(setLiveProgram(liveProgram))

    if (!shouldUpdateCurrentProgram) return
    // if current program and current live program in state is equal: update current program with live program
    dispatch(setCurrentProgram(liveProgram))
  }, [liveProgram])

  const render = useMemo(() => {
    if (!data || isLoading || !data.length)
      return Array(SHORT_PROGRAM_ELEMENTS_COUNT)
        .fill(null)
        .map((el, index) => <ShortProgramListItem key={index} isActive={false} />)

    const preparedData = [...data]
      .sort((a, b) => {
        if (new Date(a.startTime) > new Date(b.startTime)) return 1
        if (new Date(a.startTime) < new Date(b.startTime)) return -1
        return 1
      })
      .filter((el, index, arr) => {
        const time = dayjs(el.startTime)
        const nextTime = dayjs(arr[index + 1]?.startTime)
        const now = dayjs(new Date())
        if (time.isAfter(now) || (time?.isBefore(now) && nextTime.isAfter(now))) return true
      })
      .filter((el, index) => index < SHORT_PROGRAM_ELEMENTS_COUNT)

    const preparedDataForRender = preparedData.map((el, index) => {
      const isActive = el.id === liveProgram?.id
      return <ShortProgramListItem key={index} program={el} isActive={isActive} />
    })

    if (preparedData.length < SHORT_PROGRAM_ELEMENTS_COUNT) {
      const additionalElementsNumber = SHORT_PROGRAM_ELEMENTS_COUNT - preparedDataForRender.length

      const additionalElements = Array(additionalElementsNumber)
        .fill(null)
        .map((el, index) => (
          <ShortProgramListItem key={preparedDataForRender.length + index} isActive={false} />
        ))

      additionalElements.forEach((el) => {
        preparedDataForRender.push(el)
      })
    }

    return preparedDataForRender
  }, [data, isLoading, liveProgram])

  return <div className={styles.ShortProgramList}>{render}</div>
}

export default ShortProgramList
