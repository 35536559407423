import React, { FC, useCallback } from 'react'

import { toast } from '@/components/Notifications'

import Modal from '@/components/Modal'
import Button from '@/components/Button'
import QuestionWithConfirmAndCancelActions from '@/components/QuestionWithConfirmAndCancelActions'

import { useRemoveAllNotificationsMutation } from '@/modules/notifications/notifications.api'

import { useGetNotifications } from '@/modules/notifications/hooks/useGetNotifications'

interface Props {
  triggerClassName?: string
  focusedTriggerClassName?: string
}

const RemoveAllNotificationsAction: FC<Props> = ({ triggerClassName, focusedTriggerClassName }) => {
  const { notifications } = useGetNotifications()

  const [removeAll] = useRemoveAllNotificationsMutation()

  const handleRemoveAll = useCallback((onClose?: () => void) => {
    removeAll()
      .unwrap()
      .then(() => {
        toast.success({
          title: 'Удаление уведомлений',
          message: 'Уведомления удалены успешно',
        })

        onClose && onClose()
      })
      .catch(() =>
        toast.error({
          title: 'Удаление уведомлений',
          message: 'Что-то пошло не так! Попробуйте снова позже',
        }),
      )
  }, [])

  return (
    <Modal
      hideCloseButton
      trigger={(onTrigger) => {
        return (
          <Button
            disabled={!notifications || notifications.length === 0}
            buttonClassName={triggerClassName}
            focusClassName={focusedTriggerClassName}
            shouldHandleClickOnlyInFocus
            onEnterPress={onTrigger}
            onButtonClick={onTrigger}
          >
            Удалить все
          </Button>
        )
      }}
      render={(onClose) => {
        return (
          <QuestionWithConfirmAndCancelActions
            headerText={'Удалить все уведомления?'}
            additionalText={'Удалённые уведомления нельзя будет восстановить'}
            confirmText={'Удалить'}
            onConfirm={() => handleRemoveAll(onClose)}
            onCancel={() => onClose?.()}
          />
        )
      }}
    />
  )
}

export default RemoveAllNotificationsAction
