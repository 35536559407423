import React, { FC, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'

interface NavigationCardInnerProps {
  genreId?: string
  setFocus?: (key: string) => void
  specificAction?: (args: {
    setFocus?: (key: string) => void
    className?: string
  }) => React.ReactElement
}

const NavigationCardInner: FC<NavigationCardInnerProps> = ({ setFocus, specificAction }) => {
  const handleToMenu = useCallback(() => {
    setTimeout(() => {
      setFocus?.('Redirect')
    }, 10)
  }, [setFocus])

  return (
    <div className={styles.NavigationCard__Inner}>
      {specificAction && specificAction({ setFocus, className: styles.Button })}
      {setFocus && (
        <Button
          buttonClassName={classNames(styles.Button, specificAction && styles.Button__WithMargin)}
          onButtonClick={handleToMenu}
          onEnterPress={handleToMenu}
        >
          В меню
        </Button>
      )}
    </div>
  )
}

export default NavigationCardInner
