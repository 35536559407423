import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'

import { apiBaseQuery } from '@/core/api/api.query'

import { prepareChannelRelations, preparePagination } from '@/core/helpers'

import {
  ChannelRelationsArgs,
  GetChannelById,
  GetChannelsWithAllAvailableArgs,
  GetDatesByChannelIdResponseItem,
  GetProgramsByChannelId,
  GetProgramsByChannelIdAndDate,
  GetProgramsByChannelIdAndDateWithPagination,
  GetProgramsByChannelIdWithPagination,
} from '@/modules/tv/tv.types'

import { ChannelModel } from '@/models/channel.model'
import { ProgramBaseModel } from '@/models/program.model'

import { ApiFetchAllWithPaginationPayload } from '@/core/api/api'

export const tvApi = createApi({
  reducerPath: 'tvApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/clients/` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllChannels: builder.query<ChannelModel[], ChannelRelationsArgs>({
      query: ({ relations: rel = {} }) => {
        const relations = prepareChannelRelations(rel)

        const params = {
          relations,
        }
        return {
          url: 'me/channels',
          method: 'GET',
          params,
        }
      },
    }),

    getAllChannelsWithPaginate: builder.query<
      ApiFetchAllWithPaginationPayload<ChannelModel>,
      GetChannelsWithAllAvailableArgs
    >({
      query: ({ paginate: pag, relations: rel = {} }) => {
        const paginate = preparePagination(pag)
        const relations = prepareChannelRelations(rel)

        const params = {
          paginate,
          relations,
        }

        return {
          url: 'channels',
          method: 'GET',
          params,
        }
      },
    }),

    getChannelById: builder.query<ChannelModel, GetChannelById>({
      query: ({ channelId, relations: rel = {} }) => {
        const relations = prepareChannelRelations(rel)

        const params = {
          relations,
        }
        return {
          url: `channels/${channelId}`,
          method: 'GET',
          params,
        }
      },
    }),

    getProgramsByChannelId: builder.query<ProgramBaseModel[], GetProgramsByChannelId>({
      query: ({ channelId }) => ({
        url: `channels/${channelId}/programs`,
        method: 'GET',
      }),
    }),

    getProgramsByChannelIdWithPagination: builder.query<
      ApiFetchAllWithPaginationPayload<ProgramBaseModel>,
      GetProgramsByChannelIdWithPagination
    >({
      query: ({ channelId, paginate: pag }) => {
        const paginate = preparePagination(pag)

        const params = {
          paginate,
        }

        return {
          url: `channels/${channelId}/programs`,
          method: 'GET',
          params,
        }
      },
    }),

    getLiveProgramByChannelId: builder.query<ProgramBaseModel, GetProgramsByChannelId>({
      query: ({ channelId }) => ({
        url: `channels/${channelId}/programs/live`,
        method: 'GET',
      }),
    }),

    getTodayProgramsByChannelId: builder.query<ProgramBaseModel[], GetProgramsByChannelId>({
      query: ({ channelId }) => ({
        url: `channels/${channelId}/programs/today`,
        method: 'GET',
      }),
    }),

    getTodayProgramsByChannelIdWithPagination: builder.query<
      ApiFetchAllWithPaginationPayload<ProgramBaseModel>,
      GetProgramsByChannelIdWithPagination
    >({
      query: ({ channelId, paginate: pag }) => {
        const paginate = preparePagination(pag)

        const params = {
          paginate,
        }

        return {
          url: `channels/${channelId}/programs/today`,
          method: 'GET',
          params,
        }
      },
    }),
    getDatesByChannelId: builder.query<GetDatesByChannelIdResponseItem[], GetProgramsByChannelId>({
      query: ({ channelId }) => ({
        url: `channels/${channelId}/programs/dates`,
        method: 'GET',
      }),
    }),

    getProgramsByChannelIdAndDate: builder.query<ProgramBaseModel[], GetProgramsByChannelIdAndDate>(
      {
        query: ({ channelId, date }) => ({
          url: `channels/${channelId}/programs/${date}`,
          method: 'GET',
        }),
        transformResponse: (response: ProgramBaseModel[]) => {
          return [...response].sort((a, b) => {
            if (a.startTime > b.startTime) return 1
            if (a.startTime < b.startTime) return -1
            return 1
          })
        },
      },
    ),

    getProgramsByChannelIdAndDateWithPagination: builder.query<
      ApiFetchAllWithPaginationPayload<ProgramBaseModel>,
      GetProgramsByChannelIdAndDateWithPagination
    >({
      query: ({ channelId, date, paginate: pag }) => {
        const paginate = preparePagination(pag)

        const params = {
          paginate,
        }

        return {
          url: `channels/${channelId}/programs/${date}`,
          method: 'GET',
          params,
        }
      },
    }),

    getRightsCheckByChannelId: builder.query<{ streamAccessToken: string }, { channelId: string }>({
      query: ({ channelId }) => {
        return {
          url: `me/channels/${channelId}/access`,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useGetAllChannelsQuery,
  useGetTodayProgramsByChannelIdQuery,
  useLazyGetTodayProgramsByChannelIdQuery,
  useGetLiveProgramByChannelIdQuery,
  useLazyGetLiveProgramByChannelIdQuery,
  useLazyGetProgramsByChannelIdQuery,
  useLazyGetDatesByChannelIdQuery,
  useGetDatesByChannelIdQuery,
  useLazyGetProgramsByChannelIdAndDateQuery,
  useGetRightsCheckByChannelIdQuery,
  useLazyGetRightsCheckByChannelIdQuery,
} = tvApi
