import React, { useEffect } from 'react'

import ContentResourceListWithResolvedPathname from '@/modules/content/components/ContentResourceListWithResolvedPathname'

import { useAppDispatch } from '@/core/store'
import { resetChosenCard } from '@/modules/content/content.actions'

const Movies = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetChosenCard())
  }, [])
  return <ContentResourceListWithResolvedPathname />
}

export default Movies
