export type TimingFunction = (time: number) => number

export const linear: TimingFunction = (time) => time
export const quad: TimingFunction = (time) => Math.pow(time, 2)
export const circ: TimingFunction = (time) => 1 - Math.sin(Math.acos(time))

function makeEaseOut(timing: TimingFunction): TimingFunction {
  return (time) => {
    return 1 - timing(1 - time)
  }
}

const makeEaseInOut = (timing: TimingFunction): TimingFunction => {
  return function (timeFraction) {
    if (timeFraction < 0.5) return timing(2 * timeFraction) / 2
    else return (2 - timing(2 * (1 - timeFraction))) / 2
  }
}

function bounce(time: number) {
  // eslint-disable-next-line no-constant-condition
  for (let a = 0, b = 1; 1; a += b, b /= 2) {
    if (time >= (7 - 4 * a) / 11) {
      return -Math.pow((11 - 6 * a - 11 * time) / 4, 2) + Math.pow(b, 2)
    }
  }
}

const bounceEaseOut = makeEaseOut(bounce as TimingFunction)
const bounceEaseInOut = makeEaseInOut(bounce as TimingFunction)
const easeOut = makeEaseOut(linear)
const easeInOut = makeEaseOut(linear)

export const timingFunctions = {
  linear,
  quad,
  circ,
  bounceEaseOut,
  easeOut,
  bounceEaseInOut,
  easeInOut,
}

export default timingFunctions
