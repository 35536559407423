import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

import MenuButton, { MenuButtonProps } from '@/modules/menu/components/MenuButton'

import { useGetNotifications } from '@/modules/notifications/hooks/useGetNotifications'

interface Props extends MenuButtonProps {}

const NotificationsMenuButton = (props: Props) => {
  const { haveNotReadedNotifications } = useGetNotifications()
  return (
    <div
      className={classNames(props.focusWrapperClassName, styles.Notifications, {
        [styles.Notifications__Active]: haveNotReadedNotifications,
      })}
    >
      <MenuButton {...props} />
    </div>
  )
}

export default NotificationsMenuButton
