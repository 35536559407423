import { createAsyncThunk } from '@reduxjs/toolkit'
import api, { IApiErrorResponse } from '@/core/api/api'
import { BaseServerResponse, SearchArgs } from '@/modules/search/slice/search.service'
import { addManyToEndSearchResults } from '@/modules/search/slice/actions/results/results.actions'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { partialUpdatePageState } from '@/modules/search/slice/actions/page/page.actions'

export const makeSearch = createAsyncThunk<
  BaseServerResponse,
  PageUpdatePayload<SearchArgs>,
  { rejectValue: IApiErrorResponse }
>('search/makeSearch', async (attr, thunkAPI) => {
  const { pageName, payload } = attr
  if (!payload) throw new Error('Search payload doesnt exist')
  //     todo: add sync change search status action to Pending
  try {
    const { data } = await api.services.search.makeSearch(payload)
    const { items, totalPages, totalItems } = data

    thunkAPI.dispatch(addManyToEndSearchResults({ pageName, payload: items }))
    thunkAPI.dispatch(
      partialUpdatePageState({ pageName, payload: { totalPages, totalItems: totalItems || 0 } }),
    )

    //     todo: add sync change search status action to success
    return data
  } catch (e) {
    //     todo: add sync change search status action to failed
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})
