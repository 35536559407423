export enum SearchCustomEvents {
  LOADING_TURN_ON = 'searchLoadingTurnOn',
  LOADING_TURN_OFF = 'searchLoadingTurnOff',
}

const searchLoadingTurnOn = new Event(SearchCustomEvents.LOADING_TURN_ON)
export const dispatchSearchLoadingTurnOnEvent = () => {
  document.dispatchEvent(searchLoadingTurnOn)
}

const searchLoadingTurnOff = new Event(SearchCustomEvents.LOADING_TURN_OFF)
export const dispatchSearchLoadingTurnOffEvent = () => {
  document.dispatchEvent(searchLoadingTurnOff)
}
