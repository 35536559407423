import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContentModel } from '@/models/content.model'

import { resetChosenCard, setChosenCard } from '@/modules/content/content.actions'

export type ChosenCard = ContentModel | null

interface ContentSlice {
  fullDescription: boolean
  chosenCard: ContentModel | null
  trailerReady: boolean
}

const initialState: ContentSlice = {
  fullDescription: false,
  chosenCard: null,
  trailerReady: false,
}

export const contentSlice = createSlice({
  name: 'contentSlice',
  initialState,
  reducers: {
    setContentDescription: (state, action: PayloadAction<boolean>) => {
      state.fullDescription = action.payload
    },
    setTrailerReady: (state, action: PayloadAction<boolean>) => {
      state.trailerReady = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setChosenCard, (state, action) => {
        state.chosenCard = action.payload
      })
      .addCase(resetChosenCard, (state) => {
        state.chosenCard = null
      })
  },
})

export const { setContentDescription, setTrailerReady } = contentSlice.actions
