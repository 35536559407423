import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

interface SkeletonViewProps {
  rounded?: boolean
  withAnimation?: boolean
}

const SkeletonView: FC<SkeletonViewProps> = ({ rounded, withAnimation }) => {
  return (
    <div
      className={classNames(styles.SkeletonView, {
        [styles.SkeletonView__Rounded]: rounded,
        [styles.SkeletonView__WithAnimation]: withAnimation,
      })}
    >
      <span>Skeleton</span>
    </div>
  )
}

export default SkeletonView
