const transition = {
  transition: {
    duration: 0.25,
    ease: 'easeInOut',
  },
}

export const footerVariants = {
  open: {
    y: '0%',
    ...transition,
  },
  close: {
    y: '100%',
    ...transition,
  },
}

export const leftVariants = {
  open: {
    x: '0%',
    ...transition,
  },
  close: {
    x: '-100%',
    ...transition,
  },
}

export const rightVariants = {
  open: {
    x: '0%',
    ...transition,
  },
  close: {
    x: '100%',
    ...transition,
  },
}

export const headerVariants = {
  open: {
    y: '0%',
    ...transition,
  },
  close: {
    y: '-100%',
    ...transition,
  },
}
