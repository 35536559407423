import React from 'react'

import { RouteObject } from 'react-router-dom'

import { Paths, ContentPaths } from '@/core/router/router.paths'

// import DefaultLayout from '@/layouts/default'
import ContentLayout from '@/layouts/content'
import HomeView from '@/modules/home/views/Home'
import Movies from '@/modules/films/views/Movies'
import Movie from '@/modules/films/views/Movie'
import Serials from '@/modules/serials/views/Serials'
import Serial from '@/modules/serials/views/Serial'
import Tv from '@/modules/tv/views/Tv'
import Favorites from '@/modules/favorites/views/Favorites'
import FavoritesContent from '@/modules/favorites/views/FavoritesContent'
import Genre from '@/modules/genre/views/Genre'
import Partner from '@/modules/partner/views/Partner'
import Search from '@/modules/search/views/Search'
// import ApproveLayout from '@/layouts/approve'
import ApproveResolver from '@/modules/approveResolver'
import ApproveHomeViewResolver from '@/modules/approveHomeViewResolver'
import MoviePlayerFullscreen from '@/modules/films/views/MoviePlayerFullscreen'
import SerialPlayerFullscreen from '@/modules/serials/views/SerialPlayerFullscreen'
import ErrorBoundary from '@/modules/errorBoundary'
import TrailerFullscreenPlayer from '@/modules/content/views/TrailerFullscreenPlayer/TrailerFullscreenPlayer'
import Tag from '@/modules/tags/views/Tag'
import ActiveViews from '@/modules/syncMediaView/views/ActiveViews'
import NotificationsLayout from '@/layouts/notifications'
import Notifications from '@/modules/notifications/views/Notifications'

export const contentRoute: RouteObject = {
  path: Paths.CONTENT,
  element: <ApproveResolver />,
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <ApproveHomeViewResolver />,
    },
    {
      path: ContentPaths.HOME,
      element: <HomeView />,
    },
    {
      path: ContentPaths.MOVIES,
      element: <ContentLayout />,
      children: [
        {
          index: true,
          element: <Movies />,
        },
        {
          path: `:id`,
          element: <Movie />,
        },
      ],
    },
    {
      path: ContentPaths.TV,
      element: <Tv />,
    },
    {
      path: ContentPaths.SERIALS,
      element: <ContentLayout />,
      children: [
        {
          index: true,
          element: <Serials />,
        },
        {
          path: ':id',
          element: <Serial />,
        },
      ],
    },
    {
      path: ContentPaths.SEARCH,
      element: <Search />,
    },
    {
      path: ContentPaths.FAVORITES,
      element: <Favorites />,
    },
    {
      path: `${ContentPaths.FAVORITES}/:type`,
      element: <FavoritesContent />,
    },
    {
      path: `${ContentPaths.GENRES}/:id`,
      element: <Genre />,
    },
    {
      path: `${ContentPaths.PARTNERS}/:id`,
      element: <Partner />,
    },
    {
      path: `${ContentPaths.TAGS}/:id`,
      element: <Tag />,
    },
    {
      path: ContentPaths.CHILDREN,
      element: <Tv />,
    },
    {
      path: ContentPaths.SPORT,
      element: <Tv />,
    },

    {
      path: ContentPaths.MOVIE_PLAYER,
      element: <MoviePlayerFullscreen />,
    },
    {
      path: ContentPaths.SERIAL_PLAYER,
      element: <SerialPlayerFullscreen />,
    },
    {
      path: ContentPaths.TRAILER_PLAYER,
      element: <TrailerFullscreenPlayer />,
    },
    {
      path: ContentPaths.VIEWS,
      element: <ActiveViews />,
    },
    {
      path: ContentPaths.NOTIFICATIONS,
      element: (
        <NotificationsLayout>
          <Notifications />
        </NotificationsLayout>
      ),
    },
  ],
}
