import React, { FC, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'
import Sidebar from '@/components/Sidebar'
import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'
import ChannelsListWithScrollbar from '@/modules/tv/components/ChannelsListSidebarAction/ChannelsListWithScrollbar'

import { useGetAllChannelsQuery } from '@/modules/tv/tv.api'

interface Props {
  triggerClassName?: string
  shouldCloseOnChannelPress?: boolean
  withText?: boolean
  onTriggerCallback?: () => void
  onCloseCallback?: () => void
}

const ChannelsListSidebarAction: FC<Props> = ({
  triggerClassName,
  withText,
  shouldCloseOnChannelPress,
  onCloseCallback,
  onTriggerCallback,
}) => {
  const { data: channels, isFetching, isLoading } = useGetAllChannelsQuery({})

  const handleOnTrigger = useCallback(
    (onTrigger: () => void) => {
      return () => {
        onTrigger()
        onTriggerCallback && onTriggerCallback()
      }
    },
    [onTriggerCallback],
  )

  const renderTrigger = useCallback(
    (onTrigger: () => void) => (
      <Button
        onButtonClick={handleOnTrigger(onTrigger)}
        onEnterPress={handleOnTrigger(onTrigger)}
        disabled={!channels || isFetching || isLoading}
        buttonClassName={classNames(triggerClassName)}
      >
        <>
          <i className={classNames('icon-fill-menu')} />
          {withText && (
            <span data-title={'title'} className={styles.Text}>
              Список каналов
            </span>
          )}
        </>
      </Button>
    ),
    [triggerClassName, channels, isFetching, isLoading, handleOnTrigger],
  )

  return (
    <Sidebar
      trigger={renderTrigger}
      backgroundClassName={styles.Background}
      onClose={onCloseCallback}
      render={(onClose) => {
        return (
          <CommonFilterSidebar onClose={onClose} title={'ТВ каналы'}>
            <ChannelsListWithScrollbar
              channels={channels}
              onClose={onClose}
              shouldCloseOnChannelPress={shouldCloseOnChannelPress}
            />
          </CommonFilterSidebar>
        )
      }}
    />
  )
}

export default ChannelsListSidebarAction
