import React from 'react'

import styles from './styles.module.scss'

import { Outlet } from 'react-router-dom'

import CabinetMenu from '@/modules/cabinet/components/CabinetMenu'
import RouterHistoryResolver from '@/components/RouterHistoryResolver'
import CabinetNavigationBackButton from '@/modules/cabinet/components/CabinetNavigationBackButton'
import ModalsContainer from '../../components/Notifications/components/Modals/ModalsContainer'

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import { useNavigateToRootAfterResetState } from '@/modules/navigateToRootAfterResetState/hooks/useNavigateToRootAfterResetState'

const CabinetLayout = () => {
  const { ref, focusKey } = useFocusable({
    focusKey: 'CabinetLayout',
  })

  useNavigateToRootAfterResetState()

  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div ref={ref} className={styles.CabinetLayout}>
          <div className={styles.CabinetMenuContainer}>
            <CabinetNavigationBackButton className={styles.BackButton} />
            <CabinetMenu />
          </div>
          <div className={styles.CabinetContentContainer}>
            <Outlet />
          </div>
        </div>
        <RouterHistoryResolver />
      </FocusContext.Provider>
      <ModalsContainer
        id={'cabinet-notifications-modal-container'}
        returnFocusTo={'CabinetLayout'}
      />
    </>
  )
}

export default CabinetLayout
