import { useAppSelector } from '@/core/store'
import { useEffect } from 'react'
import { isNativeApp } from '@/core/utils'
import { Platform } from '@/models/platform.model'
import PlatformService from '@/core/services/platform.service'
import TizenService from '@/core/services/tizen.service'

const ApplicationSetUp = () => {
  const appInfo = useAppSelector((state) => state.app.info)
  const platform = useAppSelector((state) => state.app.platform)

  useEffect(() => {
    if (!isNativeApp || !platform || platform === Platform.LOCAL) return

    if (PlatformService.platform === Platform.TIZEN) {
      TizenService.setup()
    } else if (PlatformService.platform === Platform.WEB_OS) {
      // Empty
    }
  }, [appInfo])
  return null
}

export default ApplicationSetUp
