import dayjs from 'dayjs'
import {
  GetDatesByChannelIdResponseItem,
  GetDatesByChannelIdResponseItemStatus,
} from '@/modules/tv/tv.types'

const getToday = (date: string = '') => new Date(date)
const getTomorrow = (today: Date) => {
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  return tomorrow
}

const getYesterday = (today: Date) => {
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  return yesterday
}

export const formatDateToString = (date: Date, template: string) => {
  return dayjs(date).format(template)
}

export const formatDateToDayMonthYearString = (date: Date) => {
  const template = 'DD-MM-YYYY'

  return formatDateToString(date, template)
}

export const formatDateToDayMonthString = (date: Date) => {
  const template = 'DD.MM'

  return formatDateToString(date, template)
}

export const formatDateToWeekDay = (date: string) => {
  const formatter = new Intl.DateTimeFormat('ru-Ru', { weekday: 'long' })
  return formatter.format(new Date(date))
}

const checkIsTodayProgramDate = (card?: GetDatesByChannelIdResponseItem) => {
  return card?.status === GetDatesByChannelIdResponseItemStatus.CURRENT
}

export const getProgramDateCardTitle = (
  card: GetDatesByChannelIdResponseItem,
  todayDate?: GetDatesByChannelIdResponseItem,
): string => {
  if (checkIsTodayProgramDate(card)) {
    return `Сегодня, ${formatDateToDayMonthString(new Date(card.date))}`
  }
  const today = getToday(todayDate?.date)
  const tomorrow = getTomorrow(today)
  const formattedTomorrow = formatDateToDayMonthYearString(tomorrow)
  if (formatDateToDayMonthYearString(new Date(card.date)) === formattedTomorrow) {
    return `Завтра, ${formatDateToDayMonthString(new Date(card.date))}`
  }
  const yesterday = getYesterday(today)
  const formattedYesterday = formatDateToDayMonthYearString(yesterday)

  if (formatDateToDayMonthYearString(new Date(card.date)) === formattedYesterday) {
    return `Вчера, ${formatDateToDayMonthString(new Date(card.date))}`
  }
  return `${formatDateToWeekDay(card.date)}, ${formatDateToDayMonthString(new Date(card.date))}`
}
