import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { ListProps } from '@/components/List'
import Checkbox, { CheckboxProps } from '@/components/Checkbox'
import ListWithScrollbar from '@/components/ListWithScrollbar'
import CommonListWrapperWithLoader from '@/modules/search/components/CommonListWrapperWithLoader'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { useGetAvailableYearsQuery } from '@/modules/content/content.api'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  addYearToYearsFilter,
  removeYearFromYearsFilter,
  resetYearsFilter,
} from '@/modules/search/slice/actions/searchState/searchState.years.actions'

interface YearsListProps extends Omit<ListProps, 'children'> {
  chosenYears?: string[]
  disabledYears?: string[]
  pageName: PagesWithSearch
}

const YearsList: FC<YearsListProps> = ({
  className,
  pageName,
  chosenYears,
  disabledYears,
  ...rest
}) => {
  const { years, loading } = useGetAvailableYearsQuery(undefined, {
    selectFromResult: ({ data, isFetching, isLoading }) => {
      return {
        years: data,
        loading: isLoading || isFetching,
      }
    },
  })

  const items = useMemo(() => {
    if (!years) return []

    return [
      <DefaultYearCheckBox
        className={styles.DefaultCheckbox}
        key={'chooseAllYears'}
        pageName={pageName}
        autoFocus={true}
        chosenYears={chosenYears}
      />,
      ...years.map((year) => {
        return (
          <YearCheckBox
            key={year}
            pageName={pageName}
            name={year}
            disabled={disabledYears && !!disabledYears.find((el) => el === year)}
          />
        )
      }),
    ]
  }, [years])

  if (!years && !loading) return null
  return (
    <CommonListWrapperWithLoader isLoading={loading}>
      <ListWithScrollbar className={classNames(styles.YearsList, className)} {...rest}>
        {items}
      </ListWithScrollbar>
    </CommonListWrapperWithLoader>
  )
}

interface DefaultCountryCheckBoxProps extends CheckboxProps {
  chosenYears?: string[]
  pageName: PagesWithSearch
}

const DefaultYearCheckBox = (props: DefaultCountryCheckBoxProps) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) => state.searchByPageSlice[props.pageName].searchState.filters.years.length === 0,
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        dispatch(resetYearsFilter({ pageName: props.pageName }))
        return
      }
    },
    [props.chosenYears],
  )

  return <Checkbox name={'Выбрать всё'} checked={checked} onChange={handleChange} {...props} />
}

interface YearCheckBox extends CheckboxProps {
  pageName: PagesWithSearch
}

const YearCheckBox = (props: YearCheckBox) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) =>
      !!state.searchByPageSlice[props.pageName].searchState.filters.years.find(
        (el) => el === props.name,
      ),
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (!props.name) return

      if (checked) {
        dispatch(addYearToYearsFilter({ pageName: props.pageName, payload: props.name }))
        return
      }

      dispatch(removeYearFromYearsFilter({ pageName: props.pageName, payload: props.name }))
    },
    [props.name],
  )

  return <Checkbox checked={checked} onChange={handleChange} {...props} />
}

export default YearsList
