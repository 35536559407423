import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum MenuVariants {
  CABINET = 'cabinet',
  HOME = 'home',
  SEARCH = 'search',
  MOVIES = 'movies',
  SERIALS = 'serials',
  TV = 'tv',
  SPORT = 'sport',
  CHILDREN = 'children',
  FAVORITES = 'favorites',
  NOTIFICATIONS = 'notifications',
}

interface MenuSlice {
  menu: {
    current: MenuVariants
  }
}

const initialState: MenuSlice = {
  menu: {
    current: MenuVariants.HOME,
  },
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    changeCurrentMenuItem: (state, action: PayloadAction<MenuVariants>) => {
      state.menu.current = action.payload
    },
  },
})

export const { changeCurrentMenuItem } = menuSlice.actions
