import React, { FC } from 'react'

import styles from './styles.module.scss'

import DefaultCard from '@/components/DefaultCard'
import NavigationCardInner from '@/modules/content/components/NavigationCard/NavigationCardInner'

interface NavigationCardProps {
  genreId?: string
  specificAction?: (args: {
    setFocus?: (key: string) => void
    className?: string
  }) => React.ReactElement
}

const NavigationCard: FC<NavigationCardProps> = ({ genreId, specificAction }) => {
  return (
    <DefaultCard
      disabled
      className={styles.NavigationCard}
      wrapperClassName={styles.NavigationCardWrapper}
    >
      <NavigationCardInner genreId={genreId} specificAction={specificAction} />
    </DefaultCard>
  )
}

export default NavigationCard
