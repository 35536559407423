import React from 'react'

import styles from './styles.module.scss'

import GenreHeader from '@/modules/genre/components/GenreHeader'
import GenreFilters from '@/modules/genre/components/GenreFilters'
import GenreSearchResults from '@/modules/genre/components/GenreSearchResults'

const Genre = () => {
  return (
    <div className={styles.Genre}>
      <GenreHeader />

      <GenreFilters />

      <GenreSearchResults />
    </div>
  )
}

export default Genre
