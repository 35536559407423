import React, { FC, memo, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'
import Sidebar from '@/components/Sidebar'
import CountriesFilterSidebar from '@/modules/search/components/CountriesFilterAction/CountriesFilterSidebar'

import { useAppSelector } from '@/core/store'

import { CountryBaseModel } from '@/models/country.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface CountriesFilterActionProps {
  triggerClassName?: string
  disabled?: boolean
  chosenCountries?: CountryBaseModel[]
  disabledCountries?: CountryBaseModel[]
  pageName: PagesWithSearch
}

const CountriesFilterAction: FC<CountriesFilterActionProps> = ({
  triggerClassName,
  disabledCountries,
  chosenCountries,
  disabled,
  pageName,
}) => {
  const countries = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.countries,
  )

  const mappedCountries = useMemo(() => {
    return countries.map((el) => el.name)
  }, [countries])

  return (
    <Sidebar
      trigger={(onTrigger) => (
        <Button
          disabled={disabled}
          buttonClassName={classNames(triggerClassName, {
            [styles.FilterActive]: mappedCountries.length > 0,
          })}
          focusClassName={styles.FilterFocused}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
        >
          <>
            <span>Страны</span>
            {mappedCountries && mappedCountries.length > 0 ? (
              <span className={''}>{mappedCountries.join(', ')}</span>
            ) : (
              <span className={''}>Не выбрано</span>
            )}
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <CountriesFilterSidebar
            onClose={onClose}
            chosenCountries={chosenCountries}
            disabledCountries={disabledCountries}
            pageName={pageName}
          />
        )
      }}
    />
  )
}

export default memo(CountriesFilterAction)
