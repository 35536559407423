import React, { FC, useCallback } from 'react'

import Button from '@/components/Button'

import { useGetNotifications } from '@/modules/notifications/hooks/useGetNotifications'

import { useReadAllNotificationsMutation } from '@/modules/notifications/notifications.api'

interface Props {
  className?: string
  focusClassName?: string
  disabled?: boolean
}

const ViewAllNotificationsAction: FC<Props> = ({ focusClassName, disabled, className }) => {
  const { haveNotReadedNotifications } = useGetNotifications()

  const [readAll, { isLoading: reading }] = useReadAllNotificationsMutation()

  const handleReadAll = useCallback(() => {
    readAll()
  }, [])
  return (
    <Button
      disabled={!haveNotReadedNotifications || reading || disabled}
      buttonClassName={className}
      focusClassName={focusClassName}
      shouldHandleClickOnlyInFocus
      onEnterPress={handleReadAll}
      onButtonClick={handleReadAll}
    >
      Прочитать все
    </Button>
  )
}

export default ViewAllNotificationsAction
