import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import {
  setThrottle,
  useFocusable,
  UseFocusableConfig,
} from '@noriginmedia/norigin-spatial-navigation'

import QuestionWithConfirmAndCancelActions from '@/components/QuestionWithConfirmAndCancelActions'

import { isBackButton } from '@/core/keys/specific.keys.codes'
import { getPrevPathFromRouterHistoryFromLocalStorage, hasModalNodeOwnChild } from '@/core/helpers'

import PlatformService from '@/core/services/platform.service'

import {
  checkNativeFullscreen,
  checkCustomFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'

import { Platform } from '@/models/platform.model'

interface ReturnValue {
  isExitModalOpen: boolean
  focusKey: string
  ref: RefObject<Any>
  handleModalClose: () => void
  render: (onClose?: () => void) => JSX.Element
  trigger?: () => JSX.Element
}

const focusConfig: UseFocusableConfig = {
  focusKey: 'CONTENT',
  onFocus: () => {
    const root = document.getElementById('root')
    root && (root.dataset.location = 'CONTENT')
  },
  focusable: true,
}

export const useDefaultLayoutLogic = (): ReturnValue => {
  const navigate = useNavigate()

  const { state /*pathname*/ } = useLocation()
  const stateRef = useRef(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const { 0: isExitModalOpen, 1: setOpen } = useState(false)

  const { ref, focusKey, focusSelf } = useFocusable(focusConfig)

  useEffect(() => {
    return () => {
      setThrottle({ throttle: 300, throttleKeypresses: false })
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return

      e.preventDefault()
      e.stopPropagation()

      if (hasModalNodeOwnChild()) return
      if (checkNativeFullscreen() || checkCustomFullscreen()) return

      let prev = getPrevPathFromRouterHistoryFromLocalStorage()

      if (prev?.includes('player')) {
        prev = getPrevPathFromRouterHistoryFromLocalStorage()
      }

      if (!prev) {
        if (
          PlatformService.platform === Platform.TIZEN ||
          PlatformService.platform === Platform.LOCAL
        ) {
          setOpen(true)
          return
        }
        PlatformService.exit()
        return
      }

      navigate(prev, { state: stateRef.current })
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleModalClose = useCallback(() => {
    setOpen(false)
    focusSelf()
  }, [])

  const handleCancelExit = useCallback((onClose?: () => void) => {
    return () => {
      setOpen(false)
      onClose?.()
      focusSelf()
    }
  }, [])

  const render = useCallback((onClose?: () => void) => {
    return (
      <QuestionWithConfirmAndCancelActions
        headerText={'Выход из приложения'}
        additionalText={'Вы уверены, что хотите выйти?'}
        onCancel={handleCancelExit(onClose)}
        onConfirm={PlatformService.exit}
        confirmText={'Выйти'}
      />
    )
  }, [])

  return {
    isExitModalOpen,
    ref,
    focusKey,
    render,
    handleModalClose,
  }
}
