import React from 'react'

import styles from './styles.module.scss'

import ListWithScrollbar from '@/components/ListWithScrollbar'
import Qualities from '@/modules/content/components/ContentPlayerInteractOverlayActions/Qualities'
import Languages from '@/modules/content/components/ContentPlayerInteractOverlayActions/Languages'

import { OmitSourceState } from '@/modules/content/components/ContentPlayer'

const Settings = ({
  sourceState,
  closeMainOverlay,
}: {
  sourceState: OmitSourceState
  closeMainOverlay?: () => void
}) => {
  return (
    <div className={styles.Settings}>
      <h4>Настройки</h4>
      <ListWithScrollbar className={styles.List}>
        <Qualities
          quality={sourceState.quality}
          qualities={sourceState.qualities}
          changeResolution={sourceState.changeResolution}
          closeMainOverlay={closeMainOverlay}
        />
        <Languages
          language={sourceState.language}
          languages={sourceState.languages}
          changeLanguage={sourceState.changeLanguage}
        />
      </ListWithScrollbar>
    </div>
  )
}

export default Settings
