import React, { FC } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { setCurrentProgram } from '@/modules/tv/tv.actions'

interface BackToLiveActionProps {
  className?: string
  disabled?: boolean
}

const BackToLiveAction: FC<BackToLiveActionProps> = ({ className, disabled }) => {
  const dispatch = useAppDispatch()

  const liveProgram = useAppSelector((state) => state.tv.liveProgram)
  const handleAction = () => {
    if (!liveProgram) return
    dispatch(setCurrentProgram(liveProgram))
  }

  if (!liveProgram) return null
  return (
    <Button
      disabled={disabled}
      buttonClassName={className}
      onButtonClick={handleAction}
      onEnterPress={handleAction}
    >
      <>
        <div className={styles.Live}>
          <i className={'icon-fill-tv'} />
        </div>
        <span data-title={'title'}>Прямой эфир</span>
      </>
    </Button>
  )
}

export default BackToLiveAction
