import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import PlayerStore from '@/modules/player/player.store'

type PlayerProviderType<T> = ({
  initialState,
  children,
}: {
  initialState: T
  children?: ReactNode
}) => ReactElement

type UsePlayerStateSelectorType<T> = <Result>(selector: (state: T) => Result) => Result

type UsePlayerUpdateType<T> = () => (partialState: Partial<T>) => void

type UsePlayerDispatchType<R> = () => (action: R) => void

interface Player<T, R> {
  PlayerProvider: PlayerProviderType<T>
  usePlayerStateSelector: UsePlayerStateSelectorType<T>
  usePlayerUpdate: UsePlayerUpdateType<T>
  usePlayerDispatch: UsePlayerDispatchType<R>
}

class PlayerManager {
  static _instance: PlayerManager | null
  _cache: Record<string, unknown> = {}

  protected constructor() {}

  static getInstance() {
    if (!PlayerManager._instance) {
      PlayerManager._instance = new PlayerManager()
    }

    return PlayerManager._instance
  }

  getPlayer<T, R>(cacheId: string) {
    if (!this._cache[cacheId]) throw new Error('Player with this id doesnt exist!')
    return this._cache[cacheId] as Player<T, R>
  }

  createPlayer<T, R>(cacheId: string, reducer: (state: T, action: R) => T) {
    if (this._cache[cacheId]) return this._cache[cacheId] as Player<T, R>

    const Context = createContext<PlayerStore<T, R> | null>(null)

    // eslint-disable-next-line react/prop-types
    const PlayerProvider: PlayerProviderType<T> = ({ initialState, children }) => {
      const store = useMemo(() => new PlayerStore(initialState, reducer), [])

      return <Context.Provider value={store}>{children}</Context.Provider>
    }

    const usePlayerStore = () => {
      const store = useContext(Context)
      if (!store) {
        throw new Error('Cannot use usePlayerStore outside of the PlayerProvider')
      }

      return store
    }

    const usePlayerStateSelector = <Result,>(selector: (state: T) => Result) => {
      const store = usePlayerStore()
      const [state, setState] = useState(() => selector(store.getState()))

      const selectorRef = useRef(selector)
      const stateRef = useRef(state)

      useLayoutEffect(() => {
        selectorRef.current = selector
        stateRef.current = state
      })

      useEffect(() => {
        return store.subscribe(() => {
          const state = selectorRef.current(store.getState())

          if (stateRef.current === state) {
            return
          }

          setState(state)
        })
      }, [store])

      return state
    }

    const usePlayerUpdate = () => {
      const store = usePlayerStore()

      return store.update
    }

    const usePlayerDispatch = () => {
      const store = usePlayerStore()

      return store.dispatch
    }

    this._cache[cacheId] = {
      PlayerProvider,
      usePlayerStateSelector,
      usePlayerDispatch,
      usePlayerUpdate,
    }
    return this._cache[cacheId] as Player<T, R>
  }

  disposePlayer(cacheId: string) {
    if (this._cache[cacheId]) delete this._cache[cacheId]
  }
}

export default PlayerManager.getInstance()
