import { MutableRefObject, useCallback, useRef } from 'react'

export const useCustomRef = <T extends object>(
  initialState: T,
): [MutableRefObject<T>, (state: T) => void] => {
  const ref = useRef<T>(initialState)

  const setRef = useCallback((state: T) => {
    ref.current = { ...ref.current, ...state }
  }, [])

  return [ref, setRef]
}
