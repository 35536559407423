import React, { useEffect } from 'react'

import ContentResourceListWithResolvedPathname from '@/modules/content/components/ContentResourceListWithResolvedPathname'

import { useAppDispatch } from '@/core/store'

import { resetChosenCard } from '@/modules/content/content.actions'

const Serials = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(resetChosenCard())
  }, [])

  return <ContentResourceListWithResolvedPathname />
}

export default Serials
