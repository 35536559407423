import { createAsyncThunk } from '@reduxjs/toolkit'
import api, { IApiErrorResponse } from '@/core/api/api'
import { getFromLocalStorage } from '@/core/utils'
import SocketClient from '@/core/sockets/client'
import { SocketUrls } from '@/core/sockets/soketUrls'
import { ACCESS_TOKEN_KEY } from '@/core/config'
import {
  subscribeToMediaEventSocketConnection,
  subscribeToTvEventSocketConnection,
} from '@/core/sockets/connect.subsciptions'
import { AppState } from '@/core/store/store'

const socketClient = SocketClient.getInstance()

export const connectToTvEventsSockets = createAsyncThunk<
  void,
  void,
  { rejectValue: IApiErrorResponse; state: AppState }
>('statistics/connectToTvEventsSockets', async (_, thunkAPI) => {
  try {
    const token = getFromLocalStorage(ACCESS_TOKEN_KEY)
    if (!token) return

    await socketClient.connect(
      SocketUrls.TV_EVENTS,
      {
        extraHeaders: { Authorization: `${token}` },
        transports: ['polling', 'websocket'],
        closeOnBeforeunload: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 5000,
      },
      subscribeToTvEventSocketConnection,
    )

    return
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const connectToContentEventsSockets = createAsyncThunk<
  void,
  void,
  { rejectValue: IApiErrorResponse; state: AppState }
>('statistics/connectToContentEventsSockets', async (_, thunkAPI) => {
  try {
    const token = getFromLocalStorage(ACCESS_TOKEN_KEY)
    if (!token) return

    await socketClient.connect(
      SocketUrls.MEDIA_EVENTS,
      {
        extraHeaders: { Authorization: `${token}` },
        transports: ['polling', 'websocket'],
        closeOnBeforeunload: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 5000,
      },
      subscribeToMediaEventSocketConnection,
    )

    return
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})
