import React, { useEffect, useRef } from 'react'

import { useUpdateEffect } from '@/core/store/hooks'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'
import PlayerManager from '@/modules/player/player.manager'
import {
  PlayerReducerActions,
  PlayerState,
  setUserActivityAC,
} from '@/modules/player/player.reducer'

export type InteractionState = {
  isUserActive: boolean
  nudge: () => void
  toggleFixedUserActive: () => void
}

type InteractionRenderParameters = InteractionState & {
  handleMouseMove: (event: React.MouseEvent) => void
  handleFocus: (event: React.FocusEvent) => void
  handleTouchStart: (event: React.TouchEvent) => void
  handleTouchEnd: (event: React.TouchEvent) => void
  setUserActivity: (active: boolean) => void
  handleKeyPressed: () => void // cb for change activity when onKeyDown event fired (see in PlayerKeyboardManager)
}

export type InteractionFlags = {
  isMouseMoved: boolean
  isTouched: boolean
  isTouching: boolean
  isEntered: boolean
  isKeyPressed: boolean
  clientX: number
  clientY: number
}
export interface PlayerInteractionManagerProps {
  inactivityDelay?: number
  onChangeUserActivity?: (isUserActive: boolean) => void
}

interface FullPlayerInteractionManagerProps extends PlayerInteractionManagerProps {
  children: (params: InteractionRenderParameters) => React.ReactElement
}

const PlayerInteractionManager = ({
  inactivityDelay = 5,
  children,
  onChangeUserActivity,
}: FullPlayerInteractionManagerProps) => {
  const playerId = usePlayerIdContext()
  const { usePlayerDispatch } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(playerId)

  const dispatch = usePlayerDispatch()

  const isUserActive = useRef(true)
  const isFixed = useRef(false)
  const intervalId = useRef<NodeJS.Timer>()
  const inactivityTimeoutId = useRef<NodeJS.Timer>()
  const flags = useRef<InteractionFlags>({
    isMouseMoved: true,
    isTouched: false,
    isTouching: false,
    isEntered: false,
    isKeyPressed: false,
    clientX: -1,
    clientY: -1,
  })

  useEffect(() => {
    const delaySeconds = inactivityDelay || 0
    if (delaySeconds >= 0) {
      // Negative values deactivate
      intervalId.current = setInterval(updateActivity, 250) // This interval is not the inactivity delay.
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current)
      if (inactivityTimeoutId.current) clearTimeout(inactivityTimeoutId.current)
    }
  }, [])

  useUpdateEffect(() => {
    const delaySeconds = inactivityDelay || 0

    if (intervalId.current) clearInterval(intervalId.current)
    if (delaySeconds >= 0) {
      // Negative values deactivate
      intervalId.current = setInterval(updateActivity, 250) // This interval is not the inactivity delay.
    } else {
      // setState(() => ({ isUserActive: true }))
      isUserActive.current = true
    }
  }, [inactivityDelay])

  const handleMouseMove = (event: React.MouseEvent) => {
    if (event.clientX !== flags.current.clientX || event.clientY !== flags.current.clientY) {
      flags.current.isMouseMoved = true
      flags.current.clientX = event.clientX
      flags.current.clientY = event.clientY
    }
  }

  const handleKeyPressed = () => {
    flags.current.isKeyPressed = true
  }

  const handleTouchStart = () => {
    flags.current.isTouched = true
    flags.current.isTouching = true
  }

  const handleTouchEnd = () => {
    flags.current.isTouching = false
  }

  const nudge = () => {
    flags.current.isMouseMoved = true
  }

  const toggleFixedUserActive = () => {
    isFixed.current = !isFixed.current

    if (isFixed.current) flags.current.isMouseMoved = true
    // else setState(() => ({ isUserActive: false }))
    else isUserActive.current = false
  }

  const handleFocus = (focusEvent: React.FocusEvent) => {
    if (focusEvent.target === focusEvent.currentTarget) nudge()
  }

  const setInactive = () => {
    if (
      !isFixed.current &&
      !flags.current.isMouseMoved &&
      !flags.current.isKeyPressed &&
      isUserActive.current
    ) {
      dispatch(setUserActivityAC(false))
      isUserActive.current = false
    }
  }

  const updateActivity = () => {
    if (
      flags.current.isMouseMoved ||
      flags.current.isTouched ||
      flags.current.isTouching ||
      flags.current.isKeyPressed
    ) {
      flags.current.isTouched = false
      flags.current.isMouseMoved = false
      flags.current.isKeyPressed = false

      if (!isUserActive.current) {
        dispatch(setUserActivityAC(true))
        isUserActive.current = true
      }

      clearTimeout(inactivityTimeoutId.current)
      inactivityTimeoutId.current = setTimeout(setInactive, (inactivityDelay || 0) * 1000)
    }
  }

  useEffect(() => {
    onChangeUserActivity?.(isUserActive.current)
  }, [isUserActive.current])

  return children({
    isUserActive: isUserActive.current,
    handleMouseMove,
    handleTouchStart,
    handleTouchEnd,
    handleFocus,
    handleKeyPressed,
    toggleFixedUserActive,
    nudge,
    setUserActivity: (active) => {
      if (active) {
        flags.current.isKeyPressed = true
        dispatch(setUserActivityAC(true))
        isUserActive.current = true
        return
      }
      isFixed.current = false

      flags.current.isMouseMoved = false
      flags.current.isKeyPressed = false
      flags.current.isTouched = false
      flags.current.isTouching = false
      flags.current.isEntered = false

      dispatch(setUserActivityAC(false))
      isUserActive.current = false
    },
  })
}

export default PlayerInteractionManager
