import React, { ReactNode } from 'react'

import styles from './styles.module.scss'
import Loader from '@/components/Loader'

interface Props {
  isLoading: boolean
  children?: ReactNode
}

const CommonListWrapperWithLoader = ({ isLoading, children }: Props) => {
  return (
    <div className={styles.ListWrapper}>
      {isLoading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      {children}
    </div>
  )
}

export default CommonListWrapperWithLoader
