import { TimingFunction } from '@/core/animations/timing.functions'

export type DrawFunction = (progress: number) => void

interface Animate {
  timing: TimingFunction
  draw: DrawFunction
  duration: number
}

export const animate = ({ timing, draw, duration }: Animate) => {
  const start = performance.now()

  return requestAnimationFrame(function animate(time) {
    // timeFraction изменяется от 0 до 1
    let timeFraction = (time - start) / duration
    if (timeFraction > 1) timeFraction = 1

    // вычисление текущего состояния анимации
    const progress = timing(timeFraction)

    draw(progress) // отрисовать её

    if (timeFraction < 1) {
      requestAnimationFrame(animate)
    }
  })
}
