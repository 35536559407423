import { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios'
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import ApiUtils from './api.utils'
import { getFromLocalStorage } from '@/core/utils'
import apiHelper from '@/core/api/api'

export interface ApiQuery {
  baseUrl: string
}

export interface ApiBaseQueryArgs {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers']
}

export const apiBaseQuery =
  ({ baseUrl }: ApiQuery = { baseUrl: '/' }): BaseQueryFn<ApiBaseQueryArgs, unknown, unknown> =>
  async (args) => {
    const headers = args.headers || {}
    const url = baseUrl + args.url

    const token = getFromLocalStorage(ApiUtils.accessTokenKey)

    if (token) headers.Authorization = `Bearer ${token}`

    try {
      // const result = await axios({ ...args, url, headers })
      const result = await apiHelper.request({ ...args, url, headers })

      return { data: result.data }
    } catch (e) {
      const error = { status: 0, data: 'Unknown error' }

      if (isAxiosError(e)) {
        const response = e.response as AxiosResponse | undefined

        if (response && response.status === 401) {
          //  todo: logic for unAuthorized
        }

        if (response) {
          error.status = response.status || 0
          error.data = response.data || e.message
        }
      }

      return { error }
    }
  }

type JSONValue = string | number | boolean | { [x: string]: JSONValue } | Array<JSONValue> | null

interface JSONObject {
  [x: string]: JSONValue
}

export function isApiBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return (
    typeof error === 'object' &&
    error != null &&
    'status' in error &&
    'data' in error &&
    ['object', 'string'].includes(typeof (error as JSONObject).data)
  )
}

export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as JSONObject).message === 'string'
  )
}
