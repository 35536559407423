import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import TvChannelCardInner from '@/modules/tv/components/TvChannelCard/TvChannelCardInner'

import { ChannelModel } from '@/models/channel.model'

export interface TvChannelCardProps
  extends Omit<DefaultCardProps<ChannelModel>, 'children' /*| 'onCardFocus'*/> {
  deletable?: boolean
  deleting?: boolean
}

const TvChannelCard: FC<TvChannelCardProps> = ({
  onCardFocus,
  deleting,
  card,
  deletable,
  ...props
}) => {
  return (
    <DefaultCard<ChannelModel>
      className={styles.TvChannelCard}
      wrapperClassName={styles.TvChannelCardWrapper}
      onCardFocus={onCardFocus}
      focusClassName={classNames({
        [styles.FocusWrapper]: deletable,
      })}
      card={card}
      {...props}
    >
      <TvChannelCardInner card={card} deletable={deletable} deleting={deleting} />
    </DefaultCard>
  )
}

export default TvChannelCard
