import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { ContentType } from '@/models/content.model'

// ------------------------ CONTENT_TYPES

export const setTypesFilter = createAction(
  'searchSlice/setTypesFilter',
  withPayloadType<PageUpdatePayload<ContentType>>(),
)

export const resetTypesFilter = createAction(
  'searchSlice/resetTypesFilter',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
