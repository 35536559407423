import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { TagModel } from '@/models/tag.model'

// ------------------------ TAGS
export const addTagToTagsFilter = createAction(
  'searchSlice/addTagToTagsFilter',
  withPayloadType<PageUpdatePayload<TagModel>>(),
)
export const removeTagFromTagsFilter = createAction(
  'searchSlice/removeTagFromTagsFilter',
  withPayloadType<PageUpdatePayload<TagModel>>(),
)

export const setTagsFilter = createAction(
  'searchSlice/setTagsFilter',
  withPayloadType<PageUpdatePayload<TagModel[]>>(),
)

export const resetTagsFilter = createAction(
  'searchSlice/resetTagsFilter',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
