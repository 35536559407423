import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import dayjs from 'dayjs'

import SkeletonView from '@/components/SkeletonView'

import { useAppSelector } from '@/core/store'

import { ProgramBaseModel } from '@/models/program.model'
import { ProgramStatuses } from '@/modules/tv/components/ProgramListItemCard/index'

const getStatusIcon = (status?: ProgramStatuses) => {
  if (!status) return null

  if (status === 'live') {
    return <i className={'icon-fill-play'} />
  }

  if (status === 'archive') {
    return <i className={'icon-line-clock-back'} />
  }

  if (status === 'future') {
    return null
    // return <i className={'icon-notifications'} />
  }
}

interface ProgramListItemCardInnerProps {
  card?: ProgramBaseModel
  programStatus?: ProgramStatuses
}

const ProgramListItemCardInner: FC<ProgramListItemCardInnerProps> = ({ card, programStatus }) => {
  const currentProgram = useAppSelector((state) => state.tv.currentProgram)
  return (
    <div
      className={classNames(
        styles.ProgramListItemCardInner,
        currentProgram?.id === card?.id && styles.ProgramListItemCardInner__Active,
      )}
    >
      <div className={styles.Icon}>{getStatusIcon(programStatus)}</div>
      <div className={styles.Time}>
        {dayjs(card?.startTime).format('HH:mm') || <SkeletonView />}
      </div>
      <div className={styles.Title}>{card?.title || <SkeletonView />}</div>
      <div className={styles.Description}>{card?.description}</div>
    </div>
  )
}

export default ProgramListItemCardInner
