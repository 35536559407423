import React, { FC, memo, useCallback } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'
import FavoritesChannelsAction from '@/modules/favorites/components/FavoritesChannelsAction'
import ProgramListAction from '@/modules/tv/components/ProgramListAction'
import BackToLiveAction from '@/modules/tv/components/BackToLiveAction'
import PrevProgramAction from '@/modules/tv/components/PrevProgramAction'
import NextActionProgram from '@/modules/tv/components/NextProgramAction'
import ChannelsListSidebarAction from '@/modules/tv/components/ChannelsListSidebarAction'

import { useAppSelector } from '@/core/store'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'
import PlayerManager from '@/modules/player/player.manager'

import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

interface ChannelPlayerOverlayActionsProps {
  changeProgress?: (value: string | number) => void
  togglePlayPause?: () => Promise<void>
  isPaused: boolean
  isLive?: boolean
  setUserActivity?: (active: boolean) => void
  changeDisableOverlay?: (value: boolean) => void
}

const ChannelPlayerOverlayActions: FC<ChannelPlayerOverlayActionsProps> = ({
  togglePlayPause,
  isPaused,
  changeProgress,
  isLive,
  setUserActivity,
  changeDisableOverlay,
}) => {
  const handleReplay = useCallback(() => {
    if (!changeProgress) return
    changeProgress(0)
  }, [changeProgress])

  const handlePlayPause = useCallback(() => {
    if (!togglePlayPause) return
    togglePlayPause()
  }, [togglePlayPause])

  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isUserActive = usePlayerStateSelector((state) => state.userActivity.isUserActive)

  const handleOnTrigger = useCallback(() => {
    if (!setUserActivity || !changeDisableOverlay) return

    setUserActivity(false)
    changeDisableOverlay(true)
  }, [setUserActivity, changeDisableOverlay])

  const handleOnClose = useCallback(() => {
    if (!setUserActivity || !changeDisableOverlay) return

    setUserActivity(true)
    changeDisableOverlay(false)
  }, [setUserActivity, changeDisableOverlay])

  return (
    <div className={styles.Actions}>
      {!isLive && (
        <Button
          disabled={!isUserActive && !isPaused}
          onButtonClick={handlePlayPause}
          onEnterPress={handlePlayPause}
          buttonClassName={styles.Button}
        >
          <>
            <i className={isPaused ? 'icon-fill-play' : 'icon-fill-pause'} />
            <span data-title={'title'}>{isPaused ? 'Продолжить' : 'Остановить' + ''}</span>
          </>
        </Button>
      )}
      <ChannelsListSidebarAction
        withText
        triggerClassName={styles.Button}
        shouldCloseOnChannelPress={false}
        onTriggerCallback={handleOnTrigger}
        onCloseCallback={handleOnClose}
      />
      <ProgramListActionWrapper disabled={!isUserActive && !isPaused} className={styles.Button} />
      {!isLive && (
        <Button
          disabled={!isUserActive && !isPaused}
          onButtonClick={handleReplay}
          onEnterPress={handleReplay}
          buttonClassName={styles.Button}
        >
          <>
            <i className='icon-fill-at-first' />
            <span data-title={'title'}>Смотреть сначала</span>
          </>
        </Button>
      )}
      {!isLive && (
        <BackToLiveAction disabled={!isUserActive && !isPaused} className={styles.Button} />
      )}
      <PrevProgramAction disabled={!isUserActive && !isPaused} className={styles.Button} />
      <NextActionProgram disabled={!isUserActive && !isPaused} className={styles.Button} />
      <FavoritesChannelsAction
        disabled={!isUserActive && !isPaused}
        withText
        className={styles.Button}
      />
    </div>
  )
}

interface ProgramListActionWrapperProps {
  className?: string
  disabled?: boolean
}

const ProgramListActionWrapper = ({ className, disabled }: ProgramListActionWrapperProps) => {
  const liveProgram = useAppSelector((state) => state.tv.liveProgram)

  if (!liveProgram) return null
  return (
    <ProgramListAction
      disabled={disabled}
      appendToId={'player_overlay'}
      triggerClassName={className}
      showIcon
    />
  )
}

export default memo(ChannelPlayerOverlayActions)
