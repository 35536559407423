// import React from 'react'

export const zeroStyle = '0%'

export function toPercentString(value: number, maxValue: number): string {
  const attempt = value / maxValue
  if (
    maxValue === Infinity ||
    value === Infinity ||
    maxValue === 0 ||
    isNaN(attempt) ||
    attempt === 0
  ) {
    return zeroStyle
  } else {
    return `${(Math.min(1, attempt) * 100).toFixed(3)}%`
  }
}

// export function getKeyboardShortcutBlocker(
//   keysToBeBlocked: Array<string>,
// ): (keyboardEvent: React.KeyboardEvent<HTMLDivElement>) => void {
//   return (keyboardEvent: React.KeyboardEvent<HTMLDivElement>) => {
//     if (keysToBeBlocked.indexOf(keyboardEvent.key) >= 0) {
//       keyboardEvent.preventDefault()
//       keyboardEvent.stopPropagation()
//     }
//   }
// }

interface Coordinates {
  x: number
  y: number
  width: number
  height: number
}

export function getBoundingEventCoordinates(evt: Any, element?: HTMLElement): Coordinates {
  // Difficult to code this with strict typing in a JS-optimal way. Sticking to any for the event.
  const domRect = (element || evt.currentTarget).getBoundingClientRect()
  let extractedEvent
  if (evt.touches && evt.touches.length > 0) {
    extractedEvent = evt.touches[0]
  } else if (evt.changedTouches && evt.changedTouches.length > 0) {
    extractedEvent = evt.changedTouches[0]
  } else {
    extractedEvent = evt
  }
  return {
    x: Math.max(0, Math.min(extractedEvent.pageX - domRect.left, domRect.width)),
    y: Math.max(0, Math.min(extractedEvent.pageY - domRect.top, domRect.height)),
    width: domRect.width,
    height: domRect.height,
  }
}
