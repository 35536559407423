import React, { memo, useEffect } from 'react'

import { useAppSelector } from '@/core/store'

import DefaultLayout from '@/layouts/default'
import ApproveLayout from '@/layouts/approve'

import { useNavigate } from 'react-router-dom'

import { useNavigateToRootAfterResetState } from '@/modules/navigateToRootAfterResetState/hooks/useNavigateToRootAfterResetState'

import { ContentPaths, Paths } from '@/core/router/router.paths'

const ApproveResolver = () => {
  useNavigateToRootAfterResetState()

  const navigate = useNavigate()

  const user = useAppSelector((state) => state.auth.auth.data.loginData.user)
  const roles = user?.roles

  // todo: change role type
  const role = roles?.find((role) => role.type === 'SELLER_APPROVE')

  useEffect(() => {
    if (!role) return
    navigate(`${Paths.CONTENT}/${ContentPaths.TV}`)
  }, [role])
  if (!user) return null

  if (role) {
    return <ApproveLayout />
  }
  if (!user || !roles || roles.length === 0) return null

  return <DefaultLayout />
}

export default memo(ApproveResolver)
