import React from 'react'

import { ContentModel } from '@/models/content.model'

import imdbLogo from '@/assets/images/partners/imdb.svg'
import ContentRating from '@/modules/content/components/ContentRating'

interface ImdbRatingProps {
  card?: ContentModel
  className?: string
}

const ImdbRating = ({ card, className }: ImdbRatingProps) => {
  if (!card || !card.ratingImdb) return null
  return <ContentRating rating={card.ratingImdb} logo={imdbLogo} className={className} />
}

export default ImdbRating
