export enum Paths {
  ROOT = '/',
  AUTH = '/auth',
  CONTENT = '/content',
  CABINET = '/cabinet',
}

export enum AuthPaths {
  GREETING = 'greeting',
  LOGIN_CHOICE = 'choice',
  PROFILE_CHOICE = 'profilechoice',
  LOGIN = 'login',
  AGREEMENT = 'agreement',
  SIGNUP = 'signup',
  QR_LOGIN = 'qr',
  TG_LOGIN = 'tg',
  SESSIONS = 'sessions',
}

export enum ContentPaths {
  HOME = 'home',
  SEARCH = 'search',
  TV = 'tv',
  MOVIES = 'movies',
  SERIALS = 'serials',
  FAVORITES = 'favorites',
  SPORT = 'sport',
  CHILDREN = 'children',
  GENRES = 'genres',
  TAGS = 'tags',
  PARTNERS = 'partners',
  MOVIE_PLAYER = 'movie-player',
  SERIAL_PLAYER = 'serial-player',
  TRAILER_PLAYER = 'trailer-player',
  VIEWS = 'views',
  NOTIFICATIONS = 'notifications',
}

export enum CabinetPaths {
  PROFILE = 'profile',
  SUBSCRIPTIONS = 'subscriptions',
  DEVICES = 'devices',
  PAYMENT = 'payment',
  STATISTICS = 'statistics',
  NOTIFICATIONS = 'notifications',
  SUPPORT = 'support',
  LEARN_MASTER = 'learn-master',
}

export enum SubscriptionsPaths {
  All = 'all',
  CLIENT = 'client',
  CURRENT = ':id',
  ERIP = ':id/erip',
}

export enum PaymentsPaths {
  HISTORY = 'history',
  CARDS = 'cards',
  ADD_CARD = 'add_card',
}

export enum SupportPaths {
  TECH_INFO = 'tech_info',
}
