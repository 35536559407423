import { NotificationModel, NotificationModes } from '@/models/notification.model'
import { modal, toast } from '@/components/Notifications'
import { TypeOptions } from '@/components/Notifications/types'
import PlatformService from '@/core/services/platform.service'
import { Platform } from '@/models/platform.model'

const showNotificationToast = (notification: NotificationModel) => {
  toast({
    type: notification.variant.toLowerCase() as TypeOptions,
    title: notification.title,
    message: notification.description,
    delay: 2,
  })
}

const showNotificationModal = (notification: NotificationModel) => {
  modal({
    notification,
  })
}

export const notificationShow = (notification: NotificationModel) => {
  if (!notification) return

  if (notification.mode === NotificationModes.TOAST) {
    showNotificationToast(notification)
    return
  }

  if (notification.mode === NotificationModes.MODAL) {
    showNotificationModal(notification)
    return
  }
}

export const shouldShowNotification = (notification: NotificationModel): boolean => {
  if (!notification) return false

  const platforms = notification.platforms
  if (!platforms) return true
  if (platforms.length === 0) return true

  const platform = PlatformService.platform
  if (!platform || platform === Platform.LOCAL) return true

  return !!platforms.find((el) => el.type === platform)
}
