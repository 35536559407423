import React, { FC } from 'react'

import styles from './styles.module.scss'

import Checkbox from '@/components/Checkbox'
import Sidebar from '@/components/Sidebar'
import Button from '@/components/Button'
import ListWithScrollbar from '@/components/ListWithScrollbar'

import { PreparedLanguage } from '@/modules/player/player.url.manager'

import { saveToLocalStorage } from '@/core/utils'
import { PREFERRED_LANGUAGE } from '@/core/config'

interface LanguagesProps {
  language: PreparedLanguage | null
  languages: PreparedLanguage[]
  changeLanguage: (language: PreparedLanguage) => void
  closeMainOverlay?: () => void
}

const Languages: FC<LanguagesProps> = ({
  language,
  languages,
  closeMainOverlay,
  changeLanguage,
}) => {
  return (
    <Sidebar
      appendToId={'player_overlay'}
      backgroundClassName={styles.BgTransparent}
      trigger={(onTrigger) => (
        <Button
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
          buttonClassName={styles.ActionButton}
          focusClassName={styles.ActionButton__Focused}
          onArrowPress={(direction) => {
            if (direction === 'right') {
              onTrigger && onTrigger()
              return false
            }
            if (direction === 'left') {
              closeMainOverlay && closeMainOverlay()
              return false
            }
            return true
          }}
        >
          <>
            <span>Озвучка</span>
            <span>{language?.label}</span>
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <div className={styles.Settings}>
            <h4>Озвучка</h4>
            <ListWithScrollbar className={styles.List}>
              {languages.map((lang) => {
                const handleClick = () => {
                  saveToLocalStorage(PREFERRED_LANGUAGE, lang.label)
                  changeLanguage(lang)
                  // onClose && onClose()
                }
                return (
                  <Checkbox
                    rounded
                    key={lang.id}
                    name={lang.label}
                    checked={lang.id === language?.id}
                    onEnterPress={handleClick}
                    onClick={handleClick}
                    onArrowPress={(direction) => {
                      if (direction === 'left') {
                        onClose && onClose()
                        return false
                      }
                      return true
                    }}
                  />
                )
              })}
            </ListWithScrollbar>
          </div>
        )
      }}
    />
  )
}

export default Languages
