import {
  useGetPartnersPathBySerialIdAndByEpisodeIdQuery,
  useGetStreamsBySerialIdAndByEpisodeIdQuery,
} from '@/modules/streams/streams.api'
import { EpisodeBaseModel, SerialModel } from '@/models/serial.model'
import { useEffect, useMemo, useState } from 'react'
import { getStreams } from '@/modules/seasonvar/seasonvar.service'
import { StreamsModel } from '@/models/streams.model'
import { PartnerValue } from '@/models/partner.model'
import { SHOULD_GET_SEASONVAR_STREAMS_FROM_FRONT } from '@/core/config'

interface Args {
  episode?: EpisodeBaseModel | null
  serial?: SerialModel
  token?: string
  disabledByRights?: boolean
}

export const useGetStreams = ({ episode, serial, disabledByRights, token }: Args) => {
  const { data, isLoading, isFetching, error } = useGetStreamsBySerialIdAndByEpisodeIdQuery(
    {
      episodeId: episode?.id || '',
      serialId: serial?.serial?.id || '',
      token: token,
    },
    {
      skip:
        !episode ||
        !episode.id ||
        !serial ||
        !serial.serial ||
        (serial.partner?.type === PartnerValue.SEASONVAR &&
          SHOULD_GET_SEASONVAR_STREAMS_FROM_FRONT) ||
        !serial.serial.id ||
        disabledByRights,
    },
  )

  const {
    data: paths,
    isLoading: pathsLoading,
    isFetching: pathsFetching,
    error: pathsError,
  } = useGetPartnersPathBySerialIdAndByEpisodeIdQuery(
    {
      episodeId: episode?.id || '',
      serialId: serial?.serial?.id || '',
      token: token,
    },
    {
      skip:
        !episode ||
        !episode.id ||
        !serial ||
        !serial.serial ||
        (!SHOULD_GET_SEASONVAR_STREAMS_FROM_FRONT &&
          serial.partner?.type === PartnerValue.SEASONVAR) ||
        (serial.partner?.type !== PartnerValue.SEASONVAR &&
          serial.partner?.type !== PartnerValue.PREMIER) ||
        !serial.serial.id ||
        disabledByRights,
    },
  )

  const { 0: streams, 1: setStreams } = useState<StreamsModel[]>()
  const { 0: streamsError, 1: setStreamsError } = useState<unknown>()
  const { 0: streamsLoading, 1: setStreamsLoading } = useState<boolean>(false)

  useEffect(() => {
    if (!paths) return
    if (!serial) return
    if (serial.partner?.type !== PartnerValue.SEASONVAR) return

    setStreams(undefined)
    setStreamsError(undefined)
    setStreamsLoading(true)

    const { partnerPlaybackPath } = paths

    getStreams(partnerPlaybackPath)
      .then((res) => {
        if ('message' in res) {
          setStreamsError(res)
        }

        setStreams(res as unknown as StreamsModel[])
      })
      .finally(() => {
        setStreamsLoading(false)
      })
  }, [paths])

  return useMemo(() => {
    return {
      streams: data || streams,
      streamsLoading: isLoading || pathsLoading || streamsLoading,
      streamsFetching: isFetching || pathsFetching || streamsLoading,
      streamsError: error || streamsError || pathsError,
      partnerPlaybackPath: paths?.partnerPlaybackPath,
    }
  }, [
    data,
    streams,
    isLoading,
    isFetching,
    error,
    streamsError,
    pathsLoading,
    pathsFetching,
    streamsLoading,
    paths,
    pathsError,
  ])
}
