import { AppInfo, NetworkInfo, ProductInfo, SystemInfo } from '@/models/platform.model'

enum NetworkStateValues {
  INTERNET_CONNECTED = 0,
  LAN_CABLE_ATTACHED = 1,
  LAN_CABLE_DETACHED = 2,
  LAN_CABLE_STATE_UNKNOWN = 3,
  GATEWAY_CONNECTED = 4,
  GATEWAY_DISCONNECTED = 5,
  WIFI_MODULE_STATE_ATTACHED = 6,
  WIFI_MODULE_STATE_DETACHED = 7,
  WIFI_MODULE_STATE_UNKNOWN = 8,
  INTERNET_DISCONNECTED = 9,
}
interface NetworkState {
  INTERNET_CONNECTED: NetworkStateValues.INTERNET_CONNECTED
  LAN_CABLE_ATTACHED: NetworkStateValues.LAN_CABLE_ATTACHED
  LAN_CABLE_DETACHED: NetworkStateValues.LAN_CABLE_DETACHED
  LAN_CABLE_STATE_UNKNOWN: NetworkStateValues.LAN_CABLE_STATE_UNKNOWN
  GATEWAY_CONNECTED: NetworkStateValues.GATEWAY_CONNECTED
  GATEWAY_DISCONNECTED: NetworkStateValues.GATEWAY_DISCONNECTED
  WIFI_MODULE_STATE_ATTACHED: NetworkStateValues.WIFI_MODULE_STATE_ATTACHED
  WIFI_MODULE_STATE_DETACHED: NetworkStateValues.WIFI_MODULE_STATE_DETACHED
  WIFI_MODULE_STATE_UNKNOWN: NetworkStateValues.WIFI_MODULE_STATE_UNKNOWN
  INTERNET_DISCONNECTED: NetworkStateValues.INTERNET_DISCONNECTED
}

export interface TizenAppInfoResponse {
  id: string
  name: string
  iconPath: string
  version: string
  show: boolean
  categories: string[]
  installDate: string
  size: number
  packageId: string
}

enum NetworkActiveConnectionType {
  DISCONNECTED = 0,
  WIFI = 1,
  CELLULAR = 2,
  ETHERNET = 3,
}

interface TizenPushServiceOptions {
  events?: {
    onPushRegisterError?: ({ name }: { name: string }) => void
    onPushRegisterSuccess?: (id: string) => void

    onPushConnectError?: ({ name }: { name: string }) => void
    onPushConnectSuccess?: (id: string) => void

    onPushNotification?: (payload: Any) => void
  }
}

export interface TizenSetupOptions {
  pushService?: TizenPushServiceOptions
}

const { log } = console

export default class TizenService {
  static setup() {
    const { tizen } = window

    tizen.tvinputdevice.registerKey('ChannelUp')
    tizen.tvinputdevice.registerKey('ChannelDown')
    tizen.tvinputdevice.registerKey('0')
    tizen.tvinputdevice.registerKey('1')
    tizen.tvinputdevice.registerKey('2')
    tizen.tvinputdevice.registerKey('3')
    tizen.tvinputdevice.registerKey('4')
    tizen.tvinputdevice.registerKey('5')
    tizen.tvinputdevice.registerKey('6')
    tizen.tvinputdevice.registerKey('7')
    tizen.tvinputdevice.registerKey('8')
    tizen.tvinputdevice.registerKey('9')
    tizen.tvinputdevice.registerKey('MediaTrackPrevious')
    tizen.tvinputdevice.registerKey('MediaTrackNext')

    tizen.tvinputdevice.registerKey('MediaPlayPause')
    tizen.tvinputdevice.registerKey('MediaPlay')
    tizen.tvinputdevice.registerKey('MediaStop')
    tizen.tvinputdevice.registerKey('MediaPause')
    tizen.tvinputdevice.registerKey('MediaRewind')
    tizen.tvinputdevice.registerKey('MediaFastForward')
  }

  static #connectPushService(options?: TizenPushServiceOptions) {
    const events = options ? options.events : null

    function errorRegisterCallback(error: { name: string }) {
      if (events) events.onPushRegisterError?.(error)
    }

    function errorConnectCallback(error: { name: string }) {
      if (events) events.onPushConnectError?.(error)
    }

    function registerSuccessCallback(id: string) {
      if (events) events.onPushRegisterSuccess?.(id)
    }

    function stateChangeCallback(state: 'REGISTERED' | 'UNREGISTERED') {
      if (state === 'UNREGISTERED') {
        window.tizen.push.register(registerSuccessCallback, errorRegisterCallback)
      } else if (state === 'REGISTERED') {
        const registrationId = window.tizen.push.getRegistrationId()
        if (registrationId != null) {
          if (events) events.onPushConnectSuccess?.(registrationId)
        }
        window.tizen.push.getUnreadNotifications()
      }
    }

    function notificationCallback(notification: Any) {
      if (events) events.onPushNotification?.(notification)
    }

    try {
      window.tizen.push.connect(stateChangeCallback, notificationCallback, errorConnectCallback)
    } catch (error) {
      log(error)
    }
  }

  static volumeUp() {
    window.tizen.tvaudiocontrol.setVolumeUp()
  }

  static volumeDown() {
    window.tizen.tvaudiocontrol.setVolumeDown()
  }

  static exit() {
    const app = window.tizen.application.getCurrentApplication()
    app.exit()
  }

  static disconnect() {
    window.tizen.push.disconnect()
  }

  static fetchAppInfo(): Promise<AppInfo> {
    return new Promise((resolve, reject) => {
      if (window.tizen.application) {
        try {
          const app = window.tizen.application.getAppInfo()
          resolve({
            id: app.id,
            title: app.name,
            icon: app.iconPath,
            version: app.version,
          })
        } catch (e) {
          /* empty */
        }
      } else {
        reject(new Error('tizen.application is not define'))
      }
    })
  }

  static fetchProductInfo(): Promise<ProductInfo> {
    return new Promise((resolve, reject) => {
      const product = {} as ProductInfo

      if (window.webapis.productinfo) {
        try {
          product.uid = window.webapis.productinfo.getDuid()
        } catch (e) {
          /* empty */
        }

        try {
          product.model = window.webapis.productinfo.getModel()
        } catch (e) {
          /* empty */
        }

        try {
          product.model = window.webapis.productinfo.getModel()
        } catch (e) {
          /* empty */
        }

        try {
          product.firmwareVersion = window.webapis.productinfo.getFirmware()
        } catch (e) {
          /* empty */
        }

        try {
          product.isUdPanel = window.webapis.productinfo.isUdPanelSupported()
        } catch (e) {
          /* empty */
        }

        try {
          const noGlass3dsupport = window.webapis.productinfo.getNoGlass3dSupport()
          product.isNoGlass3d = noGlass3dsupport.NO_GLASS_3D_SUPPORTED
        } catch (e) {
          /* empty */
        }

        try {
          product.psid = window.webapis.productinfo.getPsid()
        } catch (e) {
          /* empty */
        }
      } else {
        reject(new Error('tizen.productinfo is not define'))
      }

      if (window.webapis.avinfo) {
        try {
          product.isHdr = window.webapis.avinfo.isHdrTvSupport()
        } catch (e) {
          /* empty */
        }
      } else {
        reject(new Error('tizen.avinfo is not define'))
      }

      resolve(product)
    })
  }

  static fetchSystemInfo(): Promise<SystemInfo> {
    return new Promise((resolve, reject) => {
      const system = {} as SystemInfo

      if (window.tizen.systeminfo) {
        try {
          const url = 'http://tizen.org/feature/platform.version'
          system.version = window.tizen.systeminfo.getCapability(url)
        } catch (e) {
          /* empty */
        }

        resolve(system)
      } else {
        reject(new Error('tizen.systeminfo is not define'))
      }
    })
  }

  static fetchNetworkInfo(): Promise<NetworkInfo> {
    return new Promise((resolve, reject) => {
      const network = {} as NetworkInfo

      if (window.webapis.network) {
        try {
          network.mac = window.webapis.network.getMac()
        } catch (e) {
          /* empty */
        }

        try {
          network.ip = window.webapis.network.getIp()
        } catch (e) {
          /* empty */
        }

        try {
          network.dns = window.webapis.network.getDns()
        } catch (e) {
          /* empty */
        }

        try {
          const connectionType = window.webapis.network.getActiveConnectionType()
          if (connectionType === NetworkActiveConnectionType.WIFI) {
            network.wifi = {
              ssid: window.webapis.network.getWiFiSsid(),
              signalStrengthLevel: window.webapis.network.getWiFiSignalStrengthLevel(),
            }
          }
        } catch (e) {
          /* empty */
        }

        resolve(network)
      } else {
        reject(new Error('tizen.network is not define'))
      }
    })
  }

  static subscribeToNetworkInfo(cb: (isOnline: boolean) => void) {
    const net = window.webapis.network
    if (!net) return

    net.addNetworkStateChangeListener((value: NetworkStateValues) => {
      const networkState = net.NetworkState as NetworkState
      if (value === networkState.INTERNET_CONNECTED) {
        // Something you want to do when network is disconnected
        cb(true)
      } else if (value === networkState.INTERNET_DISCONNECTED) {
        // Something you want to do when network is connected again
        cb(false)
      }
    })
  }

  static async fetchInfo() {
    const results = await Promise.allSettled([
      TizenService.fetchAppInfo(),
      TizenService.fetchProductInfo(),
      TizenService.fetchSystemInfo(),
      TizenService.fetchNetworkInfo(),
    ])

    const [app, product, system, network] = results.reduce(
      (accumulator, currentValue) => {
        if (currentValue.status === 'fulfilled') accumulator.push(currentValue.value)
        else accumulator.push({})

        return accumulator
      },
      [] as unknown as [
        Partial<AppInfo>,
        Partial<ProductInfo>,
        Partial<SystemInfo>,
        Partial<NetworkInfo>,
      ],
    )

    return { app, product, system, network }
  }
}
