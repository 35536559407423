import { PlatformModel } from '@/models/platform.model'

export enum NotificationModes {
  TOAST = 'TOAST',
  MODAL = 'MODAL',
  ALERT = 'ALERT',
}

export enum NotificationVariants {
  DEFAULT = 'DEFAULT',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
}

export enum NotificationTypes {
  DEFAULT = 'DEFAULT',
  OFFER = 'OFFER',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export interface ClientNotificationModel {
  id: string
  createdAt: string
  updatedAt: string

  expiredAt: string | null
  viewedAt: string | null
  readedAt: string | null
  deletedAt: string | null
}

export interface TemplateModel {
  id: string
  createdAt: string
  updatedAt: string
  sourceBaseUrl: string
  sourcePath: string
}

export interface NotificationModel {
  type: NotificationTypes
  mode: NotificationModes
  variant: NotificationVariants

  template?: TemplateModel

  title: string
  description: string

  actions?: { label: string; action: string }[]

  isAutoShow: boolean
  isForceShow: boolean

  clientNotification: ClientNotificationModel

  platforms?: PlatformModel[]

  id: string
  createdAt: string
  updatedAt: string
}
