import React, { ReactNode, useContext } from 'react'
import { PlayerIDContext } from '@/modules/player/player.id.context'

interface PlayerIDProviderProps {
  value: string
  children: ReactNode
}

export const PlayerIDProvider = ({ value, children }: PlayerIDProviderProps) => {
  if (!value) throw new Error('PlayerID should contain minimum 1 char!')
  return <PlayerIDContext.Provider value={value}>{children}</PlayerIDContext.Provider>
}

export const usePlayerIdContext = () => {
  const id = useContext(PlayerIDContext)

  if (!id) throw new Error('You cannot use PlayerIdContext hook outside of PlayerIDProvider')

  return id
}
