import React, { FC, useRef } from 'react'

import Range from '@/components/Range'

import TimelineTooltip from './TimelineTooltip'

import styles from './styles.module.scss'

import { formatTime } from '@/modules/player/helpers/common.helper'
import { useHotReload } from '@/core/store/hooks'

interface TimelineProps {
  currentTime: number
  duration: number
  isBuffering?: boolean
  togglePlayPause?: () => Promise<void>
  onChange: (value: number) => void
  blockPointerEvents?: (value: boolean) => void
}

const Timeline: FC<TimelineProps> = ({
  currentTime,
  duration,
  onChange,
  isBuffering,
  togglePlayPause,
  blockPointerEvents,
}) => {
  const isDraggingRef = useRef<boolean>(false)
  const timeoutIdRef = useRef<NodeJS.Timeout>()
  const hotReload = useHotReload()

  const dragValueRef = useRef<number>(currentTime)

  const resetTimerAndStartNewTimer = () => {
    //  Reset timer
    blockPointerEvents?.(false)

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined
    }
    // Create new timer
    timeoutIdRef.current = setTimeout(() => {
      isDraggingRef.current = false
      clearTimeout(timeoutIdRef.current)
    }, 1000)
  }

  const computedValue = () => {
    if (isDraggingRef.current) {
      return dragValueRef.current
    }
    return currentTime
  }

  const handleDrag = (value: number) => {
    dragValueRef.current = value
    hotReload()
    if (!isDraggingRef.current) isDraggingRef.current = true
    blockPointerEvents?.(true)
  }

  return (
    <Range
      togglePlayPause={togglePlayPause}
      value={computedValue()}
      maxValue={duration}
      onValueChange={onChange}
      isUpdateBlocked={isBuffering || isDraggingRef.current}
      onDrag={handleDrag}
      onDragEnd={resetTimerAndStartNewTimer}
      prependOuterContent={<Time time={currentTime} />}
      appendOuterContent={<Time time={duration} />}
    >
      <TimelineTooltip />
    </Range>
  )
}

export default Timeline

// Simple component for show currentTime, duration blocks
interface TimeProps {
  time: number
}

const Time: FC<TimeProps> = ({ time }) => <div className={styles.Time}>{formatTime(time)}</div>
