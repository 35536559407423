import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'

import { apiBaseQuery } from '@/core/api/api.query'
import { prepareChannelRelations } from '@/core/helpers'

import { GetCategoryByIdArgs, GetChannelsByCategoryId } from '@/modules/category/category.types'

import { CategoryModel } from '@/models/category.model'
import { ChannelModel } from '@/models/channel.model'

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/clients/me/categories/` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllCategories: builder.query<CategoryModel[], void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
    }),

    getCategoryById: builder.query<CategoryModel, GetCategoryByIdArgs>({
      query: ({ categoryId }) => ({
        url: `${categoryId}`,
        method: 'GET',
      }),
    }),

    getCategoriesHasChannels: builder.query<CategoryModel[], void>({
      query: () => ({
        url: 'has/channels',
        method: 'GET',
      }),
    }),

    getChannelsByCategoryId: builder.query<ChannelModel[], GetChannelsByCategoryId>({
      query: ({ categoryId, relations: rel = {} }) => {
        const relations = prepareChannelRelations(rel)

        const params = {
          relations,
        }

        return {
          url: `${categoryId}/channels`,
          method: 'GET',
          params,
        }
      },
    }),
  }),
})

export const {
  useGetAllCategoriesQuery,
  useGetCategoriesHasChannelsQuery,
  useGetCategoryByIdQuery,
  useGetChannelsByCategoryIdQuery,
} = categoryApi
