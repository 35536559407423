import React, { FC, ReactElement } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Slider, { SliderProps } from '@/components/Slider'
import CategoryCard from '@/modules/category/components/CategoryCard'

import { RowProps } from '@/components/Slider/Row'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { CategoryModel } from '@/models/category.model'

type RenderFn = () => ReactElement[]

export interface BaseCategorySliderProps<T> extends Omit<SliderProps, 'children'> {
  rowProps?: Omit<RowProps, 'children'>
  children?: RenderFn
  onCardPress?: (item: T) => void
  onCardFocus?: OnChildFocus
}
export interface DefaultCategorySliderProps extends BaseCategorySliderProps<CategoryModel> {
  data?: CategoryModel[]
  isLoading?: boolean
  skeletonItemsLength?: number
}

const DefaultCategorySlider: FC<DefaultCategorySliderProps> = ({
  rowProps,
  children,
  data,
  isLoading,
  skeletonItemsLength = 12,
  onCardFocus,
  onCardPress,
  ...sliderProps
}) => {
  if ((!data && isLoading) || !data) {
    return (
      <Slider className={styles.Wrapper}>
        <Slider.Horizontal
          {...rowProps}
          className={classNames(rowProps?.className, styles.ScrollingWrapper)}
        >
          {Array(skeletonItemsLength)
            .fill(1)
            .map((el, index) => {
              return <CategoryCard key={index} />
            })}
        </Slider.Horizontal>
      </Slider>
    )
  }
  return (
    <Slider className={styles.Wrapper} {...sliderProps}>
      <Slider.Horizontal
        {...rowProps}
        className={classNames(rowProps?.className, styles.ScrollingWrapper)}
      >
        {children
          ? children()
          : data?.map((el, index) => {
              return (
                <CategoryCard
                  key={el.id || index + el.pluralTitle}
                  card={el}
                  onCardFocus={onCardFocus}
                  onCardPress={onCardPress}
                />
              )
            })}
      </Slider.Horizontal>
    </Slider>
  )
}

export default DefaultCategorySlider
