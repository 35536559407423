import React from 'react'

import { RouteObject } from 'react-router-dom'

import EnterToApp from '@/modules/enterToApp'
import ErrorBoundary from '@/modules/errorBoundary'

export const defaultRoute: RouteObject = {
  path: '/',
  element: <EnterToApp />,
  errorElement: <ErrorBoundary />,
}
