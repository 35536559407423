import {
  SOCKET_AUTH_SESSION_URL,
  SOCKET_MEDIA_EVENT_URL,
  SOCKET_NOTIFICATIONS_URL,
  SOCKET_TV_EVENT_URL,
} from '@/core/config'

export const SocketUrls = {
  SESSIONS: SOCKET_AUTH_SESSION_URL,
  NOTIFICATIONS: SOCKET_NOTIFICATIONS_URL,
  TV_EVENTS: SOCKET_TV_EVENT_URL,
  MEDIA_EVENTS: SOCKET_MEDIA_EVENT_URL,
} as const
