import React, { FC, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

interface Props {
  onClose?: () => void
}

const SidebarCloseButton: FC<Props> = ({ onClose }) => {
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const id = setTimeout(() => {
      setDisabled(false)
      clearTimeout(id)
    }, 150)
  }, [])
  return (
    <Button
      focusWrapperClassName={styles.CloseButtonFocusWrapper}
      buttonClassName={styles.CloseButton}
      focusClassName={styles.FocusedCloseButton}
      onButtonClick={onClose}
      onEnterPress={onClose}
      onFocus={onClose}
      disabled={disabled}
    >
      <i className='icon-fill-chevron-left' />
    </Button>
  )
}

export default SidebarCloseButton
