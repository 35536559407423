import React, { useCallback } from 'react'

import styles from './styles.module.scss'
import ListWithScrollbar from '@/components/ListWithScrollbar'
import SessionCard from '@/modules/auth/components/SessionCard'
import { SessionModel } from '@/models/session.model'
import { useLocation, useNavigate } from 'react-router-dom'

import warning from '@/assets/images/warning.svg'
import ImageLoader from '@/components/Image'
import { Paths } from '@/core/router/router.paths'
import { useAppDispatch } from '@/core/store'
import { removeSessionById } from '@/modules/auth/auth.actions'
import { toast } from '@/components/Notifications'

const Sessions = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const {
    state: { sessions },
  }: {
    state: {
      sessions: SessionModel[]
    }
  } = useLocation()

  const handleOnSessionPress = useCallback(
    (card: SessionModel) => {
      if (!card) return

      dispatch(removeSessionById({ sessionId: card.id }))
        .unwrap()
        .then(() => {
          navigate(Paths.ROOT)
        })
        .catch(() => {
          toast.error({
            title: 'Удаление сессии',
            message: 'Что-то пошло не так! Попробуйте снова',
          })
        })
    },
    [navigate],
  )

  return (
    <div className={styles.Sessions}>
      <div className={styles.Warning}>
        <ImageLoader src={warning} alt='warning' roundedSkeleton />
      </div>

      <hgroup className={styles.Header}>
        <h1>Вы превысили допустимое количество подключенных устройств</h1>
        <p>Отключите одно из устройств</p>
      </hgroup>

      <ListWithScrollbar className={styles.List} scrollWrapperClassName={styles.ScrollContainer}>
        {sessions?.map((session, index) => {
          return (
            <SessionCard
              autoFocus={index === 0}
              key={session.id}
              card={session}
              wrapperClassName={styles.CardWrapper}
              onCardPress={handleOnSessionPress}
            />
          )
        })}
      </ListWithScrollbar>
    </div>
  )
}

export default Sessions
