import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { ListProps } from '@/components/List'
import ListWithScrollbar from '@/components/ListWithScrollbar'
import Checkbox, { CheckboxProps } from '@/components/Checkbox'
import CommonListWrapperWithLoader from '@/modules/search/components/CommonListWrapperWithLoader'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { GenreModel } from '@/models/genre.model'
import { useGetGenresHasContentsQuery } from '@/modules/genre/genre.api'
import { useLocation } from 'react-router-dom'
import { ContentPaths } from '@/core/router/router.paths'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  addGenreToGenresFilter,
  removeGenreFromGenresFilter,
  resetGenresFilter,
  setGenresFilter,
} from '@/modules/search/slice/actions/searchState/searchState.genres.actions'

interface GenresListProps extends Omit<ListProps, 'children'> {
  chosenGenres?: GenreModel[]
  disabledGenres?: string[]
  pageName: PagesWithSearch
}

const GenresList: FC<GenresListProps> = ({
  className,
  chosenGenres,
  pageName,
  disabledGenres,
  ...rest
}) => {
  const { pathname } = useLocation()

  const { genres, loading } = useGetGenresHasContentsQuery(undefined, {
    selectFromResult: ({ data, isFetching, isLoading }) => {
      return {
        genres: data,
        loading: isLoading || isFetching,
      }
    },
  })

  const items = useMemo(() => {
    if (!genres) return []

    return [
      <DefaultGenreCheckBox
        key={'chooseAllGenres'}
        className={styles.DefaultCheckbox}
        autoFocus={true}
        chosenGenres={chosenGenres}
        pageName={pageName}
      />,
      ...genres.map((genre) => {
        return (
          <GenreCheckBox
            pageName={pageName}
            disabled={
              (pathname.includes(ContentPaths.GENRES) &&
                chosenGenres &&
                !!chosenGenres.find((el) => el.id === genre.id)) ||
              (disabledGenres && !!disabledGenres.find((el) => el === genre.id))
            }
            key={genre.id}
            genre={genre}
          />
        )
      }),
    ]
  }, [genres])

  if (!genres && !loading) return null

  return (
    <CommonListWrapperWithLoader isLoading={loading}>
      <ListWithScrollbar className={classNames(styles.GenresList, className)} {...rest}>
        {items}
      </ListWithScrollbar>
    </CommonListWrapperWithLoader>
  )
}

interface DefaultGenreCheckBoxProps extends CheckboxProps {
  chosenGenres?: GenreModel[]
  pageName: PagesWithSearch
}

const DefaultGenreCheckBox = (props: DefaultGenreCheckBoxProps) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const checked = useAppSelector(
    (state) =>
      state.searchByPageSlice[props.pageName].searchState.filters.genres.length === 0 ||
      !!(
        pathname.includes(ContentPaths.GENRES) &&
        props.chosenGenres &&
        props.chosenGenres.length ===
          state.searchByPageSlice[props.pageName].searchState.filters.genres.length
      ),
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (props.chosenGenres && pathname.includes(ContentPaths.GENRES)) {
        dispatch(setGenresFilter({ pageName: props.pageName, payload: props.chosenGenres }))
        return
      }

      if (checked) {
        dispatch(resetGenresFilter({ pageName: props.pageName }))
        return
      }
    },
    [props.chosenGenres],
  )

  return <Checkbox name={'Выбрать всё'} checked={checked} onChange={handleChange} {...props} />
}

interface GenreCheckBoxProps extends CheckboxProps {
  genre: GenreModel
  pageName: PagesWithSearch
}

const GenreCheckBox = ({ genre, ...props }: GenreCheckBoxProps) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) =>
      !!state.searchByPageSlice[props.pageName].searchState.filters.genres.find(
        (el) => el.id === genre.id,
      ),
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (!genre) return

      if (checked) {
        dispatch(addGenreToGenresFilter({ pageName: props.pageName, payload: genre }))
        return
      }

      dispatch(removeGenreFromGenresFilter({ pageName: props.pageName, payload: genre }))
    },
    [genre],
  )

  return <Checkbox name={genre.pluralTitle} checked={checked} onChange={handleChange} {...props} />
}

export default GenresList
