import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import ContentPlayer from '@/modules/content/components/ContentPlayer'
import MoviePlayerOverlayActions from '@/modules/films/components/MoviePlayerOverlayActions'
import ContentPlayerTitle from '@/modules/content/components/ContentPlayerTitle'
import StreamsErrorFallback from '@/components/StreamsErrorFallback'

import { useLocation } from 'react-router-dom'

import { useContentPlayerRequestExitFullscreen } from '@/modules/content/hooks/useContentPlayerRequestFullscreen'

import { DRM_CONFIG } from '@/core/drm.config'

import { MovieModel } from '@/models/movie.model'

import { PlayerProps } from '@/modules/player/Player'
import { useLazyContentRightsCheckQuery } from '@/modules/content/content.api'
import {
  useGetPartnersPathByMovieIdQuery,
  useGetStreamsByMovieIdQuery,
} from '@/modules/streams/streams.api'
import { useAppSelector } from '@/core/store'
import Loader from '@/components/Loader'
import { PartnerValue } from '@/models/partner.model'
import { useLazyGetOneContentMediaViewQuery } from '@/modules/syncMediaView/syncMediaView.api'
import { getProgressState } from '@/modules/syncMediaView/helpers'
import { isObject } from '@/models/rightsError.model'

interface MoviePlayerLocationState {
  movie: MovieModel
}

const MoviePlayerFullscreen = () => {
  const platform = useAppSelector((state) => state.app.platform)

  const {
    state: { movie },
  }: { state: MoviePlayerLocationState } = useLocation()

  const rightsParams = useMemo(() => {
    return {
      contentId: movie.id!,
    }
  }, [movie.id])

  const [
    getContentMediaView,
    { data: viewInfo, isLoading: contentMediaViewLoading, isFetching: contentMediaViewFetching },
  ] = useLazyGetOneContentMediaViewQuery()

  useEffect(() => {
    getContentMediaView(rightsParams)
  }, [rightsParams])

  const isContentMediaLoading = useMemo(() => {
    return contentMediaViewFetching || contentMediaViewLoading
  }, [contentMediaViewLoading, contentMediaViewFetching])

  const progressState = useMemo(() => {
    if (isContentMediaLoading) return

    return getProgressState(viewInfo)
  }, [viewInfo, isContentMediaLoading])

  const [rightsChecked, setRights] = useState<{ streamAccessToken: string } | undefined>(undefined)

  const [
    contentRightsCheck,
    { error: rightsError, isLoading: loadingRights, isFetching: rightsFetching, originalArgs },
  ] = useLazyContentRightsCheckQuery()

  useEffect(() => {
    if (!movie.id || !movie) return
    setRights(undefined)
    contentRightsCheck(rightsParams)
      .unwrap()
      .then((res) => {
        setRights(res)
      })
      .catch(console.error)
  }, [rightsParams])

  const {
    data: streams,
    isLoading: loadingStreams,
    isFetching: streamsFetching,
    error: streamsError,
  } = useGetStreamsByMovieIdQuery(
    { movieId: movie.movie!.id!, platform, token: rightsChecked?.streamAccessToken },
    {
      skip:
        !movie ||
        !movie.movie ||
        !movie.movie.id ||
        !rightsChecked ||
        !rightsChecked.streamAccessToken ||
        !!rightsError ||
        originalArgs?.contentId !== movie.id ||
        loadingRights,

      selectFromResult: ({ data, isLoading, isFetching, error }) => ({
        data,
        isLoading,
        isFetching,
        error,
      }),
    },
  )

  const { data: paths } = useGetPartnersPathByMovieIdQuery(
    {
      movieId: movie?.movie?.id || '',
      token: rightsChecked?.streamAccessToken || '',
    },
    {
      skip:
        movie.partner?.type !== PartnerValue.PREMIER ||
        loadingRights ||
        !!rightsError ||
        !rightsChecked ||
        originalArgs?.contentId !== movie.id ||
        !rightsChecked.streamAccessToken,
    },
  )

  const resolved404Error = useMemo(() => {
    if (!streamsError) return false

    if (!isObject(streamsError)) return false

    if (!('status' in streamsError && streamsError.status === 404)) return false

    return true
  }, [streamsError])

  const loading = useMemo(() => {
    return (
      loadingRights || rightsFetching || loadingStreams || streamsFetching || isContentMediaLoading
    )
  }, [loadingRights, rightsFetching, loadingStreams, streamsFetching, isContentMediaLoading])

  const { navigateToPreviousPage, navigateByDelta } = useContentPlayerRequestExitFullscreen()

  const playerProps: Partial<PlayerProps> = useMemo(() => {
    return {
      playerId: 'movie-player',
      className: classNames(styles.MoviePlayer),
      videoStreamerProps: {
        autoPlay: true,
        url: '',
      },
      videoState: {
        isPaused: false,
      },
      progressState,
      fullscreenState: {
        status: true,
      },
      videoManagerProps: {
        onEnded: navigateToPreviousPage,
      },
      overlayProps: {
        leftSlotRenderFn: (isPaused, togglePlayPause, changeProgress) => {
          return (
            <MoviePlayerOverlayActions
              isPaused={isPaused}
              togglePlayPause={togglePlayPause}
              changeProgress={changeProgress}
            />
          )
        },
        headerSlotRenderFn: () => {
          return <ContentPlayerTitle title={movie.title} />
        },
      },
    }
  }, [progressState])

  const handleOnBackButton = useCallback(() => {
    if (resolved404Error) {
      navigateByDelta(2)
      return
    }

    navigateToPreviousPage()
  }, [streamsError, resolved404Error])
  return (
    <div className={styles.MoviePlayerPage}>
      <StreamsErrorFallback error={!!streamsError} onBack={handleOnBackButton} />
      {loading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      {!isContentMediaLoading && streams && (
        <ContentPlayer
          url={streams ? streams : undefined}
          drm={movie?.partner?.type === PartnerValue.AMEDIATEKA ? DRM_CONFIG : undefined}
          playerId={'movie-player'}
          playerProps={playerProps}
          content={movie}
          movie={movie.movie}
          partnerPlaybackPath={paths?.partnerPlaybackPath}
          viewInfo={viewInfo}
        />
      )}
    </div>
  )
}

export default MoviePlayerFullscreen
