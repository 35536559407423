import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'

// ------------------------ Years
export const addYearToYearsFilter = createAction(
  'searchSlice/addYearToYearsFilter',
  withPayloadType<PageUpdatePayload<string>>(),
)

export const removeYearFromYearsFilter = createAction(
  'searchSlice/removeYearFromYearsFilter',
  withPayloadType<PageUpdatePayload<string>>(),
)

export const setYearsFilter = createAction(
  'searchSlice/setYearsFilter',
  withPayloadType<PageUpdatePayload<string[]>>(),
)

export const resetYearsFilter = createAction(
  'searchSlice/resetYearsFilter',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
