import { ToastProps } from '@/components/Notifications/components/Toasts/Toast'
import { NotificationsModalProps } from '@/components/Notifications/components/Modals/Modal'

export enum Events {
  ADD_TOAST,
  DELETE_TOAST,
  ADD_ALERT,
  DELETE_ALERT,
  ADD_MODAL,
  DELETE_MODAL,
  TOASTS_CONTAINER_MOUNTED,
  TOASTS_CONTAINER_UNMOUNTED,
  NETWORK_ALERT_CONTAINER_MOUNTED,
  NETWORK_ALERT_CONTAINER_UNMOUNTED,
  ALERTS_CONTAINER_MOUNTED,
  ALERTS_CONTAINER_UNMOUNTED,
  MODAL_CONTAINER_MOUNTED,
  MODAL_CONTAINER_UNMOUNTED,
}

export type Callback = (manager: EventManager, ...args: Any[]) => void

export enum ContainerType {
  TOAST_CONTAINER = 'TOAST_CONTAINER',
  NETWORK_ALERT_CONTAINER = 'NETWORK_ALERT_CONTAINER',
  ALERT_CONTAINER = 'ALERT_CONTAINER',
  MODAL_CONTAINER = 'MODAL_CONTAINER',
}

export type TimeoutId = ReturnType<typeof setTimeout>

class EventManager {
  static #inctance: EventManager | null = null

  public static getInstance() {
    if (!EventManager.#inctance) {
      EventManager.#inctance = new EventManager()
    }
    return EventManager.#inctance
  }

  #containersList: Map<ContainerType, string[]> = new Map()

  private getContainers(type: ContainerType) {
    return this.#containersList.has(type) ? this.#containersList.get(type) || [] : []
  }

  getContainersLengthByType(type: ContainerType) {
    return this.getContainers(type).length
  }

  setContainer(id: string, type: ContainerType) {
    if (this.#containersList.has(type) && this.#containersList.get(type)?.includes(id)) {
      return EventManager.getInstance()
    }

    const list = this.#containersList.get(type)
    const newList = list ? list.concat(id) : [id]
    this.#containersList.set(type, newList)
    return EventManager.getInstance()
  }

  removeContainer(id: string, type: ContainerType) {
    if (
      !this.#containersList.has(type) ||
      !(this.#containersList.has(type) && this.#containersList.get(type)?.includes(id))
    ) {
      return EventManager.getInstance()
    }

    const list = this.#containersList.get(type)
    const newList = list ? list.filter((el) => el !== id) : []
    this.#containersList.set(type, newList)
    return EventManager.getInstance()
  }

  #toastsList: Map<string, ToastProps> = new Map()

  setToast(id: string, props: ToastProps) {
    if (this.#toastsList.has(id)) return EventManager.getInstance()
    this.#toastsList.set(id, props)
    return EventManager.getInstance()
  }

  deleteToast(id: string) {
    if (!this.#toastsList.has(id)) return EventManager.getInstance()
    this.#toastsList.delete(id)
    return EventManager.getInstance()
  }

  #modalsList: Map<string, NotificationsModalProps> = new Map()

  setModal(id: string, props: NotificationsModalProps) {
    if (this.#modalsList.has(id)) return EventManager.getInstance()
    this.#modalsList.set(id, props)
    return EventManager.getInstance()
  }

  deleteModal(id: string) {
    if (!this.#modalsList.has(id)) return EventManager.getInstance()
    this.#modalsList.delete(id)
    return EventManager.getInstance()
  }

  #list: Map<Events, Callback[]> = new Map()
  #emitQueue: Map<Events, TimeoutId[]> = new Map()

  on(event: Events, callback: Callback) {
    if (!this.#list.has(event)) {
      this.#list.set(event, [])
    }
    this.#list.get(event)!.push(callback)
    return EventManager.getInstance()
  }

  off(event: Events, callback: Callback) {
    if (!this.#list.has(event)) return EventManager.getInstance()
    const callbacks = this.#list.get(event)!.filter((cb) => cb !== callback)
    this.#list.set(event, callbacks)
    return EventManager.getInstance()
  }

  emit(event: Events, ...args: Any[]) {
    if (!this.#list.has(event)) return EventManager.getInstance()

    this.#list.get(event)!.forEach((callback: Callback) => {
      const timer: TimeoutId = setTimeout(() => {
        callback(EventManager.getInstance(), ...args)
      }, 0)

      if (!this.#emitQueue.has(event)) {
        this.#emitQueue.set(event, [])
      }
      this.#emitQueue.get(event)!.push(timer)
    })

    return EventManager.getInstance()
  }

  cancelEmit(event: Events) {
    const timers = this.#emitQueue.get(event)
    if (timers) {
      timers.forEach(clearTimeout)
      this.#emitQueue.delete(event)
    }

    return this
  }
}

export default EventManager.getInstance()
