import React, { FC, memo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Sidebar from '@/components/Sidebar'
import Button from '@/components/Button'
import YearsFilterSidebar from '@/modules/search/components/YearsFilterAction/YearsFilterSidebar'

import { useAppSelector } from '@/core/store'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface YearsFilterActionProps {
  triggerClassName?: string
  disabled?: boolean
  chosenYears?: string[]
  disabledYears?: string[]
  pageName: PagesWithSearch
}

const YearsFilterAction: FC<YearsFilterActionProps> = ({
  triggerClassName,
  disabledYears,
  chosenYears,
  disabled,
  pageName,
}) => {
  const years = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.years,
  )

  return (
    <Sidebar
      trigger={(onTrigger) => (
        <Button
          disabled={disabled}
          buttonClassName={classNames(triggerClassName, {
            [styles.FilterActive]: years.length > 0,
          })}
          focusClassName={styles.FilterFocused}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
        >
          <>
            <span>Годы</span>
            {years && years.length > 0 ? (
              <span className={''}>{years.join(', ')}</span>
            ) : (
              <span className={''}>Не выбрано</span>
            )}
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <YearsFilterSidebar
            pageName={pageName}
            onClose={onClose}
            disabledYears={disabledYears}
            chosenYears={chosenYears}
          />
        )
      }}
    />
  )
}

export default memo(YearsFilterAction)
