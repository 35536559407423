import React from 'react'
import ReactDOM from 'react-dom/client'

import { Provider } from 'react-redux'
import { init } from '@noriginmedia/norigin-spatial-navigation'

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import 'core-js/proposals/promise-all-settled'
import 'core-js/full'

import scrollPolyfill from '@/core/polyfills/scrollTo'

import { store } from '@/core/store'

import App from './App'

import './styles/index.scss'
import './styles/_variables.scss'

scrollPolyfill.polyfill()

init({
  debug: false,
  visualDebug: false,
  throttle: 300,
  // throttleKeypresses: true,
  // shouldFocusDOMNode: true,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
