import React from 'react'
import { useGetResources } from '@/modules/feeds/hooks/useGetResources'
import { GroupTypes } from '@/modules/feeds/feeds.api'
import ResourcesList from '@/modules/feeds/components/ResourcesList'

const HomeResourceList = () => {
  const { resources, loading } = useGetResources(GroupTypes.CONTENT)
  return <ResourcesList resources={resources} loading={loading} />
}

export default HomeResourceList
