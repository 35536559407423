import React, { useEffect, useRef } from 'react'

export enum ListTopEvents {
  TOP = 'topOfList',
  NOT_TOP = 'notTopOfList',
}

const topOfListEvent = new Event(ListTopEvents.TOP)
const notTopOfListEvent = new Event(ListTopEvents.NOT_TOP)

const dispatchTopOfListEvent = () => {
  document.dispatchEvent(topOfListEvent)
}

const dispatchNotTopOfListEvent = () => {
  document.dispatchEvent(notTopOfListEvent)
}

interface Props {
  listFocusKey: string
}

const FirstLineOfGridWithObserver = ({ listFocusKey }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const list = document.getElementById(listFocusKey)
    const first = ref.current

    if (!list || !first) return

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.length === 0) return

        const entry = entries[0]
        if (entry.intersectionRatio > 0) {
          // in screen
          dispatchTopOfListEvent()
          return
        }

        dispatchNotTopOfListEvent()
        //     not in screen
      },
      { root: list, threshold: [0.1] },
    )
    // начать наблюдение
    intersectionObserver.observe(first)

    return () => {
      intersectionObserver.unobserve(first)
      intersectionObserver.disconnect()
    }
  }, [])

  return <div ref={ref} style={{ width: '100%' }} />
}

export default FirstLineOfGridWithObserver
