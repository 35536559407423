import React, { FC, MutableRefObject, useEffect } from 'react'

import styles from '@/modules/player/components/player/Overlay/styles.module.scss'

import { motion } from 'framer-motion'

import BackButton from '@/modules/player/components/controls/BackButton'

import { headerVariants } from '@/modules/player/components/player/Overlay/overlay.slots.transitions'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'
import PlayerManager from '@/modules/player/player.manager'
import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'
import { isBackButton } from '@/core/keys/specific.keys.codes'

import {
  checkCustomFullscreen,
  checkNativeFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'
import { dispatchRequestPlayerExitFromFullscreenEvent } from '@/core/customEvents/player.custom.events'

interface OverlayHeaderProps {
  className: string
  headerSlotRenderFn?: () => React.ReactNode
  toggleFullscreen?: () => void
  setUserActivity: (active: boolean) => void
  overlay: MutableRefObject<HTMLDivElement | null>
  animate: string
}

const OverlayHeader: FC<OverlayHeaderProps> = ({
  className,
  headerSlotRenderFn,
  toggleFullscreen,
  setUserActivity,
  overlay,
  animate,
}) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isPaused = usePlayerStateSelector((state) => state.video.isPaused)
  const isUserActive = usePlayerStateSelector((state) => state.userActivity.isUserActive)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return

      if (!overlay.current) return
      const childrenLength = overlay.current.childNodes.length

      const childrenLengthCondition =
        childrenLength > 3 ||
        (childrenLength === 3 &&
          overlay.current.getElementsByClassName(styles.Overlay__Footer).length === 0)

      if (!isPaused && isUserActive) {
        e.stopPropagation()
        e.preventDefault()
        if (childrenLengthCondition) {
          return
        }

        setUserActivity(false)
        return
      }

      if (childrenLengthCondition) {
        return
      }

      if (checkCustomFullscreen()) {
        e.stopPropagation()
        e.preventDefault()

        dispatchRequestPlayerExitFromFullscreenEvent()

        return
      }

      if (!checkNativeFullscreen()) return
      toggleFullscreen && toggleFullscreen()
    }

    document.addEventListener('keydown', handleKeyDown, { capture: true })

    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true })
    }
  }, [isUserActive, isPaused])

  return (
    <motion.div initial={'open'} animate={animate} variants={headerVariants} className={className}>
      <BackButton toggleFullscreen={toggleFullscreen} />
      {headerSlotRenderFn && headerSlotRenderFn()}
    </motion.div>
  )
}

export default OverlayHeader
