import { useCallback, useEffect } from 'react'
import { PlayerCustomEvents } from '@/core/customEvents/player.custom.events'
import { useNavigate } from 'react-router-dom'

export const useContentPlayerRequestExitFullscreen = () => {
  const navigate = useNavigate()

  const navigateToPreviousPage = useCallback(() => {
    navigate(-1)
  }, [])

  const navigateByDelta = useCallback((delta: number) => {
    navigate(-delta)
  }, [])

  useEffect(() => {
    const node = document.getElementById('content_redirect_container')
    if (!node) return
    node.style.zIndex = '2'

    return () => {
      node.style.zIndex = '0'
    }
  }, [])

  useEffect(() => {
    const handleRequestExitFS = () => {
      navigate(-1)
    }

    document.addEventListener(PlayerCustomEvents.REQUEST_PLAYER_OUT_FS, handleRequestExitFS)

    return () => {
      document.removeEventListener(PlayerCustomEvents.REQUEST_PLAYER_OUT_FS, handleRequestExitFS)
    }
  }, [])

  return { navigateToPreviousPage, navigateByDelta }
}
