import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Modal, { ModalProps } from '@/components/Modal'

interface BottomBarProps extends ModalProps {}

const BottomBar: FC<BottomBarProps> = ({
  renderContainerClassName,
  activeRenderContainerClassName,
  ...rest
}) => {
  return (
    <Modal
      renderContainerClassName={classNames(renderContainerClassName, styles.ModalRenderContainer)}
      activeRenderContainerClassName={classNames(
        activeRenderContainerClassName,
        styles.ActiveModalRenderContainer,
      )}
      backgroundClassName={styles.Background}
      unActiveRenderContainerClassName={styles.UnActiveModalRenderContainer}
      hideCloseButton
      closeByClickBackground
      {...rest}
    />
  )
}

export default BottomBar
