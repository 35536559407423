import React, { useCallback } from 'react'

import styles from './styles.module.scss'

import { useAppDispatch } from '@/core/store'

import BigKeyboard from '@/components/BigKeyboard'
import classNames from 'classnames'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  addSymbolToSearchTerm,
  removeLastSymbolFromSearchTerm,
  resetSearchTerm,
} from '@/modules/search/slice/actions/searchState/searchState.term.actions'

const SearchKeyboard = ({
  className,
  pageName = 'search',
}: {
  className?: string
  pageName?: PagesWithSearch
}) => {
  const dispatch = useAppDispatch()

  const handlePressDelete = useCallback(() => {
    dispatch(removeLastSymbolFromSearchTerm({ pageName }))
  }, [])

  const handlePressReset = useCallback(() => {
    dispatch(resetSearchTerm({ pageName }))
  }, [])

  const handlePressKey = useCallback((key: string) => {
    dispatch(addSymbolToSearchTerm({ pageName, payload: key }))
  }, [])
  return (
    <BigKeyboard
      className={classNames(className, styles.Keyboard)}
      onDeletePress={handlePressDelete}
      onResetPress={handlePressReset}
      onKeyPress={handlePressKey}
    />
  )
}

export default SearchKeyboard
