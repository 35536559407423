export enum MediaViewResourceType {
  MOVIE = 'MOVIE',
  SERIAL_EPISODE = 'SERIAL_EPISODE',
}

export enum MediaViewStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
}

export interface SyncMediaViewResourceModel {
  season: number
  episode: number
  seasonId: string
}

export interface SyncMediaViewModel {
  id: string | null
  contentId: string
  resourceId: string
  resourceType: MediaViewResourceType
  language: string
  status: MediaViewStatus
  currentTime: number
  duration: number
  timestamp: number | null
  createdAt: string
  updatedAt: string
  next?: boolean
}

export interface SyncMediaViewModelWithResource extends SyncMediaViewModel {
  resource?: SyncMediaViewResourceModel
}
