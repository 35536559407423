import React, { memo, useEffect, useRef, useState } from 'react'

import SwitchChannelByNumericKeysModal from '@/modules/tv/components/SwitchChannelByNumericKeysModal'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { isNumericKey } from '@/core/keys/isNumericKey'

import { useGetAllChannelsQuery } from '@/modules/tv/tv.api'
import { setChosenChannel } from '@/modules/tv/tv.actions'

const SwitchChannelByNumericKeys = () => {
  const dispatch = useAppDispatch()

  const { 0: value, 1: setValue } = useState<string | null>(null)

  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)
  const currentChannelRef = useRef(chosenChannel)
  currentChannelRef.current = chosenChannel

  const { data: channels } = useGetAllChannelsQuery({})
  const channelsRef = useRef(channels)
  channelsRef.current = channels

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (!isNumericKey(e)) return

      e.preventDefault()

      const key = e.key

      setValue((value) => {
        if (!value) return key
        if (value.length >= 3) return value

        return value + key
      })
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      const channels = channelsRef.current
      if (!channels) {
        setValue(null)
        return
      }

      const currentChannel = currentChannelRef.current
      if (!currentChannel) {
        setValue(null)
        return
      }
      if (!value) return

      const index = +value - 1
      if (Number.isNaN(index)) {
        setValue(null)
        return
      }

      if (index >= channels.length) {
        setValue(null)
        return
      }

      const nextChannel = channels[index]
      if (!nextChannel) {
        setValue(null)
        return
      }

      if (currentChannel.id === nextChannel.id) {
        setValue(null)
        return
      }

      dispatch(setChosenChannel(nextChannel))
      setValue(null)
    }, 1500)

    return () => {
      clearTimeout(timer)
    }
  }, [value])

  return <SwitchChannelByNumericKeysModal value={value} />
}

export default memo(SwitchChannelByNumericKeys)
