import React, { FC, useCallback, useRef } from 'react'

import DefaultScrollingWrapper, {
  DefaultScrollingWrapperProps,
  OnChildFocus,
} from '@/components/Slider/DefaultScrollingWrapper'

export type OmittedDefaultScrollingWrapperProps = Omit<
  DefaultScrollingWrapperProps,
  'onChildFocus' | 'children'
>

export interface RowScrollingWrapperProps extends OmittedDefaultScrollingWrapperProps {
  children: React.ReactElement[]
  setFocus?: (focusKey: string) => void
  getCurrentFocusKey?: () => string
}

const RowScrollingWrapper: FC<RowScrollingWrapperProps> = ({
  className,
  contentWrapperClassName,
  onChildPress,
  children,
}) => {
  const scrollingRef = useRef<HTMLDivElement | null>(null)

  const handleChildFocus: OnChildFocus = useCallback(
    (...args) => {
      const layout = args[0]

      const { x /*node*/ } = layout

      const scrollNode = scrollingRef.current
      if (!scrollNode) return
      const child = scrollNode.firstChild as HTMLDivElement
      if (child.offsetWidth - x < 1800) {
        child.style.transform = `translateX(-${child.offsetWidth - 1750}px)`
        return
      }
      child.style.transform = `translateX(-${x}px)`
      // scrollNode.scrollTo({
      //   left: x,
      //   behavior: 'smooth',
      // })
    },
    [scrollingRef.current],
  )

  return (
    <DefaultScrollingWrapper
      ref={scrollingRef}
      className={className}
      contentWrapperClassName={contentWrapperClassName}
      onChildFocus={handleChildFocus}
      onChildPress={onChildPress}
    >
      {children}
    </DefaultScrollingWrapper>
  )
}

export default RowScrollingWrapper
