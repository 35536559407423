import { useMemo } from 'react'

import { GroupTypes, useGetFeedsTemplatesByGroupTypeQuery } from '@/modules/feeds/feeds.api'

export const useGetResources = (groupType: GroupTypes) => {
  const args = useMemo(() => {
    return {
      params: {
        groupType,
      },
    }
  }, [groupType])

  const { data, isLoading } = useGetFeedsTemplatesByGroupTypeQuery(args)

  return useMemo(() => {
    return {
      resources: data?.categories?.[0]?.resources,
      loading: isLoading,
    }
  }, [data, isLoading])
}
