import { createSlice } from '@reduxjs/toolkit'
import { EpisodeBaseModel, SeasonBaseModel } from '@/models/serial.model'
import { resetEpisode, resetSeason, setEpisode, setSeason } from '@/modules/serials/serials.actions'

interface SerialsSlice {
  season?: SeasonBaseModel | null
  episode?: EpisodeBaseModel | null
}

const initialState: SerialsSlice = {
  season: null,
  episode: null,
}

export const serialsSlice = createSlice({
  name: 'serialsSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSeason, (state, action) => {
        state.season = action.payload
      })
      .addCase(resetSeason, (state) => {
        state.season = null
      })

      .addCase(setEpisode, (state, action) => {
        state.episode = action.payload
      })
      .addCase(resetEpisode, (state) => {
        state.episode = null
      })
  },
})
