import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit'

import { APIStatus, IApiErrorResponse } from '@/core/api/api'

import { LoginResponse } from '@/modules/auth/auth.service'
import {
  checkPhone,
  login,
  logout,
  me,
  meSession,
  refreshPassword,
  removeSessionById,
  signup,
  updateSessionById,
} from '@/modules/auth/auth.actions'
import { SessionModel } from '@/models/session.model'

interface AuthSlice {
  auth: {
    status: APIStatus
    data: {
      loginStatus: APIStatus
      passwordStatus: APIStatus
      loginData: Partial<LoginResponse>
      session: SessionModel | null
    }
    error?: IApiErrorResponse | null
  }
}

const initialState: AuthSlice = {
  auth: {
    status: APIStatus.IDLE,
    data: {
      loginStatus: APIStatus.IDLE,
      passwordStatus: APIStatus.IDLE,
      loginData: {},
      session: null,
    },
    error: null,
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetSession: (state) => {
      state.auth = initialState.auth
    },
    changeLoginStatus: (state, action: PayloadAction<APIStatus>) => {
      state.auth.data.loginStatus = action.payload
    },
    changePasswordStatus: (state, action: PayloadAction<APIStatus>) => {
      state.auth.data.passwordStatus = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      //   check phone
      .addCase(checkPhone.fulfilled, (state) => {
        state.auth.status = APIStatus.FULFILLED
        state.auth.data.loginStatus = APIStatus.FULFILLED
      })
      .addCase(checkPhone.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
        state.auth.data.loginStatus = APIStatus.REJECTED
      })

      // login
      .addCase(login.fulfilled, (state, action) => {
        state.auth.status = APIStatus.FULFILLED
        state.auth.data.loginData = action.payload
        state.auth.data.passwordStatus = APIStatus.FULFILLED
      })
      .addCase(login.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
        state.auth.data.passwordStatus = APIStatus.REJECTED
      })

      .addCase(logout.fulfilled, (state) => {
        state.auth.status = APIStatus.FULFILLED
        state.auth.data.loginData = {}
        state.auth.data.passwordStatus = APIStatus.IDLE
      })
      .addCase(logout.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
      })

      //  me
      .addCase(me.fulfilled, (state, action) => {
        state.auth.status = APIStatus.FULFILLED
        state.auth.data.loginData.user = action.payload
      })

      .addCase(me.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
      })

      //  me session
      .addCase(meSession.fulfilled, (state, action) => {
        state.auth.status = APIStatus.FULFILLED
        state.auth.data.session = action.payload
      })

      .addCase(updateSessionById.fulfilled, (state) => {
        state.auth.status = APIStatus.FULFILLED
      })

      .addCase(removeSessionById.fulfilled, (state) => {
        state.auth.status = APIStatus.FULFILLED
      })

      .addCase(meSession.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
      })

      .addCase(updateSessionById.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
      })

      .addCase(removeSessionById.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
      })

      //   signup
      .addCase(signup.fulfilled, (state) => {
        state.auth.status = APIStatus.FULFILLED
        state.auth.data.loginStatus = APIStatus.FULFILLED
      })
      .addCase(signup.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
        state.auth.data.loginStatus = APIStatus.REJECTED
      })

      //   refresh password

      .addCase(refreshPassword.fulfilled, (state) => {
        state.auth.status = APIStatus.FULFILLED
        // state.auth.data.loginStatus = APIStatus.FULFILLED
      })
      .addCase(refreshPassword.rejected, (state, action) => {
        state.auth.error = action.payload
        state.auth.status = APIStatus.REJECTED
        // state.auth.data.loginStatus = APIStatus.REJECTED
      })

      // pending
      .addMatcher(
        isAnyOf(
          checkPhone.pending,
          refreshPassword.pending,
          login.pending,
          logout.pending,
          signup.pending,
          me.pending,
          meSession.pending,
          removeSessionById.pending,
          updateSessionById.pending,
        ),
        (state) => {
          state.auth.status = APIStatus.PENDING
        },
      )
  },
})

export const { changePasswordStatus, resetSession, changeLoginStatus } = authSlice.actions
