import React, { FC } from 'react'

import classNames from 'classnames'

import Loader from '@/components/Loader'

interface Props {
  deletable?: boolean
  deleting?: boolean
  notDeletingClassName?: string
  deletingClassName?: string
}

const DeleteCardActionInner: FC<Props> = ({
  deleting,
  deletable,
  deletingClassName,
  notDeletingClassName,
}) => {
  return (
    <>
      {deletable && !deleting && (
        <i className={classNames('icon-line-close', notDeletingClassName)} />
      )}
      {deletable && deleting && (
        <div className={deletingClassName}>
          <div>
            <Loader>
              <Loader.Spinner />
            </Loader>
          </div>
        </div>
      )}
    </>
  )
}

export default DeleteCardActionInner
