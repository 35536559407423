import React, { useEffect, useMemo } from 'react'

import styles from './styles.module.scss'

import { useLocation, useParams } from 'react-router-dom'

import SkeletonView from '@/components/SkeletonView'

import { useAppDispatch, useAppSelector } from '@/core/store'

import {
  dispatchSearchLoadingTurnOffEvent,
  dispatchSearchLoadingTurnOnEvent,
} from '@/core/customEvents/search.custom.events'

import { ContentType } from '@/models/content.model'
import { useGetTagByIdQuery } from '@/modules/tags/tags.api'
import { setTypesFilter } from '@/modules/search/slice/actions/searchState/searchState.contentTypes.actions'
import { setTagsFilter } from '@/modules/search/slice/actions/searchState/searchState.tags.actions'

const TagHeader = () => {
  const dispatch = useAppDispatch()

  const tags = useAppSelector((state) => state.searchByPageSlice['tags'].searchState.filters.tags)

  const { id } = useParams()
  const { state } = useLocation()

  const {
    data: tag,
    isLoading: tagLoading,
    isFetching: tagFetching,
  } = useGetTagByIdQuery(
    { tagId: id || '' },
    {
      skip: !id,
    },
  )

  const fromType = useMemo(() => {
    if (!state) return
    const { movies, serials } = state
    if (!movies && !serials) return

    if (movies) return ContentType.MOVIE

    return ContentType.SERIAL
  }, [state])

  useEffect(() => {
    if (!fromType) return

    dispatch(setTypesFilter({ pageName: 'tags', payload: fromType }))
  }, [fromType])

  useEffect(() => {
    if (tagLoading || tagFetching) {
      dispatchSearchLoadingTurnOnEvent()
      return
    }
    dispatchSearchLoadingTurnOffEvent()
  }, [tagLoading, tagFetching])

  useEffect(() => {
    if (!tag) return
    if (tags.length > 0) return

    dispatch(setTagsFilter({ pageName: 'tags', payload: [tag] }))
  }, [tag])

  return (
    <div className={styles.TagHeader}>
      <div className={styles.TagTitle}>{tag && tag.title ? tag.title : <SkeletonView />}</div>
    </div>
  )
}

export default TagHeader
