import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import CategoryCardInner from '@/modules/category/components/CategoryCard/CategoryCardInner'

import { CategoryModel } from '@/models/category.model'

interface CategoryCardProps extends Omit<DefaultCardProps<CategoryModel>, 'children'> {}

const CategoryCard: FC<CategoryCardProps> = ({ card, className, wrapperClassName, ...rest }) => {
  return (
    <DefaultCard
      card={card}
      className={classNames(className)}
      wrapperClassName={classNames(wrapperClassName, styles.CardWrapper)}
      {...rest}
    >
      <CategoryCardInner card={card} />
    </DefaultCard>
  )
}

export default CategoryCard
