import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useAppSelector } from '@/core/store'

import CircleButton from '@/components/CircleButton'
import ProgramListSlider from '@/modules/tv/components/ProgramListSlider'
import ProgramDatesSlider from '@/modules/tv/components/ProgramDatesSlider'
import { getChannelTitle } from '@/modules/tv/tv.helpers'

interface ProgramListModalProps {
  onClose?: () => void
}

const ProgramListModal: FC<ProgramListModalProps> = ({ onClose }) => {
  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)

  return (
    <div className={classNames(styles.ProgramListModal)}>
      <div className={styles.Header}>
        <CircleButton onButtonClick={onClose} onEnterPress={onClose}>
          <i className='icon-fill-arrow-left' />
        </CircleButton>
        <div className={styles.Title}>
          <h2>{`Программа передач канала ${getChannelTitle(chosenChannel)}`}</h2>
        </div>
      </div>

      <div className={styles.TabsContainer}>
        <ProgramDatesSlider />
        <ProgramListSlider onClose={onClose} />
      </div>
    </div>
  )
}

export default ProgramListModal
