import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

import ApiBaseHelper from './api.base'
import ApiUtils from './api.utils'

import { API_URL } from '../config'
import { getFromLocalStorage, saveToLocalStorage } from '../utils'

import AuthService from '@/modules/auth/auth.service'
import ProfileService from '@/modules/profile/profile.service'
import { store } from '@/core/store'
import SearchService from '@/modules/search/slice/search.service'
import { resetAction } from '@/core/store/store'

export interface PaginateArgs {
  page: number
  limit: number
}

export interface ApiFetchAllWithPaginationPayload<P> {
  totalPages: number
  page: number
  perPage: number
  totalItems: number | null
  items: P[]
}

interface ExtendedConfig extends AxiosRequestConfig {
  isRetry?: boolean
}

type FailQueueItem = {
  resolve: (value: unknown) => void
  reject: (reason?: unknown) => void
}

export enum APIStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}

export interface IApiErrorResponse {
  code: number | string
  msg: string
}

interface NotFoundError {
  status: 404
}

export const isNotFoundError = (e: unknown): e is NotFoundError => {
  if (!e) return false
  return typeof e === 'object' && 'status' in e && typeof e.status === 'number' && e.status === 404
}

export class ApiHelper extends ApiBaseHelper {
  static #instance: ApiHelper | null = null

  #api: AxiosInstance | null = null

  public unInterceptedAxiosInstance: AxiosInstance | null = null

  private isRefreshing = false

  private failedQueue: FailQueueItem[] = []

  private processQueue = (error: AxiosError | null, token: string | null = null) => {
    this.failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error)
        return
      }
      promise.resolve(token)
    })

    this.failedQueue = []
  }

  static onRequestFullFilled(config: AxiosRequestConfig) {
    const configCopy = { ...config }
    configCopy.headers = ApiUtils.normalizeHeaders(config.headers) || {}
    // config.params = this.stringifyParams(config.params)

    if (ApiUtils.accessTokenKey) {
      const token = getFromLocalStorage(ApiUtils.accessTokenKey)
      if (token) configCopy.headers.Authorization = `Bearer ${token}`
    }

    return configCopy as InternalAxiosRequestConfig
  }

  static onRequestRejected = (error: AxiosError) => Promise.reject(error)

  static async onResponseFullFilled(response: AxiosResponse) {
    return response
  }

  private async onResponseRejected(error: AxiosError) {
    const originalRequest = error.config as ExtendedConfig

    if (error.response?.status === 401 && !originalRequest.isRetry) {
      if (this.isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject })
          })

          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${token}`
          }

          return await this.#api?.request(originalRequest)
        } catch (e) {
          return await Promise.reject(e)
        }
      }

      originalRequest.isRetry = true
      this.isRefreshing = true
      return new Promise((resolve, reject) => {
        const sessionId = getFromLocalStorage<string>(ApiUtils.sessionId)
        const refreshToken = getFromLocalStorage(ApiUtils.refreshTokenKey)

        this.unInterceptedAxiosInstance
          ?.get(`/auth/client/refresh/tokens`, {
            headers: { SessionId: sessionId, Authorization: `Bearer ${refreshToken}` },
          })
          .then((res) => {
            const { accessToken, refreshToken } = res.data
            saveToLocalStorage(ApiUtils.accessTokenKey, accessToken)
            saveToLocalStorage(ApiUtils.refreshTokenKey, refreshToken)

            this.processQueue(null, accessToken)

            resolve(this.#api?.request(originalRequest))
          })
          .catch((e: AxiosError) => {
            store.dispatch(resetAction)

            this.processQueue(e, null)
            reject(e)
          })
          .finally(() => {
            this.isRefreshing = false
          })
      })
    }

    return Promise.reject(error)
  }

  constructor() {
    super()

    this.#api = this.getApi()

    this.unInterceptedAxiosInstance = this.getApi(API_URL, true)

    this.failedQueue = []

    this.#api?.interceptors.request.use(ApiHelper.onRequestFullFilled, ApiHelper.onRequestRejected)

    this.#api?.interceptors.response.use(
      ApiHelper.onResponseFullFilled,
      this.onResponseRejected.bind(this),
    )
  }

  public static getInstance(): ApiHelper {
    if (!ApiHelper.#instance) {
      ApiHelper.#instance = new ApiHelper()
    }

    return ApiHelper.#instance
  }

  get services() {
    return {
      auth: AuthService(this),
      profile: ProfileService(this),
      search: SearchService(this),
    }
  }
}

export default ApiHelper.getInstance()
