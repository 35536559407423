import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard from '@/components/DefaultCard'
import NotificationCardInner from '@/modules/notifications/components/NotificationCard/NotificationCardInner'

import type { DefaultCardProps } from '@/components/DefaultCard'
import { NotificationModel } from '@/models/notification.model'

interface Props extends Omit<DefaultCardProps<NotificationModel>, 'children'> {}

const NotificationCard: FC<Props> = ({ card, className, wrapperClassName, ...props }) => {
  return (
    <DefaultCard<NotificationModel>
      card={card}
      className={classNames(className, styles.CardFocusWrapper)}
      wrapperClassName={classNames(wrapperClassName, styles.CardWrapper)}
      {...props}
    >
      <NotificationCardInner card={card} />
    </DefaultCard>
  )
}

export default NotificationCard
