import React from 'react'
import { ContentModel } from '@/models/content.model'
import Info from '@/modules/content/components/Info'

interface UhdInfoProps {
  card?: ContentModel
  className?: string
}

const UhdInfo = ({ card, className }: UhdInfoProps) => {
  const isUhd = card?.isUhd
  if (!isUhd) return null
  return <Info infoText={'UHD'} className={className} />
}

export default UhdInfo
