import React, { FC, useMemo } from 'react'

import DefaultCategorySlider, {
  BaseCategorySliderProps,
} from '@/modules/category/components/DefaultCategorySlider'

import { useGetCategoriesHasChannelsQuery } from '@/modules/category/category.api'

import { CategoryModel } from '@/models/category.model'

interface CategorySliderProps extends BaseCategorySliderProps<CategoryModel> {}

const CategorySlider: FC<CategorySliderProps> = (props) => {
  const { data, isLoading } = useGetCategoriesHasChannelsQuery()

  const preparedData = useMemo(() => {
    if (!data) return

    const commonCategory = { pluralTitle: 'Все' } as CategoryModel
    const favoritesCategory = { pluralTitle: 'Избранное', id: 'favorites' } as CategoryModel
    return [commonCategory, favoritesCategory, ...data]
  }, [data])
  return <DefaultCategorySlider {...props} data={preparedData} isLoading={isLoading} />
}

export default CategorySlider
