import React, { FC } from 'react'

import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'
import SortList from '@/modules/search/components/SortList'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface SortSidebar {
  onClose?: () => void
  pageName: PagesWithSearch
}

const SortSidebar: FC<SortSidebar> = ({ onClose, pageName }) => {
  return (
    <CommonFilterSidebar onClose={onClose} title={'Сортировка'}>
      <SortList pageName={pageName} />
    </CommonFilterSidebar>
  )
}

export default SortSidebar
