import React, { useEffect, useMemo } from 'react'

import styles from './styles.module.scss'

import { useLocation, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'
import {
  dispatchSearchLoadingTurnOnEvent,
  dispatchSearchLoadingTurnOffEvent,
} from '@/core/customEvents/search.custom.events'

import { useGetAllPartnersQuery } from '@/modules/partner/partner.api'

import SkeletonView from '@/components/SkeletonView'
import ImageLoader from '@/components/Image'

import { ContentType } from '@/models/content.model'

import fallbackLogo from '@/assets/images/genres/genre-fallback.svg'
import { setTypesFilter } from '@/modules/search/slice/actions/searchState/searchState.contentTypes.actions'
import { addPartnerToPartnersFilter } from '@/modules/search/slice/actions/searchState/searchState.partners.actions'

const PartnerHeader = () => {
  const partnersState = useAppSelector(
    (state) => state.searchByPageSlice['partners'].searchState.filters.partners,
  )

  const { id } = useParams()
  const { state } = useLocation()

  const dispatch = useAppDispatch()

  const {
    data: partners,
    isLoading: partnersLoading,
    isFetching: partnersFetching,
  } = useGetAllPartnersQuery(undefined, { skip: !id })

  const partner = useMemo(() => {
    if (!partners || !id) return

    return partners.find((partner) => partner.id === id)
  }, [partners, id])

  const partnerLogoUrl = useMemo(() => {
    if (!partner) return
    if (!partner.logo) return

    const sourceBaseUrl = partner.logo.sourceBaseUrl
    const sourcePath = partner.logo.sourcePath

    return sourceBaseUrl + sourcePath
  }, [partner])

  const fromType = useMemo(() => {
    if (!state) return
    const { movies, serials } = state
    if (!movies && !serials) return

    if (movies) return ContentType.MOVIE

    return ContentType.SERIAL
  }, [state])

  useEffect(() => {
    if (!fromType) return

    dispatch(setTypesFilter({ pageName: 'partners', payload: fromType }))
  }, [fromType])

  useEffect(() => {
    if (partnersLoading || partnersFetching) {
      dispatchSearchLoadingTurnOnEvent()
      return
    }
    dispatchSearchLoadingTurnOffEvent()
  }, [partnersLoading, partnersFetching])

  useEffect(() => {
    if (!partner) return
    if (partnersState.length > 0) return
    dispatch(addPartnerToPartnersFilter({ pageName: 'partners', payload: partner }))
  }, [partner])

  return (
    <div className={styles.PartnerHeader}>
      <div className={styles.PartnerTitle}>
        {partner && partner.type ? partner.type : <SkeletonView />}
      </div>
      <div className={styles.PartnerLogo}>
        <ImageLoader
          src={partnerLogoUrl || fallbackLogo}
          fallbackSrc={fallbackLogo}
          roundedSkeleton
        />
      </div>
    </div>
  )
}

export default PartnerHeader
