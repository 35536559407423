import React, { FC, useCallback, useEffect, useMemo } from 'react'

import styles from './styles.module.scss'

import ProgramListItemCard from '@/modules/tv/components/ProgramListItemCard'
import List from '@/components/List'

import { useLazyGetProgramsByChannelIdAndDateQuery } from '@/modules/tv/tv.api'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { setCurrentProgram } from '@/modules/tv/tv.actions'

import { ProgramBaseModel } from '@/models/program.model'

interface ProgramListSliderProps {
  onClose?: () => void
}

const ProgramListSlider: FC<ProgramListSliderProps> = ({ onClose }) => {
  const dispatch = useAppDispatch()

  const chosenDate = useAppSelector((state) => state.tvProgram.chosenDate)
  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)
  const liveProgram = useAppSelector((state) => state.tv.liveProgram)

  const [getPrograms, { data, isLoading }] = useLazyGetProgramsByChannelIdAndDateQuery()

  const handleProgramItemPress = useCallback(
    (program: ProgramBaseModel) => {
      dispatch(setCurrentProgram(program))

      if (!onClose) return
      onClose()
    },
    [onClose],
  )

  useEffect(() => {
    if (!chosenDate || !chosenChannel) return

    getPrograms({ channelId: chosenChannel.id, date: chosenDate.date }, true)
  }, [chosenDate, chosenChannel])

  const mappedData = useMemo(() => {
    if (!data && !isLoading) return []
    if (!data && isLoading) {
      return Array(6)
        .fill('1')
        .map((el, index) => <ProgramListItemCard key={index} />)
    }

    return data!.map((el, index) => {
      return (
        <ProgramListItemCard
          key={index}
          card={el}
          onCardPress={handleProgramItemPress}
          chosenChannel={chosenChannel}
          liveProgram={liveProgram}
        />
      )
    })
  }, [data, isLoading, liveProgram])

  return (
    <List
      throttleScrollDelay={50}
      className={styles.ProgramListFocusWrapper}
      scrollWrapperClassName={styles.ProgramListScrollingWrapper}
    >
      {mappedData}
    </List>
  )
}

export default ProgramListSlider
