import React, { FC } from 'react'

import styles from './styles.module.scss'

import GenresList from '@/modules/search/components/GenresList'
import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'

import { GenreModel } from '@/models/genre.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface GenresFilterSidebarProps {
  chosenGenres?: GenreModel[]
  disabledGenres?: string[]
  onClose?: () => void
  pageName: PagesWithSearch
}

const GenresFilterSidebar: FC<GenresFilterSidebarProps> = ({
  onClose,
  disabledGenres,
  chosenGenres,
  pageName,
}) => {
  return (
    <CommonFilterSidebar onClose={onClose} title={'Жанры'}>
      <GenresList
        pageName={pageName}
        className={styles.ItemsList}
        chosenGenres={chosenGenres}
        disabledGenres={disabledGenres}
        throttleScrollDelay={20}
      />
    </CommonFilterSidebar>
  )
}

export default GenresFilterSidebar
