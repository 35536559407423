import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import RowScrollingWrapper, {
  RowScrollingWrapperProps,
} from '@/components/Slider/RowScrollingWrapper'

import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import LoopRowScrollingWrapper from '@/components/Slider/LoopRowScrollingWrapper'

export interface RowProps extends RowScrollingWrapperProps {
  scrollIndex?: number
  onFocus?: OnChildFocus
  title?: string
  titleClassName?: string
  loop?: boolean
}

const Row: FC<RowProps> = ({
  children,
  title,
  titleClassName,
  loop,
  className,
  contentWrapperClassName,
  onChildPress,
  getCurrentFocusKey,
  setFocus,
}) => {
  return (
    <>
      {title && <div className={classNames(styles.Title, titleClassName)}>{title}</div>}
      {loop ? (
        <LoopRowScrollingWrapper
          onChildPress={onChildPress}
          className={classNames(styles.ScrollingWrapper, className)}
          contentWrapperClassName={classNames(styles.ContentWrapper, contentWrapperClassName)}
          setFocus={setFocus}
          getCurrentFocusKey={getCurrentFocusKey}
        >
          {children}
        </LoopRowScrollingWrapper>
      ) : (
        <RowScrollingWrapper
          onChildPress={onChildPress}
          className={classNames(styles.ScrollingWrapper, className)}
          contentWrapperClassName={classNames(styles.ContentWrapper, contentWrapperClassName)}
        >
          {children}
        </RowScrollingWrapper>
      )}
    </>
  )
}

export default Row
