import React from 'react'

import styles from './styles.module.scss'

import SearchInput from '@/modules/search/components/SearchInput'
import SearchResults from '@/modules/search/components/SearchResults'
import SearchFilters from '@/modules/search/components/SearchFilters'
import FilterByTags from '@/modules/tags/components/FilterByTags'

const Search = () => {
  return (
    <div className={styles.Search}>
      <SearchInput />
      <SearchFilters className={styles.Filters} />
      <FilterByTags />
      <SearchResults />
    </div>
  )
}

export default Search
