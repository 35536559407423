export type Drm = {
  servers: DrmServers | null
}

export type DrmServers = Record<DrmTypes, string>

export enum DrmTypes {
  CLEAR_KEY = 'org.w3.clearkey',
  FAIRPLAY = 'com.apple.fps.1_0',
  PLAYREADY = 'com.microsoft.playready',
  WIDEVINE = 'com.widevine.alpha',
}

export interface DrmLicenceServers {
  licence_server_fairplay?: string | null
  licence_server_playready?: string | null
  licence_server_widevine?: string | null
}

export interface VideoStreamerBaseProps<T, V> {
  url: string
  drm?: Drm | null

  controls?: boolean
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  disablePictureInPicture?: boolean
  playsInline?: boolean

  onInit?: (videoEl: HTMLVideoElement, player?: T, variant?: V) => void
  onBeforeReInit?: (videoEl: HTMLVideoElement) => void
  onReInit?: (videoEl: HTMLVideoElement, player?: T, variant?: V) => void
  onError?: (videoEl: HTMLVideoElement, error: unknown) => void
}
