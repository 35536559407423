import React, { FC, ReactElement } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Slider, { SliderProps } from '@/components/Slider'
import GenreCard from '@/modules/genre/components/GenreCard'

import { RowProps } from '@/components/Slider/Row'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { GenreBaseModel } from '@/models/genre.model'

type RenderFn = () => ReactElement[]

export interface BaseGenreSliderProps<T> extends Omit<SliderProps, 'children'> {
  rowProps?: Omit<RowProps, 'children'>
  children?: RenderFn
  onCardPress?: (item: T) => void
  onCardFocus?: OnChildFocus
}

export interface DefaultGenreSliderProps extends BaseGenreSliderProps<GenreBaseModel> {
  data?: GenreBaseModel[]
  isLoading?: boolean
  skeletonItemsLength?: number
}

const DefaultGenreSlider: FC<DefaultGenreSliderProps> = ({
  data,
  isLoading,
  rowProps,
  onCardFocus,
  onCardPress,
  skeletonItemsLength = 7,
  children,
  ...sliderProps
}) => {
  return (
    <>
      {!isLoading && (!data || data.length === 0) ? null : (
        <Slider {...sliderProps}>
          <Slider.Horizontal
            title={'Жанры'}
            {...rowProps}
            className={classNames(styles.ScrollingWrapper, rowProps?.className)}
          >
            {children
              ? children()
              : data && data.length > 0 && !isLoading
              ? data.map((el, index) => {
                  return (
                    <GenreCard
                      key={index}
                      card={el}
                      onCardFocus={onCardFocus}
                      onCardPress={onCardPress}
                    />
                  )
                })
              : Array(skeletonItemsLength)
                  .fill('')
                  .map((el, index) => {
                    return <GenreCard key={index} />
                  })}
          </Slider.Horizontal>
        </Slider>
      )}
    </>
  )
}

export default DefaultGenreSlider
