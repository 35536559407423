import React, { FC, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Sidebar from '@/components/Sidebar'
import Button from '@/components/Button'
import GenresFilterSidebar from '@/modules/search/components/GenresFilterAction/GenresFilterSidebar'

import { useAppSelector } from '@/core/store'
import { GenreModel } from '@/models/genre.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface GenresFilterActionProps {
  chosenGenres?: GenreModel[]
  disabledGenres?: string[]
  triggerClassName?: string
  disabled?: boolean
  pageName: PagesWithSearch
}

const GenresFilterAction: FC<GenresFilterActionProps> = ({
  disabled,
  chosenGenres,
  disabledGenres,
  triggerClassName,
  pageName,
}) => {
  const genres = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.genres,
  )

  const mappedGenres = useMemo(() => {
    return genres.map((el) => el.pluralTitle)
  }, [genres])

  return (
    <Sidebar
      trigger={(onTrigger) => (
        <Button
          disabled={disabled}
          buttonClassName={classNames(triggerClassName, {
            [styles.FilterActive]:
              (chosenGenres && mappedGenres.length >= chosenGenres.length) ||
              (!chosenGenres && mappedGenres.length > 0),
          })}
          focusClassName={styles.FilterFocused}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
        >
          <>
            <span>Жанры</span>

            <span>
              {mappedGenres && mappedGenres.length > 0 ? mappedGenres.join(', ') : 'Не выбрано'}
            </span>
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <GenresFilterSidebar
            chosenGenres={chosenGenres}
            disabledGenres={disabledGenres}
            onClose={onClose}
            pageName={pageName}
          />
        )
      }}
    />
  )
}

export default GenresFilterAction
