import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

interface InfoProps {
  infoText: string
  className?: string
}

const Info = ({ infoText, className }: InfoProps) => {
  return <div className={classNames(styles.Info, className)}>{infoText}</div>
}

export default Info
