import React from 'react'

import styles from './styles.module.scss'

import PartnerHeader from '@/modules/partner/components/PartnerHeader'
import PartnerFilters from '@/modules/partner/components/PartnerFilters'
import PartnerSearchResults from '@/modules/partner/components/PartnerSearchResults'

const Partner = () => {
  return (
    <div className={styles.Partner}>
      <PartnerHeader />

      <PartnerFilters />

      <PartnerSearchResults />
    </div>
  )
}

export default Partner
