import React, { FC, useCallback, useState } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useNavigate } from 'react-router-dom'

import { CabinetPaths, Paths, SubscriptionsPaths } from '@/core/router/router.paths'
import { RightsErrorMessage } from '@/models/rightsError.model'
import classNames from 'classnames'

interface ChannelNeedPackageProps {
  packages: RightsErrorMessage[]
}

const ChannelNeedPackage: FC<ChannelNeedPackageProps> = () => {
  const { 0: focused, 1: setFocused } = useState(false)
  const navigate = useNavigate()
  const handlePackageCardClick = useCallback(() => {
    navigate(`${Paths.CABINET}/${CabinetPaths.SUBSCRIPTIONS}/${SubscriptionsPaths.All}`)
  }, [])
  return (
    <div
      className={classNames(styles.ChannelNeedPackage, {
        [styles.ChannelNeedPackage__Focused]: focused,
      })}
    >
      <div className={styles.BG}>
        <h3 className={styles.Title}>
          Для просмотра необходимо
          <br /> оформить подписку
        </h3>

        <Button
          buttonClassName={styles.Action}
          onButtonClick={handlePackageCardClick}
          onEnterPress={handlePackageCardClick}
          autoFocus
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          Оформить сейчас
        </Button>
      </div>
    </div>
  )
}

export default ChannelNeedPackage
