import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { BaseProfileModel, ClientProfileModel } from '@/models/profile.model'

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: ['CLIENT-PROFILES', 'ALL-PROFILES'],
  endpoints: (builder) => ({
    getAllClientProfiles: builder.query<ClientProfileModel[], void>({
      query: () => {
        return {
          url: `clients/me/profiles`,
          method: 'GET',
        }
      },
      providesTags: ['CLIENT-PROFILES'],
    }),
    getAllProfiles: builder.query<BaseProfileModel[], void>({
      query: () => {
        return {
          url: `profiles`,
          method: 'GET',
        }
      },
      providesTags: ['ALL-PROFILES'],
    }),
    createClientProfile: builder.mutation<ClientProfileModel, CreateProfileDto>({
      query: (data) => {
        return {
          url: `clients/me/profiles`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['CLIENT-PROFILES'],
    }),

    updateProfile: builder.mutation<
      ClientProfileModel,
      { data: Partial<CreateProfileDto>; profileId: string }
    >({
      query: ({ data, profileId }) => {
        return {
          url: `clients/me/profiles/${profileId}`,
          method: 'PATCH',
          data,
        }
      },
      invalidatesTags: ['CLIENT-PROFILES'],
    }),

    deleteProfile: builder.mutation<ClientProfileModel, { profileId: string }>({
      query: ({ profileId }) => {
        return {
          url: `clients/me/profiles/${profileId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['CLIENT-PROFILES'],
    }),
  }),
})

interface CreateProfileDto {
  name: string
  profileId: string
  password?: string
  birthday?: string
}

export const {
  useGetAllClientProfilesQuery,
  useDeleteProfileMutation,
  useUpdateProfileMutation,
  useCreateClientProfileMutation,
  useGetAllProfilesQuery,
} = profileApi
