import React, { FC, useMemo } from 'react'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { setEpisode } from '@/modules/serials/serials.actions'

import { SerialModel } from '@/models/serial.model'
import { getNextEpisode } from '@/modules/serials/serials.helpers'

interface NextEpisodeActionProps {
  className?: string
  disabled?: boolean
}

const NextEpisodeAction: FC<NextEpisodeActionProps> = ({ className, disabled }) => {
  const dispatch = useAppDispatch()

  const serial = useAppSelector((state) => state.content.chosenCard) as SerialModel
  const episode = useAppSelector((state) => state.serial.episode)

  const nextEpisode = useMemo(() => {
    return getNextEpisode(serial, episode)
  }, [serial, episode])

  if (!nextEpisode) return null

  const handleAction = () => {
    dispatch(setEpisode(nextEpisode))
  }

  return (
    <Button
      disabled={disabled}
      onButtonClick={handleAction}
      onEnterPress={handleAction}
      buttonClassName={className}
    >
      <>
        <i className='icon-fill-next' />
        <span data-title={'title'}>Следующая серия</span>
      </>
    </Button>
  )
}

export default NextEpisodeAction
