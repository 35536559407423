import React from 'react'

import styles from './styles.module.scss'

import Modal from '@/components/Modal'

interface Props {
  value: string | null
}

const SwitchChannelByNumericKeysModal = ({ value }: Props) => {
  return (
    <Modal
      isOpen={!!value}
      autoFocus={false}
      closeByClickBackground={false}
      hideCloseButton
      trigger={() => <></>}
      render={() => (
        <div className={styles.ValueContainer}>
          <div className={styles.Value}>{value}</div>
        </div>
      )}
      renderContainerClassName={styles.RenderContainer}
      backgroundClassName={styles.Background}
      overlayClassName={styles.Overlay}
    />
  )
}

export default SwitchChannelByNumericKeysModal
