import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'
import Sidebar from '@/components/Sidebar'
import ContentTypesFilterSidebar from '@/modules/search/components/ContentTypesFilterAction/ContentTypesFilterSidebar'

import { useAppSelector } from '@/core/store'

import { ContentType } from '@/models/content.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface ContentTypesFilterActionProps {
  triggerClassName?: string
  disabled?: boolean
  chosenType?: ContentType
  disabledTypes?: ContentType[]
  pageName: PagesWithSearch
}

const ContentTypesFilterAction: FC<ContentTypesFilterActionProps> = ({
  disabled,
  disabledTypes,
  chosenType,
  triggerClassName,
  pageName,
}) => {
  const type = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.filters.type)

  return (
    <Sidebar
      trigger={(onTrigger) => (
        <Button
          disabled={disabled}
          buttonClassName={classNames(triggerClassName, { [styles.FilterActive]: !!type })}
          focusClassName={styles.FilterFocused}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
        >
          <>
            <span className={type ? 'active' : undefined}>Тип контента</span>
            {type ? (
              <span>{type === 'SERIAL' ? 'Сериалы' : 'Фильмы'}</span>
            ) : (
              <span>Не выбрано</span>
            )}
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <ContentTypesFilterSidebar
            chosenType={chosenType}
            disabledTypes={disabledTypes}
            onClose={onClose}
            pageName={pageName}
          />
        )
      }}
    />
  )
}

export default ContentTypesFilterAction
