import { APIStatus, IApiErrorResponse } from '@/core/api/api'
import { ClientProfileModel } from '@/models/profile.model'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import {
  createProfile,
  getProfiles,
  resetProfile,
  setProfile,
} from '@/modules/profile/profile.actions'

interface ProfileSlice {
  status: APIStatus
  data: {
    profile: ClientProfileModel | null
    profiles: ClientProfileModel[]
  }
  error?: IApiErrorResponse | null
}

const initialState: ProfileSlice = {
  status: APIStatus.IDLE,
  data: {
    profile: null,
    profiles: [],
  },
  error: null,
}

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfiles.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED
        state.data.profiles = action.payload
      })

      .addCase(createProfile.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED
        // state.data.profile = action.payload
      })

      .addCase(setProfile, (state, action) => {
        state.data.profile = action.payload
      })
      .addCase(resetProfile, (state) => {
        state.data.profile = null
      })

      .addMatcher(isAnyOf(getProfiles.pending, createProfile.pending), (state) => {
        state.status = APIStatus.PENDING
      })
      .addMatcher(isAnyOf(getProfiles.rejected, createProfile.rejected), (state, action) => {
        state.status = APIStatus.REJECTED
        state.error = action.payload
      })
  },
})
