import React from 'react'

import styles from './styles.module.scss'

import ChannelsList from '@/modules/tv/components/ChannelsList'
import FilterByCategory from '@/modules/tv/components/FilterByCategory'
import TvChosenChannel from '@/modules/tv/components/TvChosenChannel'

const Tv = () => {
  return (
    <div className={styles.Tv}>
      <TvChosenChannel />
      <div className={styles.Tv__Footer}>
        <FilterByCategory />
        <ChannelsList />
      </div>
    </div>
  )
}

export default Tv
