const Escape = 27
const BackButtonLg = 461
const BackButtonSamsung = 10009

export const isBackButton = (e?: KeyboardEvent): boolean => {
  if (!e || !e.keyCode) return false

  const keyCode = e.keyCode

  return keyCode === Escape || keyCode === BackButtonLg || keyCode === BackButtonSamsung
}
