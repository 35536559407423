import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'

import { ContentModel, ContentType } from '@/models/content.model'
import { searchStateExtraReducers } from '@/modules/search/slice/extraReducers/searchState'
import { pageExtraReducers } from '@/modules/search/slice/extraReducers/page'
import { currentPageExtraReducers } from '@/modules/search/slice/extraReducers/currentPage'
import { contentIdExtraReducers } from '@/modules/search/slice/extraReducers/contentId'
import { APIStatus, IApiErrorResponse } from '@/core/api/api'
import { searchResultsExtraReducers } from '@/modules/search/slice/extraReducers/results'
import { CountryBaseModel } from '@/models/country.model'
import { GenreModel } from '@/models/genre.model'
import { PartnerBaseModel } from '@/models/partner.model'
import { TagModel } from '@/models/tag.model'

export interface SearchFilters {
  countries: CountryBaseModel[]
  years: string[]
  genres: GenreModel[]
  partners: PartnerBaseModel[]
  tags: TagModel[]
  type: ContentType | null
}

export interface SearchState {
  searchTerm: string
  filters: SearchFilters
  sort: string
  asc: boolean
}

export interface PageState {
  searchState: SearchState
  results: ContentModel[]
  currentPage: number
  totalItems: number
  totalPages: number
  contentId?: string
}

export interface SearchSlice {
  search: PageState
  partners: PageState
  tags: PageState
  genres: PageState
  status: APIStatus
  error?: IApiErrorResponse | null
}

const getInitialSearchParamsState = (): SearchState => {
  return {
    searchTerm: '',
    sort: '',
    asc: false,
    filters: {
      countries: [],
      years: [],
      genres: [],
      type: null,
      partners: [],
      tags: [],
    },
  }
}

export const getInitialPageState = (): PageState => {
  return {
    searchState: getInitialSearchParamsState(),
    results: [],
    currentPage: -1,
    totalPages: -1,
    totalItems: -1,
  }
}

const getInitialSearchState = (): SearchSlice => {
  return {
    search: getInitialPageState(),
    tags: getInitialPageState(),
    genres: getInitialPageState(),
    partners: getInitialPageState(),
    status: APIStatus.IDLE,
  }
}

export type PagesWithSearch = keyof Omit<SearchSlice, 'error' | 'status'>

export interface PageUpdatePayload<T = undefined> {
  pageName: PagesWithSearch
  payload?: T
}

export type SearchSliceBuilderType = ActionReducerMapBuilder<SearchSlice>

export const searchByPageSlice = createSlice({
  name: 'searchByPageSlice',
  initialState: getInitialSearchState(),
  reducers: {},
  extraReducers: (builder) => {
    pageExtraReducers(builder)
    searchStateExtraReducers(builder)
    currentPageExtraReducers(builder)
    contentIdExtraReducers(builder)
    searchResultsExtraReducers(builder)
  },
})
