import React from 'react'

import styles from './styles.module.scss'

import YearsList from '@/modules/search/components/YearsList'
import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface YearsFilterSidebarProps {
  onClose?: () => void
  chosenYears?: string[]
  disabledYears?: string[]
  pageName: PagesWithSearch
}

const YearsFilterSidebar = ({
  onClose,
  disabledYears,
  chosenYears,
  pageName,
}: YearsFilterSidebarProps) => {
  return (
    <CommonFilterSidebar onClose={onClose} title={'Годы'}>
      <YearsList
        pageName={pageName}
        className={styles.ItemsList}
        throttleScrollDelay={20}
        chosenYears={chosenYears}
        disabledYears={disabledYears}
      />
    </CommonFilterSidebar>
  )
}

export default YearsFilterSidebar
