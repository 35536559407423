import React, { FC, HTMLProps } from 'react'

import styles from './styles.module.scss'

import logo from '@/assets/images/logo.png'
import classNames from 'classnames'
import ImageLoader from '@/components/Image'

type DivProps = HTMLProps<HTMLDivElement>

interface LogoProps extends Omit<DivProps, 'className'> {
  className?: string
}

const Logo: FC<LogoProps> = ({ className, ...restProps }) => {
  return (
    <div className={classNames(styles.Logo, className && className)} {...restProps}>
      <ImageLoader src={logo} alt={'ITV'} skeleton roundedSkeleton />
    </div>
  )
}

export default Logo
