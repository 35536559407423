import React, { FC, useCallback, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultScrollingWrapper, {
  DefaultScrollingWrapperProps,
  OnChildFocus,
} from '@/components/Slider/DefaultScrollingWrapper'

import { useHandleWheelScroll } from '@/components/Slider/UseHandleWheelScrollHook'
import { FocusDetails } from '@noriginmedia/norigin-spatial-navigation'

export type OmittedDefaultScrollingWrapperProps = Omit<DefaultScrollingWrapperProps, 'onChildFocus'>

interface ColumnScrollingWrapperProps extends OmittedDefaultScrollingWrapperProps {
  setFocus?: (focusKey: string) => void
  getCurrentFocusKey?: () => string
  navigateByDirection?: (direction: string, focusDetails: FocusDetails) => void
}

const ColumnScrollingWrapper: FC<ColumnScrollingWrapperProps> = ({
  children,
  className,
  contentWrapperClassName,
  onChildPress,
  navigateByDirection,
}) => {
  const scrollingRef = useRef<HTMLDivElement>(null)

  useHandleWheelScroll(scrollingRef, navigateByDirection)

  const handleOnChildFocus: OnChildFocus = useCallback(
    (...args) => {
      const scrollNode = scrollingRef.current
      if (!scrollNode) return
      const [layout] = args

      const child = scrollNode.firstChild as HTMLDivElement

      // uncomment if last element should be in the end of scrollable area
      // if (layout.y + layout.height >= child.offsetHeight) {
      //   // for last scroll element
      //   child.style.transform = `translateY(-${child.offsetHeight - scrollNode.offsetHeight}px)`
      //   return
      // }

      child.style.transform = `translateY(-${layout.y}px)`
    },
    [scrollingRef.current],
  )

  return (
    <DefaultScrollingWrapper
      ref={scrollingRef}
      className={classNames(styles.ColumnScrollingWrapper, className)}
      contentWrapperClassName={classNames(styles.ContentWrapper, contentWrapperClassName)}
      onChildPress={onChildPress}
      onChildFocus={handleOnChildFocus}
    >
      {children}
    </DefaultScrollingWrapper>
  )
}

export default ColumnScrollingWrapper
