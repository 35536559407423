import React, { useLayoutEffect, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'

interface Props {
  title?: string
  focused?: boolean
  active?: boolean
  className?: string
}

const DefaultCardInnerForDarkSlider = ({ title, focused, active, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!ref.current) return

    // important!!! without that line you should set width manually for every slider card
    ref.current.style.width = `${ref.current.offsetWidth}px`
  }, [])
  return (
    <div
      ref={ref}
      className={classNames(styles.CardInner, className, {
        [styles.Active]: active,
        [styles.Focused]: focused,
      })}
    >
      {title ? title : <SkeletonView />}
    </div>
  )
}

export default DefaultCardInnerForDarkSlider
