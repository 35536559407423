import React, { memo, useEffect } from 'react'

import styles from './styles.module.scss'

import Loader from '@/components/Loader'

import { useNavigate } from 'react-router-dom'

import PlatformService from '@/core/services/platform.service'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { getFromLocalStorage } from '@/core/utils'
import ApiUtils from '@/core/api/api.utils'
import { AuthPaths, Paths } from '@/core/router/router.paths'

import { me, meSession, refresh, updateSessionById } from '@/modules/auth/auth.actions'
import { getProfiles, setProfile } from '@/modules/profile/profile.actions'
import { setAppState } from '@/modules/app/app.slice'
import { preparePlatformInfo } from '@/modules/auth/helpers'

const EnterToApp = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const hasUserId = useAppSelector((state) => !!state.auth.auth.data.loginData.user?.id)

  const hasSession = useAppSelector((state) => !!state.auth.auth.data.session)
  const sessionId = useAppSelector((state) => state.auth.auth.data.session?.id)
  const clientProfileId = useAppSelector((state) => state.auth.auth.data.session?.clientProfileId)

  const profileId = useAppSelector((state) => state.profile.data.profile?.profile.id)
  //   me request
  useEffect(() => {
    if (hasUserId) return

    const token = getFromLocalStorage(ApiUtils.accessTokenKey)
    if (!token) {
      navigate(Paths.AUTH)
      return
    }

    dispatch(me())
      .unwrap()
      .catch(() => {
        navigate(Paths.AUTH, { replace: true })
      })
  }, [hasUserId])

  //   session access
  useEffect(() => {
    if (!hasUserId) return

    dispatch(meSession())
      .unwrap()
      .catch((e) => {
        console.log(e)
        if (e && e.statusCode === 403 && e.message && Array.isArray(e.message)) {
          navigate(`${Paths.AUTH}/${AuthPaths.SESSIONS}`, {
            state: {
              sessions: e.message,
            },
          })

          return
        }
        navigate(Paths.AUTH, { replace: true })
      })
  }, [hasUserId])

  //   if it doesn't have linked profile navigate to profile choice
  useEffect(() => {
    if (!hasSession) return
    if (clientProfileId) return

    navigate(`${Paths.AUTH}/${AuthPaths.PROFILE_CHOICE}`)
  }, [hasSession, clientProfileId])

  useEffect(() => {
    if (!hasSession) return
    if (!clientProfileId) return

    dispatch(getProfiles({ profileId: clientProfileId }))
      .unwrap()
      .then((profiles) => {
        const profile = profiles.find((pr) => pr.id === clientProfileId)

        if (!profile) {
          navigate(Paths.AUTH, { replace: true })
          return
        }

        dispatch(setProfile(profile))
      })
      .catch(() => navigate(Paths.AUTH, { replace: true }))
  }, [hasSession, clientProfileId])

  useEffect(() => {
    if (!sessionId || !profileId) return

    PlatformService.fetchInfo()
      .then((info) => {
        dispatch(setAppState({ info }))

        const deviceApplicationInfoForUpdateSession = preparePlatformInfo(info)

        const data = { ...deviceApplicationInfoForUpdateSession }

        return dispatch(updateSessionById({ sessionId, data })).unwrap()
      })
      .then(() => {
        dispatch(refresh())
        navigate(Paths.CONTENT, { replace: true })
      })
      .catch((e) => {
        dispatch(refresh())
        navigate(Paths.CONTENT, { replace: true })
        console.error(' PlatformService.fetchInfo: ', e)
      })
  }, [sessionId, profileId])

  return (
    <div className={styles.EnterToApp}>
      <Loader>
        <Loader.Spinner />
      </Loader>
    </div>
  )
}

export default memo(EnterToApp)
