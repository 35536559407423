import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationTypes } from '@/models/notification.model'

interface Filter {
  label: string
  value: NotificationTypes | null
}

interface InitialState {
  filters: Filter[]
  currentFilter: NotificationTypes | null
}

const initialState: InitialState = {
  filters: [
    { value: null, label: 'Все' },
    { value: NotificationTypes.DEFAULT, label: 'Уведомления' },
    { value: NotificationTypes.SUBSCRIPTION, label: 'Подписки' },
    { value: NotificationTypes.OFFER, label: 'Предложения' },
  ],
  currentFilter: null,
}

export const notificationsSlice = createSlice({
  name: 'notificationsSlice',
  reducers: {
    setFilter: (state, action: PayloadAction<NotificationTypes | null>) => {
      state.currentFilter = action.payload
    },
    resetFilter: (state) => {
      state.currentFilter = null
    },
  },
  initialState,
})

export const { setFilter, resetFilter } = notificationsSlice.actions
