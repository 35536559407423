import React, { cloneElement, CSSProperties, FC, ReactElement, useCallback, useEffect } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import { isBoolean } from '@/core/isBoolean'

import { ExtendedFocusConfig } from '@/components/Slider/DefaultFocusWrapper'

export interface DefaultCardFocusWrapperProps /*extends UseFocusableConfig*/ {
  focusProps?: ExtendedFocusConfig
  disabled?: boolean
  onCardClick?: (...args: Any[]) => void
  onFocusedCardClick?: (...args: Any[]) => void
  autoFocus?: boolean
  className?: string
  wrapperClassName?: string
  focusClassName?: string
  children: ReactElement
  style?: CSSProperties
  width?: number
  border?: boolean
}

const DefaultCardFocusWrapper: FC<DefaultCardFocusWrapperProps> = ({
  children,
  disabled,
  onCardClick,
  autoFocus,
  className,
  wrapperClassName,
  style,
  border,
  onFocusedCardClick,
  focusProps,
  focusClassName,
}) => {
  const { ref, focused, focusSelf, focusKey, setFocus } = useFocusable({
    ...focusProps,
    focusable: isBoolean(disabled)
      ? !disabled
      : isBoolean(focusProps?.focusable)
      ? focusProps?.focusable
      : !disabled,
  })

  const handleCardClick = useCallback(
    (...args: Any[]) => {
      if (!focused) {
        if (disabled) return
        if (focusProps?.focusable === false) return

        focusSelf()
        return
      }
      onCardClick?.(...args)
      focused && onFocusedCardClick?.(...args)
    },
    [onCardClick, onFocusedCardClick, focusSelf, focused, disabled],
  )

  useEffect(() => {
    if (!autoFocus) return
    focusSelf()
  }, [autoFocus])

  return (
    <div
      id={focusKey}
      ref={ref}
      onClick={handleCardClick}
      style={style}
      className={classNames(styles.Wrapper, wrapperClassName && wrapperClassName)}
    >
      <div
        className={classNames(
          className,
          styles.FocusWrapper,
          border && styles.FocusWrapper__WithBorder,
          focused && !className && styles.FocusWrapper__Focused,
          focused && focusClassName && focusClassName,
          disabled && styles.FocusWrapper__Disabled,
        )}
      >
        {cloneElement(children, {
          ...children.props,
          focused,
          setFocus,
          // onCardClick: handleCardClick,
        })}
      </div>
    </div>
  )
}

export default DefaultCardFocusWrapper
