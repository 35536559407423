import React, { FC } from 'react'
import styles from './styles.module.scss'

import PartnersList from '@/modules/search/components/PartnersList'
import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'

import { PartnerBaseModel } from '@/models/partner.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface PartnersFilterSidebar {
  chosenPartners?: PartnerBaseModel[]
  disabledPartners?: string[]
  onClose?: () => void
  pageName: PagesWithSearch
}

const PartnersFilterSidebar: FC<PartnersFilterSidebar> = ({
  disabledPartners,
  chosenPartners,
  onClose,
  pageName,
}) => {
  return (
    <CommonFilterSidebar onClose={onClose} title={'Партнеры'}>
      <PartnersList
        className={styles.ItemsList}
        chosenPartners={chosenPartners}
        disabledPartners={disabledPartners}
        throttleScrollDelay={20}
        pageName={pageName}
      />
    </CommonFilterSidebar>
  )
}

export default PartnersFilterSidebar
