import React, { FC, useCallback } from 'react'

import styles from './styles.module.scss'

import Modal, { ModalProps } from '@/components/Modal'
import List from '@/components/List'
import NeedPackageCard from '@/modules/content/components/NeedPackageCard'

import { useNavigate } from 'react-router-dom'

import { CabinetPaths, Paths } from '@/core/router/router.paths'
import { RightsErrorMessage } from '@/models/rightsError.model'

interface NeedPackagesModalProps extends ModalProps {
  packages?: RightsErrorMessage[]
}

const NeedPackagesModal: FC<NeedPackagesModalProps> = ({ packages, ...props }) => {
  const navigate = useNavigate()

  const handlePackageCardClick = useCallback((card: RightsErrorMessage) => {
    if (!card) return
    navigate(`${Paths.CABINET}/${CabinetPaths.SUBSCRIPTIONS}/${card.id}`)
  }, [])

  if (!packages) return null
  return (
    <Modal
      render={() => {
        return (
          <div className={styles.NeedPackages}>
            <h2 className={styles.Title}>Для просмотра необходима подписка</h2>
            <List className={styles.List} scrollWrapperClassName={styles.ListScrollWrapper}>
              {packages.map((pack) => {
                return (
                  <NeedPackageCard
                    key={`${pack.id}-${pack.name}`}
                    card={pack}
                    onCardPress={handlePackageCardClick}
                  />
                )
              })}
            </List>
          </div>
        )
      }}
      {...props}
    />
  )
}

export default NeedPackagesModal
