import React, { memo } from 'react'
import styles from './styles.module.scss'

import ContentPlayerTitle from '@/modules/content/components/ContentPlayerTitle'
import dayjs from 'dayjs'
import { useAppSelector } from '@/core/store'

const ChannelPlayerTitle = () => {
  const currentProgram = useAppSelector((state) => state.tv.currentProgram)
  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)

  if (!chosenChannel || !currentProgram || chosenChannel.id !== currentProgram.channelId) return
  return (
    <ContentPlayerTitle
      title={
        <>
          <div>{currentProgram?.title}</div>
          <div className={styles.ChannelSubTitle}>
            {`${dayjs(currentProgram?.startTime).format('HH:mm')} - ${dayjs(
              currentProgram?.endTime,
            ).format('HH:mm')}`}
          </div>
        </>
      }
    />
  )
}

export default memo(ChannelPlayerTitle)
