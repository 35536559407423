import React, { FC, Fragment, ReactElement } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Slider, { SliderProps } from '@/components/Slider'

import { RowProps } from '@/components/Slider/Row'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { ContentWithViewModel } from '@/models/content.model'
import ViewCard from '@/modules/syncMediaView/components/ViewCard'
import NavigationCard from '@/modules/content/components/NavigationCard'

type RenderFn = () => ReactElement[]

export interface BaseViewSliderProps<T> extends Omit<SliderProps, 'children'> {
  rowProps?: Omit<RowProps, 'children'>
  children?: RenderFn
  onCardPress?: (item: T) => void
  onCardFocus?: OnChildFocus
}

export interface ViewSliderProps extends BaseViewSliderProps<ContentWithViewModel> {
  data?: ContentWithViewModel[]
  isLoading?: boolean
  skeletonItemsLength?: number
  withoutNavigationCard?: boolean
  specificAction?: (args: {
    setFocus?: (key: string) => void
    className?: string
  }) => React.ReactElement
}

const ViewSlider: FC<ViewSliderProps> = ({
  data,
  isLoading,
  rowProps,
  onCardFocus,
  onCardPress,
  skeletonItemsLength = 7,
  children,
  withoutNavigationCard,
  specificAction,
  ...sliderProps
}) => {
  return (
    <>
      {!isLoading && (!data || data.length === 0) ? null : (
        <Slider {...sliderProps}>
          <Slider.Horizontal
            title={'Я смотрю'}
            {...rowProps}
            className={classNames(styles.ScrollingWrapper, rowProps?.className)}
          >
            {children
              ? children()
              : data && data.length > 0 && !isLoading
              ? data
                  .map((el) => {
                    return (
                      <ViewCard
                        key={el.id}
                        card={el}
                        onCardFocus={onCardFocus}
                        onCardPress={onCardPress}
                      />
                    )
                  })
                  .concat([
                    !withoutNavigationCard ? (
                      <NavigationCard key={data.length} specificAction={specificAction} />
                    ) : (
                      <Fragment key={data.length} />
                    ),
                  ])
              : Array(skeletonItemsLength)
                  .fill('')
                  .map((el, index) => {
                    return <ViewCard key={index} />
                  })}
          </Slider.Horizontal>
        </Slider>
      )}
    </>
  )
}

export default ViewSlider
