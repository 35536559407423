import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { CountryBaseModel } from '@/models/country.model'

// ------------------------ COUNTRIES
export const addCountryToCountriesFilter = createAction(
  'searchSlice/addCountryToCountriesFilter',
  withPayloadType<PageUpdatePayload<CountryBaseModel>>(),
)
export const removeCountryFromCountriesFilter = createAction(
  'searchSlice/removeCountryFromCountriesFilter',
  withPayloadType<PageUpdatePayload<CountryBaseModel>>(),
)

export const setCountriesFilter = createAction(
  'searchSlice/setCountriesFilter',
  withPayloadType<PageUpdatePayload<CountryBaseModel[]>>(),
)

export const resetCountriesFilter = createAction(
  'searchSlice/resetCountriesFilter',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
