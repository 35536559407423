import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import SearchResultsList from '@/modules/search/components/SearchResultsList'

import { useSearchByPage } from '@/modules/search/hooks/useSearchByPage'

const TagSearchResults = () => {
  const { searchResult, handleLoadMore, handleOnCardPress, setActions, shouldShowLoadMore } =
    useSearchByPage('tags')
  if (!searchResult || !(searchResult.length > 0))
    return (
      <div className={classNames(styles.Results, styles.Results__Empty)}>Ничего не найдено</div>
    )
  return (
    <div className={styles.Results}>
      <SearchResultsList
        searchResult={searchResult}
        shouldShowLoadMore={shouldShowLoadMore}
        onInit={setActions}
        onCardPress={handleOnCardPress}
        onLoadMorePress={handleLoadMore}
      />
    </div>
  )
}

export default TagSearchResults
