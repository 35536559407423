import React, { CSSProperties, FC, useEffect } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { Alert, AlertProps, Callback, ContainerType, Events } from '@/components/Notifications'
import { containerStartUp } from '@/components/Notifications/notifications.helpers'
import { useRenderListAndQueue } from '@/components/Notifications/notifications.hooks'

export interface NetworkAlertsContainerProps {
  className?: string
  style?: CSSProperties
  id?: string
  limit?: number
  newestOnTop?: boolean
  networkStatus: boolean
}

const NetworkAlertsContainer: FC<NetworkAlertsContainerProps> = ({
  className,
  style,
  limit = 1,
  id,
  newestOnTop = true,
  networkStatus,
}) => {
  const { render, handleAdd, handleDelete } = useRenderListAndQueue<AlertProps>({
    limit,
    newestOnTop,
  })
  useEffect(() => {
    const containerId = id ? id : `${Date.now()}`

    const subs: { event: Events; cb: Callback }[] = [
      { event: Events.ADD_ALERT, cb: handleAdd },
      { event: Events.DELETE_ALERT, cb: handleDelete },
    ]
    const emit: Events[] = [Events.ALERTS_CONTAINER_MOUNTED]
    const cleanUpEmit: Events[] = [Events.ALERTS_CONTAINER_UNMOUNTED]

    const cleanUp = containerStartUp(
      containerId,
      ContainerType.ALERT_CONTAINER,
      subs,
      emit,
      cleanUpEmit,
    )

    return () => {
      cleanUp()
    }
  }, [])

  return (
    <div
      id={id}
      className={classNames(
        className,
        styles.AlertsContainer,
        render.length && styles.AlertsContainer__WithChilds,
      )}
      style={style}
    >
      {render.length > 0
        ? render.map((el) => {
            return (
              <div key={el.id} className={styles.AlertsContainer__Inner}>
                <Alert {...el} show={!networkStatus} />
              </div>
            )
          })
        : null}
    </div>
  )
}

export default NetworkAlertsContainer
