import React, { useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { AnimatePresence, motion } from 'framer-motion'

import ChosenContentDescription from '@/modules/content/components/ChosenContentDescription'

import {
  getContentPosterUrl,
  PosterExtension,
  getContentBackgroundUrl,
} from '@/modules/content/content.helpers'

import { useAppSelector } from '@/core/store'

function ChosenContent() {
  const card = useAppSelector((state) => state.content.chosenCard)

  const bgUrl = useMemo(() => {
    if (!card) return

    return getContentBackgroundUrl({
      content: card,
      extension: PosterExtension.WebP,
      query: {
        h: 1080,
        w: 1920,
        q: 70,
      },
    })
  }, [card])

  const posterUrl = useMemo(() => {
    if (!card) return

    return getContentPosterUrl({
      content: card,
      extension: PosterExtension.WebP,
      query: {
        h: 800,
        w: 1420,
        q: 70,
      },
    })
  }, [card])

  if (!card) return null

  return (
    <div className={classNames(styles.ChosenContent)}>
      <ChosenContentDescription card={card} />

      <div className={classNames(styles.ChosenContent__BgContainer)}>
        <div
          className={classNames(styles.ChosenContent__BgContainer__Overlay, {
            [styles.ChosenContent__BgContainer__Overlay__Background]: !!bgUrl,
          })}
        />
        <BG key={card.id} url={posterUrl} bgUrl={bgUrl} />
      </div>
    </div>
  )
}

const BG = ({ url, bgUrl }: { url?: string; bgUrl?: string }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          backgroundImage: `url(${bgUrl || url})`,
          height: bgUrl ? '1080px' : '800px',
          width: bgUrl ? '1920px' : '1420px',
        }}
        className={styles.Image}
      />
    </AnimatePresence>
  )
}

export default ChosenContent
