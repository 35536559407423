import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { CategoryModel } from '@/models/category.model'
import { ChannelModel } from '@/models/channel.model'
import { ProgramBaseModel } from '@/models/program.model'

export const setTvPlayerFullscreen = createAction(
  'tvSlice/setTvPlayerFullscreen',
  withPayloadType<boolean>(),
)

export const setChosenCategory = createAction(
  'tvSlice/setChosenCategory',
  withPayloadType<CategoryModel>(),
)

export const resetChosenCategory = createAction('tvSlice/resetChosenCategory')

export const setChosenChannel = createAction(
  'tvSlice/setChosenChannel',
  withPayloadType<ChannelModel>(),
)

export const resetChosenChannel = createAction('tvSlice/resetChosenChannel')

export const setFocusedChannel = createAction(
  'tvSlice/setFocusedChannel',
  withPayloadType<ChannelModel>(),
)

export const setCurrentProgram = createAction(
  'tvSlice/setCurrentProgram',
  withPayloadType<ProgramBaseModel>(),
)

export const resetCurrentProgram = createAction('tvSlice/resetCurrentProgram')

export const setLiveProgram = createAction(
  'tvSlice/setLiveProgram',
  withPayloadType<ProgramBaseModel>(),
)

export const resetLiveProgram = createAction('tvSlice/resetLiveProgram')

export const setLive = createAction('tvSlice/setLive', withPayloadType<boolean>())
