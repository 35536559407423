import React, {
  FC,
  ReactElement,
  MouseEvent,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Loader from '@/components/Loader'

export interface DefaultButtonProps {
  type?: 'button' | 'submit' | 'reset'
  focused?: boolean
  className?: string
  disabled?: boolean
  focusClassName?: string
  children?: string | ReactElement
  onButtonClick?: (e?: MouseEvent<HTMLButtonElement>) => void
  shouldHandleClickOnlyInFocus?: boolean
  loading?: boolean
}

const DefaultButton: FC<DefaultButtonProps> = ({
  focused,
  className,
  focusClassName,
  children,
  type,
  disabled,
  onButtonClick,
  shouldHandleClickOnlyInFocus = false,
  loading,
}) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const loaderRef = useRef<HTMLDivElement | null>(null)

  const [loader, setLoader] = useState(false)

  const handleOnButtonClick = useCallback(
    (e?: MouseEvent<HTMLButtonElement>) => {
      if (!onButtonClick) return
      if (!shouldHandleClickOnlyInFocus) {
        onButtonClick(e)
        return
      }
      if (!focused) return
      onButtonClick(e)
    },
    [focused, onButtonClick, shouldHandleClickOnlyInFocus],
  )

  useEffect(() => {
    const button = ref.current
    if (!button) return

    if (loading) {
      const width = button.offsetWidth
      const height = button.offsetHeight

      setLoader(true)

      button.style.width = `${width}px`
      button.style.height = `${height}px`
      return
    }

    setLoader(false)
  }, [loading])

  return (
    <button
      ref={ref}
      type={type}
      onClick={handleOnButtonClick}
      disabled={disabled}
      className={classNames(className && className, focused && focusClassName, styles.Reset)}
    >
      {loader && (
        <Loader ref={loaderRef} classname={styles.Loader}>
          <Loader.Spinner />
        </Loader>
      )}
      {!loader && children}
    </button>
  )
}

export default DefaultButton
