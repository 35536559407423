import React, { useMemo } from 'react'

import styles from './styles.module.scss'

import Filters from '@/modules/search/components/Filters'
import { useParams } from 'react-router-dom'
import { useGetTagByIdQuery } from '@/modules/tags/tags.api'

const TagFilters = () => {
  const { id } = useParams()

  const { data: tag } = useGetTagByIdQuery(
    { tagId: id || '' },
    {
      skip: !id,
    },
  )

  const chosenTags = useMemo(() => {
    if (!id || !tag) return

    return [tag]
  }, [id, tag])

  return <Filters pageName={'tags'} className={styles.Filters} chosenTags={chosenTags} />
}

export default TagFilters
