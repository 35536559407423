import React, { useMemo } from 'react'

import { ContentPaths } from '@/core/router/router.paths'
import { useAppDispatch } from '@/core/store'

import { resetChosenCategory, setChosenCategory } from '@/modules/tv/tv.actions'
import { useGetCategoriesHasChannelsQuery } from '@/modules/category/category.api'
import { TvCategoryId } from '@/modules/tv/tv.constants'

import MenuButton, { MenuButtonProps } from '@/modules/menu/components/MenuButton'

interface ApproveMenuItem extends MenuButtonProps {}

const ApproveMenuItem = (props: ApproveMenuItem) => {
  const { data: categories } = useGetCategoriesHasChannelsQuery()

  const sport = useMemo(() => {
    if (!categories) return

    return categories.find((cat) => cat.id === TvCategoryId.SPORT)
  }, [categories])

  const children = useMemo(() => {
    if (!categories) return

    return categories.find((cat) => cat.id === TvCategoryId.CHILDREN)
  }, [categories])
  const dispatch = useAppDispatch()
  const handleAction = () => {
    if (props.to === ContentPaths.CHILDREN && children) {
      dispatch(setChosenCategory(children))
      return
    }

    if (props.to === ContentPaths.SPORT && sport) {
      dispatch(setChosenCategory(sport))
      return
    }

    if (props.to === ContentPaths.TV) {
      dispatch(resetChosenCategory())
      return
    }
  }
  return <MenuButton {...props} onButtonClick={handleAction} />
}

export default ApproveMenuItem
