import { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Drm, DrmLicenceServers } from '@/models/streamer.model'
import { StreamsModel } from '@/models/streams.model'
import PlayerDrmManager from '@/modules/player/player.drm.manager'
import PlayerUrlManager, {
  PreparedLanguage,
  PreparedQuality,
} from '@/modules/player/player.url.manager'

const playerDrmManager = PlayerDrmManager.getInstance()
const playerUrlManager = PlayerUrlManager.getInstance()

export type UrlState = {
  url: string
  drm: Drm | null
  quality: PreparedQuality | null
  qualities: PreparedQuality[]
  language: PreparedLanguage | null
  languages: PreparedLanguage[]
}

export type PlayerSourceRenderParameters = UrlState & {
  changeResolution: (quality: PreparedQuality) => void
  changeLanguage: (language: PreparedLanguage) => void
}

export type SourceManagerChild = (params: PlayerSourceRenderParameters) => ReactElement

export interface PlayerSourceManagerProps {
  urls?: StreamsModel[] | string
  drm?: DrmLicenceServers | null
  children: SourceManagerChild
  preferredLanguage?: string
}

const PlayerSourceManager = (props: PlayerSourceManagerProps) => {
  const [state, setState] = useState<UrlState>({
    url: '',
    drm: null,
    quality: null,
    qualities: [],
    language: null,
    languages: [],
  })

  useEffect(() => {
    if (!props.drm) return

    playerDrmManager.setServers(props.drm)
    const servers = playerDrmManager.getServers()

    setState((prev) => ({ ...prev, drm: { servers } }))
  }, [props.drm])

  useEffect(() => {
    if (!props.urls) {
      setState((prev) => ({ ...prev, url: '' as string }))
      return
    }

    if (typeof props.urls === 'string') {
      setState((prev) => ({ ...prev, url: props.urls as string }))
      return
    }

    playerUrlManager.setUrls(props.urls)

    const url = playerUrlManager.getUrlByPreferredLanguageOrFirst(props.preferredLanguage)

    if (!url) return

    //  sort qualities and get max
    const quality = url.qualities.sort((a, b) => {
      return a.value > b.value ? -1 : 1
    })[0]

    const qualities = url.qualities
    const language = url.language
    const languages = playerUrlManager.getLanguages()

    setState((prev) => ({
      ...prev,
      // todo: add check for first source with OPTIONS REQUEST
      url: quality.sources[0].url,
      quality,
      qualities,
      language,
      languages,
    }))
  }, [props.urls])

  const changeResolution = useCallback((quality: PreparedQuality) => {
    setState((prev) => ({ ...prev, url: quality.sources[0].url, quality }))
  }, [])
  //
  const changeLanguage = useCallback((language: PreparedLanguage) => {
    const qualities = playerUrlManager.getResolutionsByLanguageId(language.id)
    const quality = qualities[0]

    setState((prev) => ({ ...prev, url: quality.sources[0].url, quality, qualities, language }))
  }, [])
  //
  const sourceState = useMemo(() => {
    return { ...state, changeResolution, changeLanguage }
  }, [state, changeResolution, changeLanguage])
  return props.children(sourceState)
}

export default memo(PlayerSourceManager)
