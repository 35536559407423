import { DrmLicenceServers, DrmServers, DrmTypes } from '@/models/streamer.model'

class PlayerDrmManager {
  static #instance: PlayerDrmManager | null = null

  #servers: DrmServers | null = null

  constructor(rawServers?: DrmLicenceServers) {
    if (rawServers) this.#servers = this.#prepareServers(rawServers)
  }

  static getInstance(rawServers?: DrmLicenceServers) {
    if (!this.#instance) this.#instance = new PlayerDrmManager(rawServers)
    return this.#instance
  }

  setServers(rawServers: DrmLicenceServers) {
    this.#servers = this.#prepareServers(rawServers)
  }

  getServers() {
    return this.#servers
  }

  #prepareServers(servers: DrmLicenceServers) {
    const result = {} as DrmServers

    if (servers.licence_server_playready) {
      result[DrmTypes.PLAYREADY] = servers.licence_server_playready
    }
    if (servers.licence_server_widevine) {
      result[DrmTypes.WIDEVINE] = servers.licence_server_widevine
    }
    if (servers.licence_server_fairplay) {
      result[DrmTypes.FAIRPLAY] = servers.licence_server_fairplay
    }

    if (Object.keys(result).length) return result

    return null
  }
}

export default PlayerDrmManager
