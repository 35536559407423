import React, { FC, useEffect, useRef } from 'react'
import styles from './styles.module.scss'

interface AnimatedPlayPauseProps {
  isPaused: boolean
}

const AnimatedPlayPause: FC<AnimatedPlayPauseProps> = ({ isPaused }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    if (!ref.current) return

    ref.current.classList.add(styles.Show)
  }, [isPaused])

  return (
    <div
      ref={ref}
      className={styles.Animated}
      onAnimationEnd={() => {
        if (!ref.current) return
        ref.current.classList.remove(styles.Show)
      }}
    >
      {isPaused ? <i className={'icon-fill-pause'} /> : <i className={'icon-fill-play'} />}
    </div>
  )
}

export default AnimatedPlayPause
