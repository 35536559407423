import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { v4 } from 'uuid'

import { useAppSelector } from '@/core/store'
import PlatformService from '@/core/services/platform.service'

import { premierStatisticsApi } from '@/modules/statistics/premier.stat.api'
import { useSendStatistic } from '@/modules/player/hooks/useSendStatistics'
import { useSendStatisticsCallbacks } from '@/modules/player/hooks/useSendStatisticsCallbacks'

import { PartnerValue } from '@/models/partner.model'
import { Platform } from '@/models/platform.model'
import { ContentType } from '@/models/content.model'

import type { MovieBaseModel } from '@/models/movie.model'
import type { EpisodeBaseModel } from '@/models/serial.model'
import type { ContentModel } from '@/models/content.model'
import type { SendHBOptions } from '@/modules/player/hooks/useSendStatistics'
import type { EventsRenderParameters } from '@/modules/player/Player/EventsRenderParams.type'

export interface PlayerMediaEventManagerProps extends Partial<EventsRenderParameters> {
  disabled?: boolean

  content: ContentModel | null
  episode?: EpisodeBaseModel | null
  movie?: MovieBaseModel | null
  partnerPlaybackPath?: string

  children: (params: Partial<EventsRenderParameters>) => ReactElement
}

const VIEW_POINT = 300
const HB = 30

export const PlayerPremierEventManager = ({
  disabled: extDisabled,
  content,
  partnerPlaybackPath,
  episode,
  movie,
  ...props
}: PlayerMediaEventManagerProps) => {
  const disabled = useMemo(() => {
    return (
      extDisabled ||
      !content ||
      !content.partner ||
      content.partner.type !== PartnerValue.PREMIER ||
      !partnerPlaybackPath
    )
  }, [extDisabled, content, partnerPlaybackPath])
  const disabledRef = useRef(disabled)
  disabledRef.current = disabled

  const partnerPlaybackPathRef = useRef(partnerPlaybackPath)
  partnerPlaybackPathRef.current = partnerPlaybackPath

  const episodeRef = useRef(episode)
  episodeRef.current = episode

  const movieRef = useRef(movie)
  movieRef.current = movie

  const billingId = useAppSelector(
    (state) => state.auth.auth.data.loginData?.user?.client?.billingId,
  )
  const billingIdRef = useRef(billingId)
  billingIdRef.current = billingId

  const contentRef = useRef(content)
  contentRef.current = content

  const videoElRef = useRef<HTMLVideoElement | null>(null)

  const groupIdRef = useRef<string | null>(null)
  const userAgentIdRef = useRef<string | null>(null)

  const sendView = useCallback(({ isViewPointAchievedRef, resetCounter }: SendHBOptions) => {
    if (disabledRef.current) return

    const content_id = partnerPlaybackPathRef.current
    if (!content_id) return

    const user_id = billingIdRef.current
    if (!user_id) return

    const view_id = groupIdRef.current
    if (!view_id) return

    const user_agent_id = userAgentIdRef.current
    if (!user_agent_id && PlatformService.platform !== Platform.LOCAL) return

    const data = {
      content_id,
      user_id,
      view_id,
      user_agent_id,
    }

    isViewPointAchievedRef.current = true

    premierStatisticsApi.sendView(data)

    resetCounter()
  }, [])

  const sendHB = useCallback(
    ({ isViewPointAchievedRef, counterRef, resetCounter }: SendHBOptions) => {
      if (disabledRef.current) return

      if (!isViewPointAchievedRef.current) return
      if (counterRef.current < HB) return

      const view_id = groupIdRef.current
      if (!view_id) return

      const secs = counterRef.current
      if (secs === 0) return

      const videoEl = videoElRef.current
      if (!videoEl) return

      const position = videoEl.currentTime

      const data = {
        view_id,
        position,
        secs,
      }

      premierStatisticsApi.sendHB(data)

      resetCounter()
    },
    [],
  )

  const {
    resetCounterInterval,
    resetTotalCount,
    resetCounter,
    resetIsViewPointAchieved,
    startCount,
    send,
  } = useSendStatistic({
    sendViewCallback: sendView,
    sendHBCallback: sendHB,
    viewPoint: VIEW_POINT,
    hb: HB,
    hbBefore: 0,
  })

  const callbacks = useSendStatisticsCallbacks({
    videoElRef,
    send,
    startCount,
    resetCounterInterval,
    resetCounter,
    resetIsViewPointAchieved,
    resetTotalCount,
    ...props,
  })

  useEffect(() => {
    PlatformService.fetchInfo().then((result) => {
      const user_agent_id = result.product?.uid

      userAgentIdRef.current = user_agent_id || null
    })
  }, [])

  // for movies
  useEffect(() => {
    if (!content) return
    if (content.type === ContentType.SERIAL) return

    // reset before start count
    groupIdRef.current = v4()
    resetCounter()
    resetTotalCount()
    resetIsViewPointAchieved()

    return () => {
      resetCounterInterval()
      send()
    }
  }, [movie])

  // for serials
  useEffect(() => {
    if (!content) return
    if (content.type === ContentType.MOVIE) return
    if (!episode) return

    // reset before start count
    groupIdRef.current = v4()
    resetCounter()
    resetTotalCount()
    resetIsViewPointAchieved()

    return () => {
      groupIdRef.current = null
      resetCounterInterval()
      send()
    }
  }, [episode])

  const renderParams: Partial<EventsRenderParameters> = useMemo(() => {
    return {
      ...props,
      ...callbacks,
    }
  }, [callbacks])

  return props.children(renderParams)
}
