import React, { FC, useCallback, useRef } from 'react'
import VirtualScrollingWrapper from '@/components/Slider/VirtualScrollingWrapper'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { OmittedDefaultScrollingWrapperProps } from '@/components/Slider/RowScrollingWrapper'
import classNames from 'classnames'
import styles from '@/components/Slider/Row/styles.module.scss'

export interface VirtualRowScrollingWrapperProps extends OmittedDefaultScrollingWrapperProps {
  setFocus?: (focusKey: string) => void
  getCurrentFocusKey?: () => string
  width?: number
  height?: number
  overscanCount?: number
  title?: string
  titleClassName?: string
  children: React.ReactElement[]
}

const VirtualRowScrollingWrapper: FC<VirtualRowScrollingWrapperProps> = ({
  className,
  onChildPress,
  children,
  height,
  width,
  overscanCount,
  titleClassName,
  title,
}) => {
  const virtualScrollingRef = useRef<HTMLDivElement | null>(null)

  const handleChildFocus: OnChildFocus = useCallback(
    (...args) => {
      const layout = args[0]

      const { x } = layout

      const scrollNode = virtualScrollingRef.current

      if (!scrollNode) return
      scrollNode.scrollTo({
        left: x - 170,
        behavior: 'smooth',
      })
    },
    [virtualScrollingRef.current],
  )
  return (
    <>
      {title && <div className={classNames(styles.Title, titleClassName)}>{title}</div>}
      <VirtualScrollingWrapper
        ref={virtualScrollingRef}
        className={className}
        layout={'horizontal'}
        overscanCount={overscanCount || 1}
        width={width || 1920}
        height={height || 258}
        onChildPress={onChildPress}
        onChildFocus={handleChildFocus}
      >
        {children}
      </VirtualScrollingWrapper>
    </>
  )
}

export default VirtualRowScrollingWrapper
