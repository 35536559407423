import { ContentType } from '@/models/content.model'
import {
  PagesWithSearch,
  PageUpdatePayload,
  SearchFilters,
} from '@/modules/search/slice/search.slice'
import { SearchArgs } from '@/modules/search/slice/search.service'
import { AppDispatch } from '@/core/store/store'
import { makeSearch } from '@/modules/search/slice/search.async.actions'

export const preparePaginate = (page: number, perPage: number): string => {
  return `${page}:${perPage}`
}

export const prepareOrderForSort = (asc: boolean, sort?: string): string | undefined => {
  return !sort ? undefined : `${sort}:${asc ? 'ASC' : 'DESC'}`
}

export const prepareContentTypeForSearch = (type: ContentType | null): ContentType | undefined => {
  return type || undefined
}

export const prepareArrayOfStringsForSearch = (arr: string[]): string[] | undefined => {
  return arr.length > 0 ? arr : undefined
}

export const prepareArrayOfObjectsToArrOfStringsForSearch = (
  arr: { id: string }[],
): string[] | undefined => {
  return prepareArrayOfStringsForSearch(arr.map((el) => el.id))
}

export const prepareSearchTerm = (term: string): string | undefined => {
  return term || undefined
}

export const prepareSearchQueryPayload = (filters: SearchFilters, searchTerm: string) => {
  const { genres, partners, countries, years, tags, type } = filters

  return {
    type: prepareContentTypeForSearch(type),
    genreIds: prepareArrayOfObjectsToArrOfStringsForSearch(genres),
    countryIds: prepareArrayOfObjectsToArrOfStringsForSearch(countries),
    partnerIds: prepareArrayOfObjectsToArrOfStringsForSearch(partners),
    tagsIds: prepareArrayOfObjectsToArrOfStringsForSearch(tags),
    years: prepareArrayOfStringsForSearch(years),
    title: prepareSearchTerm(searchTerm),
  }
}

export const prepareMakeSearchPayload = (
  page: number,
  perPage: number,
  filters: SearchFilters,
  searchTerm: string,
  asc: boolean,
  sort?: string,
): SearchArgs => {
  return {
    paginate: preparePaginate(page, perPage),
    order: prepareOrderForSort(asc, sort),
    searchData: prepareSearchQueryPayload(filters, searchTerm),
  }
}

const pureMakeSearch = async (dispatch: AppDispatch, searchArgs: PageUpdatePayload<SearchArgs>) => {
  return dispatch(makeSearch(searchArgs)).unwrap()
}

export const purePrepareDataAndMakeSearch = async (
  dispatch: AppDispatch,
  pageName: PagesWithSearch,
  page: number,
  perPage: number,
  filters: SearchFilters,
  searchTerm: string,
  asc: boolean,
  sort?: string,
) => {
  return pureMakeSearch(dispatch, {
    pageName,
    payload: prepareMakeSearchPayload(page, perPage, filters, searchTerm, asc, sort),
  })
}
