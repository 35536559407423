export enum ClientTvEventSocketMessage {
  JOIN_CHANNEL = 'client:tv-event:join-channel',
  LEAVE_CHANNEL = 'client:tv-event:leave-channel',

  VIEW_CHANNEL = 'client:tv-event:view-channel',
}

export enum ClientMediaEventSocketMessage {
  // JOIN_CONTENT = 'client:media-event:join-content',
  // LEAVE_CONTENT = 'client:media-event:leave-content',

  VIEW_CONTENT = 'client:media-event:view-content',
}
