import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { UnionContentType } from '@/modules/promotes/promotes.api'
import { TagModel } from '@/models/tag.model'
import { ContentModel, ContentRelations } from '@/models/content.model'
import { prepareContentRelations } from '@/core/helpers'

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllTags: builder.query<TagModel[], void>({
      query: () => ({
        url: `clients/me/tags/has/contents`,
        method: 'GET',
      }),
    }),

    getTagById: builder.query<TagModel, { tagId: string }>({
      query: ({ tagId }) => ({
        url: `tags/${tagId}`,
        method: 'GET',
      }),
    }),

    getTagsByType: builder.query<TagModel[], { type: UnionContentType }>({
      query: ({ type }) => ({
        url: `clients/me/tags/has/${type}`,
        method: 'GET',
      }),
    }),

    getContentByTagByIdAndType: builder.query<
      ContentModel[],
      { tagId: string; type: UnionContentType; relations?: Partial<ContentRelations> }
    >({
      query: ({ type, tagId, relations: rel }) => {
        const relations = prepareContentRelations(rel || {})
        const params = { relations }

        return {
          url: `clients/me/tags/${tagId}/${type}`,
          method: 'GET',
          params,
        }
      },
    }),
  }),
})

export const { useGetTagByIdQuery, useGetAllTagsQuery } = tagsApi
