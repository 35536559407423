import React from 'react'

import Loader from '@/components/Loader'
import PlayerManager from '@/modules/player/player.manager'
import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

const LoaderForUnFullScreen = () => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )
  const isBuffering = usePlayerStateSelector((state) => state.video.isBuffering)
  const isLoading = usePlayerStateSelector((state) => state.screen.isLoading)

  if (isBuffering || isLoading) {
    return (
      <Loader>
        <Loader.Spinner />
      </Loader>
    )
  }
  return null
}

export default LoaderForUnFullScreen
