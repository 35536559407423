import React, { useCallback, useEffect } from 'react'

import { EnterPressHandler, FocusHandler } from '@noriginmedia/norigin-spatial-navigation'

import DefaultCardFocusWrapper, {
  DefaultCardFocusWrapperProps,
} from '@/components/DefaultCardFocusWrapper'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'

export interface DefaultCardProps<T = Any>
  extends Omit<DefaultCardFocusWrapperProps, 'onCardClick'> {
  card?: T
  onCardFocus?: OnChildFocus<T>
  onCardPress?: (item: T) => void
  scrollIndex?: number
}
const cardReadyEvent = new Event('cardReadyEvent')

function DefaultCard<T>({
  card,
  focusProps,
  onCardFocus,
  scrollIndex,
  onCardPress,
  children,
  ...props
}: DefaultCardProps<T>) {
  const handleOnFocus: FocusHandler = useCallback(
    (...args) => {
      onCardFocus?.(...args, scrollIndex, card)

      focusProps?.onFocus?.(...args, scrollIndex)
    },
    [focusProps, onCardFocus, scrollIndex],
  )

  const handleOnEnterPress: EnterPressHandler = useCallback(
    (...args) => {
      focusProps?.onEnterPress?.(...args)
      if (!card) return

      onCardPress?.(card)
    },
    [onCardPress, card],
  )

  const handleOnCardClick: EnterPressHandler = useCallback(() => {
    if (!card) return

    onCardPress?.(card)
  }, [onCardPress, card])

  useEffect(() => {
    if (!card) return

    document.dispatchEvent(cardReadyEvent)
  }, [])

  return (
    <DefaultCardFocusWrapper
      {...props}
      focusProps={{
        ...focusProps,
        onFocus: handleOnFocus,
        onEnterPress: handleOnEnterPress,
      }}
      onFocusedCardClick={handleOnCardClick}
    >
      {children}
    </DefaultCardFocusWrapper>
  )
}

export default DefaultCard
