import axios, { AxiosInstance, isAxiosError } from 'axios'
import {
  LanguageCodes,
  LanguageLabels,
  QualityLabels,
  QualityValue,
  Source,
  SourceType,
  StreamsModel,
} from '@/models/streams.model'
import { VOICES } from '@/modules/seasonvar/seasonvar.constants'
import { SEASONVAR_URL } from '@/core/config'

const getSecureMarkByDocument = (document: Document) => {
  const secureMarkEls = document.querySelectorAll('.pgs-player > script[type="text/javascript"]')
  const secureMarkText = secureMarkEls[secureMarkEls.length - 1].innerHTML
  const secureMarkJson = secureMarkText.replace('var data4play = ', '').replace(/'/g, '"').trim()

  const { secureMark } = JSON.parse(secureMarkJson)

  return secureMark
}

export interface GetPlaylistResponse {
  title: string
  file: string
  subtitle: string
  galabel: string
  id: string
  vars: string
}

interface RawPlaylist extends GetPlaylistResponse {
  voice: string
}

const getManyVoiceByDocument = async (document: Document, api: AxiosInstance) => {
  const serialInfoEl = document.querySelector('.pgs-sinfo')
  if (!serialInfoEl) return
  const seasonId = serialInfoEl.getAttribute('data-id-season')
  const serialId = serialInfoEl.getAttribute('data-id-serial')

  if (!serialId || !seasonId) return
  const secureMark = getSecureMarkByDocument(document)

  const data = new FormData()
  data.append('id', seasonId)
  data.append('serial', serialId)
  data.append('secure', secureMark)

  return api
    .post('player.php', data)
    .then((res) => res.data)
    .then((htmlString) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')

      const translateEls = doc.querySelectorAll<HTMLElement>(
        'ul.pgs-trans > li[data-click="translate"]',
      )

      const elements = Array.from(translateEls.values())

      return elements.map((el) => {
        if (el.innerText === 'Стандартный') return ''
        return el.innerText
      })
    })
}

const getPlaylistDetailByDocument = async (
  document: Document,
  api: AxiosInstance,
): Promise<RawPlaylist[][]> => {
  const seasonIdEl = document.querySelector('.pgs-sinfo[data-id-season]')!
  const seasonId = seasonIdEl.getAttribute('data-id-season')!

  const secureMark = getSecureMarkByDocument(document)

  let voices: string[] = []

  const voicesFromDocument = await getManyVoiceByDocument(document, api)

  if (!voicesFromDocument) {
    voices = ['']
  }

  if (voicesFromDocument) {
    voices = voicesFromDocument.filter((voice) => VOICES.includes(voice))
  }

  if (voices.length === 0) {
    voices = ['']
  }

  const playlistPs = voices.map((voice) => {
    return new Promise<RawPlaylist[]>((resolve, reject) => {
      const url = `playls2/${secureMark}/trans${voice}/${seasonId}/plist.txt`
      api<GetPlaylistResponse[]>(url)
        .then((response) => {
          const data: RawPlaylist[] = response.data.map((data) => ({ ...data, voice }))
          return resolve(data)
        })
        .catch(reject)
    })
  })
  const results = await Promise.allSettled(playlistPs)

  return results.reduce((previousValue, currentValue) => {
    if (currentValue.status === 'fulfilled') {
      previousValue.push(currentValue.value)
    } else if (currentValue.status === 'rejected') {
      console.error(`ERROR (PLAYLIST): ${currentValue.reason.message}`)
    }

    return previousValue
  }, [] as RawPlaylist[][])
}

interface ConvertPlaylist {
  id: string
  quality: string
  voice: string
  url: string
}

const convertFileToUrl = (file: string) => {
  const base64 = file.replace(/(\/\/b2xvbG8=)|(#2)/g, '')
  return atob(base64)
}

const convertPlaylistItem = (item: RawPlaylist) => {
  const url = convertFileToUrl(item.file)

  let quality = 'SD'
  const qualityMatch = item.title.match(/(SD\/FullHD)|(FullHD)|(HD)|(SD)/gi)
  if (qualityMatch && qualityMatch.length && typeof qualityMatch[0] === 'string')
    quality = qualityMatch[0]

  if (quality === 'SD/FullHD') quality = 'SD'
  else if (quality === 'FullHD') quality = 'FHD'

  return { id: item.id, quality, voice: item.voice, url }
}

const convertPlaylistToEpisodeByEpisodeId = (
  playlist: RawPlaylist[][],
  episodeId: string,
): ConvertPlaylist[] => {
  return playlist
    .reduce((previousValue, currentValue) => {
      const episode = currentValue.find((item) => item.id === episodeId)
      if (episode) return [...previousValue, episode]

      return previousValue
    }, [])
    .reduce((previousValue, currentValue) => {
      if (!currentValue.file) return previousValue

      const playlistItem = convertPlaylistItem(currentValue)
      previousValue.push(playlistItem)

      return previousValue
    }, [] as ConvertPlaylist[])
}

export const getStreams = async (
  playbackPath: string,
): Promise<StreamsModel[] | { message: string; status: number }> => {
  const instance = axios.create({
    baseURL: SEASONVAR_URL,
  })

  const [episodeId, serialHref] = playbackPath.split(/__(.*)/s)

  try {
    const { data: htmlString } = await instance.get<string>('http://seasonvar.ru' + serialHref)

    const parser = new DOMParser()
    const document = parser.parseFromString(htmlString, 'text/html')

    const playlistDetail = await getPlaylistDetailByDocument(document, instance)

    const playlist = convertPlaylistToEpisodeByEpisodeId(playlistDetail, episodeId)

    return playlist.map(({ url, quality, voice }) => {
      const urls = url.split(' or ').reverse()
      const sources: Source[] = urls.map((url) => ({ url, type: SourceType.MP4 }))
      const language = voice === '' ? 'Стандартный' : voice

      return {
        sources,
        language: { value: voice as LanguageCodes, label: language as LanguageLabels },
        quality: {
          value: QualityValue[quality as keyof typeof QualityValue],
          label: QualityLabels[quality as keyof typeof QualityLabels],
        },
      }
    })
  } catch (e) {
    if (!isAxiosError(e)) {
      return { message: serialHref, status: 400 }
    }

    return { message: serialHref, status: e.response?.status || 400 }
  }
}
