import React, { useCallback, useEffect } from 'react'

import {
  allCaptureKeys,
  getKeyboardShortcutBlocker,
} from '@/modules/player/helpers/keyboard.helper'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'
import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

interface PlayerKeyboardManagerProps {
  isFullScreen?: boolean
  toggleFullscreen?: () => void
  togglePlayPause?: () => Promise<void>
  onKeyPressed: () => void

  children: () => React.ReactElement
}

const PlayerKeyboardManager = ({
  children,
  onKeyPressed,
  togglePlayPause,
}: PlayerKeyboardManagerProps) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isLive = usePlayerStateSelector((state) => state.video.isLive)
  const isBuffering = usePlayerStateSelector((state) => state.video.isBuffering)

  // Subscribe and unsubscribe every video and progress state (closure trouble shouting)
  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isLive, isBuffering, togglePlayPause])

  // Just preventDefault and stopPropagation for capture keys
  // ATTENTION: it's true, only after player loaded
  // else will be default browser behavior
  const handleKeyDown = useCallback(getKeyboardShortcutBlocker(allCaptureKeys, onKeyPressed), [])

  const handleKeyUp = (e: KeyboardEvent) => {
    const key = e.key
    e.preventDefault()

    // Handle space key ( toggle play/pause)
    if (key === ' ' || key === 'Space' || key === 'Pause' || key === 'XF86PlayBack') {
      if (isBuffering || isLive) return
      togglePlayPause?.()
    }
  }
  return children()
}

export default PlayerKeyboardManager
