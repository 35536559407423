import { PlayMode } from '@/modules/player/components/controls/Timeline/TimelineTooltip'

export const formatTimeComponent = (
  integer: number,
  separator = '',
  emptyIfZero = false,
  pad = true,
): string => {
  return emptyIfZero && integer === 0
    ? ''
    : integer < 10 && pad
    ? `0${integer}${separator}`
    : `${integer}${separator}`
}

export const formatTime = (seconds: number, negativeMark = '-'): string => {
  let rounded = Math.round(seconds)
  let includedNegativeMark = ''
  if (typeof seconds !== 'number' || isNaN(seconds) || seconds === Infinity) {
    rounded = 0
  } else if (rounded < 0) {
    rounded = -rounded
    includedNegativeMark = negativeMark
  }
  const days = Math.floor(rounded / 86400)
  const daysInSeconds = days * 86400
  const hours = Math.floor((rounded - daysInSeconds) / 3600)
  const hoursAndDaysInSeconds = daysInSeconds + hours * 3600
  const minutes = Math.floor((rounded - hoursAndDaysInSeconds) / 60)
  const secs = rounded - hoursAndDaysInSeconds - minutes * 60
  return (
    includedNegativeMark +
    formatTimeComponent(days, '.', true, false) +
    formatTimeComponent(hours, ':', hours === 0) +
    formatTimeComponent(minutes, ':', false) +
    formatTimeComponent(secs)
  )
}

export const formatClockTime = (date: Date) => {
  const isValidDate = date instanceof Date && !isNaN(date.getTime())
  let hours = 0
  let minutes = 0
  let seconds = 0
  if (isValidDate && date != null) {
    hours = isValidDate ? date.getHours() : 0
    minutes = isValidDate ? date.getMinutes() : 0
    seconds = isValidDate ? date.getSeconds() : 0
  }
  return (
    formatTimeComponent(hours, ':', false) +
    formatTimeComponent(minutes, ':', false) +
    formatTimeComponent(seconds)
  )
}

export const getTimeDisplay = (
  playMode?: PlayMode | null,
  absoluteStartPosition?: Date | null,
  previewValue?: number | null,
): string => {
  if (previewValue !== null && previewValue !== undefined) {
    if (
      playMode === 'livedvr' &&
      absoluteStartPosition instanceof Date &&
      absoluteStartPosition.getTime() > 0
    ) {
      return formatClockTime(new Date(absoluteStartPosition.getTime() + previewValue * 1000))
    } else {
      return formatTime(previewValue)
    }
  } else {
    return ''
  }
}
