import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'

import SocketClient from '@/core/sockets/client'
import { useAppSelector } from '@/core/store'
import { getFromLocalStorage } from '@/core/utils'
import { SOCKET_TV_EVENT_URL, TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY } from '@/core/config'

import type { SendHBOptions } from '@/modules/player/hooks/useSendStatistics'
import { EventTypes, useSendStatistic } from '@/modules/player/hooks/useSendStatistics'
import { useSendStatisticsCallbacks } from '@/modules/player/hooks/useSendStatisticsCallbacks'

import { ClientTvEventSocketMessage } from '@/core/sockets/messages'

import type { ChannelModel } from '@/models/channel.model'
import type { ClientViewChannelDto } from '@/core/sockets/dto/clientViewChannel.dto'
import type { TVEventsOptions } from '@/core/sockets/connect.subsciptions'
import { EventsRenderParameters } from '@/modules/player/Player/EventsRenderParams.type'

export interface PlayerTvEventManagerProps extends Partial<EventsRenderParameters> {
  disabled?: boolean

  channel: ChannelModel | null

  children: (params: Partial<EventsRenderParameters>) => ReactElement
}

const socketClient = SocketClient.getInstance()

const OPTIONS = getFromLocalStorage<TVEventsOptions>(TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY)

const HB_BEFORE_VIEW_POINT = OPTIONS?.beforeViewChannelHb || 30
const VIEW_POINT = OPTIONS?.viewChannelPoint || 60
const HB = OPTIONS?.afterViewChannelHb || 300

export const PlayerTvEventManager = ({
  disabled: extDisabled,
  channel,
  children,
  ...props
}: PlayerTvEventManagerProps) => {
  const disabled = useMemo(() => !!extDisabled, [extDisabled])
  const disabledRef = useRef(disabled)
  disabledRef.current = disabled

  const client = useAppSelector((state) => state.auth.auth.data.loginData?.user?.client)
  const clientRef = useRef(client)
  clientRef.current = client

  const channelRef = useRef(channel)
  channelRef.current = channel

  const videoElRef = useRef<HTMLVideoElement | null>(null)

  const groupIdRef = useRef<string | null>(null)

  const sendHB = useCallback(
    ({ totalCountRef, isViewPointAchievedRef, counterRef, resetCounter, type }: SendHBOptions) => {
      if (disabledRef.current) return

      if (totalCountRef.current >= VIEW_POINT && !isViewPointAchievedRef.current) {
        isViewPointAchievedRef.current = true
      }

      const client = clientRef.current
      if (!client) return

      const channel = channelRef.current
      if (!channel) return

      const duration = counterRef.current
      if (
        duration === 0 &&
        type !== EventTypes.MANUAL_PLAY &&
        type !== EventTypes.MANUAL_STOP &&
        type !== EventTypes.MANUAL_PAUSE
      )
        return

      if (duration < HB_BEFORE_VIEW_POINT && totalCountRef.current < HB_BEFORE_VIEW_POINT) return

      socketClient.emit<ClientViewChannelDto>(
        SOCKET_TV_EVENT_URL,
        ClientTvEventSocketMessage.VIEW_CHANNEL,
        {
          client,
          channel,
          event: { id: groupIdRef.current || undefined, duration, type },
        },
      )

      resetCounter()
    },
    [],
  )

  const {
    resetCounterInterval,
    resetTotalCount,
    resetIsViewPointAchieved,
    startCount,
    resetCounter,
    send,
  } = useSendStatistic({
    sendHBCallback: sendHB,
    sendHBBeforeCallback: sendHB,
    sendViewCallback: sendHB,
    hb: HB,
    hbBefore: HB_BEFORE_VIEW_POINT,
    viewPoint: VIEW_POINT,
  })

  const callbacks = useSendStatisticsCallbacks({
    videoElRef,
    send,
    startCount,
    resetCounterInterval,
    resetCounter,
    resetIsViewPointAchieved,
    resetTotalCount,
    ...props,
  })

  const handleViewChannel = useCallback((res: { id: string }) => {
    if (!res) return
    if (!res.id) return

    groupIdRef.current = res.id
  }, [])

  const handleReconnect = useCallback(() => {
    socketClient.emit(SOCKET_TV_EVENT_URL, ClientTvEventSocketMessage.JOIN_CHANNEL, {
      channel: channelRef.current,
    })
  }, [])

  useEffect(() => {
    socketClient.on(SOCKET_TV_EVENT_URL, 'reconnect', handleReconnect)

    return () => {
      socketClient.off(SOCKET_TV_EVENT_URL, 'reconnect', handleReconnect)
    }
  }, [])

  useEffect(() => {
    if (!channel) return

    socketClient.emit(SOCKET_TV_EVENT_URL, ClientTvEventSocketMessage.JOIN_CHANNEL, { channel })
    socketClient.on(SOCKET_TV_EVENT_URL, ClientTvEventSocketMessage.VIEW_CHANNEL, handleViewChannel)

    // reset before start count
    groupIdRef.current = null
    resetCounter()
    resetTotalCount()
    resetIsViewPointAchieved()

    return () => {
      resetCounterInterval()
      send(EventTypes.MANUAL_STOP)

      socketClient.emit(SOCKET_TV_EVENT_URL, ClientTvEventSocketMessage.LEAVE_CHANNEL, { channel })
      socketClient.off(
        SOCKET_TV_EVENT_URL,
        ClientTvEventSocketMessage.VIEW_CHANNEL,
        handleViewChannel,
      )
    }
  }, [channel])

  const renderParams: Partial<EventsRenderParameters> = useMemo(() => {
    return {
      ...props,
      ...callbacks,
    }
  }, [callbacks])

  return children(renderParams)
}
