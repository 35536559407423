export enum LanguageLabels {
  EN = 'English',
  RU = 'Русский',
}

export enum LanguageCodes {
  RU = 'RU',
  EN = 'EN',
}

export enum QualityLabels {
  'SD' = 'SD',
  'HD' = 'HD',
  'FHD' = 'FHD',
}

export enum QualityValue {
  UHD = 2160,
  FHD = 1080,
  HD = 720,
  SD = 480,
  AUTO = 'AUTO',
}

export interface Language {
  value: LanguageCodes
  label: LanguageLabels
}

export interface Quality {
  value: QualityValue
  label: QualityLabels
}

export enum SourceType {
  MP4 = 'mp4',
  HLS = 'hls',
  DASH_PLAYREADY = 'dash_playready',
  DASH_WIDEVINE = 'dash_widevine',
  HLS_FAIRPLAY = 'hls_fairplay',
  MSS_PLAYREADY = 'mss_playready',
}

export interface Source {
  url: string
  type: SourceType
}

export type Sources = Source[]

export interface StreamsModel {
  sources: Sources
  language: Language
  quality: Quality
}
