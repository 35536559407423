import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import PromoCardInner from '@/modules/promotes/components/PromoCard/PromoCardInner'

import { ContentModel } from '@/models/content.model'

interface PromoCardProps<T> extends Omit<DefaultCardProps<T>, 'children'> {}

const PromoCard = <T extends ContentModel>({
  card,
  className,
  wrapperClassName,
  onCardFocus,
  ...props
}: PromoCardProps<T>) => {
  return (
    <DefaultCard
      card={card}
      className={classNames(className, styles.PromoCardFocusWrapper)}
      wrapperClassName={classNames(wrapperClassName, styles.PromoCardWrapper)}
      focusClassName={styles.PromoCardFocusWrapper__Focus}
      onCardFocus={onCardFocus}
      {...props}
    >
      <PromoCardInner card={card} />
    </DefaultCard>
  )
}

export default PromoCard
