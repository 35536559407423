import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '@/core/store'
import {
  dispatchSearchLoadingTurnOffEvent,
  dispatchSearchLoadingTurnOnEvent,
} from '@/core/customEvents/search.custom.events'
import { useCustomRef } from '@/core/hooks/useCustomRef'

import { useNavigate } from 'react-router-dom'

import { setChosenCard } from '@/modules/content/content.actions'

import { ContentPaths, Paths } from '@/core/router/router.paths'

import { InitListActions } from '@/components/List'
import { ContentModel, ContentType } from '@/models/content.model'
import { useDebounce } from '@/core/store/hooks'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import { APIStatus } from '@/core/api/api'
import { purePrepareDataAndMakeSearch } from '@/modules/search/search.helpers'
import {
  resetCurrenPage,
  setCurrenPage,
} from '@/modules/search/slice/actions/currentPage/currentPage.actions'
import {
  resetContentId,
  setContentId,
} from '@/modules/search/slice/actions/contentId/contentId.actions'
import { resetSearchResults } from '@/modules/search/slice/actions/results/results.actions'
import { resetPageState } from '@/modules/search/slice/actions/page/page.actions'

export const useSearchByPage = (pageName: PagesWithSearch) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { 0: listActions, 1: setActions } = useCustomRef<InitListActions>({})

  const isSearchQueryCalledRef = useRef(false)
  const isCurrentPageUpdatedRef = useRef(false)

  const pageRef = useRef(1)
  const totalPagesRef = useRef(-1)
  const currentPageFromStateRef = useRef(-1)
  const contentIdFromStateRef = useRef<string>()
  const perPageRef = useRef(20)

  const searchResult = useAppSelector((state) => state.searchByPageSlice[pageName].results)

  const totalPages = useAppSelector((state) => state.searchByPageSlice[pageName].totalPages)
  totalPagesRef.current = totalPages

  const contentId = useAppSelector((state) => state.searchByPageSlice[pageName].contentId)
  useEffect(() => {
    contentIdFromStateRef.current = contentId
  }, [contentId])

  // it currentPage from state, used when we return to search after navigation by card
  const currentPage = useAppSelector((state) => state.searchByPageSlice[pageName].currentPage)
  if (currentPage > 0 && !isCurrentPageUpdatedRef.current) {
    pageRef.current = currentPage
    currentPageFromStateRef.current = currentPage
    isCurrentPageUpdatedRef.current = true
  }

  const searchLoading = useAppSelector(
    (state) => state.searchByPageSlice.status === APIStatus.PENDING,
  )

  const searchFilters = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters,
  )
  const sort = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.sort)
  const asc = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.asc)

  const unDebouncedSearchTermSearchTerm = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.searchTerm,
  )

  const debouncedSearchTerm = useDebounce(unDebouncedSearchTermSearchTerm, 1000)

  const handleOnCardPress = useCallback((item: ContentModel) => {
    dispatch(setChosenCard(item))

    dispatch(setCurrenPage({ pageName, payload: pageRef.current }))
    dispatch(setContentId({ pageName, payload: item.id }))

    contentIdFromStateRef.current = item.id

    const isMovie = item.type === ContentType.MOVIE

    navigate(`${Paths.CONTENT}/${isMovie ? ContentPaths.MOVIES : ContentPaths.SERIALS}/${item.id}`)
  }, [])

  const handleLoadMore = useCallback(() => {
    if (currentPageFromStateRef.current !== -1) {
      dispatch(resetCurrenPage({ pageName }))
      dispatch(resetContentId({ pageName }))

      if (pageRef.current === totalPagesRef.current) {
        return
      }
    }

    pageRef.current = pageRef.current + 1

    const perPage = perPageRef.current
    const page = pageRef.current

    purePrepareDataAndMakeSearch(
      dispatch,
      pageName,
      page,
      perPage,
      searchFilters,
      debouncedSearchTerm,
      asc,
      sort,
    )
  }, [searchFilters, debouncedSearchTerm, sort, asc])

  useEffect(() => {
    if (searchLoading) {
      dispatchSearchLoadingTurnOnEvent()
      return
    }
    dispatchSearchLoadingTurnOffEvent()
  }, [searchLoading])

  useEffect(() => {
    if (contentIdFromStateRef.current) {
      isSearchQueryCalledRef.current = true

      const id = contentIdFromStateRef.current

      currentPageFromStateRef.current = -1
      contentIdFromStateRef.current = undefined

      if (!listActions.current.setFocusWithoutScroll) return
      listActions.current.setFocusWithoutScroll(id)
      return
    }

    const { genres, partners, countries, years, type, tags } = searchFilters

    if (pageName === 'genres' && (!genres || !genres.length)) return
    if (pageName === 'partners' && (!partners || !partners.length)) return
    if (pageName === 'tags' && (!tags || !tags.length)) return
    if (
      !(pageName === 'genres') &&
      !(pageName === 'partners') &&
      !isSearchQueryCalledRef.current &&
      !debouncedSearchTerm &&
      (!partners || !partners.length) &&
      (!genres || !genres.length) &&
      (!countries || !countries.length) &&
      (!tags || !tags.length) &&
      !type &&
      (!years || !years.length)
    )
      return

    pageRef.current = 1

    const perPage = perPageRef.current
    const page = pageRef.current

    dispatch(resetSearchResults({ pageName }))

    purePrepareDataAndMakeSearch(
      dispatch,
      pageName,
      page,
      perPage,
      searchFilters,
      debouncedSearchTerm,
      asc,
      sort,
    ).then(() => {
      if (!listActions.current.reset) return
      listActions.current.reset()
    })

    isSearchQueryCalledRef.current = true
  }, [searchFilters, debouncedSearchTerm, sort, asc])

  useEffect(() => {
    return () => {
      // reset page state
      if (contentIdFromStateRef.current) return

      dispatch(resetPageState({ pageName }))
    }
  }, [])

  const shouldShowLoadMore = useMemo(() => {
    return currentPageFromStateRef.current > 0
      ? pageRef.current < totalPages && pageRef.current >= currentPageFromStateRef.current
      : pageRef.current < totalPages
  }, [searchResult, totalPages])

  return useMemo(() => {
    return {
      setActions,
      searchResult,
      handleLoadMore,
      handleOnCardPress,
      searchLoading,
      isSearchQueryCalledRef,
      shouldShowLoadMore,
    }
  }, [searchResult, handleLoadMore, searchLoading, shouldShowLoadMore])
}
