import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  addTagToTagsFilter,
  removeTagFromTagsFilter,
  resetTagsFilter,
  setTagsFilter,
} from '@/modules/search/slice/actions/searchState/searchState.tags.actions'

export const tagsExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ TAGS
  builder

    .addCase(addTagToTagsFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.tags.push(action.payload.payload)
    })
    .addCase(removeTagFromTagsFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.tags = state[
          action.payload.pageName
        ].searchState.filters.tags.filter((el) => el.id !== action.payload.payload?.id)
    })
    .addCase(setTagsFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.tags = action.payload.payload
    })
    .addCase(resetTagsFilter, (state, action) => {
      state[action.payload.pageName].searchState.filters.tags = []
    })

  //-----------------------------------------------------------
}
