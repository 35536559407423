import React, { FC, memo, useCallback } from 'react'

import { useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import NeedPackagesModal from '@/modules/content/components/NeedPackagesModal'

import { useAppSelector } from '@/core/store'

import { checkIsRightsErrorModel, RightsErrorModel } from '@/models/rightsError.model'

import { MovieModel } from '@/models/movie.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface PlayChosenMovieActionProps {
  disabled?: boolean
  className?: string
  rightsError?: RightsErrorModel
  rightsLoading?: boolean
}

const PlayChosenMovieAction: FC<PlayChosenMovieActionProps> = ({
  disabled,
  rightsError,
  className,
  rightsLoading,
}) => {
  const movie = useAppSelector((state) => state.content.chosenCard) as MovieModel

  const navigate = useNavigate()

  const handleOnPress = useCallback(() => {
    if (disabled || rightsError) return

    navigate(`${Paths.CONTENT}/${ContentPaths.MOVIE_PLAYER}`, {
      state: {
        movie,
      },
    })
  }, [rightsError, movie, disabled])

  if (!movie) return

  return (
    <>
      {rightsError && checkIsRightsErrorModel(rightsError) && (
        <NeedPackagesModal
          packages={rightsError.data.message}
          trigger={(onTrigger) => (
            <Button
              // disabled={disabled || loadingRights}
              onEnterPress={onTrigger}
              onButtonClick={onTrigger}
              buttonClassName={className}
              autoFocus
              loading={rightsLoading}
            >
              <div>
                <span style={{ display: 'block', lineHeight: 1.2 }}>Смотреть фильм</span>
                <span
                  style={{
                    display: 'block',
                    lineHeight: 1,
                    fontSize: '28px',
                    opacity: 0.6,
                    textAlign: 'left',
                  }}
                >
                  <small>нужна подписка</small>
                </span>
              </div>
            </Button>
          )}
        />
      )}

      {!rightsError && (
        <Button
          // disabled={loadingRights || disabled}
          onEnterPress={handleOnPress}
          onButtonClick={handleOnPress}
          buttonClassName={className}
          autoFocus
          loading={rightsLoading}
        >
          <span style={{ display: 'block' }}>Смотреть фильм</span>
        </Button>
      )}
    </>
  )
}

export default memo(PlayChosenMovieAction)
