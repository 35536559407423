import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Timeline from '@/modules/player/components/controls/Timeline'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'
import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

interface ProgressBarProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  onChange: (value: string | number) => void
  blockPointerEvents?: (value: boolean) => void
  togglePlayPause?: () => Promise<void>
}

const ProgressBar: FC<ProgressBarProps> = ({ onChange, blockPointerEvents, togglePlayPause }) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isBuffering = usePlayerStateSelector((state) => state.video.isBuffering)

  const duration = usePlayerStateSelector((state) => state.progress.duration)
  const currentTime = usePlayerStateSelector((state) => state.progress.currentTime)

  return (
    <div className={classNames(styles.ProgressBar)}>
      <Timeline
        togglePlayPause={togglePlayPause}
        currentTime={currentTime || 0}
        duration={duration || 0}
        onChange={onChange}
        isBuffering={isBuffering}
        blockPointerEvents={blockPointerEvents}
      />
    </div>
  )
}

export default ProgressBar
