import React, { useMemo } from 'react'

import styles from './styles.module.scss'

import ContentPlayerTitle from '@/modules/content/components/ContentPlayerTitle'
import Player, { PlayerProps } from '@/modules/player/Player'

import { useLocation } from 'react-router-dom'

import { useContentPlayerRequestExitFullscreen } from '@/modules/content/hooks/useContentPlayerRequestFullscreen'

import { ContentModel } from '@/models/content.model'

interface TrailerPlayerLocationState {
  content: ContentModel
}

const TrailerFullscreenPlayer = () => {
  const {
    state: { content },
  }: { state: TrailerPlayerLocationState } = useLocation()

  const playerProps: Partial<PlayerProps> = useMemo(() => {
    return {
      playerId: 'trailer-player',
      videoState: {
        isPaused: false,
      },
      fullscreenState: {
        status: true,
      },
      overlayProps: {
        headerSlotRenderFn: () => {
          return (
            <ContentPlayerTitle
              title={
                <>
                  <div>{content?.title}</div>
                  <div className={styles.SubTitle}>Трейлер</div>
                </>
              }
            />
          )
        },
      },
    }
  }, [])

  const { navigateToPreviousPage } = useContentPlayerRequestExitFullscreen()
  return (
    <>
      <Player
        playerId={'trailer-player'}
        videoStreamerProps={{
          autoPlay: true,
          url: content.trailer?.url || '',
        }}
        videoManagerProps={{
          onEnded: navigateToPreviousPage,
        }}
        {...playerProps}
      />
    </>
  )
}

export default TrailerFullscreenPlayer
