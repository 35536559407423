import React, { memo } from 'react'

import styles from './styles.module.scss'

import { Outlet } from 'react-router-dom'

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import Modal from '@/components/Modal'
import Redirect from '@/components/Redirect'
import ContentMenu from '@/modules/menu/components/ContentMenu'
import RouterHistoryResolver from '@/components/RouterHistoryResolver'

import { useDefaultLayoutLogic } from '@/layouts/commonLogicForApproveAndDefaultLayouts/useDefaultLayoutLogic'
import ModalsContainer from '../../components/Notifications/components/Modals/ModalsContainer'

const DefaultLayout = () => {
  const { ref, focusKey, focusSelf } = useFocusable({
    preferredChildFocusKey: 'Redirect',
  })

  React.useEffect(() => {
    focusSelf()
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className={styles.DefaultLayout}>
        <RouterHistoryResolver />

        <div className={styles.MenuContainer}>
          <ContentMenu />
        </div>

        <ContentRedirect />
      </div>
    </FocusContext.Provider>
  )
}

const ContentRedirect = () => {
  const { ref: root, focusKey: root_key } = useFocusable()
  return (
    <div id={'content_redirect_container'} className={styles.ContentRedirectContainer}>
      <FocusContext.Provider value={root_key}>
        <div ref={root} className={styles.ContentRedirect}>
          <Redirect focusKey={'Redirect'} />
          <Content />
        </div>
      </FocusContext.Provider>
    </div>
  )
}

const Content = () => {
  const { ref, focusKey, isExitModalOpen, handleModalClose, render } = useDefaultLayoutLogic()
  return (
    <>
      <FocusContext.Provider value={focusKey}>
        <div id={focusKey} ref={ref} className={styles.ContentContainer}>
          <Outlet />
        </div>
      </FocusContext.Provider>
      <Modal
        isOpen={isExitModalOpen}
        closeByClickBackground={false}
        hideCloseButton
        onClose={handleModalClose}
        trigger={() => <></>}
        render={render}
      />
      <ModalsContainer id={'content-notifications-modal-container'} returnFocusTo={'CONTENT'} />
    </>
  )
}

export default memo(DefaultLayout)
