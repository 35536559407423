import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useHandleWheelScroll } from '@/components/Slider/UseHandleWheelScrollHook'

import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { OmittedDefaultScrollingWrapperProps } from '@/components/Slider/ColumnScrollingWrapper'
import { FocusDetails } from '@noriginmedia/norigin-spatial-navigation'

export interface VirtualColumnScrollingWrapperProps extends OmittedDefaultScrollingWrapperProps {
  setFocus?: (focusKey: string) => void
  getCurrentFocusKey?: () => string
  scrollingWrapperClassname?: string
  scrollingHeight?: number
  navigateByDirection?: (direction: string, focusDetails: FocusDetails) => void
}

const VirtualColumnScrollingWrapper: FC<VirtualColumnScrollingWrapperProps> = ({
  children,
  className,
  scrollingWrapperClassname,
  navigateByDirection,
}) => {
  const heights = React.useMemo(() => {
    const arr: number[] = []
    React.Children.forEach(children, (child) => {
      arr.push(child.props.height)
    })
    return arr
  }, [children])

  const [curIndex, setCurIndex] = useState(0)
  const ref = useRef<HTMLDivElement | null>(null)

  useHandleWheelScroll(ref, navigateByDirection)

  const onFocus: OnChildFocus = (layout, b, c, id) => {
    if (id === undefined) return
    setCurIndex(id)

    const scrollNode = ref.current as HTMLDivElement
    if (!scrollNode) return

    const child = scrollNode.childNodes.item(id) as HTMLDivElement
    scrollNode.style.transform = `translateY(-${child.dataset.top}px)`
  }

  const top = useCallback(
    (index: number) => {
      if (index === 0) return 0

      let acc = 0

      for (const i in heights) {
        if (ref.current && heights.length - 1 === +i) {
          const scrollNode = ref.current as HTMLDivElement
          const parentNode = scrollNode.parentNode as HTMLDivElement
          acc = height - parentNode.offsetHeight
          break
        }

        if (+i >= index) break

        acc = acc + heights[i]
      }
      return acc
    },
    [heights],
  )

  const height = useMemo(() => {
    return heights.reduce((pr, cur) => {
      return pr + cur
    }, 0)
  }, [heights])

  const render = useMemo(() => {
    return React.Children.map(children, (child, index) => {
      return (
        <div id={`${index}`} data-top={`${top(index)}`} style={{ height: child.props.height }}>
          {curIndex === index ||
          curIndex + 1 === index ||
          curIndex - 1 === index ||
          curIndex + 2 === index ||
          curIndex - 2 === index
            ? React.cloneElement(child, {
                ...child.props,
                onFocus,
                scrollIndex: index,
              })
            : null}
        </div>
      )
    })
  }, [children, height, curIndex])

  return (
    <div className={classNames(className, styles.Wrapper)}>
      <div
        ref={ref}
        className={classNames(scrollingWrapperClassname, styles.ScrollingWrapper)}
        style={{
          height: `${height}px`,
        }}
      >
        {render}
      </div>
    </div>
  )
}

export default memo(VirtualColumnScrollingWrapper)
