import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { ListProps } from '@/components/List'
import ListWithScrollbar from '@/components/ListWithScrollbar'
import CommonListWrapperWithLoader from '@/modules/search/components/CommonListWrapperWithLoader'

import { useGetClientsCountriesHasContentByTypeQuery } from '@/modules/countries/countries.api'

import Checkbox, { CheckboxProps } from '@/components/Checkbox'
import { CountryBaseModel } from '@/models/country.model'
import { useAppDispatch, useAppSelector } from '@/core/store'

import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  addCountryToCountriesFilter,
  removeCountryFromCountriesFilter,
  resetCountriesFilter,
} from '@/modules/search/slice/actions/searchState/searchState.countries.actions'

interface CountriesListProps extends Omit<ListProps, 'children'> {
  chosenCountries?: CountryBaseModel[]
  disabledCountries?: CountryBaseModel[]
  pageName: PagesWithSearch
}

const CountriesList: FC<CountriesListProps> = ({
  className,
  disabledCountries,
  pageName,
  ...rest
}) => {
  const { countries, loading } = useGetClientsCountriesHasContentByTypeQuery(
    {},
    {
      selectFromResult: ({ data, isLoading, isFetching }) => {
        return {
          countries: data,
          loading: isLoading || isFetching,
        }
      },
    },
  )

  const items = useMemo(() => {
    if (!countries) return []

    return [
      <DefaultCountryCheckBox
        className={styles.DefaultCheckbox}
        key={'chooseAllCountries'}
        autoFocus={true}
        pageName={pageName}
      />,
      ...countries.map((country) => {
        return (
          <CountryCheckBox
            key={country.id}
            country={country}
            pageName={pageName}
            disabled={disabledCountries && !!disabledCountries.find((el) => el.id === country.id)}
          />
        )
      }),
    ]
  }, [countries])

  if (!countries && !loading) return null

  return (
    <CommonListWrapperWithLoader isLoading={loading}>
      <ListWithScrollbar className={classNames(styles.CountriesList, className)} {...rest}>
        {items}
      </ListWithScrollbar>
    </CommonListWrapperWithLoader>
  )
}

interface DefaultCountryCheckBoxProps extends CheckboxProps {
  chosenCountries?: CountryBaseModel[]
  pageName: PagesWithSearch
}

const DefaultCountryCheckBox = (props: DefaultCountryCheckBoxProps) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) => state.searchByPageSlice[props.pageName].searchState.filters.countries.length === 0,
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        dispatch(resetCountriesFilter({ pageName: props.pageName }))
        return
      }
    },
    [props.chosenCountries],
  )

  return <Checkbox name={'Выбрать всё'} checked={checked} onChange={handleChange} {...props} />
}

interface CountryCheckBoxProps extends CheckboxProps {
  country: CountryBaseModel
  pageName: PagesWithSearch
}

const CountryCheckBox = ({ country, pageName, ...props }: CountryCheckBoxProps) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) =>
      !!state.searchByPageSlice[pageName].searchState.filters.countries.find(
        (el) => el.id === country.id,
      ),
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (!country) return

      if (checked) {
        dispatch(addCountryToCountriesFilter({ pageName, payload: country }))
        return
      }

      dispatch(removeCountryFromCountriesFilter({ pageName, payload: country }))
    },
    [country],
  )

  return <Checkbox name={country.name} checked={checked} onChange={handleChange} {...props} />
}

export default CountriesList
