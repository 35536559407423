import { ContentModel, ContentWithPromoteModel } from '@/models/content.model'
import { BasePosterModel, ImageModel, PosterType } from '@/models/poster.model'
import { CDN_IMAGES_API_BASE_URL } from '@/core/config'
import { BackgroundType } from '@/models/background.model'
import { GroupTypes } from '@/modules/feeds/feeds.api'
import { ContentPaths } from '@/core/router/router.paths'
import { ContentPromotePoster } from '@/models/contentPromote.model'

export enum PosterOrientation {
  HORIZONTAL = 'horizontalImage',
  VERTICAL = 'verticalImage',
}

export enum PosterExtension {
  JPEG = '.jpeg',
  PNG = '.png',
  WebP = '.webp',
}

export interface GetPosterUrlArgs<T> {
  content?: T
  orientation?: PosterOrientation
  extension?: PosterExtension
  query?: { [key: string]: number | string }
}

const getOrientedImage = <T extends Omit<BasePosterModel, 'type' | 'contentId'>>(
  poster: T,
  orientation: PosterOrientation,
) => {
  return orientation
    ? poster[orientation] && poster[orientation]?.hash
      ? poster[orientation]
      : poster.horizontalImage && poster.horizontalImage.hash
      ? poster.horizontalImage
      : poster.verticalImage && poster.verticalImage.hash
      ? poster.verticalImage
      : undefined
    : undefined
}

const getOrientedUrl = (orientedImage: ImageModel, extension?: PosterExtension) => {
  return `${CDN_IMAGES_API_BASE_URL}/${orientedImage.folder}/${orientedImage.hash}${
    extension ? extension : ''
  }`
}

const getFinalUrl = (url: string, query?: { [key: string]: number | string }) => {
  if (!query) return url

  const keys = Object.keys(query)

  if (keys.length === 0) return url

  const mappedQuery = keys.map((key) => {
    return `${key}=${query[key]}`
  })

  return `${url}?${mappedQuery.join('&')}`
}

export const getContentPromotePosterUrl = <T extends ContentWithPromoteModel>({
  content,
  orientation = PosterOrientation.HORIZONTAL,
  extension,
  query,
}: GetPosterUrlArgs<T>): string | undefined => {
  if (!content) return
  if (!content.promoteContent || !content.promoteContent.poster)
    return getContentPosterUrl({ content, orientation, extension, query })
  const poster = content.promoteContent.poster

  const oriented = getOrientedImage<ContentPromotePoster>(poster, orientation)

  if (!oriented) return getContentPosterUrl({ content, orientation, extension, query })
  const url = getOrientedUrl(oriented, extension)

  return getFinalUrl(url, query)
}

export const getContentPosterUrl = <T extends ContentModel>({
  content,
  orientation = PosterOrientation.HORIZONTAL,
  extension,
  query,
}: GetPosterUrlArgs<T>): string | undefined => {
  if (!content) return

  const poster =
    content.posters?.find((el) => el.type === PosterType.CUSTOM) ||
    content.posters?.find((el) => el.type === PosterType.PARTNER)

  if (!poster) return

  const oriented = getOrientedImage(poster, orientation)

  if (!oriented) return

  const url = getOrientedUrl(oriented, extension)

  return getFinalUrl(url, query)
}

export const getContentBackgroundUrl = <T extends ContentModel>({
  content,
  extension = PosterExtension.JPEG,
  query,
}: GetPosterUrlArgs<T>): string | undefined => {
  if (!content) return

  const background =
    content.backgrounds?.find((el) => el.type === BackgroundType.CUSTOM) ||
    content.backgrounds?.find((el) => el.type === BackgroundType.PARTNER)

  if (!background || !background.image || !background.image.hash) return

  const url = getOrientedUrl(background.image, extension)

  return getFinalUrl(url, query)
}

export const getKeywordFromCard = <T extends ContentModel>(card?: T): string => {
  if (!card) return ''

  const year = card.year
  const arrOfCountries = card.countries

  if (!arrOfCountries && !year) return ''

  let finalArr: (string | number)[] = []
  if (year) {
    finalArr.push(year)
  }
  if (arrOfCountries) {
    const countries = arrOfCountries.map((country) => country.name)
    finalArr = finalArr.concat(countries)
  }

  return finalArr.join(', ')
}

export const getAgeFromContentCard = <T extends ContentModel>(card?: T): string => {
  if (!card || !card.age) return ''

  return card.age.title
}

export const getTitleFromContentCard = <T extends ContentModel>(card?: T): string => {
  if (!card || !card.title) return ''

  return card.title
}
export const getDescriptionFromContentCard = <T extends ContentModel>(card?: T): string => {
  if (!card || !card.description) return ''

  return card.description
}

export const getActorsFromContent = <T extends ContentModel>(card?: T | null): string => {
  if (!card || !card.actors) return ''

  return card.actors.map((el) => el.name).join(', ')
}

export const getGenresFromContent = <T extends ContentModel>(card?: T | null): string => {
  if (!card || !card.genres) return ''

  return card.genres.map((el) => el.title).join(', ')
}

export const resolvePathnameForGetFeedsResources = (pathname: string): GroupTypes => {
  if (pathname.includes(ContentPaths.MOVIES)) {
    return GroupTypes.MOVIE
  }

  if (pathname.includes(ContentPaths.SERIALS)) {
    return GroupTypes.SERIAL
  }

  return GroupTypes.CONTENT
}
