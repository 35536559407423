import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'

// _____________________Current PAGE STATE UPDATE___________

export const setCurrenPage = createAction(
  'searchSlice/setCurrenPage',
  withPayloadType<PageUpdatePayload<number>>(),
)

export const resetCurrenPage = createAction(
  'searchSlice/resetCurrenPage',
  withPayloadType<PageUpdatePayload>(),
)
