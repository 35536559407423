import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'
import {
  addCountryToCountriesFilter,
  removeCountryFromCountriesFilter,
  resetCountriesFilter,
  setCountriesFilter,
} from '@/modules/search/slice/actions/searchState/searchState.countries.actions'

export const countriesExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ COUNTRIES

  builder
    .addCase(addCountryToCountriesFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.countries.push(action.payload.payload)
    })
    .addCase(removeCountryFromCountriesFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.countries = state[
          action.payload.pageName
        ].searchState.filters.countries.filter((el) => el.id !== action.payload.payload?.id)
    })
    .addCase(setCountriesFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.countries = action.payload.payload
    })
    .addCase(resetCountriesFilter, (state, action) => {
      state[action.payload.pageName].searchState.filters.countries = []
    })

  //-----------------------------------------------------------
}
