export enum FeedsResourceTypes {
  TAG_CONTENT = 'TAG_CONTENT',
  PARTNER_CONTENT = 'PARTNER_CONTENT',
  GENRE_CONTENT = 'GENRE_CONTENT',
  GENRE_LIST = 'GENRE_LIST',
  CATEGORY_CHANNEL = 'CATEGORY_CHANNEL',
  FAVORITE_CHANNEL = 'FAVORITE_CHANNEL',
  FAVORITE_CONTENT = 'FAVORITE_CONTENT',
  VIEW_CONTENT = 'VIEW_CONTENT',
  EMPTY = 'EMPTY',
}

export interface FeedsResourceBaseModel {
  id: string
  createdAt: string
  updatedAt: string
  title: string
  alias: string
  slug: string
  type: FeedsResourceTypes
  url: string
  internalTitle: string | null
  internalDescription: string | null
}
