import { useSyncOneMediaViewMutation } from '@/modules/syncMediaView/syncMediaView.api'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  MediaViewResourceType,
  SyncMediaViewModelWithResource,
} from '@/models/sync.media.view.model'
import { MovieBaseModel } from '@/models/movie.model'
import { EpisodeBaseModel, SerialModel } from '@/models/serial.model'
import { getCurrentSeason, getNextEpisode } from '@/modules/serials/serials.helpers'
import { ContentModel, ContentType } from '@/models/content.model'

interface useSyncOneMediaViewProps {
  content: ContentModel
  movie?: MovieBaseModel
  episode?: EpisodeBaseModel
  preferredLanguage?: string
  viewInfo?: SyncMediaViewModelWithResource | null
}

type BaseViewInfoType = Pick<
  SyncMediaViewModelWithResource,
  'resourceId' | 'resourceType' | 'contentId' | 'duration'
> & { id?: string }

export const useSyncOneMediaView = ({
  movie,
  episode,
  preferredLanguage,
  viewInfo,
  content,
}: useSyncOneMediaViewProps) => {
  const [syncOne, { data: afterSyncData }] = useSyncOneMediaViewMutation()

  const next = useMemo(() => {
    if (!content || content.type !== ContentType.SERIAL || !episode) return false

    const serial = content as SerialModel
    return !!getNextEpisode(serial, episode)
  }, [content, episode])

  const seasonNumber = useMemo(() => {
    if (!content || content.type !== ContentType.SERIAL || !episode) return

    const serial = content as SerialModel

    const season = getCurrentSeason(serial, episode)

    if (!season) return

    return season.number
  }, [content, episode])

  const episodeIdRef = useRef<string | undefined>(episode?.id)
  const episodeNumberRef = useRef<number | undefined>(episode?.number)
  const seasonNumberRef = useRef<number | undefined>(seasonNumber)
  const seasonIdRef = useRef<string | undefined>(episode?.seasonId)
  const nextRef = useRef<boolean>(next)
  const syncViewIdRef = useRef<string | null | undefined>(viewInfo?.id)

  useEffect(() => {
    nextRef.current = next
  }, [next])

  useEffect(() => {
    seasonNumberRef.current = seasonNumber
  }, [seasonNumber])

  useEffect(() => {
    if (!episode) return

    episodeIdRef.current = episode.id
    episodeNumberRef.current = episode.number
    seasonIdRef.current = episode.seasonId
  }, [episode])

  // baseViewInfo
  const baseViewInfoRef = useRef<BaseViewInfoType>()
  useEffect(() => {
    if (!movie) return
    baseViewInfoRef.current = {
      resourceId: movie.id,
      contentId: movie.contentId,
      duration: movie.duration,
      resourceType: MediaViewResourceType.MOVIE,
    }
  }, [movie])

  useEffect(() => {
    if (!episode) return

    baseViewInfoRef.current = {
      resourceId: episode.id,
      contentId: episode.contentId,
      duration: episode.duration || 0,
      resourceType: MediaViewResourceType.SERIAL_EPISODE,
    }
  }, [episode])

  useEffect(() => {
    if (!afterSyncData) return
    if (episode && episodeIdRef.current !== afterSyncData.resourceId) return

    baseViewInfoRef.current = {
      resourceId: afterSyncData.resourceId,
      contentId: afterSyncData.contentId,
      duration: afterSyncData.duration,
      resourceType: afterSyncData.resourceType,
    }
  }, [afterSyncData])
  // baseViewInfo

  // preferredLanguage
  const preferredLanguageRef = useRef<string | undefined>(preferredLanguage)
  useEffect(() => {
    preferredLanguageRef.current = preferredLanguage
  }, [preferredLanguage])
  // preferredLanguage

  // viewInfo?.timestamp
  const timestampRef = useRef<number | null>(null)
  useEffect(() => {
    if (!viewInfo) return

    timestampRef.current = viewInfo.timestamp
  }, [])

  useEffect(() => {
    if (!afterSyncData) return

    timestampRef.current = afterSyncData.timestamp
  }, [afterSyncData])
  // viewInfo?.timestamp

  const pureSync = useCallback((videoEl: HTMLVideoElement, baseViewInfo: BaseViewInfoType) => {
    return syncOne({
      contentId: baseViewInfo.contentId,
      data: {
        id: syncViewIdRef.current,
        contentId: baseViewInfo.contentId,
        currentTime: videoEl.currentTime,
        duration: videoEl.duration || baseViewInfo.duration,
        language:
          typeof preferredLanguageRef.current === 'string' ? preferredLanguageRef.current : '',
        resourceId: baseViewInfo.resourceId,
        resourceType: baseViewInfo.resourceType,
        timestamp: timestampRef.current ? +timestampRef.current : undefined,
        next: nextRef.current,
        resource:
          episodeIdRef.current &&
          episodeNumberRef.current &&
          seasonIdRef.current &&
          seasonNumberRef.current
            ? {
                episode: episodeNumberRef.current,
                seasonId: seasonIdRef.current,
                season: seasonNumberRef.current,
              }
            : undefined,
      },
    }).unwrap()
  }, [])

  const syncWithVideoEl = useCallback(async (videoEl: HTMLVideoElement) => {
    if (!videoEl || !videoEl.duration) return
    const baseViewInfo = baseViewInfoRef.current
    if (!baseViewInfo) return
    try {
      const { id } = await pureSync(videoEl, baseViewInfo)
      syncViewIdRef.current = id
      return
    } catch (e) {
      console.log(e)
      return
    }
  }, [])

  return useMemo(() => {
    return {
      syncWithVideoEl,
    }
  }, [])
}
