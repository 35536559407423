import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  setCurrenPage,
  resetCurrenPage,
} from '@/modules/search/slice/actions/currentPage/currentPage.actions'

export const currentPageExtraReducers = (builder: SearchSliceBuilderType) => {
  builder

    .addCase(setCurrenPage, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].currentPage = action.payload.payload
    })

    .addCase(resetCurrenPage, (state, action) => {
      state[action.payload.pageName].currentPage = -1
    })
}
