import React, { FC } from 'react'

import { motion } from 'framer-motion'
import { rightVariants } from '@/modules/player/components/player/Overlay/overlay.slots.transitions'

interface OverlayRightSlotProps {
  className?: string
  rightSlotRenderFn?: (
    setUserActivity: (active: boolean) => void,
    changeDisableOverlay: (value: boolean) => void,
  ) => React.ReactNode
  setUserActivity: (active: boolean) => void
  changeDisableOverlay: (value: boolean) => void
  animate: string
}

const OverlayRightSlot: FC<OverlayRightSlotProps> = ({
  rightSlotRenderFn,
  className,
  animate,
  setUserActivity,
  changeDisableOverlay,
}) => {
  if (!rightSlotRenderFn) return null
  return (
    <motion.div initial={'open'} animate={animate} variants={rightVariants} className={className}>
      {rightSlotRenderFn(setUserActivity, changeDisableOverlay)}
    </motion.div>
  )
}

export default OverlayRightSlot
