import { useCallback, useEffect, useRef } from 'react'

import { useLocation } from 'react-router-dom'

import {
  addPathToRouterHistoryToLocalstorage,
  removeRouterHistoryInLocalstorage,
} from '@/core/helpers'
import { ContentPaths, Paths } from '@/core/router/router.paths'
import { useAppDispatch } from '@/core/store'
import { resetPageState } from '@/modules/search/slice/actions/page/page.actions'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

const RouterHistoryResolver = () => {
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()

  const searchPathsRef = useRef(new Set<string>([]))

  const resolveSearchState = useCallback((pathname?: string) => {
    if (!pathname) return

    const searchPaths = searchPathsRef.current

    if (!searchPaths.has(pathname)) return

    const pageName: PagesWithSearch | undefined = pathname.includes(ContentPaths.TAGS)
      ? 'tags'
      : pathname.includes(ContentPaths.GENRES)
      ? 'genres'
      : pathname.includes(ContentPaths.PARTNERS)
      ? 'partners'
      : pathname.includes(ContentPaths.SEARCH)
      ? 'search'
      : undefined

    if (!pageName) return

    searchPaths.delete(pathname)

    dispatch(resetPageState({ pageName }))
  }, [])

  if (
    pathname.includes(ContentPaths.TAGS) ||
    pathname.includes(ContentPaths.GENRES) ||
    pathname.includes(ContentPaths.PARTNERS) ||
    pathname.includes(ContentPaths.SEARCH)
  ) {
    searchPathsRef.current.add(pathname)
  }

  useEffect(() => {
    removeRouterHistoryInLocalstorage()
    const searchPaths = searchPathsRef.current

    searchPaths.forEach((value) => resolveSearchState(value))
    searchPaths.clear()
    return () => {
      removeRouterHistoryInLocalstorage()
      searchPaths.forEach((value) => resolveSearchState(value))
      searchPaths.clear()
    }
  }, [])

  useEffect(() => {
    if (pathname.includes(ContentPaths.HOME) || pathname === Paths.CONTENT) {
      removeRouterHistoryInLocalstorage()
      const removed = addPathToRouterHistoryToLocalstorage(pathname)
      resolveSearchState(removed)
      return
    }

    if (pathname.includes('player')) return

    const removed = addPathToRouterHistoryToLocalstorage(pathname)
    resolveSearchState(removed)
  }, [pathname])

  return null
}

export default RouterHistoryResolver
