import React, { FC, useCallback, useMemo } from 'react'

import styles from './styles.module.scss'

import dayjs from 'dayjs'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import ProgramListItemCardInner from '@/modules/tv/components/ProgramListItemCard/ProgramListItemCardInner'

import { ProgramBaseModel } from '@/models/program.model'
import { ArchiveStatuses, ChannelModel } from '@/models/channel.model'

interface ProgramListItemCardProps extends Omit<DefaultCardProps<ProgramBaseModel>, 'children'> {
  chosenChannel?: ChannelModel | null
  liveProgram?: ProgramBaseModel | null
}

export type ProgramStatuses = 'live' | 'archive' | 'future'

const ProgramListItemCard: FC<ProgramListItemCardProps> = ({
  card,
  chosenChannel,
  onCardPress,
  liveProgram,
  ...props
}) => {
  const status: ProgramStatuses | undefined = useMemo(() => {
    if (!card || !liveProgram || !chosenChannel) return
    if (card.id === liveProgram.id) return 'live'
    const now = dayjs(liveProgram.startTime)
    const startTime = dayjs(card.startTime)
    if (now.isBefore(startTime)) return 'future'

    const endTime = dayjs(card.endTime)
    const minus48 = now.add(-47, 'hour')

    if (
      chosenChannel.archiveStatus === ArchiveStatuses.AVAILABLE &&
      (now.isAfter(endTime) || now.isSame(endTime)) &&
      minus48.isBefore(startTime)
    ) {
      return 'archive'
    }
    return
  }, [chosenChannel, card, liveProgram])

  const handleCardPress = useCallback(
    (program: ProgramBaseModel) => {
      if (!onCardPress || !status) return

      //   live program
      if (status === 'live' || status === 'archive') {
        onCardPress(program)
        return
      }

      if (status === 'future') {
        // todo: add logic for notificate user before this program start
        return
      }
    },
    [status],
  )
  return (
    <DefaultCard
      card={card}
      wrapperClassName={styles.ProgramListItemCard}
      focusClassName={styles.ProgramListItemCardFocus}
      onCardPress={handleCardPress}
      {...props}
    >
      <ProgramListItemCardInner card={card} programStatus={status} />
    </DefaultCard>
  )
}

export default ProgramListItemCard
