import React from 'react'

import styles from './styles.module.scss'

import { Outlet } from 'react-router-dom'

import ContentLayoutHeader from '@/layouts/content/components/ContentLayoutHeader'

const ContentLayout = () => {
  return (
    <div className={styles.ContentLayout}>
      <ContentLayoutHeader />
      <div id={'footer-container'} className={styles.ContentLayout__Footer}>
        <Outlet />
      </div>
    </div>
  )
}

export default ContentLayout
