import React, { FC } from 'react'

import styles from './styles.module.scss'

import Image from '@/components/Image'

import { RightsErrorMessage } from '@/models/rightsError.model'
import classNames from 'classnames'

interface NeedPackageCardInnerProps {
  card?: RightsErrorMessage
}

const NeedPackageCardInner: FC<NeedPackageCardInnerProps> = ({ card }) => {
  return (
    <div className={classNames(styles.NeedPackageCardInner)}>
      <div className={styles.Image}>
        <Image src={card?.image} />
        <div className={styles.PackageTitle}>{card?.title}</div>
      </div>

      <table className={styles.InfoWrapper}>
        <tbody>
          <tr>
            <th>Название:</th>
            <td>{card?.title}</td>
          </tr>
          <tr>
            <th>Срок действия:</th>
            <td>{`${card?.days} дней`}</td>
          </tr>

          <tr>
            <th>Стоимость пакета:</th>
            <td>{`${card?.price} рублей`}</td>
          </tr>

          <tr>
            <th>Описание пакета:</th>
            <td>{card?.description}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default NeedPackageCardInner
