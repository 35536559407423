import { createApi } from '@reduxjs/toolkit/query/react'
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { ContentModel, ContentType } from '@/models/content.model'
import { ChannelModel } from '@/models/channel.model'

enum FavoritesApiTag {
  ALL_FAVORITES_CONTENTS = 'ALL_FAVORITES_CONTENTS',
  ALL_FAVORITES_CHANNELS = 'ALL_FAVORITES_CHANNELS',
}

export const favoritesContentAdapter = createEntityAdapter<ContentModel>({
  selectId: (model) => model.id,
})

export const favoritesContentSelectors = favoritesContentAdapter.getSelectors()

export const favoritesChannelsAdapter = createEntityAdapter<ChannelModel>({
  selectId: (model) => model.id,
})

export const favoritesChannelsSelectors = favoritesChannelsAdapter.getSelectors()

export const favoritesApi = createApi({
  reducerPath: 'favoritesApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/clients/me/favorites/` }),
  tagTypes: [FavoritesApiTag.ALL_FAVORITES_CONTENTS, FavoritesApiTag.ALL_FAVORITES_CHANNELS],
  endpoints: (builder) => ({
    // CONTENT FAVORITES
    getAllFavoritesContent: builder.query<EntityState<ContentModel>, { type?: ContentType }>({
      query: ({ type }) => {
        return {
          url: 'contents',
          method: 'GET',
          params: {
            type,
            relations:
              'posters.verticalImage,posters.horizontalImage,age,partner,countries,backgrounds.image',
          },
        }
      },
      transformResponse: (response: ContentModel[]) => {
        return favoritesContentAdapter.addMany(favoritesContentAdapter.getInitialState(), response)
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return endpointName + '--' + queryArgs.type
      },
      providesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    deleteContentFromFavorites: builder.mutation<ContentModel[], { type?: ContentType }>({
      query: ({ type }) => {
        return {
          url: 'contents',
          method: 'DELETE',
          params: {
            type,
          },
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    getFavoritesContentByContentId: builder.query<'', { id: string }>({
      query: ({ id }) => {
        return {
          url: `contents/${id}`,
          method: 'GET',
        }
      },
    }),

    addContentToFavorites: builder.mutation<ContentModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `contents/${id}`,
          method: 'POST',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    removeContentFromFavorites: builder.mutation<ContentModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `contents/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CONTENTS],
    }),

    // CHANNELS FAVORITES
    getAllMeFavoritesChannels: builder.query<EntityState<ChannelModel>, void>({
      query: () => {
        return {
          url: 'channels',
          method: 'GET',
          params: {
            relations: 'logo',
          },
        }
      },
      transformResponse: (response: ChannelModel[]) => {
        return favoritesChannelsAdapter.addMany(
          favoritesChannelsAdapter.getInitialState(),
          response,
        )
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      providesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),

    deleteAllChannelsFromFavorites: builder.mutation<ChannelModel[], void>({
      query: () => {
        return {
          url: 'channels',
          method: 'DELETE',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),

    getFavoritesChannelByChannelId: builder.query<'', { id: string }>({
      query: ({ id }) => {
        return {
          url: `channels/${id}`,
          method: 'GET',
        }
      },
    }),

    addChannelToFavorites: builder.mutation<ChannelModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `channels/${id}`,
          method: 'POST',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),

    removeChannelFromFavorites: builder.mutation<ChannelModel, { id: string }>({
      query: ({ id }) => {
        return {
          url: `channels/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: [FavoritesApiTag.ALL_FAVORITES_CHANNELS],
    }),
  }),
})

export const {
  useGetAllFavoritesContentQuery,
  useGetFavoritesContentByContentIdQuery,
  useAddContentToFavoritesMutation,
  useRemoveContentFromFavoritesMutation,
  useGetAllMeFavoritesChannelsQuery,
  useAddChannelToFavoritesMutation,
  useRemoveChannelFromFavoritesMutation,
} = favoritesApi
