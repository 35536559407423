import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  addManyToEndSearchResults,
  addManyToStartSearchResults,
  removeOneFromSearchResults,
  resetSearchResults,
  setSearchResults,
} from '@/modules/search/slice/actions/results/results.actions'

export const searchResultsExtraReducers = (builder: SearchSliceBuilderType) => {
  builder

    .addCase(setSearchResults, (state, action) => {
      if (action.payload.payload) state[action.payload.pageName].results = action.payload.payload
    })

    .addCase(addManyToEndSearchResults, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].results.push(...action.payload.payload)
    })

    .addCase(addManyToStartSearchResults, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].results.unshift(...action.payload.payload)
    })

    .addCase(removeOneFromSearchResults, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].results.filter((el) => el.id !== action.payload.payload?.id)
    })

    .addCase(resetSearchResults, (state, action) => {
      state[action.payload.pageName].results = []
    })
}
