import React, { FC } from 'react'

import DefaultCardInnerForDarkSlider from '@/components/DefaultCardInnerForDarkSlider'

import { useAppSelector } from '@/core/store'

import { TagModel } from '@/models/tag.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface TagCardInnerProps {
  card?: TagModel
  pageName?: PagesWithSearch
  focused?: boolean
}

const TagCardInner: FC<TagCardInnerProps> = ({ card, pageName = 'search', focused }) => {
  const active = useAppSelector(
    (state) =>
      !!state.searchByPageSlice[pageName].searchState.filters.tags.find((el) => el.id === card?.id),
  )
  return <DefaultCardInnerForDarkSlider title={card?.title} active={active} focused={focused} />
}

export default TagCardInner
