import React, { FC, ReactElement, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Slider, { SliderProps } from '@/components/Slider'
import { RowProps } from '@/components/Slider/Row'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { TagModel } from '@/models/tag.model'
import TagCard from '@/modules/tags/components/TagCard'

type RenderFn = () => ReactElement[]

export interface BaseTagsSliderProps<T> extends Omit<SliderProps, 'children'> {
  rowProps?: Omit<RowProps, 'children'>
  children?: RenderFn
  onCardPress?: (item: T) => void
  onCardFocus?: OnChildFocus
}
export interface TagsSliderProps extends BaseTagsSliderProps<TagModel> {
  data?: TagModel[]
  loading?: boolean
  skeletonItemsLength?: number
}

const TagsSlider: FC<TagsSliderProps> = ({
  rowProps,
  data,
  skeletonItemsLength = 5,
  loading,
  onCardFocus,
  onCardPress,
  ...sliderProps
}) => {
  const loadingRef = useRef<boolean>(!!loading)
  useEffect(() => {
    loadingRef.current = !!loading
  }, [loading])

  const render = useMemo(() => {
    if ((!data && loadingRef.current) || !data)
      return Array(skeletonItemsLength)
        .fill(<TagCard />)
        .map((el, index) => <TagCard key={index} />)

    return data?.map((tag) => (
      <TagCard key={tag.id} card={tag} onCardFocus={onCardFocus} onCardPress={onCardPress} />
    ))
  }, [data])

  return (
    <Slider {...sliderProps}>
      <Slider.Horizontal
        {...rowProps}
        className={classNames(rowProps?.className, styles.ScrollingWrapper)}
      >
        {render}
      </Slider.Horizontal>
    </Slider>
  )
}

export default TagsSlider
