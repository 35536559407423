import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import {
  PlayerReducerActions,
  PlayerState,
  setFullscreenStatusAC,
} from '@/modules/player/player.reducer'

import {
  requestEnterFullscreen,
  requestExitFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'

export interface FullscreenState {
  isFullscreen: boolean
  enterFullscreen: () => void
  exitFullscreen: () => void
  toggleFullscreen: () => void
}

type FullscreenRenderParameters = FullscreenState & {
  onFullscreenRef: (element: HTMLDivElement) => void
}

export interface FullScreenAction {
  toggleFullscreen: () => void
  isFullscreen: boolean
}

export interface PlayerFullscreenManagerProps {
  onFullScreenActionReady?: (arg: FullScreenAction) => void
  onRequestFullscreenApproved?: () => void
  onRequestExitFullscreenApproved?: () => void
}

interface FullPlayerFullscreenManagerProps extends PlayerFullscreenManagerProps {
  children: (params: FullscreenRenderParameters) => React.ReactElement
}

const PlayerFullscreenManager: FC<FullPlayerFullscreenManagerProps> = ({
  children,
  onFullScreenActionReady,
  onRequestFullscreenApproved,
  onRequestExitFullscreenApproved,
}) => {
  const playerId = usePlayerIdContext()
  const { usePlayerDispatch, usePlayerStateSelector } = PlayerManager.getPlayer<
    PlayerState,
    PlayerReducerActions
  >(playerId)

  const dispatch = usePlayerDispatch()
  const isFullscreen = usePlayerStateSelector((state) => state.fullscreen.status)

  const fullscreenElementRef = useRef<HTMLDivElement | null>(null)
  // initial cb, that define fullscreen Element ref
  const onFullscreenRef = useCallback((element: HTMLDivElement) => {
    // element
    fullscreenElementRef.current = element
  }, [])

  const enterFullscreen = useCallback(() => {
    if (!fullscreenElementRef.current) return
    requestEnterFullscreen(fullscreenElementRef.current)
      .then((status) => {
        if (status) {
          dispatch(setFullscreenStatusAC(status))
          onRequestFullscreenApproved?.()
        }
      })
      .catch(() => {
        dispatch(setFullscreenStatusAC(false))
      })
  }, [fullscreenElementRef.current])

  const exitFullscreen = useCallback(() => {
    if (!fullscreenElementRef.current) return
    requestExitFullscreen().then(() => {
      dispatch(setFullscreenStatusAC(false))
      onRequestExitFullscreenApproved?.()
    })
  }, [fullscreenElementRef.current])

  const toggleFullscreen = useCallback(() => {
    if (isFullscreen) exitFullscreen()
    else enterFullscreen()
  }, [exitFullscreen, enterFullscreen, isFullscreen])

  const params: FullscreenRenderParameters = useMemo(() => {
    return {
      onFullscreenRef,
      isFullscreen,
      enterFullscreen,
      exitFullscreen,
      toggleFullscreen,
    }
  }, [toggleFullscreen, onFullscreenRef])

  useEffect(() => {
    onFullScreenActionReady?.({ toggleFullscreen, isFullscreen })
  }, [toggleFullscreen, isFullscreen])
  return children(params)
}

export default PlayerFullscreenManager
