import React, { FC, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useAppDispatch } from '@/core/store'

import { GenreModel } from '@/models/genre.model'
import { PartnerBaseModel } from '@/models/partner.model'
import { useShouldDisableResetButton } from '@/modules/search/components/ResetFiltersAction/useShouldDisableResetButton'
import { useLocation } from 'react-router-dom'
import { ContentPaths } from '@/core/router/router.paths'
import { TagModel } from '@/models/tag.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import { resetSearchTerm } from '@/modules/search/slice/actions/searchState/searchState.term.actions'
import { resetYearsFilter } from '@/modules/search/slice/actions/searchState/searchState.years.actions'
import { resetTypesFilter } from '@/modules/search/slice/actions/searchState/searchState.contentTypes.actions'
import { resetCountriesFilter } from '@/modules/search/slice/actions/searchState/searchState.countries.actions'
import { resetSort } from '@/modules/search/slice/actions/searchState/searchState.sort.actions'
import {
  resetGenresFilter,
  setGenresFilter,
} from '@/modules/search/slice/actions/searchState/searchState.genres.actions'
import {
  resetTagsFilter,
  setTagsFilter,
} from '@/modules/search/slice/actions/searchState/searchState.tags.actions'
import {
  resetPartnersFilter,
  setPartnersFilter,
} from '@/modules/search/slice/actions/searchState/searchState.partners.actions'

interface ResetFiltersAction {
  disabled?: boolean
  className?: string
  chosenGenres?: GenreModel[]
  chosenTags?: TagModel[]
  chosenPartners?: PartnerBaseModel[]
  pageName: PagesWithSearch
}

const ResetFiltersAction: FC<ResetFiltersAction> = ({
  className,
  disabled,
  chosenGenres,
  chosenPartners,
  chosenTags,
  pageName,
}) => {
  const { pathname } = useLocation()
  const shouldDisable = useShouldDisableResetButton({
    chosenGenres,
    chosenPartners,
    chosenTags,
    pageName,
  })

  const dispatch = useAppDispatch()

  const handleButtonPress = useCallback(() => {
    dispatch(resetSearchTerm({ pageName }))
    dispatch(resetYearsFilter({ pageName }))
    dispatch(resetTypesFilter({ pageName }))
    dispatch(resetCountriesFilter({ pageName }))
    dispatch(resetSort({ pageName }))

    if (chosenGenres && pathname.includes(ContentPaths.GENRES)) {
      dispatch(setGenresFilter({ pageName, payload: chosenGenres }))
    } else {
      dispatch(resetGenresFilter({ pageName }))
    }

    if (chosenTags && pathname.includes(ContentPaths.TAGS)) {
      dispatch(setTagsFilter({ pageName, payload: chosenTags }))
    } else {
      dispatch(resetTagsFilter({ pageName }))
    }

    if (chosenPartners && pathname.includes(ContentPaths.PARTNERS)) {
      dispatch(setPartnersFilter({ pageName, payload: chosenPartners }))
      return
    }
    dispatch(resetPartnersFilter({ pageName }))
  }, [chosenGenres, chosenPartners, chosenTags])
  return (
    <Button
      disabled={disabled || shouldDisable}
      buttonClassName={classNames(className, {
        [styles.ResetActive]: !shouldDisable,
      })}
      focusClassName={classNames(styles.ResetFocused)}
      onButtonClick={handleButtonPress}
      onEnterPress={handleButtonPress}
    />
  )
}

export default ResetFiltersAction
