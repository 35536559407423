export enum TvCategoryId {
  INFORMATION = '17bca6c3-5467-4a16-b114-91e47a52bf4a',
  BELARUS = '8cd2617d-2e27-409f-a646-8cf0865c83ed',
  ENTERTAINMENT = 'ab39e2ea-1b45-448f-bbfa-fcc2bee8760b',
  MUSIC = 'e1c1984b-1f55-4813-80f7-6d98ca67d246',
  SPORT = 'bf209745-558b-4dc0-9154-d115fe1a5b01',
  POPULAR_SCIENCE = 'a3890921-77c7-46cc-bc2b-8bf0fd8f6bb7',
  MOVIE = '1ce2eb4d-f606-467b-ab99-d5ee352e3697',
  CHILDREN = '8c8ae443-897a-4445-a481-1ff64fcc27de',
  UHD_HD = '43bc053c-c7b0-4e99-aa58-b21b401f34c6',
  INTERNET = 'ed76075c-9fbb-4f2a-87ff-0f13540cde1f',
}
