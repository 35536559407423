import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'

interface ContentRatingProps {
  rating: string | number
  logo: string
  className?: string
}

const ContentRating = ({ rating, logo, className }: ContentRatingProps) => {
  return (
    <div className={classNames(styles.Rating, className)}>
      <img src={logo} />
      <span>{rating}</span>
    </div>
  )
}

export default ContentRating
