import React, { FC } from 'react'

import ContentSlider, { BaseContentSliderProps } from '@/modules/content/components/ContentSlider'

import { MovieModel } from '@/models/movie.model'

export interface MovieSliderProps extends BaseContentSliderProps<MovieModel> {
  data?: MovieModel[]
  isLoading?: boolean
  withoutNavigationCard?: boolean
  specificAction?: (args: {
    setFocus?: (key: string) => void
    className?: string
  }) => React.ReactElement
}

const MovieSlider: FC<MovieSliderProps> = ({
  onCardPress,
  onCardFocus,
  isLoading,
  data,
  ...rest
}) => {
  return (
    <ContentSlider<MovieModel>
      data={data?.slice(0, 15)}
      isLoading={isLoading}
      onCardPress={onCardPress}
      onCardFocus={onCardFocus}
      rowProps={{
        title: 'Фильмы',
      }}
      {...rest}
    />
  )
}

export default MovieSlider
