import React, { FC, useCallback } from 'react'

import { useGetSerialsByGenreIdWithPaginationQuery } from '@/modules/genre/genre.api'

import SerialsSlider from '@/modules/serials/component/SerialsSlider'

import { SerialModel } from '@/models/serial.model'
import { BaseContentSliderProps } from '@/modules/content/components/ContentSlider'
import { useNavigate } from 'react-router-dom'
import { ContentPaths, Paths } from '@/core/router/router.paths'
import Button from '@/components/Button'

interface SerialsByGenreIdSliderProps extends BaseContentSliderProps<SerialModel> {
  genreId: string
}

const SerialsByGenreIdSlider: FC<SerialsByGenreIdSliderProps> = ({ genreId, ...rest }) => {
  const { data, isLoading } = useGetSerialsByGenreIdWithPaginationQuery({
    genreId,
    relations: {
      serial: false,
      trailer: false,
      actors: false,
      genres: false,
      backgrounds: true,
    },
    paginate: {
      page: 1,
      limit: 10,
    },
  })

  const navigate = useNavigate()

  const handleOnAction = useCallback(() => {
    if (!genreId) return

    navigate(`${Paths.CONTENT}/${ContentPaths.GENRES}/${genreId}`, {
      state: { movies: false, serials: true },
    })
  }, [genreId])

  return (
    <SerialsSlider
      isLoading={isLoading}
      data={data?.items}
      specificAction={({ className }) => (
        <Button
          buttonClassName={className}
          onButtonClick={handleOnAction}
          onEnterPress={handleOnAction}
        >
          Смотреть всё
        </Button>
      )}
      {...rest}
    />
  )
}

export default SerialsByGenreIdSlider
