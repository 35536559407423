import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  addPartnerToPartnersFilter,
  removePartnerFromPartnersFilter,
  resetPartnersFilter,
  setPartnersFilter,
} from '@/modules/search/slice/actions/searchState/searchState.partners.actions'

export const partnersExtraReducers = (builder: SearchSliceBuilderType) => {
  builder
    // ------------------------ PARTNERS

    .addCase(addPartnerToPartnersFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.partners.push(action.payload.payload)
    })
    .addCase(removePartnerFromPartnersFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.partners = state[
          action.payload.pageName
        ].searchState.filters.partners.filter((el) => el.id !== action.payload.payload?.id)
    })
    .addCase(setPartnersFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.partners = action.payload.payload
    })
    .addCase(resetPartnersFilter, (state, action) => {
      state[action.payload.pageName].searchState.filters.partners = []
    })

  //-----------------------------------------------------------
}
