import React, { FC } from 'react'

import styles from './styles.module.scss'

import DefaultCard from '@/components/DefaultCard'
import ChannelListItemInner from '@/modules/tv/components/ChannelsListSidebarAction/ChannelListItemInner'

import type { DefaultCardProps } from '@/components/DefaultCard'
import { ChannelModel } from '@/models/channel.model'

export interface Props extends Omit<DefaultCardProps<ChannelModel>, 'children'> {
  index: number
}

const ChannelListItem: FC<Props> = ({ card, index, onCardFocus, ...props }) => {
  return (
    <DefaultCard<ChannelModel>
      className={styles.TvChannelCard}
      wrapperClassName={styles.FocusWrapper}
      onCardFocus={onCardFocus}
      card={card}
      {...props}
    >
      <ChannelListItemInner card={card} index={index} />
    </DefaultCard>
  )
}

export default ChannelListItem
