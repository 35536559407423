import React, { useMemo } from 'react'

import styles from './styles.module.scss'

import Loader from '@/components/Loader'
import Header from '@/modules/notifications/components/Header'
import ListWithScrollbar from '@/components/ListWithScrollbar'
import NotificationCard from '@/modules/notifications/components/NotificationCard'
import modal from '@/components/Notifications/modal'

import { useGetNotifications } from '@/modules/notifications/hooks/useGetNotifications'

import { useAppSelector } from '@/core/store'

const Notifications = () => {
  const { notifications, loading } = useGetNotifications()

  const currentFilter = useAppSelector((state) => state.notifications.currentFilter)

  const filteredNotifications = useMemo(() => {
    if (!notifications) return []
    if (!currentFilter) return notifications

    return notifications.filter((el) => el.type === currentFilter)
  }, [currentFilter, notifications])

  return (
    <div className={styles.Notifications}>
      <Header disabled={loading || !notifications || notifications.length === 0} />
      {loading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      {!loading && notifications && notifications.length === 0 && (
        <div className={styles.Empty}>
          В данный момент у Вас нет сообщений. Здесь будут отображаться напоминания об истечении
          подписки, рекомендации, информация о работе видеосервиса ITV.
        </div>
      )}
      {notifications && notifications.length > 0 && (
        <ListWithScrollbar className={styles.List}>
          {filteredNotifications.map((notification) => (
            <NotificationCard
              key={notification.id}
              focusProps={{ focusKey: notification.id }}
              card={notification}
              onCardPress={() => {
                modal({ notification })
              }}
            />
          ))}
        </ListWithScrollbar>
      )}
    </div>
  )
}

export default Notifications
