import React, { FC, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { ListProps } from '@/components/List'
import Checkbox, { CheckboxProps } from '@/components/Checkbox'
import ListWithScrollbar from '@/components/ListWithScrollbar'

import { useAppDispatch, useAppSelector } from '@/core/store'

import {
  resetTypesFilter,
  setTypesFilter,
} from '@/modules/search/slice/actions/searchState/searchState.contentTypes.actions'

import { ContentType } from '@/models/content.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface ContentTypesListProps extends Omit<ListProps, 'children'> {
  chosenType?: ContentType
  disabledTypes?: ContentType[]
  pageName: PagesWithSearch
}

const CONTENT_TYPES = [ContentType.MOVIE, ContentType.SERIAL]

const ContentTypesList: FC<ContentTypesListProps> = ({
  className,
  // chosenType,
  disabledTypes,
  pageName,
  ...rest
}) => {
  return (
    <ListWithScrollbar className={classNames(styles.ContentTypesList, className)} {...rest}>
      {[
        <DefaultTypeCheckBox
          className={styles.DefaultCheckbox}
          key={'chooseAllTypes'}
          autoFocus={true}
          pageName={pageName}
        />,
        ...CONTENT_TYPES.map((type) => {
          return (
            <TypeCheckBox
              key={type}
              type={type}
              pageName={pageName}
              name={type === 'SERIAL' ? 'Сериалы' : 'Фильмы'}
              disabled={disabledTypes?.includes(type)}
            />
          )
        }),
      ]}
    </ListWithScrollbar>
  )
}

interface DefaultTypeCheckBoxProps extends CheckboxProps {
  pageName: PagesWithSearch
  chosenType?: ContentType
}

const DefaultTypeCheckBox = (props: DefaultTypeCheckBoxProps) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) => state.searchByPageSlice[props.pageName].searchState.filters.type,
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        dispatch(resetTypesFilter({ pageName: props.pageName }))
        return
      }
    },
    [props.chosenType],
  )

  return (
    <Checkbox rounded name={'Выбрать всё'} checked={!checked} onChange={handleChange} {...props} />
  )
}

interface TypeCheckBox extends CheckboxProps {
  type: ContentType
  pageName: PagesWithSearch
}

const TypeCheckBox = (props: TypeCheckBox) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) => state.searchByPageSlice[props.pageName].searchState.filters.type === props.type,
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (!props.type) return

      if (checked) {
        dispatch(setTypesFilter({ pageName: props.pageName, payload: props.type }))
        return
      }

      dispatch(resetTypesFilter({ pageName: props.pageName }))
    },
    [props.type],
  )

  return <Checkbox rounded checked={checked} onChange={handleChange} {...props} />
}

export default ContentTypesList
