import React, { FC, MouseEvent, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import {
  toPercentString,
  zeroStyle,
  getBoundingEventCoordinates,
} from '@/modules/player/helpers/range.helpers'

import { useHotReload } from '@/core/store/hooks'
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { SKIP_TIMELINE_TIME } from '@/core/config'

interface UpdateConditions {
  isDragging?: boolean
  isEnded?: boolean
  isPreviewing?: boolean
}

interface ChildrenProps {
  previewValue?: number
  isDragging: boolean
  isPointerInside?: boolean
  maxValue: number
}

interface RangeProps {
  value: number
  maxValue: number

  isUpdateBlocked: boolean
  isVertical?: boolean

  children?: React.ReactElement<ChildrenProps, string | React.JSXElementConstructor<ChildrenProps>>

  containerClassname?: string
  sliderClassname?: string
  thumbClassName?: string
  backGroundLayerClassname?: string
  secondaryLayerClassName?: string
  progressLayerClassname?: string

  onValueChange?: (value: number) => void
  onDrag?: (value: number) => void
  onDragEnd?: () => void

  appendOuterContent?: React.ReactNode
  prependOuterContent?: React.ReactNode
  appendOuterContentClassname?: string
  prependOuterContentClassname?: string

  togglePlayPause?: () => Promise<void>
}

const Range: FC<RangeProps> = (props) => {
  // just for hot reload component (simple useState)
  const hotReload = useHotReload()

  const dragValueRef = useRef(props.value)
  const previewValueRef = useRef(props.value)

  const isPointerInsideRef = useRef(false)

  const renderedTrackRef = useRef<HTMLDivElement | null>(null)
  const secondaryLayerRef = useRef<HTMLDivElement | null>(null)
  const progressLayerRef = useRef<HTMLDivElement | null>(null)

  const isDragRef = useRef(false)

  const isTouchSupported = 'ontouchend' in window

  const updateValueFromCoordinates = (
    evt:
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
      | MouseEvent
      | TouchEvent,
    conditions: UpdateConditions,
  ) => {
    if (!renderedTrackRef.current) return

    const clickCoordinates = getBoundingEventCoordinates(evt, renderedTrackRef.current)
    if (props.isVertical) {
      const relativeVerticalValue =
        (clickCoordinates.height - clickCoordinates.y) / clickCoordinates.height
      updateValue(relativeVerticalValue, conditions)
    } else {
      const relativeHorizontalValue = clickCoordinates.x / clickCoordinates.width
      updateValue(relativeHorizontalValue, conditions)
    }
  }

  const updateValue = (
    relativeValue: number,
    { isDragging, isEnded, isPreviewing }: UpdateConditions,
  ) => {
    const value = relativeValue * props.maxValue
    if (isPreviewing) {
      dragValueRef.current = value
      previewValueRef.current = value

      // hot reload view after values changed
      hotReload()
    } else {
      if (isDragRef.current) {
        dragValueRef.current = value
        previewValueRef.current = value
        if (props.onDrag) {
          props.onDrag(value)
        }
      }
      if (props.onValueChange && (isEnded || !(isDragging || isDragRef.current))) {
        props.onValueChange(value)
      }
    }
  }

  const handleHandleOrTrackClick = (evt: React.MouseEvent<HTMLDivElement>) => {
    updateValueFromCoordinates(evt, {})
  }

  const handleHandleStartDrag = (
    evt: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    if (evt.type !== 'touchstart') {
      evt.stopPropagation()
    }
    if (isDragRef.current) return

    isDragRef.current = true
    updateValueFromCoordinates(evt, { isDragging: true })

    // We are OK with no position updates yet.
    if (isTouchSupported) {
      document.addEventListener('touchmove', handleHandleDrag)
      document.addEventListener('touchend', handleHandleEndDrag)
      document.addEventListener('touchcancel', handleHandleEndDrag)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.addEventListener('mousemove', handleHandleDrag)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.addEventListener('mouseup', handleHandleEndDrag)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.addEventListener('mouseleave', handleHandleEndDrag)
    }
  }

  const handleHandleDrag = (
    evt:
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
      | MouseEvent
      | TouchEvent,
  ) => {
    if (isDragRef.current) {
      updateValueFromCoordinates(evt, {})
    }
    if (props.isUpdateBlocked && isDragRef.current) return

    updateValueFromCoordinates(evt, { isPreviewing: true })
  }

  const handleHandleEndDrag = (
    evt:
      | React.MouseEvent<HTMLDivElement>
      | React.TouchEvent<HTMLDivElement>
      | MouseEvent
      | TouchEvent,
  ) => {
    if (isDragRef.current) {
      updateValueFromCoordinates(evt, { /*isDragging: true,*/ isEnded: true })
    }
    if (isTouchSupported) {
      document.removeEventListener('touchmove', handleHandleDrag)
      document.removeEventListener('touchend', handleHandleEndDrag)
      document.removeEventListener('touchcancel', handleHandleEndDrag)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener('mousemove', handleHandleDrag)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener('mouseup', handleHandleEndDrag)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener('mouseleave', handleHandleEndDrag)
    }
    isDragRef.current = false
    props.onDragEnd?.()
  }

  const handleMouseEnter = () => {
    if (isDragRef.current && props.isUpdateBlocked) return
    isPointerInsideRef.current = true
  }

  const handleMouseLeave = () => {
    isPointerInsideRef.current = false
    hotReload()
  }

  // Value for tooltip while hovering or dragging
  const displayValue =
    isPointerInsideRef.current || isDragRef.current ? dragValueRef.current : props.value

  // Progress layer value with blocking flag while dragging
  const progressValue = isDragRef.current ? displayValue : props.value

  // Additional className added to Thumb element only while dragging
  const progressDragThumbClassName = isDragRef.current ? styles.Thumb__Drag : ''
  const { ref, focusKey, focused } = useFocusable({
    focusKey: 'timeline-thumb',
    onEnterPress: () => {
      props.togglePlayPause?.()
    },
    onArrowPress: (direction) => {
      if (direction === 'left') {
        props.onValueChange?.(
          props.value - SKIP_TIMELINE_TIME < 0 ? 0 : props.value - SKIP_TIMELINE_TIME,
        )
        return false
      }
      if (direction === 'right') {
        props.onValueChange?.(
          props.value + SKIP_TIMELINE_TIME > props.maxValue
            ? props.maxValue
            : props.value + SKIP_TIMELINE_TIME,
        )
        return false
      }
      return true
    },
  })

  return (
    <div className={classNames(styles.SliderRange, props.containerClassname)}>
      {/* After track render content */}
      {props.prependOuterContent && (
        <div
          className={classNames(props.prependOuterContentClassname, styles.SliderRange__Prepend)}
        >
          {props.prependOuterContent}
        </div>
      )}

      {/* Main track container*/}
      <div
        id={focusKey}
        ref={ref}
        className={classNames(
          classNames(
            styles.SliderRange__SliderWrapper,
            focused && styles.SliderRange__SliderWrapper__Active,
          ),
        )}
      >
        <div
          onClick={handleHandleOrTrackClick}
          onTouchStart={handleHandleStartDrag}
          onTouchMove={handleHandleDrag}
          onTouchEnd={handleHandleEndDrag}
          onMouseDown={handleHandleStartDrag}
          onMouseUp={handleHandleEndDrag}
          onMouseMove={handleHandleDrag}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          role='slider'
          aria-valuemin={0}
          aria-valuemax={props.maxValue}
          aria-valuenow={props.value}
          tabIndex={0}
          className={classNames(styles.SliderRange__Slider, props.sliderClassname)}
        >
          {/* Track */}
          <div
            ref={renderedTrackRef}
            className={classNames(styles.Layer, styles.Background, props.backGroundLayerClassname)}
          />

          {/* Secondary Layer */}
          <div
            ref={secondaryLayerRef}
            style={{
              width:
                !isDragRef.current && isPointerInsideRef.current
                  ? // ? toPercentString(state.previewValue || displayValue, props.maxValue)
                    toPercentString(previewValueRef.current || displayValue, props.maxValue)
                  : zeroStyle,
            }}
            className={classNames(styles.Layer, styles.Secondary, props.secondaryLayerClassName)}
          />

          {/* Progress Layer */}
          <div
            ref={progressLayerRef}
            style={{ width: toPercentString(progressValue, props.maxValue) }}
            className={classNames(styles.Layer, styles.Progress, props.progressLayerClassname)}
          />

          {/* Tooltip rendered as Children */}
          {React.Children.map(
            props.children,
            (child) =>
              child &&
              React.cloneElement(child, {
                previewValue:
                  isPointerInsideRef.current || isDragRef.current
                    ? previewValueRef.current
                    : undefined,
                // previewValue: previewValueRef.current,
                isDragging: isDragRef.current,
                isPointerInside: isPointerInsideRef.current,
                maxValue: props.maxValue,
              }),
          )}

          {/* Thumb */}
          <div
            style={{
              left: toPercentString(progressValue, props.maxValue),
            }}
            className={classNames(
              styles.Thumb,
              progressDragThumbClassName,
              props.thumbClassName,
              focused && styles.Thumb__Active,
            )}
            role='button'
            tabIndex={-5}
            // ref={renderedThumbRef}
          />
        </div>
      </div>

      {/* After track render content */}
      {props.appendOuterContent && (
        <div className={classNames(props.appendOuterContentClassname, styles.SliderRange__Append)}>
          {props.appendOuterContent}
        </div>
      )}
    </div>
  )
}

export default Range
