import { FeedsResourceBaseModel } from '@/models/feeds.resource.model'
import React, { JSX, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  getResourcesChunks,
  isChannelsResource,
  isContentResource,
  isFavoriteChannelsResource,
  isFavoriteContentResource,
  isGenresListResource,
  isIWatchResource,
} from '@/modules/feeds/components/ResourcesList/helpers'
import { ContentModel, ContentWithViewModel } from '@/models/content.model'
import { ChannelModel } from '@/models/channel.model'
import { GenreModel } from '@/models/genre.model'
import { useHandleCardPressAndCardFocus } from '@/modules/feeds/hooks/useHandleCardPressAndCardFocus'
import ChannelsFromResource from '@/modules/feeds/components/ChannelsFromResource'
import ContentFromResource from '@/modules/feeds/components/ContentFromResource'
import GenresFromResource from '@/modules/feeds/components/GenresFromResource'
import {
  useLazyGetChannelsByResourceUrlQuery,
  useLazyGetContentByResourceUrlQuery,
  useLazyGetFavoriteChannelsByResourceUrlQuery,
  useLazyGetFavoriteContentByResourceUrlQuery,
  useLazyGetGenresByResourceUrlQuery,
  useLazyGetViewedContentByResourceUrlQuery,
} from '@/modules/feeds/feeds.api'
import { RESOURCES_CHUNK_SIZE } from '@/core/config'
import ViewContentFromResource from '@/modules/feeds/components/ViewContentFromResource'

interface ResourceWithItems {
  resource: FeedsResourceBaseModel
  items?: (ContentModel | ChannelModel | GenreModel)[]
}

type ResourcesWithItemsType = ResourceWithItems[]

const blockContentPosterChangeEvent = new Event('blockContentPosterChangeEvent')
const unBlockContentPosterChangeEvent = new Event('unBlockContentPosterChangeEvent')

const MINIMAL_SLIDERS_LENGTH = 2

export const usePrepareResources = (resources?: FeedsResourceBaseModel[]) => {
  const [getContent] = useLazyGetContentByResourceUrlQuery()
  const [getFavoriteContent, { data: favoriteContent, originalArgs: favoriteContentOriginalArgs }] =
    useLazyGetFavoriteContentByResourceUrlQuery()
  const [getViewedContent, { data: viewedContent, originalArgs: viewedContentOriginalArgs }] =
    useLazyGetViewedContentByResourceUrlQuery()
  const [getGenres] = useLazyGetGenresByResourceUrlQuery()
  const [getChannels] = useLazyGetChannelsByResourceUrlQuery()
  const [
    getFavoriteChannels,
    { data: favoriteChannels, originalArgs: favoriteChannelsOriginalArgs },
  ] = useLazyGetFavoriteChannelsByResourceUrlQuery()

  const { 0: resourcesWithItems, 1: setResourcesWithItems } = useState<ResourcesWithItemsType>([])

  const activeChunkRef = useRef(-1)
  const chunkLoadingRef = useRef(false)

  useEffect(() => {
    if (!favoriteChannels) return
    if (!favoriteChannelsOriginalArgs) return

    setResourcesWithItems((prevState) => {
      return prevState.map((el) =>
        el.resource.url === favoriteChannelsOriginalArgs.url
          ? { ...el, items: favoriteChannels }
          : el,
      )
    })
  }, [favoriteChannels])

  useEffect(() => {
    if (!favoriteContent) return
    if (!favoriteContentOriginalArgs) return

    setResourcesWithItems((prevState) => {
      return prevState.map((el) =>
        el.resource.url === favoriteContentOriginalArgs.url
          ? { ...el, items: favoriteContent }
          : el,
      )
    })
  }, [favoriteContent])

  useEffect(() => {
    if (!viewedContent) return
    if (!viewedContentOriginalArgs) return

    setResourcesWithItems((prevState) => {
      return prevState.map((el) =>
        el.resource.url === viewedContentOriginalArgs.url ? { ...el, items: viewedContent } : el,
      )
    })
  }, [viewedContent])

  const chunks = useMemo(() => {
    if (!resources) return

    return getResourcesChunks(resources)
  }, [resources])

  const loadContent = useCallback(
    async (chunk: FeedsResourceBaseModel[]): Promise<ResourcesWithItemsType | undefined> => {
      chunkLoadingRef.current = true
      document.dispatchEvent(blockContentPosterChangeEvent)
      try {
        const promises = chunk?.map((resource) => {
          if (isFavoriteContentResource(resource)) {
            return getFavoriteContent({ url: resource.url }, true)
              .unwrap()
              .then((items) => {
                return {
                  resource,
                  items,
                }
              })
              .catch(() => {
                return {
                  resource,
                }
              })
          }

          if (isContentResource(resource)) {
            return getContent({ url: resource.url }, true)
              .unwrap()
              .then((items) => {
                return {
                  resource,
                  items,
                }
              })
              .catch(() => {
                return {
                  resource,
                }
              })
          }

          if (isFavoriteChannelsResource(resource)) {
            return getFavoriteChannels({ url: resource.url }, true)
              .unwrap()
              .then((items) => {
                return {
                  resource,
                  items,
                }
              })
              .catch(() => {
                return {
                  resource,
                }
              })
          }

          if (isChannelsResource(resource)) {
            return getChannels({ url: resource.url }, true)
              .unwrap()
              .then((items) => {
                return {
                  resource,
                  items,
                }
              })
              .catch(() => {
                return {
                  resource,
                }
              })
          }

          if (isIWatchResource(resource)) {
            return getViewedContent({ url: resource.url })
              .unwrap()
              .then((items) => {
                return {
                  resource,
                  items,
                }
              })
              .catch(() => {
                return {
                  resource,
                }
              })
          }

          if (isGenresListResource(resource)) {
            return getGenres({ url: resource.url }, true)
              .unwrap()
              .then((items) => {
                return {
                  resource,
                  items,
                }
              })
              .catch(() => {
                return {
                  resource,
                }
              })
          }

          return {
            resource,
          }
        })

        return (await Promise.all(promises)) as unknown as Promise<
          ResourcesWithItemsType | undefined
        >
      } catch (e) {
        console.log(e)
      }
    },
    [],
  )

  const loadMoreChunksContents = useCallback(async () => {
    if (!chunks) return
    activeChunkRef.current = activeChunkRef.current + 1
    const chunk = chunks[activeChunkRef.current]

    const res = await loadContent(chunk)
    if (!res) return
    setResourcesWithItems((prev) => prev.concat(res))
    chunkLoadingRef.current = false
    document.dispatchEvent(unBlockContentPosterChangeEvent)
  }, [chunks])

  useEffect(() => {
    if (!chunks || !chunks.length || activeChunkRef.current !== -1) return

    loadMoreChunksContents()
  }, [chunks])

  const { handleOnCardFocus, handleCardPress } = useHandleCardPressAndCardFocus()

  useEffect(() => {
    // ANALYZING for chunk load result

    if (!chunks) return
    if (!resourcesWithItems || !resourcesWithItems.length) return
    if (activeChunkRef.current >= chunks.length - 1) return

    // ANALYZING get last chunk result and filter resources that contains non empty items for render
    const filteredLastChunkResourcesWithItems = resourcesWithItems
      .slice(-RESOURCES_CHUNK_SIZE)
      .filter((res) => Boolean(res.items && res.items.length > 0))

    // if filtered resources contains fewer items than RESOURCES_CHUNK_SIZE, load more
    if (filteredLastChunkResourcesWithItems.length >= MINIMAL_SLIDERS_LENGTH) return

    loadMoreChunksContents()
  }, [resourcesWithItems])

  const filteredResourcesWithItems = useMemo(() => {
    return resourcesWithItems.filter((res) => !!res.items)
  }, [resourcesWithItems])

  const sliders = useMemo(() => {
    if (!resources) return []

    const handlers = {
      onCardPress: handleCardPress,
      onCardFocus: handleOnCardFocus,
    }

    return filteredResourcesWithItems
      .map(({ resource, items }) =>
        isChannelsResource(resource) || isFavoriteChannelsResource(resource) ? (
          <ChannelsFromResource
            key={resource.id}
            resource={resource}
            channels={items as ChannelModel[]}
            // height={354}
            height={320}
            {...handlers}
          />
        ) : isGenresListResource(resource) ? (
          <GenresFromResource
            key={resource.id}
            resource={resource}
            genres={items as GenreModel[]}
            // height={302}
            height={295}
            {...handlers}
          />
        ) : isFavoriteContentResource(resource) || isContentResource(resource) ? (
          <ContentFromResource
            key={resource.id}
            resource={resource}
            content={items as ContentModel[]}
            // height={477}
            height={423}
            {...handlers}
          />
        ) : isIWatchResource(resource) ? (
          <ViewContentFromResource
            key={resource.id}
            resource={resource}
            content={items as ContentWithViewModel[]}
            // height={477}
            height={423}
            {...handlers}
          />
        ) : null,
      )
      .filter((el) => el !== null) as unknown as JSX.Element[]
  }, [filteredResourcesWithItems])

  const shouldShowLoadMore = useMemo(() => {
    return (
      activeChunkRef.current !== -1 &&
      resources &&
      resourcesWithItems.length > 0 &&
      resourcesWithItems.length < resources.length
    )
  }, [resources, resourcesWithItems])

  return useMemo(() => {
    return {
      sliders,
      shouldShowLoadMore,
      loadMoreChunksContents,
    }
  }, [sliders, shouldShowLoadMore, loadMoreChunksContents])
}
