import React, { FC } from 'react'
import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import classNames from 'classnames'
import styles from './styles.module.scss'
import SessionCardInner from '@/modules/auth/components/SessionCard/SessionCardInner'
import { SessionModel } from '@/models/session.model'

interface SessionCardProps extends Omit<DefaultCardProps<SessionModel>, 'children'> {
  showCloseIcon?: boolean
}

const SessionCard: FC<SessionCardProps> = ({
  card,
  disabled,
  className,
  wrapperClassName,
  showCloseIcon,
  ...rest
}) => {
  return (
    <DefaultCard
      card={card}
      className={classNames(className)}
      wrapperClassName={classNames(wrapperClassName, styles.SessionCardWrapper)}
      disabled={disabled}
      {...rest}
    >
      <SessionCardInner card={card} disabled={disabled} showCloseIcon={showCloseIcon} />
    </DefaultCard>
  )
}

export default SessionCard
