import React, { FC, useMemo } from 'react'

import { useAppSelector } from '@/core/store'

import DefaultCardInnerForDarkSlider from '@/components/DefaultCardInnerForDarkSlider'

import { CategoryModel } from '@/models/category.model'

interface CategoryCardInnerProps {
  card?: CategoryModel
  focused?: boolean
}

const CategoryCardInner: FC<CategoryCardInnerProps> = ({ card, focused }) => {
  const chosenCategoryId = useAppSelector((state) => state.tv.chosenCategory?.id)

  const active = useMemo(() => {
    return !!(
      (chosenCategoryId && chosenCategoryId === card?.id) ||
      (!chosenCategoryId && card && !card.id)
    )
  }, [chosenCategoryId])

  return (
    <DefaultCardInnerForDarkSlider title={card?.pluralTitle} active={active} focused={focused} />
  )
}

export default CategoryCardInner
