import React, { Children, cloneElement, CSSProperties, FC, ReactElement, useEffect } from 'react'

import {
  useFocusable,
  UseFocusableConfig,
  FocusContext,
} from '@noriginmedia/norigin-spatial-navigation'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { useMutationObserverForListsWithDynamicLength } from '@/core/hooks/useMutationObserverForListsWithDynamicLength'

export type ExtendedFocusConfig = UseFocusableConfig & {
  onFocus?: OnChildFocus
}

export interface DefaultFocusWrapperProps {
  className?: string
  children: ReactElement | ReactElement[]
  style?: CSSProperties
  height?: number
  focusProps?: ExtendedFocusConfig
  withoutChildProps?: boolean
  autoFocus?: boolean
}

const DefaultFocusWrapper: FC<DefaultFocusWrapperProps> = ({
  children,
  className,
  style,
  withoutChildProps,
  focusProps,
  autoFocus,
}) => {
  const {
    ref,
    focusKey,
    hasFocusedChild,
    focused,
    focusSelf,
    setFocus,
    getCurrentFocusKey,
    navigateByDirection,
  } = useFocusable(focusProps)

  useEffect(() => {
    if (!autoFocus) return
    focusSelf()
  }, [autoFocus])

  useMutationObserverForListsWithDynamicLength<HTMLDivElement>({
    hasFocusedChild,
    focused,
    focusSelf,
    observedRef: ref,
  })
  return (
    <FocusContext.Provider value={focusKey}>
      <div id={focusKey} ref={ref} style={style} className={className}>
        {withoutChildProps
          ? children
          : Children.map(children, (child) => {
              return cloneElement(child, {
                ...child.props,
                setFocus,
                getCurrentFocusKey,
                navigateByDirection,
              })
            })}
      </div>
    </FocusContext.Provider>
  )
}

export default DefaultFocusWrapper
