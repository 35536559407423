import { PaginateArgs } from '@/core/api/api'
import { ChannelRelations } from '@/models/channel.model'

export interface ChannelRelationsArgs {
  relations?: Partial<ChannelRelations>
}

export interface ChannelPaginateArgs {
  paginate?: Partial<PaginateArgs>
}

export interface GetChannelsWithAllAvailableArgs
  extends ChannelRelationsArgs,
    ChannelPaginateArgs {}

export interface GetChannelById extends ChannelRelationsArgs {
  channelId: string
}

export interface GetProgramsByChannelId extends Omit<GetChannelById, 'relations'> {}

export interface GetProgramsByChannelIdWithPagination
  extends GetProgramsByChannelId,
    ChannelPaginateArgs {}

export interface GetProgramsByChannelIdAndDate extends Omit<GetChannelById, 'relations'> {
  date?: string
}

export interface GetProgramsByChannelIdAndDateWithPagination
  extends GetProgramsByChannelIdAndDate,
    ChannelPaginateArgs {}

export enum GetDatesByChannelIdResponseItemStatus {
  PAST = 'PAST',
  CURRENT = 'CURRENT',
  FUTURE = 'FUTURE',
}

export interface GetDatesByChannelIdResponseItem {
  date: string
  status: GetDatesByChannelIdResponseItemStatus
}
