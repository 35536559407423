import React, { useEffect, useRef } from 'react'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { toast, alert, NetworkAlertsContainer } from '@/components/Notifications'

import WebOsService, {
  WebOsConnectionStatusInfoResponse,
  WebOsSubscription,
} from '@/core/services/webOS.service'
import TizenService from '@/core/services/tizen.service'

import { changeNetworkStatus } from '@/modules/network/network.slice'

const title = 'Интернет'
const message =
  'Проблема с подключением к интернету, сообщение пропадет после того как соединение будет востановлено'

function NetworkChangesSubscription() {
  const shouldShowConnectionRestoredToast = useRef(false)

  const dispatch = useAppDispatch()

  const status = useAppSelector((state) => state.network.online)
  const platform = useAppSelector((state) => state.app.platform)

  useEffect(() => {
    if (!status) {
      alert.error({ message, title, theme: 'dark' })
      return
    }
    if (!shouldShowConnectionRestoredToast.current) {
      shouldShowConnectionRestoredToast.current = true
      return
    }
    toast.success({ title, message: 'Соединение восстановлено!', theme: 'dark', delay: 2 })
  }, [status])

  useEffect(() => {
    if (!platform) return

    let webOsNetworkSubscription: WebOsSubscription
    if (platform === 'WEB_OS') {
      const handleOnSuccess = (info: WebOsConnectionStatusInfoResponse) => {
        dispatch(changeNetworkStatus(info.isInternetConnectionAvailable))

        if (typeof info.subscribed !== 'undefined') {
          if (!info.subscribed) {
            console.error('Failed to subscribe network state')
            return
          }

          if (info.subscribed) {
            console.info('Subscribed to network state')
            return
          }
        }
      }

      webOsNetworkSubscription = WebOsService.subscribeToNetworkInfo(handleOnSuccess)
    }

    if (platform === 'TIZEN') {
      const handleOnNetworkStateChange = (isOnline: boolean) => {
        dispatch(changeNetworkStatus(isOnline))
      }

      TizenService.subscribeToNetworkInfo(handleOnNetworkStateChange)
    }

    return () => {
      webOsNetworkSubscription && webOsNetworkSubscription.cancel()
    }
  }, [])

  return <NetworkAlertsContainer networkStatus={status} />
}

export default NetworkChangesSubscription
