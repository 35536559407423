import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultFocusWrapper, {
  DefaultFocusWrapperProps,
} from '@/components/Slider/DefaultFocusWrapper'
import CircleButton from '@/components/CircleButton'
import { isBackButton } from '@/core/keys/specific.keys.codes'
import { hasModalNextSibling } from '@/core/helpers'
import ToastsContainer from '../../Notifications/components/Toasts/ToastsContainer'

export interface ModalPortalProps {
  overlayClassName?: string
  renderContainerClassName?: string
  backgroundClassName?: string
  activeRenderContainerClassName?: string
  unActiveRenderContainerClassName?: string
  onClose?: () => void
  focusWrapperProps?: Omit<DefaultFocusWrapperProps, 'children' | 'withoutChildProps'>
  render?: (onClose?: () => void) => ReactNode
  hideCloseButton?: boolean
  closeByClickBackground?: boolean
  autoFocus?: boolean
}

const ModalPortal: FC<ModalPortalProps> = ({
  focusWrapperProps,
  hideCloseButton,
  render,
  onClose,
  overlayClassName,
  renderContainerClassName,
  activeRenderContainerClassName,
  unActiveRenderContainerClassName,
  backgroundClassName,
  closeByClickBackground = false,
  autoFocus = true,
}) => {
  const bgRef = useRef<HTMLDivElement | null>(null)
  const renderRef = useRef<HTMLDivElement | null>(null)
  const overlayRef = useRef<HTMLDivElement | null>(null)

  const handleTransitionEnd = useCallback(() => {
    const overlay = overlayRef.current
    if (!overlay) {
      onClose?.()
      return
    }
    overlay.style.opacity = '0'
    const id = setTimeout(() => {
      onClose?.()
      clearTimeout(id)
    }, 300)

    const render = renderRef.current

    if (!render) return

    render.removeEventListener('transitionend', handleTransitionEnd)
  }, [onClose])

  const handleOnClose = useCallback(() => {
    const overlay = overlayRef.current
    if (!overlay) {
      onClose?.()
      return
    }

    const render = renderRef.current
    if (render && unActiveRenderContainerClassName) {
      render.addEventListener('transitionend', handleTransitionEnd)
      render.classList.add(unActiveRenderContainerClassName)
      return
    }

    overlay.style.opacity = '0'
    const id = setTimeout(() => {
      onClose?.()
      clearTimeout(id)
    }, 600)
  }, [unActiveRenderContainerClassName])

  const handleBackgroundClick = useCallback(() => {
    if (!closeByClickBackground) return

    handleOnClose()
  }, [closeByClickBackground])

  useEffect(() => {
    const bg = bgRef.current
    if (!bg) return

    bg.classList?.add(styles.Background__Active)
    if (backgroundClassName) {
      bg.classList?.add(backgroundClassName)
    }

    const render = renderRef.current
    if (!render) return

    render.classList?.add?.(styles.Active, styles.Show)
    if (activeRenderContainerClassName) {
      render.classList?.add?.(activeRenderContainerClassName)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return
      if (hasModalNextSibling(overlayRef.current)) return

      e.preventDefault()
      e.stopPropagation()
      handleOnClose()
    }

    document.addEventListener('keydown', handleKeyDown, { capture: true })

    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true })
    }
  }, [])

  return (
    <DefaultFocusWrapper
      {...focusWrapperProps}
      withoutChildProps
      autoFocus={autoFocus}
      focusProps={{
        ...focusWrapperProps?.focusProps,
        isFocusBoundary: true,
        trackChildren: true,
        saveLastFocusedChild: true,
      }}
    >
      <div ref={overlayRef} className={classNames(styles.Overlay, overlayClassName)}>
        <ToastsContainer id={'IN_MODAL'} />
        <div ref={bgRef} className={styles.Background} onClick={handleBackgroundClick} />
        <div
          ref={renderRef}
          className={classNames(styles.RenderContainer, renderContainerClassName)}
        >
          {!hideCloseButton && (
            <CircleButton
              onButtonClick={handleOnClose}
              onEnterPress={handleOnClose}
              buttonClassName={styles.CloseButton}
            >
              <i className='icon-fill-arrow-left' />
            </CircleButton>
          )}
          {render && render(handleOnClose)}
        </div>
      </div>
    </DefaultFocusWrapper>
  )
}

export default ModalPortal
