import React, { FC } from 'react'

import {
  UnionContentType,
  useGetContentFromPromotesByContentTypeQuery,
} from '@/modules/promotes/promotes.api'
import RenderPromosSlider from '@/modules/promotes/components/RenderPromosSlider'

interface Props {
  contentType?: UnionContentType
  shouldAutoFocusPromoCard?: boolean
  onAutoFocus?: () => void
}

const FetchAndRenderPromotesContent: FC<Props> = ({
  contentType = 'contents',
  shouldAutoFocusPromoCard,
  onAutoFocus,
}) => {
  const { data, isLoading } = useGetContentFromPromotesByContentTypeQuery({ type: contentType })

  return (
    <RenderPromosSlider
      promos={data}
      loading={isLoading}
      shouldAutoFocusPromoCard={shouldAutoFocusPromoCard}
      onAutoFocus={onAutoFocus}
    />
  )
}

export default FetchAndRenderPromotesContent
