import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { ContentWithPromoteModel } from '@/models/content.model'
import { PromotesBaseModel } from '@/models/promotes.model'

export type UnionContentType = 'contents' | 'movies' | 'serials'

export const promotesApi = createApi({
  reducerPath: 'promotesApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: [],
  endpoints: (build) => ({
    getAllPromotes: build.query<PromotesBaseModel[], void>({
      query: () => ({
        url: 'promotes',
        method: 'GET',
      }),
    }),

    getContentFromPromotesByContentType: build.query<
      ContentWithPromoteModel[],
      { type: UnionContentType }
    >({
      query: ({ type }) => {
        const params = {
          relations:
            'posters.verticalImage,posters.horizontalImage,countries,age,partner,backgrounds.image',
        }
        return {
          url: `clients/me/promotes/${type}`,
          method: 'GET',
          params,
        }
      },
    }),
  }),
})

export const { useGetAllPromotesQuery, useGetContentFromPromotesByContentTypeQuery } = promotesApi
