import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'

import api, { IApiErrorResponse } from '@/core/api/api'

import { CreateProfileArgs } from '@/modules/profile/profile.service'
import { ClientProfileModel } from '@/models/profile.model'

export const getProfiles = createAsyncThunk<
  ClientProfileModel[],
  { profileId?: string | null },
  { rejectValue: IApiErrorResponse }
>('profile/getProfiles', async (arg, thunkAPI) => {
  try {
    const { data } = await api.services.profile.getAllMeProfiles()
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const createProfile = createAsyncThunk<
  ClientProfileModel,
  Omit<CreateProfileArgs, 'clientId'>,
  { rejectValue: IApiErrorResponse }
>('profile/createProfile', async (arg, thunkAPI) => {
  try {
    const { data } = await api.services.profile.createNewMeProfile(arg)
    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const setProfile = createAction('profile/setProfile', withPayloadType<ClientProfileModel>())
export const resetProfile = createAction('profile/resetProfile')
