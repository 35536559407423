import React, { memo, useMemo } from 'react'

import styles from './styles.module.scss'

import ChosenContentDescription from '@/modules/content/components/ChosenContentDescription'

import { ContentModel } from '@/models/content.model'
import ImageLoader from '@/components/Image'
import { getContentPromotePosterUrl, PosterExtension } from '@/modules/content/content.helpers'
import SkeletonView from '@/components/SkeletonView'

interface PromoCardInnerProps<T> {
  card?: T
}

const PromoCardInner = <T extends ContentModel>({ card }: PromoCardInnerProps<T>) => {
  const url = useMemo(() => {
    return getContentPromotePosterUrl({
      content: card,
      extension: PosterExtension.WebP,
      query: {
        w: 750,
        q: 70,
      },
    })
  }, [card])
  return (
    <div className={styles.PromoCardInner}>
      {!card && <SkeletonView />}
      <ChosenContentDescription
        card={card}
        className={styles.DescriptionContainer}
        descriptionClassName={styles.Description}
      />
      <div className={styles.ImageBackground}>
        <ImageLoader src={url} alt={''} fallbackSrc={url} />
      </div>
    </div>
  )
}

export default memo(PromoCardInner)
