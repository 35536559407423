import { v4 } from 'uuid'

import {
  LanguageCodes,
  LanguageLabels,
  QualityLabels,
  QualityValue,
  Sources,
  StreamsModel,
} from '@/models/streams.model'

export interface PreparedLanguage {
  id: string
  value: LanguageCodes
  label: LanguageLabels
}

export interface PreparedQuality {
  id: string
  value: QualityValue
  label: QualityLabels
  sources: Sources
}

export interface IUrl {
  id: string
  language: PreparedLanguage
  qualities: PreparedQuality[]
}

class PlayerUrlManager {
  static #instance: PlayerUrlManager | null = null

  #urls: IUrl[] = []

  constructor(rawUrls?: StreamsModel[]) {
    if (rawUrls) this.#urls = this.#prepareUrls(rawUrls)
  }

  static getInstance(urls?: StreamsModel[]) {
    if (!this.#instance) this.#instance = new PlayerUrlManager(urls)
    return this.#instance
  }

  setUrls(rawUrls: StreamsModel[]) {
    this.#urls = this.#prepareUrls(rawUrls)
  }

  getUrls() {
    return this.#urls
  }

  #getLanguageCode(language: LanguageLabels): LanguageCodes | LanguageLabels {
    switch (language) {
      case 'Русский':
        return LanguageCodes.RU
      case 'English':
        return LanguageCodes.EN
      default:
        return language
    }
  }

  #prepareUrls(urls: StreamsModel[]): IUrl[] {
    const newUrls: IUrl[] = []
    urls.forEach((url: StreamsModel) => {
      const foundedLang = newUrls.find((el) => el.language.value === url.language.value)
      if (!foundedLang) {
        newUrls.push({
          id: `${v4()}`,
          qualities: [
            {
              id: `${v4()}`,
              sources: url.sources,
              ...url.quality,
            },
          ],
          language: {
            id: `${v4()}`,
            ...url.language,
          },
        })
      } else {
        foundedLang.qualities.push({
          id: `${v4()}`,
          sources: url.sources,
          ...url.quality,
        })
      }
    })

    return newUrls
  }

  getLanguages() {
    return this.#urls.map(({ language }) => language).filter((language) => !!language.label)
  }

  getUrlByIndex(index: number) {
    if (index < 0 && index > this.#urls.length - 1) return null

    return this.#urls[index]
  }

  getFirstUrl() {
    return this.getUrlByIndex(0)
  }

  getResolutionsByLanguageId(id: string) {
    const url = this.#urls.find((url) => url.language.id === id)
    if (!url) return []

    return url.qualities
  }

  getUrlByPreferredLanguageOrFirst(lang?: string) {
    // const preferredLanguage = getFromLocalStorage(PREFERRED_LANGUAGE)
    const preferredLanguage = lang || 'LostFilm' || '' || 'RU'
    // LostFilm
    // if (!preferredLanguage) {
    //   return this.getFirstUrl()
    // }
    const languages = this.getLanguages()
    const language = languages.find((lang) => lang.value === preferredLanguage)

    if (!language) {
      return this.getFirstUrl()
    }
    return this.#urls.find((url) => url.language.id === language.id) || null
  }
}

export default PlayerUrlManager
