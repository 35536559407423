import { ApiHelper } from '@/core/api/api'
import { ClientProfileModel, ProfileType } from '@/models/profile.model'

const ProfileService = (api: ApiHelper) => ({
  getAllProfiles: async ({ clientId }: GetProfilesArgs) => {
    return api.get<ClientProfileModel[]>(`/clients/${clientId}/profiles`)
  },

  createNewProfile: async ({ clientId, data }: CreateProfileArgs) => {
    return api.post<ClientProfileModel>(`/clients/${clientId}/profiles`, { data })
  },
  getAllMeProfiles: async () => {
    return api.get<ClientProfileModel[]>(`/clients/me/profiles`)
  },

  createNewMeProfile: async ({ data }: Omit<CreateProfileArgs, 'clientId'>) => {
    return api.post<ClientProfileModel>(`/clients/me/profiles`, { data })
  },
})

export interface GetProfilesArgs {
  clientId: string
}

interface CreateProfileDTO {
  name: string
  type: ProfileType
  password: string
  birthday: string
}

export interface CreateProfileArgs extends GetProfilesArgs {
  data: CreateProfileDTO
}

export default ProfileService
