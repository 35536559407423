import { useGetAllNotificationsQuery } from '@/modules/notifications/notifications.api'
import { useMemo } from 'react'
import { shouldShowNotification } from '@/modules/notifications/notification.helper'

export const useGetNotifications = () => {
  const { data, isFetching, isLoading, refetch } = useGetAllNotificationsQuery()

  const filteredData = useMemo(() => {
    if (!data) return
    return data.filter((notification) => shouldShowNotification(notification))
  }, [data])

  const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])

  const haveNotReadedNotifications = useMemo(() => {
    if (!filteredData) return false

    let haveNotReaded: boolean = false

    for (let i = 0; i < filteredData.length; i++) {
      if (!filteredData[i].clientNotification.readedAt) {
        haveNotReaded = true
        break
      }
    }

    return haveNotReaded
  }, [filteredData])

  return useMemo(() => {
    return {
      notifications: filteredData,
      loading,
      haveNotReadedNotifications,
      refetch,
    }
  }, [data, loading, haveNotReadedNotifications])
}
