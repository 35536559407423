import React, { FC } from 'react'

import classNames from 'classnames'
import styles from '@/modules/genre/components/GenreCard/styles.module.scss'

import Image from '@/components/Image'
import SkeletonView from '@/components/SkeletonView'

import poster from '@/assets/images/genres/genre-fallback.svg'

import { GenreModel } from '@/models/genre.model'

interface GenreCardInnerProps {
  card?: GenreModel
  focused?: boolean
}

type ExcludeTitleCardsAliases = 'start' | 'amediateka' | 'premier'
const excludeTitleCardsAliases: ExcludeTitleCardsAliases[] = ['start', 'premier', 'amediateka']

const GenreCardInner: FC<GenreCardInnerProps> = ({ card }) => {
  const hiddenTitleCondition = excludeTitleCardsAliases.includes(
    card?.alias as ExcludeTitleCardsAliases,
  )
  return (
    <div className={styles.GenreCardInner}>
      <div className={styles.Image}>
        <Image
          src={`${card?.logo?.sourceBaseUrl}${card?.logo?.sourcePath}`}
          fallbackSrc={poster}
          skeleton
          roundedSkeleton
          alt={card?.pluralTitle || 'лого жанра'}
        />
      </div>
      <div
        className={classNames(styles.CardTitle, hiddenTitleCondition && styles.CardTitle__Hidden)}
      >
        {!hiddenTitleCondition && <h4>{card?.pluralTitle || <SkeletonView />}</h4>}
      </div>
    </div>
  )
}

export default GenreCardInner
