import React, { memo } from 'react'

import styles from './styles.module.scss'

import HomeHeader from '@/modules/home/components/HomeHeader'
import HomeResourceList from '@/modules/home/components/HomeResourceList'

import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

const HomeView = () => {
  const { ref, focusKey } = useFocusable()
  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} key={'home-view'} className={styles.Home}>
        <HomeHeader />

        <div id={'footer-container'} className={styles.Home__Footer}>
          <HomeResourceList />
        </div>
      </div>
    </FocusContext.Provider>
  )
}

export default memo(HomeView)
