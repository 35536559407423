import React, { RefObject, useEffect, useRef } from 'react'

interface Props<T extends Element> {
  rootRef: RefObject<T>
  onVisibilityChange?: (isVisible: boolean) => void
}

const IntersectedElement = <T extends Element = HTMLElement>({
  rootRef,
  onVisibilityChange,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const root = rootRef.current
    const intersectedElement = ref.current

    if (!root || !intersectedElement) return

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.length === 0) return

        const entry = entries[0]

        onVisibilityChange && onVisibilityChange(entry.intersectionRatio > 0)
      },
      { root: root, threshold: [0.1] },
    )
    // начать наблюдение
    intersectionObserver.observe(intersectedElement)

    return () => {
      intersectionObserver.unobserve(intersectedElement)
      intersectionObserver.disconnect()
    }
  }, [onVisibilityChange])

  return <div ref={ref} style={{ height: '1px' }} />
}

export default IntersectedElement
