import { GetDatesByChannelIdResponseItem } from '@/modules/tv/tv.types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TvProgramSlice {
  currentDate: GetDatesByChannelIdResponseItem | null
  chosenDate: GetDatesByChannelIdResponseItem | null
}

const initialState: TvProgramSlice = {
  chosenDate: null,
  currentDate: null,
}

export const tvProgramSlice = createSlice({
  name: 'tvProgramSlice',
  initialState,
  reducers: {
    setCurrentDate: (state, action: PayloadAction<GetDatesByChannelIdResponseItem>) => {
      state.currentDate = action.payload
    },
    resetCurrentDate: (state) => {
      state.currentDate = null
    },
    setChosenDate: (state, action: PayloadAction<GetDatesByChannelIdResponseItem>) => {
      state.chosenDate = action.payload
    },
    resetChosenDate: (state) => {
      state.chosenDate = null
    },
  },
})

export const { setChosenDate, setCurrentDate, resetCurrentDate, resetChosenDate } =
  tvProgramSlice.actions
