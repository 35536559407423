import { useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import { useAppSelector } from '@/core/store'

import { GenreModel } from '@/models/genre.model'
import { PartnerBaseModel } from '@/models/partner.model'
import { ContentPaths } from '@/core/router/router.paths'
import { TagModel } from '@/models/tag.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

export const useShouldDisableResetButton = ({
  chosenPartners,
  chosenTags,
  chosenGenres,
  pageName,
}: {
  chosenGenres?: GenreModel[]
  chosenPartners?: PartnerBaseModel[]
  chosenTags?: TagModel[]
  pageName: PagesWithSearch
}): boolean => {
  const { pathname } = useLocation()

  const searchTerm = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.searchTerm,
  )
  const sort = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.sort)
  const type = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.filters.type)
  const partners = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.partners,
  )
  const genres = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.genres,
  )
  const years = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.years,
  )
  const tags = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.filters.tags)
  const countries = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.countries,
  )

  return useMemo(() => {
    return (
      (!searchTerm || !searchTerm.trim()) &&
      !sort &&
      !type &&
      years.length === 0 &&
      countries.length === 0 &&
      ((chosenGenres &&
        pathname.includes(ContentPaths.GENRES) &&
        genres.length === chosenGenres.length) ||
        (!chosenGenres && genres.length === 0)) &&
      ((chosenTags && pathname.includes(ContentPaths.TAGS) && tags.length === chosenTags.length) ||
        (!chosenTags && tags.length === 0)) &&
      ((chosenPartners &&
        pathname.includes(ContentPaths.PARTNERS) &&
        partners.length === chosenPartners.length) ||
        (!chosenPartners && partners.length === 0))
    )
  }, [
    type,
    partners,
    genres,
    years,
    tags,
    countries,
    chosenGenres,
    chosenPartners,
    chosenTags,
    searchTerm,
    sort,
  ])
}
