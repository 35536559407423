export const bgAnimation = {
  opened: {
    opacity: 0.8,
    transition: {
      duration: 0.5,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
}
