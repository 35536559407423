import { ChannelModel } from '@/models/channel.model'

export const getChannelLogoUrl = (channel?: ChannelModel | null) => {
  if (!channel) return
  if (!channel.logo) return
  const logo = channel.logo

  const sourceBaseUrl = logo.sourceBaseUrl
  const sourcePath = logo.sourcePath

  return `${sourceBaseUrl}${sourcePath}`
}

export const getChannelTitle = (channel?: ChannelModel | null) => {
  if (!channel) return
  if (!channel.title) return

  if (channel.isFromInternet) {
    return `* ${channel.title}`
  }

  return channel.title
}
