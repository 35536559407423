import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { GenreModel } from '@/models/genre.model'

import GenreCardInner from '@/modules/genre/components/GenreCard/GenreCardInner'
import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'

interface GenreCardProps extends Omit<DefaultCardProps<GenreModel>, 'children'> {}

const GenreCard: FC<GenreCardProps> = ({ card, className, wrapperClassName, ...props }) => {
  return (
    <DefaultCard<GenreModel>
      card={card}
      className={classNames(className, styles.GenreCardFocusWrapper)}
      wrapperClassName={classNames(wrapperClassName, styles.GenreCardWrapper)}
      {...props}
    >
      <GenreCardInner card={card} />
    </DefaultCard>
  )
}

export default GenreCard
