import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Modal from '@/components/Modal'
import Button from '@/components/Button'
import ProgramListModal from '@/modules/tv/components/ProgramListModal'

import { useAppSelector } from '@/core/store'

interface ProgramListActionProps {
  appendToId?: string
  triggerClassName?: string
  showIcon?: boolean
  disabled?: boolean
}

const ProgramListAction: FC<ProgramListActionProps> = ({
  triggerClassName,
  appendToId,
  showIcon,
  disabled,
}) => {
  const liveProgram = useAppSelector((state) => state.tv.liveProgram)
  return (
    <Modal
      appendToId={appendToId}
      hideCloseButton
      autoFocus={false}
      trigger={(onTrigger) => (
        <Button
          disabled={!liveProgram || disabled}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
          buttonClassName={classNames(triggerClassName, styles.Button)}
        >
          <>
            {showIcon && <i className={'icon-fill-catalog'} />}
            <span data-title={'title'}>Программа передач</span>
          </>
        </Button>
      )}
      render={(onClose) => {
        return <ProgramListModal onClose={onClose} />
      }}
    />
  )
}

export default ProgramListAction
