import React from 'react'

import Filters from '@/modules/search/components/Filters'

interface SearchFiltersProps {
  className?: string
}

const SearchFilters = (props: SearchFiltersProps) => {
  return <Filters pageName={'search'} {...props} />
}

export default SearchFilters
