import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  addYearToYearsFilter,
  removeYearFromYearsFilter,
  resetYearsFilter,
  setYearsFilter,
} from '@/modules/search/slice/actions/searchState/searchState.years.actions'

export const yearsExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ YEARS
  builder
    .addCase(addYearToYearsFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.years.push(action.payload.payload)
    })
    .addCase(removeYearFromYearsFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.years = state[
          action.payload.pageName
        ].searchState.filters.years.filter((el) => {
          return +el !== +action.payload.payload!
        })
    })
    .addCase(setYearsFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.years = action.payload.payload
    })
    .addCase(resetYearsFilter, (state, action) => {
      state[action.payload.pageName].searchState.filters.years = []
    })

  //-----------------------------------------------------------
}
