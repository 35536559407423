import { CabinetPaths } from '@/core/router/router.paths'

export interface CabinetMenuItem {
  title: string
  navigatePath: CabinetPaths
}

export const cabinetMenuItems: CabinetMenuItem[] = [
  {
    title: 'Профиль',
    navigatePath: CabinetPaths.PROFILE,
  },
  {
    title: 'Мои устройства',
    navigatePath: CabinetPaths.DEVICES,
  },
  {
    title: 'Подписки',
    navigatePath: CabinetPaths.SUBSCRIPTIONS,
  },
  {
    title: 'Оплата',
    navigatePath: CabinetPaths.PAYMENT,
  },
  // {
  //   title: 'Статистика',
  //   navigatePath: CabinetPaths.PROFILE,
  // },
  {
    title: 'Рассылка',
    navigatePath: CabinetPaths.NOTIFICATIONS,
  },
  {
    title: 'Техподдержка',
    navigatePath: CabinetPaths.SUPPORT,
  },
  // {
  //   title: 'Мастер обучения',
  //   navigatePath: CabinetPaths.PROFILE,
  // },
]
