import React, { lazy } from 'react'

import { RouteObject } from 'react-router-dom'
import {
  CabinetPaths,
  Paths,
  PaymentsPaths,
  SubscriptionsPaths,
  SupportPaths,
} from '@/core/router/router.paths'
import CabinetLayout from '@/layouts/cabinet'
import SuspenseWithLoaderFallback from '@/components/SuspenseWithLoaderFallback'
import ErrorBoundary from '@/modules/errorBoundary'
// const CabinetLayout = lazy(
//   () => import(/* webpackChunkName: "CabinetLayout" */ '@/layouts/cabinet'),
// )
// import Profile from '@/modules/cabinet/views/profile'
const Profile = lazy(
  () => import(/* webpackChunkName: "Profile" */ '@/modules/cabinet/views/profile'),
)

const Devices = lazy(
  () => import(/* webpackChunkName: "Profile" */ '@/modules/cabinet/views/devices'),
)
// import Subscriptions from '@/modules/cabinet/views/subscriptions'
const Subscriptions = lazy(
  () => import(/* webpackChunkName: "Subscriptions" */ '@/modules/cabinet/views/subscriptions'),
)
// import SubscriptionsAll from '@/modules/cabinet/views/subscriptionsAll'
const SubscriptionsAll = lazy(
  () =>
    import(/* webpackChunkName: "SubscriptionsAll" */ '@/modules/cabinet/views/subscriptionsAll'),
)
// import Subscription from '@/modules/cabinet/views/subscription'
const Subscription = lazy(
  () => import(/* webpackChunkName: "Subscription" */ '@/modules/cabinet/views/subscription'),
)
// import SubscriptionErip from '@/modules/cabinet/views/subscriptionErip'
const SubscriptionErip = lazy(
  () =>
    import(/* webpackChunkName: "SubscriptionErip" */ '@/modules/cabinet/views/subscriptionErip'),
)
// import Payments from '@/modules/cabinet/views/payments'
const Payments = lazy(
  () => import(/* webpackChunkName: "Payments" */ '@/modules/cabinet/views/payments'),
)
// import PaymentsLayout from '@/layouts/payments'
const PaymentsLayout = lazy(
  () => import(/* webpackChunkName: "PaymentsLayout" */ '@/layouts/payments'),
)
// import PaymentsCards from '@/modules/cabinet/views/paymentsCards'
const PaymentsCards = lazy(
  () => import(/* webpackChunkName: "PaymentsCards" */ '@/modules/cabinet/views/paymentsCards'),
)
// import PaymentsCardsAdd from '@/modules/cabinet/views/paymentsCardsAdd'
const PaymentsCardsAdd = lazy(
  () =>
    import(/* webpackChunkName: "PaymentsCardsAdd" */ '@/modules/cabinet/views/paymentsCardsAdd'),
)
// import Notifications from '@/modules/cabinet/views/notifications'
const Notifications = lazy(
  () => import(/* webpackChunkName: "Notifications" */ '@/modules/cabinet/views/notifications'),
)
// import SupportLayout from '@/layouts/support'
const SupportLayout = lazy(
  () => import(/* webpackChunkName: "SupportLayout" */ '@/layouts/support'),
)
// import Support from '@/modules/cabinet/views/support'
const Support = lazy(
  () => import(/* webpackChunkName: "Support" */ '@/modules/cabinet/views/support'),
)
// import SupportTechInfo from '@/modules/cabinet/views/supportTechInfo'
const SupportTechInfo = lazy(
  () => import(/* webpackChunkName: "SupportTechInfo" */ '@/modules/cabinet/views/supportTechInfo'),
)

export const cabinetRoute: RouteObject = {
  path: Paths.CABINET,
  element: <CabinetLayout />,
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: (
        <SuspenseWithLoaderFallback>
          <Profile />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: CabinetPaths.PROFILE,
      element: (
        <SuspenseWithLoaderFallback>
          <Profile />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: CabinetPaths.DEVICES,
      element: (
        <SuspenseWithLoaderFallback>
          <Devices />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: CabinetPaths.SUBSCRIPTIONS,
      children: [
        {
          index: true,
          element: (
            <SuspenseWithLoaderFallback>
              <Subscriptions />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: SubscriptionsPaths.CLIENT,
          element: (
            <SuspenseWithLoaderFallback>
              <Subscriptions />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: SubscriptionsPaths.All,
          element: (
            <SuspenseWithLoaderFallback>
              <SubscriptionsAll />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: SubscriptionsPaths.CURRENT,
          element: (
            <SuspenseWithLoaderFallback>
              <Subscription />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: SubscriptionsPaths.ERIP,
          element: (
            <SuspenseWithLoaderFallback>
              <SubscriptionErip />
            </SuspenseWithLoaderFallback>
          ),
        },
      ],
    },
    {
      path: CabinetPaths.PAYMENT,
      element: (
        <SuspenseWithLoaderFallback>
          <PaymentsLayout />
        </SuspenseWithLoaderFallback>
      ),
      children: [
        {
          index: true,
          element: (
            <SuspenseWithLoaderFallback>
              <Payments />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: PaymentsPaths.HISTORY,
          element: (
            <SuspenseWithLoaderFallback>
              <Payments />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: PaymentsPaths.CARDS,
          element: (
            <SuspenseWithLoaderFallback>
              <PaymentsCards />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: PaymentsPaths.ADD_CARD,
          element: (
            <SuspenseWithLoaderFallback>
              <PaymentsCardsAdd />
            </SuspenseWithLoaderFallback>
          ),
        },
      ],
    },
    {
      path: CabinetPaths.NOTIFICATIONS,
      element: (
        <SuspenseWithLoaderFallback>
          <Notifications />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: CabinetPaths.SUPPORT,
      element: (
        <SuspenseWithLoaderFallback>
          <SupportLayout />
        </SuspenseWithLoaderFallback>
      ),
      children: [
        {
          index: true,
          element: (
            <SuspenseWithLoaderFallback>
              <Support />
            </SuspenseWithLoaderFallback>
          ),
        },
        {
          path: SupportPaths.TECH_INFO,
          element: (
            <SuspenseWithLoaderFallback>
              <SupportTechInfo />
            </SuspenseWithLoaderFallback>
          ),
        },
      ],
    },
  ],
}
