interface FullscreenVariants {
  fullScreenElement?: HTMLElement
  webkitFullscreenElement?: HTMLElement
  webkitFullScreenElement?: HTMLElement
  mozFullScreenElement?: HTMLElement
  msFullscreenElement?: HTMLElement
  mozRequestFullScreen?: () => Promise<void>
  msRequestFullscreen?: () => Promise<void>
  webkitRequestFullscreen?: () => Promise<void>
  webkitRequestFullScreen?: () => Promise<void>
  webkitEnterFullscreen?: () => Promise<void>
  mozCancelFullScreen?: () => Promise<void>
  webkitExitFullscreen?: () => Promise<void>
  webkitExitFullScreen?: () => Promise<void>
  msExitFullscreen?: () => Promise<void>
}

export function getFullscreenElement() {
  // Ridiculous camel casing confusion ruling the Interwebs.
  const doc = document as Document & FullscreenVariants
  return (
    doc.fullScreenElement ||
    doc.fullscreenElement ||
    doc.webkitFullscreenElement ||
    doc.webkitFullScreenElement ||
    doc.mozFullScreenElement ||
    doc.msFullscreenElement
  )
}

export const checkNativeFullscreen = (): boolean => {
  return !!getFullscreenElement()
}

export const checkCustomFullscreen = (): boolean => {
  const root = document.getElementById('root')
  if (!root) return false

  return root.dataset.fullscreen === 'true'
}

export async function requestEnterFullscreen(element: HTMLElement) {
  const typedElement = element as HTMLElement & FullscreenVariants
  if (element.requestFullscreen) {
    await element.requestFullscreen()
    return true
  } else if (typedElement.mozRequestFullScreen) {
    await typedElement.mozRequestFullScreen()
    return true
  } else if (typedElement.msRequestFullscreen) {
    await typedElement.msRequestFullscreen()
    return true
  } else if (typedElement.webkitRequestFullscreen) {
    await typedElement.webkitRequestFullscreen()
    return true
  } else if (typedElement.webkitRequestFullScreen) {
    await typedElement.webkitRequestFullScreen() // ALLOW_KEY_BOARD_INPUT makes this function not work in older Safari versions.
    return true
  } else {
    // Try native fullscreen for video element in Safari on iPhone and older iOS in general.
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
      element = element.getElementsByTagName('video')[0]
      if (element) {
        await typedElement.webkitEnterFullscreen?.()
        return true
      }
    }
  }
  return false
}

export async function requestExitFullscreen() {
  const doc = document as Document & FullscreenVariants
  if (doc.exitFullscreen) {
    await doc.exitFullscreen()
  } else if (doc.mozCancelFullScreen) {
    await doc.mozCancelFullScreen()
  } else if (doc.webkitExitFullscreen) {
    await doc.webkitExitFullscreen()
  } else if (doc.webkitExitFullScreen) {
    await doc.webkitExitFullScreen()
  } else if (doc.msExitFullscreen) {
    await doc.msExitFullscreen()
  }
}

type FullscreenChangeHandler = (e?: Event) => void

export function notifyFullscreenChange(handler: FullscreenChangeHandler) {
  const fullscreenEventNames = [
    'fullscreenchange',
    'mozfullscreenchange',
    'webkitfullscreenchange',
    'MSFullscreenChange',
  ]

  fullscreenEventNames.forEach(function (eventName) {
    document.addEventListener(eventName, handler)
  })

  return function () {
    fullscreenEventNames.forEach(function (eventName) {
      document.removeEventListener(eventName, handler)
    })
  }
}
