import React, { useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { motion } from 'framer-motion'

import { FocusContext } from '@noriginmedia/norigin-spatial-navigation'

import MenuButton from '@/modules/menu/components/MenuButton'
import ContentMenuLogo from '@/modules/menu/components/ContentMenuLogo'
import NotificationsMenuButton from '@/modules/menu/components/NotificationsMenuButton'

import { useContentMenu } from '@/modules/menu/hooks/useContentMenu'

import { menuItems } from '@/modules/menu/menu.constants'
import { bgAnimation } from '@/modules/menu/menuBg.animation.variants'

import { MenuVariants } from '@/modules/menu/menu.slice'

const ContentMenu = () => {
  const {
    focusKey,
    ref,
    handleAnimationComplete,
    handleAnimationStart,
    bgRef,
    setFocus,
    hasFocusedChild,
    items,
  } = useContentMenu(menuItems)

  const render = useMemo(() => {
    return items.map((el, index) => {
      const { focusWrapperClassName, menuVariant, to, focusKey, title, iconClassName } = el
      if (menuVariant === MenuVariants.NOTIFICATIONS) {
        return (
          <NotificationsMenuButton
            key={`menu_${index}_${title}`}
            focusKey={focusKey}
            menuVariant={menuVariant}
            title={title}
            to={to}
            setFocus={setFocus}
            iconClassName={iconClassName}
            focusWrapperClassName={classNames(
              focusWrapperClassName && styles[focusWrapperClassName],
            )}
          />
        )
      }

      return (
        <MenuButton
          key={`menu_${index}_${title}`}
          focusKey={focusKey}
          menuVariant={menuVariant}
          title={title}
          to={to}
          setFocus={setFocus}
          iconClassName={iconClassName}
          focusWrapperClassName={classNames(focusWrapperClassName && styles[focusWrapperClassName])}
        />
      )
    })
  }, [items])
  return (
    <FocusContext.Provider key={'menu-focus-context'} value={focusKey}>
      <div key={'menu-focus-wrapper-container'} ref={ref} className={styles.MenuWrapper}>
        <motion.div
          key={'menu_bg'}
          ref={bgRef}
          initial={'closed'}
          animate={hasFocusedChild ? 'opened' : 'closed'}
          variants={bgAnimation}
          onAnimationStart={handleAnimationStart}
          onAnimationComplete={handleAnimationComplete}
          className={styles.BG}
        />
        <div id={'menu_bgCover'} key={'menu_bgCover'} className={styles.BGCover} />
        <ContentMenuLogo key={'menu_logo'} />
        {render}
      </div>
    </FocusContext.Provider>
  )
}

export default ContentMenu
