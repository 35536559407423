import React, { FC } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

import warning from '@/assets/images/warning.png'

interface Props {
  error: unknown
  onBack?: () => void
}

const StreamsErrorFallback: FC<Props> = ({ error, onBack }) => {
  if (!error) return

  return (
    <div className={styles.Error}>
      <img src={warning} alt={'Стримы не загрузились'} />
      <h2>
        Видео временно недоступно. <br />
        На сайтах партнеров проводятся профилактические работы.
      </h2>

      <Button
        autoFocus
        buttonClassName={styles.BackAction}
        onEnterPress={onBack}
        onButtonClick={onBack}
      >
        Вернуться
      </Button>
    </div>
  )
}

export default StreamsErrorFallback
