import React, { FC } from 'react'

import ProgressBar from '@/modules/player/components/controls/ProgressBar'
import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import { motion } from 'framer-motion'

import { footerVariants } from '@/modules/player/components/player/Overlay/overlay.slots.transitions'

import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'
import { useFocusable } from '@noriginmedia/norigin-spatial-navigation'

interface OverlayFooterProps {
  className: string
  handleTogglePlayPause: () => Promise<void>
  handleChangeProgress: (value: string | number) => void
  animate: string
}

const OverlayFooter: FC<OverlayFooterProps> = ({
  handleTogglePlayPause,
  handleChangeProgress,
  className,
  animate,
}) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isLive = usePlayerStateSelector((state) => state.video.isLive)

  if (isLive) return null
  return (
    <motion.div initial={'open'} animate={animate} variants={footerVariants} className={className}>
      <RedirectFromFooter />
      <ProgressBar togglePlayPause={handleTogglePlayPause} onChange={handleChangeProgress} />
    </motion.div>
  )
}

const RedirectFromFooter = () => {
  const { ref, focusKey, navigateByDirection } = useFocusable({
    onFocus: (layout, props, details) => {
      const event = details.event as KeyboardEvent
      if (event?.code === 'ArrowUp') {
        navigateByDirection('up', {})
      }
      if (event?.code === 'ArrowDown') {
        navigateByDirection('down', {})
      }
      if (event?.code === 'ArrowLeft') {
        navigateByDirection('up', {})
      }
    },
  })

  return <div ref={ref} id={focusKey} style={{ height: '1px' }} />
}

export default OverlayFooter
