import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import TagCardInner from '@/modules/tags/components/TagCard/TagCardInner'

import { TagModel } from '@/models/tag.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface TagCardProps extends Omit<DefaultCardProps<TagModel>, 'children'> {
  pageName?: PagesWithSearch
}

const TagCard: FC<TagCardProps> = ({ card, pageName, className, wrapperClassName, ...rest }) => {
  return (
    <DefaultCard
      card={card}
      className={className}
      wrapperClassName={classNames(wrapperClassName, styles.CardWrapper)}
      focusClassName={styles.CardWrapperFocused}
      {...rest}
    >
      <TagCardInner card={card} pageName={pageName} />
    </DefaultCard>
  )
}

export default TagCard
