import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react'
import DefaultFocusButtonWrapper, {
  DefaultFocusButtonProps,
} from '@/components/DefaultFocusButtonWrapper'
import DefaultButton, { DefaultButtonProps } from '@/components/DefaultButton'

import classNames from 'classnames'
import styles from './styles.module.scss'

type OmittedDefaultFocusButtonProps = Omit<DefaultFocusButtonProps, 'className' | 'children'>
type OmittedDefaultButtonProps = Omit<DefaultButtonProps, 'className' | 'focused'>

type DefaultProps = OmittedDefaultButtonProps & OmittedDefaultFocusButtonProps

export interface CircleButton extends DefaultProps {
  buttonClassName?: string
  focusWrapperClassName?: string
  disabled?: boolean
}

const CircleButton: FC<CircleButton> = ({
  buttonClassName,
  focusClassName,
  focusWrapperClassName,
  children,
  autoFocus: focus,
  disabled,
  onButtonClick,
  ...restFocusProps
}) => {
  const [autoFocus, setAutoFocus] = useState(focus || false)

  useEffect(() => {
    if (!autoFocus) return

    const id = setTimeout(() => {
      setAutoFocus(false)
      clearTimeout(id)
    }, 100)
  }, [autoFocus])

  const handleOnClick = useCallback(
    (e?: MouseEvent<HTMLButtonElement>) => {
      setAutoFocus(true)
      onButtonClick?.(e)
    },
    [onButtonClick],
  )
  return (
    <DefaultFocusButtonWrapper
      className={focusWrapperClassName}
      autoFocus={autoFocus}
      focusable={!disabled}
      {...restFocusProps}
    >
      <DefaultButton
        disabled={disabled}
        onButtonClick={handleOnClick}
        className={classNames(buttonClassName, styles.Button, disabled && styles.Disabled)}
        focusClassName={classNames(styles.Focused, focusClassName)}
      >
        {children}
      </DefaultButton>
    </DefaultFocusButtonWrapper>
  )
}

export default CircleButton
