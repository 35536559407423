import React, { useCallback, useEffect, useRef } from 'react'
import { setThrottle } from '@noriginmedia/norigin-spatial-navigation'

import styles from '@/modules/tv/components/ChannelsListSidebarAction/styles.module.scss'

import ListWithScrollbar from '@/components/ListWithScrollbar'
import ChannelListItem from '@/modules/tv/components/ChannelsListSidebarAction/ChannelListItem'

import { useCustomRef } from '@/core/hooks/useCustomRef'
import { useAppDispatch, useAppSelector } from '@/core/store'

import { dispatchRequestPlayerEnterToFullscreenEvent } from '@/core/customEvents/player.custom.events'
import { resetCurrentProgram, setChosenChannel, setFocusedChannel } from '@/modules/tv/tv.actions'

import { InitListActions } from '@/components/List'
import { ChannelModel } from '@/models/channel.model'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'

interface ChannelsListWithScrollbarProps {
  channels?: ChannelModel[]
  shouldCloseOnChannelPress?: boolean
  onClose?: () => void
}

const ChannelsListWithScrollbar = ({
  channels,
  onClose,
  shouldCloseOnChannelPress = true,
}: ChannelsListWithScrollbarProps) => {
  const dispatch = useAppDispatch()

  const shouldCloseOnChannelPressRef = useRef<boolean>(!!shouldCloseOnChannelPress)
  shouldCloseOnChannelPressRef.current = !!shouldCloseOnChannelPress

  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null)

  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)
  const chosenChannelRef = useRef(chosenChannel)
  chosenChannelRef.current = chosenChannel

  const handleChannelCardFocus: OnChildFocus<ChannelModel> = useCallback((...args) => {
    const channel = args[4]
    if (!channel) return
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    const id = setTimeout(() => {
      dispatch(setFocusedChannel(channel))
      clearTimeout(id)
      timeoutId.current = null
    }, 1000)

    timeoutId.current = id
  }, [])

  const handleCardPress = useCallback((onClose?: () => void) => {
    return (channel: ChannelModel) => {
      if (!chosenChannelRef.current) return
      shouldCloseOnChannelPressRef.current && onClose && onClose()
      if (channel.id === chosenChannelRef.current.id) {
        dispatchRequestPlayerEnterToFullscreenEvent()
        onClose && onClose()
        return
      }
      dispatch(setChosenChannel(channel))
      dispatch(resetCurrentProgram())
    }
  }, [])

  const handleListBlur = useCallback(() => {
    if (!chosenChannelRef.current) return
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    if (!chosenChannel) return
    dispatch(setFocusedChannel(chosenChannelRef.current))
  }, [])

  const [actions, setActions] = useCustomRef<InitListActions>({})

  const activeChannelRef = useRef<string>()

  useEffect(() => {
    setThrottle({ throttle: 100, throttleKeypresses: true })
    return () => {
      setThrottle({ throttleKeypresses: true, throttle: 300 })
    }
  }, [])

  return (
    <ListWithScrollbar
      className={styles.ChannelsList}
      focusProps={{ onBlur: handleListBlur, trackChildren: true, saveLastFocusedChild: true }}
      onInit={(args) => {
        setActions(args)

        activeChannelRef.current &&
          args.setFocusWithoutScroll &&
          args.setFocusWithoutScroll(activeChannelRef.current)
      }}
      throttleScrollDelay={10}
    >
      {channels?.map((channel, index) => {
        if (!!chosenChannel && chosenChannel.id === channel.id) {
          activeChannelRef.current = channel.id

          if (index === 0) {
            activeChannelRef.current = 'first-channel'
          }

          if (index === channels?.length - 1) {
            activeChannelRef.current = 'last-channel'
          }
        }
        return (
          <ChannelListItem
            key={channel.id}
            index={index}
            card={channel}
            focusProps={{
              focusKey:
                index === 0
                  ? 'first-channel'
                  : index === channels.length - 1
                  ? 'last-channel'
                  : channel.id,
              onArrowPress: (direction) => {
                if (direction === 'up' && index === 0) {
                  if (!actions.current.setFocusWithoutScroll) return true
                  actions.current.setFocusWithoutScroll('last-channel')
                  return false
                }

                if (direction === 'down' && index === channels.length - 1) {
                  if (!actions.current.setFocusWithoutScroll) return true
                  actions.current.setFocusWithoutScroll('first-channel')
                  return false
                }

                return true
              },
            }}
            onCardFocus={handleChannelCardFocus}
            onCardPress={handleCardPress(onClose)}
          />
        )
      }) || []}
    </ListWithScrollbar>
  )
}

export default ChannelsListWithScrollbar
