import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useLocation, useNavigate } from 'react-router-dom'

import { CabinetPaths, Paths } from '@/core/router/router.paths'

interface CabinetMenuItemProps {
  title: string
  navigatePath: CabinetPaths
}

const CabinetMenuItem: FC<CabinetMenuItemProps> = ({ navigatePath, title }) => {
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const handleActCabinetMenuItem = () => {
    if (!navigatePath) return
    navigate(navigatePath)
  }
  return (
    <Button
      onButtonClick={handleActCabinetMenuItem}
      onEnterPress={handleActCabinetMenuItem}
      buttonClassName={classNames(styles.CabinetMenuItem, {
        [styles.Active]:
          pathname.includes(navigatePath) ||
          (navigatePath === 'profile' && pathname === Paths.CABINET),
      })}
      focusClassName={styles.Focused}
    >
      {title}
    </Button>
  )
}

export default CabinetMenuItem
