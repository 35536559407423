import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/core/store'
import { useEffect } from 'react'
import { Paths } from '@/core/router/router.paths'

export const useNavigateToRootAfterResetState = () => {
  const navigate = useNavigate()
  const haveSession = useAppSelector((state) => !!state.auth.auth.data.session)

  useEffect(() => {
    if (haveSession) return
    navigate(Paths.ROOT)
  }, [haveSession])
}
