import React, { FC, memo, useCallback } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'
import ContentSlider, { ContentSliderProps } from '@/modules/content/components/ContentSlider'

import { useNavigate } from 'react-router-dom'

import { ContentModel, ContentType } from '@/models/content.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'
import { FeedsResourceBaseModel, FeedsResourceTypes } from '@/models/feeds.resource.model'

interface Props extends ContentSliderProps<ContentModel> {
  resource: FeedsResourceBaseModel
  content: ContentModel[]
}

const ContentFromResource: FC<Props> = ({ resource, content, ...props }) => {
  const navigate = useNavigate()

  const handleOnAction = useCallback(() => {
    if (!resource) return

    if (resource.type === FeedsResourceTypes.GENRE_CONTENT) {
      const uri = resource.url.split('/')
      const genreIndex = uri.indexOf('genres')

      if (genreIndex === -1) return

      const genreId = uri[genreIndex + 1]
      if (!genreId) return

      navigate(`${Paths.CONTENT}/${ContentPaths.GENRES}/${genreId}`, {
        state: {
          movies: uri[uri.length - 1].includes('movies'),
          serials: uri[uri.length - 1].includes('serials'),
        },
      })

      return
    }

    if (resource.type === FeedsResourceTypes.TAG_CONTENT) {
      const uri = resource.url.split('/')
      const tagsIndex = uri.indexOf('tags')

      if (tagsIndex === -1) return

      const tagId = uri[tagsIndex + 1]
      if (!tagId) return

      navigate(`${Paths.CONTENT}/${ContentPaths.TAGS}/${tagId}`, {
        state: {
          movies: uri[uri.length - 1].includes('movies'),
          serials: uri[uri.length - 1].includes('serials'),
        },
      })

      return
    }

    if (resource.type === FeedsResourceTypes.PARTNER_CONTENT) {
      const uri = resource.url.split('/')
      const partnerIndex = uri.indexOf('partners')

      if (partnerIndex === -1) return

      const partnerId = uri[partnerIndex + 1]
      if (!partnerId) return

      navigate(`${Paths.CONTENT}/${ContentPaths.PARTNERS}/${partnerId}`, {
        state: {
          movies: uri[uri.length - 1].includes('movies'),
          serials: uri[uri.length - 1].includes('serials'),
        },
      })

      return
    }

    if (resource.type === FeedsResourceTypes.FAVORITE_CONTENT) {
      navigate(
        `${Paths.CONTENT}/${ContentPaths.FAVORITES}${
          resource.url.includes(ContentType.SERIAL)
            ? '/' + ContentType.SERIAL
            : resource.url.includes(ContentType.MOVIE)
            ? '/' + ContentType.MOVIE
            : ''
        }`,
      )
    }
  }, [resource, navigate])

  if (!content || !content.length) return null

  return (
    <ContentSlider
      className={styles.FocusWrapper}
      rowProps={{ title: resource.title }}
      data={content}
      // isLoading={loading}
      specificAction={({ className }) => {
        return resource.type === FeedsResourceTypes.GENRE_CONTENT ||
          resource.type === FeedsResourceTypes.PARTNER_CONTENT ||
          resource.type === FeedsResourceTypes.TAG_CONTENT ? (
          <Button
            buttonClassName={className}
            onButtonClick={handleOnAction}
            onEnterPress={handleOnAction}
          >
            Смотреть всё
          </Button>
        ) : resource.type === FeedsResourceTypes.FAVORITE_CONTENT ? (
          <Button
            buttonClassName={className}
            onButtonClick={handleOnAction}
            onEnterPress={handleOnAction}
          >
            В избранное
          </Button>
        ) : (
          <></>
        )
      }}
      {...props}
    />
  )
}

export default memo(ContentFromResource)
