import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { PartnerBaseModel } from '@/models/partner.model'

// ------------------------ PARTNERS
export const addPartnerToPartnersFilter = createAction(
  'searchSlice/addPartnerToPartnersFilter',
  withPayloadType<PageUpdatePayload<PartnerBaseModel>>(),
)
export const removePartnerFromPartnersFilter = createAction(
  'searchSlice/removePartnerFromPartnersFilter',
  withPayloadType<PageUpdatePayload<PartnerBaseModel>>(),
)

export const setPartnersFilter = createAction(
  'searchSlice/setPartnersFilter',
  withPayloadType<PageUpdatePayload<PartnerBaseModel[]>>(),
)

export const resetPartnersFilter = createAction(
  'searchSlice/resetPartnersFilter',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
