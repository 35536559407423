export interface AppInfo {
  id: string
  title: string
  icon: string
  version: string
}

export interface ProductInfo {
  uid?: string
  model?: string
  firmwareVersion?: string

  // Samsung
  isUdPanel?: boolean
  isNoGlass3d?: boolean
  psid?: string
  // ----

  // LG
  isOled?: boolean
  ddrSize?: string
  isUhd?: boolean
  isUhd8K?: boolean
  isDolbyVision?: boolean
  isDolbyAtmos?: boolean
  // ----

  isHdr: string
}

export interface SystemInfo {
  version?: string
}

export interface NetworkInfo {
  mac?: string
  ip?: string
  dns?: string

  wifi?: {
    ssid: string
    signalStrengthLevel?: number
  }
}

export type PlatformInfo = {
  app: Partial<AppInfo>
  product: Partial<ProductInfo>
  system: Partial<SystemInfo>
  network?: Partial<NetworkInfo> | null
}

export enum Platform {
  TIZEN = 'TIZEN',
  WEB_OS = 'WEB_OS',
  ANDROID_TV = 'ANDROID_TV',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
  LOCAL = 'LOCAL',
}

export interface PlatformModel {
  id: string
  createdAt: string
  updatedAt: string
  type: Platform
}
