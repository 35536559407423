import React, { FC, memo, useCallback } from 'react'
import { FeedsResourceBaseModel } from '@/models/feeds.resource.model'

import styles from './styles.module.scss'
import { ContentWithViewModel } from '@/models/content.model'
import ViewSlider, { ViewSliderProps } from '@/modules/syncMediaView/components/ViewSlider'
import Button from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface Props extends ViewSliderProps {
  resource: FeedsResourceBaseModel
  content: ContentWithViewModel[]
}

const ViewContentFromResource: FC<Props> = ({ resource, content, ...props }) => {
  const navigate = useNavigate()

  if (!content || !content.length) {
    return null
  }

  const handleOnAction = useCallback(() => {
    navigate(`${Paths.CONTENT}/${ContentPaths.VIEWS}`, {
      state: { movies: resource.url.includes('movies'), serials: resource.url.includes('serials') },
    })
  }, [])

  return (
    <ViewSlider
      className={styles.HeightWrapper}
      data={content}
      // isLoading={loading}
      rowProps={{ title: resource.title }}
      specificAction={({ className }) => (
        <Button
          buttonClassName={className}
          onButtonClick={handleOnAction}
          onEnterPress={handleOnAction}
        >
          Смотреть всё
        </Button>
      )}
      {...props}
    />
  )
}

export default memo(ViewContentFromResource)
