import React, { FC, MouseEvent, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultFocusButtonWrapper, {
  DefaultFocusButtonProps,
} from '@/components/DefaultFocusButtonWrapper'
import DefaultButton, { DefaultButtonProps } from '@/components/DefaultButton'

type OmittedDefaultFocusButtonProps = Omit<DefaultFocusButtonProps, 'className' | 'children'>
type OmittedDefaultButtonProps = Omit<DefaultButtonProps, 'className' | 'focused'>

type DefaultProps = OmittedDefaultButtonProps & OmittedDefaultFocusButtonProps

export interface Button extends DefaultProps {
  disabled?: boolean
  buttonClassName?: string
  focusWrapperClassName?: string
}

const Button: FC<Button> = ({
  disabled,
  children,
  focusWrapperClassName,
  buttonClassName,
  focusClassName,
  onButtonClick,
  type,
  autoFocus,
  shouldHandleClickOnlyInFocus,
  loading,
  ...restFocusProps
}) => {
  const handleOnClick = useCallback(
    (e?: MouseEvent<HTMLButtonElement>) => {
      onButtonClick?.(e)
    },
    [onButtonClick],
  )

  return (
    <DefaultFocusButtonWrapper
      className={focusWrapperClassName}
      focusable={!disabled}
      autoFocus={autoFocus}
      {...restFocusProps}
    >
      <DefaultButton
        type={type}
        onButtonClick={handleOnClick}
        disabled={disabled}
        className={classNames(styles.Button, disabled && styles.Disabled, buttonClassName)}
        focusClassName={classNames(styles.Focused, focusClassName)}
        shouldHandleClickOnlyInFocus={shouldHandleClickOnlyInFocus}
        loading={loading}
      >
        {children}
      </DefaultButton>
    </DefaultFocusButtonWrapper>
  )
}

export default Button
