import React, { FC, memo } from 'react'
import { useGetAllTagsQuery } from '@/modules/tags/tags.api'
import TagsSlider, { TagsSliderProps } from '@/modules/tags/components/TagsSlider'

export interface FetchAndRenderAllTagsProps extends Omit<TagsSliderProps, 'data' | 'loading'> {}

const FetchAndRenderAllTags: FC<FetchAndRenderAllTagsProps> = (props) => {
  const { data: tags, isLoading, isFetching } = useGetAllTagsQuery()

  return <TagsSlider data={tags} loading={isLoading || isFetching} {...props} />
}

export default memo(FetchAndRenderAllTags)
