import React, { FC } from 'react'

import styles from './styles.module.scss'

import CountriesList from '@/modules/search/components/CountriesList'
import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'
import { CountryBaseModel } from '@/models/country.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface CountriesFilterSidebarProps {
  onClose?: () => void
  chosenCountries?: CountryBaseModel[]
  disabledCountries?: CountryBaseModel[]
  pageName: PagesWithSearch
}

const CountriesFilterSidebar: FC<CountriesFilterSidebarProps> = ({
  onClose,
  disabledCountries,
  chosenCountries,
  pageName,
}) => {
  return (
    <CommonFilterSidebar onClose={onClose} title={'Страны'}>
      <CountriesList
        className={styles.ItemsList}
        throttleScrollDelay={20}
        disabledCountries={disabledCountries}
        chosenCountries={chosenCountries}
        pageName={pageName}
      />
    </CommonFilterSidebar>
  )
}

export default CountriesFilterSidebar
