import { DrmLicenceServers } from '@/models/streamer.model'

export const {
  REACT_APP_LICENCE_SERVER_FAIRPLAY,
  REACT_APP_LICENCE_SERVER_PLAYREADY,
  REACT_APP_LICENCE_SERVER_WIDEVINE,
} = process.env

const licence_server_fairplay = REACT_APP_LICENCE_SERVER_FAIRPLAY
const licence_server_playready = REACT_APP_LICENCE_SERVER_PLAYREADY
const licence_server_widevine = REACT_APP_LICENCE_SERVER_WIDEVINE

export const DRM_CONFIG: DrmLicenceServers = {
  licence_server_fairplay,
  licence_server_playready,
  licence_server_widevine,
}
