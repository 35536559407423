import React from 'react'

const decreaseKeys: string[] = [
  /*'Left', 'ArrowLeft', 'Down', 'ArrowDown'*/
]
const increaseKeys: string[] = [
  /*'Right', 'ArrowRight', 'Up', 'ArrowUp'*/
]
const eventKeys = [' ', 'Space', 'Escape', 'Esc', 'Pause' /*'-', '+'*/]
export const allCaptureKeys = decreaseKeys.concat(increaseKeys).concat(eventKeys)

export function getKeyboardShortcutBlocker(
  keysToBeBlocked: Array<string>,
  cb?: (e?: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => void,
): (keyboardEvent: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => void {
  return (keyboardEvent: KeyboardEvent | React.KeyboardEvent<HTMLDivElement>) => {
    cb && cb(keyboardEvent)
    if (keysToBeBlocked.indexOf(keyboardEvent.key) >= 0) {
      keyboardEvent.preventDefault()
      keyboardEvent.stopPropagation()
    }
  }
}
