import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'

// ------------------------ SORT--------------------------
export const setSort = createAction(
  'searchSlice/setSort',
  withPayloadType<PageUpdatePayload<string>>(),
)

export const resetSort = createAction(
  'searchSlice/resetSorts',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------

// ------------------------ SORT ASC--------------------------
export const setSortAsc = createAction(
  'searchSlice/setSortAsc',
  withPayloadType<PageUpdatePayload<boolean>>(),
)

export const resetSortAsc = createAction(
  'searchSlice/resetSortAsc',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
