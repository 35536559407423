import React from 'react'

import styles from './styles.module.scss'

import TagHeader from '@/modules/tags/components/TagHeader'
import TagFilters from '@/modules/tags/components/TagFilters'
import TagSearchResults from '@/modules/tags/components/TagSearchResults'

const Tag = () => {
  return (
    <div className={styles.Genre}>
      <TagHeader />

      <TagFilters />

      <TagSearchResults />
    </div>
  )
}

export default Tag
