import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface NetworkState {
  online: boolean
}

const initialState: NetworkState = {
  online: window.navigator.onLine,
}

export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    changeNetworkStatus: (state, action: PayloadAction<boolean>) => {
      state.online = action.payload
    },
  },
})

export const { changeNetworkStatus } = networkSlice.actions
