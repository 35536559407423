export const VOICES = [
  '',
  'HDRezka',
  'BaibaKo',
  'FOX',
  'LostFilm',
  'NewStudio',
  // 'Оригинал', // ???
  'Пифагор',
  'кубик в кубе',
  'TVShows',
  // 'DubLik',
  'RuDub',
  // 'SoftBox',
  // 'DreamRecords',
  // 'RenTV',
  // 'AniDub',
  // 'AniMaunt',
  // 'turok1990',
  // 'AniLibria',
  // 'Субтитры',
  // 'Трейлеры',
]
