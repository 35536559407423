import React, { FC, useCallback } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { setCurrentProgram } from '@/modules/tv/tv.actions'
import { useGetNextProgram } from '@/modules/tv/hooks/useGetNextProgram'
import { ArchiveStatuses } from '@/models/channel.model'

interface NextActionProgramProps {
  className?: string
  disabled?: boolean
}

const NextProgramAction: FC<NextActionProgramProps> = ({ className, disabled }) => {
  const dispatch = useAppDispatch()

  const channel = useAppSelector((state) => state.tv.chosenChannel)

  const { nextProgram, programsLoading } = useGetNextProgram()

  const handleNextProgramAction = useCallback(() => {
    if (!nextProgram) return
    dispatch(setCurrentProgram(nextProgram))
  }, [nextProgram])

  if (!nextProgram || !channel || channel.archiveStatus !== ArchiveStatuses.AVAILABLE) return null

  return (
    <Button
      buttonClassName={className}
      disabled={programsLoading || disabled}
      onButtonClick={handleNextProgramAction}
      onEnterPress={handleNextProgramAction}
    >
      <>
        <i className={'icon-fill-next'} />
        {/*<span>Следующая программа</span>*/}
        <span data-title={'title'} className={styles.Title}>
          {nextProgram.title}
        </span>
      </>
    </Button>
  )
}

export default NextProgramAction
