export enum PartnerValue {
  AMEDIATEKA = 'AMEDIATEKA',
  START = 'START',
  PREMIER = 'PREMIER',
  SEASONVAR = 'SEASONVAR',
  RUTUBE = 'RUTUBE',
}

interface PartnerLogo {
  id: string
  createdAt: string
  updatedAt: string
  hash: string
  folder: string
  sourceBaseUrl: string
  sourcePath: string
}

export interface PartnerBaseModel {
  createdAt: string
  updatedAt: string
  id: string
  type: PartnerValue
}

export interface PartnerModel extends PartnerBaseModel {
  logo?: PartnerLogo | null
}
