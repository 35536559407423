export enum PlayerCustomEvents {
  PLAYER_TO_FS = 'playerRequestFullscreenApproved',
  PLAYER_OUT_FS = 'playerRequestExitFullscreenApproved',
  TRAILER_PLAYER_TO_FS = 'trailerPlayerRequestFullscreenApproved',
  TRAILER_PLAYER_OUT_FS = 'trailerPlayerRequestExitFullscreenApproved',

  REQUEST_PLAYER_TO_FS = 'requestPlayerToFullscreen',
  REQUEST_PLAYER_OUT_FS = 'requestPlayerExitFromFullscreen',
}

// Main player to FS
const playerRequestFullscreenApproved = new Event(PlayerCustomEvents.PLAYER_TO_FS)
export const dispatchPlayerRequestFullscreenApprovedEvent = () => {
  document.dispatchEvent(playerRequestFullscreenApproved)
}
// Main player out FS
const playerRequestExitFullscreenApproved = new Event(PlayerCustomEvents.PLAYER_OUT_FS)
export const dispatchPlayerRequestExitFullscreenApprovedEvent = () => {
  document.dispatchEvent(playerRequestExitFullscreenApproved)
}

// Main player request to FS
const requestPlayerEnterToFullscreen = new Event(PlayerCustomEvents.REQUEST_PLAYER_TO_FS)
export const dispatchRequestPlayerEnterToFullscreenEvent = () => {
  document.dispatchEvent(requestPlayerEnterToFullscreen)
}

// Main player request exit from FS
const requestPlayerExitFromFullscreen = new Event(PlayerCustomEvents.REQUEST_PLAYER_OUT_FS)
export const dispatchRequestPlayerExitFromFullscreenEvent = () => {
  document.dispatchEvent(requestPlayerExitFromFullscreen)
}

// Trailer player to FS
const trailerPlayerRequestFullscreenApproved = new Event(PlayerCustomEvents.TRAILER_PLAYER_TO_FS)
export const dispatchTrailerPlayerRequestFullscreenApprovedEvent = () => {
  document.dispatchEvent(trailerPlayerRequestFullscreenApproved)
}
// Trailer player out FS
const trailerPlayerRequestExitFullscreenApproved = new Event(
  PlayerCustomEvents.TRAILER_PLAYER_OUT_FS,
)
export const dispatchTrailerPlayerRequestExitFullscreenApprovedEvent = () => {
  document.dispatchEvent(trailerPlayerRequestExitFullscreenApproved)
}
