import React, { FC, useEffect, useRef, useState } from 'react'

import { createPortal } from 'react-dom'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useNavigate } from 'react-router-dom'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'
import Player from '@/modules/player/Player'

import { TRAILER_DELAY_TIME, TRAILER_SKIP_TIME } from '@/core/config'
import {
  checkCustomFullscreen,
  checkNativeFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'
import { setTrailerReady } from '@/modules/content/content.slice'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface TrailerActionProps {
  disabled?: boolean
  buttonClassName?: string
  overlayClassName?: string
  shouldDelayingTrailer?: boolean
}

const trailerSkipTime = +TRAILER_SKIP_TIME
const trailerShowDelay = +TRAILER_DELAY_TIME

const TrailerAction: FC<TrailerActionProps> = ({
  disabled,
  shouldDelayingTrailer = true,
  overlayClassName,
  buttonClassName,
}) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const shouldDelayingTrailerRef = useRef(shouldDelayingTrailer)

  const [url, setUrl] = useState<string>()

  const card = useAppSelector((state) => state.content.chosenCard)
  const trailerReady = useAppSelector((state) => state.content.trailerReady)

  const handleCanTrailerPlay = (videoEl: HTMLVideoElement) => {
    if (shouldDelayingTrailerRef.current) {
      shouldDelayingTrailerRef.current = false
      dispatch(setTrailerReady(true))
      return
    }

    if (!checkNativeFullscreen() && !checkCustomFullscreen()) {
      videoEl && videoEl.currentTime < trailerSkipTime && (videoEl.currentTime = trailerSkipTime)
      videoEl && !videoEl.muted && (videoEl.muted = true)
      return
    }
  }

  const handleTrailerPlay = () => {
    if (!card) return
    navigate(`${Paths.CONTENT}/${ContentPaths.TRAILER_PLAYER}`, { state: { content: card } })
  }

  useEffect(() => {
    return () => {
      dispatch(setTrailerReady(false))
    }
  }, [card?.id])

  useEffect(() => {
    if (!(card && card.trailer && card.trailer.url)) return

    const id = setTimeout(() => {
      if (!(card && card.trailer && card.trailer.url)) return

      setUrl(card.trailer.url)
      clearTimeout(id)
    }, trailerShowDelay * 1000)

    return () => {
      setUrl(undefined)
    }
  }, [card])

  if (!(card && card.trailer && card.trailer.url)) return null

  return (
    <>
      <Button
        disabled={disabled}
        onButtonClick={handleTrailerPlay}
        onEnterPress={handleTrailerPlay}
        buttonClassName={buttonClassName}
      >
        <span>Трейлер</span>
      </Button>

      {document.getElementById('trailer') &&
        createPortal(
          <>
            <Player
              playerId={'CONTENT_TRAILER_PLAYER'}
              hideOverlay
              className={classNames(
                styles.Trailer,
                styles.TrailerPlayer,
                trailerReady && styles.Show,
              )}
              videoStreamerProps={{
                muted: true,
                controls: false,
                url: url || '',
                loop: true,
                autoPlay: true,
              }}
              videoState={{
                isPaused: false,
                url: url,
              }}
              videoManagerProps={{
                onCanPlay: handleCanTrailerPlay,
              }}
            />
            <div
              className={classNames(
                overlayClassName,
                styles.Trailer,
                styles.Overlay,
                trailerReady && styles.Show,
              )}
            />
          </>,
          document.getElementById('trailer')!,
        )}
    </>
  )
}

export default TrailerAction
