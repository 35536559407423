import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'

// ------------------------ SEARCH_TERM
export const addSymbolToSearchTerm = createAction(
  'searchSlice/addSymbolToSearchTerm',
  withPayloadType<PageUpdatePayload<string>>(),
)
export const removeLastSymbolFromSearchTerm = createAction(
  'searchSlice/removeLastSymbolFromSearchTerm',
  withPayloadType<PageUpdatePayload>(),
)

export const setSearchTerm = createAction(
  'searchSlice/setSearchTerm',
  withPayloadType<PageUpdatePayload<string>>(),
)

export const resetSearchTerm = createAction(
  'searchSlice/resetSearchTerm',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
