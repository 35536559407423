import React, { useEffect } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'

import TrailerAction from '@/modules/content/components/TrailerAction'
import DefaultFocusWrapper from '@/components/Slider/DefaultFocusWrapper'
import ActorsAndGenres from '@/modules/content/components/ActorsAndGenres'
import FavoritesAction from '@/modules/favorites/components/FavoritesAction'
import PlayChosenEpisodeAction from '@/modules/serials/component/PlayChosenEpisodeAction'
import ChoseSeasonEpisodeAction from '@/modules/serials/component/ChoseSeasonEpisodeAction'
import ChosenContentSkeleton from '@/modules/content/components/ChosenContentSkeleton'

import { useChosenContent } from '@/modules/content/hooks/useChosenContent'
import { useCheckRights } from '@/modules/content/hooks/useCheckRights'

import { SerialModel } from '@/models/serial.model'
import { ContentType } from '@/models/content.model'

const Serial = () => {
  const { rightsLoading, rightsError } = useCheckRights('serial')

  const { shouldShowSkeleton, loading, sliders } = useChosenContent<SerialModel>('serial')

  useEffect(() => {
    const desc = document.getElementById('content-description')
    if (desc) {
      desc.dataset.full = 'true'
    }
    return () => {
      if (desc) {
        desc.dataset.full = 'false'
      }
    }
  }, [])

  return (
    <div id={'serial-page'} className={styles.Serial}>
      <div className={styles.Serial__Footer}>
        {shouldShowSkeleton && <ChosenContentSkeleton />}
        {!shouldShowSkeleton && (
          <Slider className={styles.FocusWrapper}>
            <Slider.Vertical>
              {[
                <DefaultFocusWrapper
                  key={0}
                  withoutChildProps
                  className={styles.ActionsFocusWrapper}
                >
                  <ActorsAndGenres className={styles.AdditionalInfo} />
                  <div className={styles.ActionsContainer}>
                    <PlayChosenEpisodeAction
                      className={styles.ActionButton}
                      disabled={loading}
                      rightsLoading={rightsLoading}
                      rightsError={rightsError}
                    />
                    <ChoseSeasonEpisodeAction className={styles.ActionButton} disabled={loading} />
                    <TrailerAction disabled={loading} buttonClassName={styles.ActionButton} />
                    <FavoritesAction
                      disabled={loading}
                      className={styles.ActionButton}
                      favoritesContentType={ContentType.SERIAL}
                    />
                  </div>
                </DefaultFocusWrapper>,
                ...sliders,
              ]}
            </Slider.Vertical>
          </Slider>
        )}
      </div>
      <div id={'trailer'} />
      <div id={'serial-player'} />
    </div>
  )
}

export default Serial
