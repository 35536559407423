import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'

// _____________________ContentID___________

export const setContentId = createAction(
  'searchSlice/setContentId',
  withPayloadType<PageUpdatePayload<string>>(),
)

export const resetContentId = createAction(
  'searchSlice/resetContentId',
  withPayloadType<PageUpdatePayload>(),
)
