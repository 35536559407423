export const saveToLocalStorage = <T>(key: string, data: T) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const removeKeyFromLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export const getFromLocalStorage = <T>(key: string): T | null => {
  const data = localStorage.getItem(key)

  if (!data) return null

  return JSON.parse(data)
}

export const getChromeVersion = (): Partial<{
  full: string
  major: string
  minor: string
  build: string
  patch: string
}> => {
  const versions: string[] | null = navigator.userAgent.match(
    /Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/,
  )
  if (versions === null || versions.length !== 5) {
    return {}
  }

  return {
    full: versions[0],
    major: versions[1],
    minor: versions[2],
    build: versions[3],
    patch: versions[4],
  }
}

export const isIframeApp = !(window === window.parent || window.opener)
export const isNativeApp = !isIframeApp
