import { MenuVariants } from '@/modules/menu/menu.slice'
import { CabinetPaths, ContentPaths, Paths } from '@/core/router/router.paths'

export interface MenuItem {
  title: string
  to?: string
  focusKey?: string
  focusWrapperClassName?: string
  iconClassName: string
  menuVariant: MenuVariants
}

export const menuItems: MenuItem[] = [
  {
    focusKey: 'firstContentMenuItem',
    focusWrapperClassName: 'User',
    iconClassName: 'account',
    title: 'Пользователь',
    menuVariant: MenuVariants.CABINET,
    to: Paths.CABINET,
  },
  {
    iconClassName: 'home',
    title: 'Главная',
    menuVariant: MenuVariants.HOME,
    to: ContentPaths.HOME,
  },
  {
    iconClassName: 'heart',
    title: 'Избранное',
    menuVariant: MenuVariants.FAVORITES,
    to: ContentPaths.FAVORITES,
  },
  {
    iconClassName: 'tv',
    title: 'ТВ-каналы',
    menuVariant: MenuVariants.TV,
    to: ContentPaths.TV,
  },
  {
    iconClassName: 'movie',
    title: 'Фильмы',
    menuVariant: MenuVariants.MOVIES,
    to: ContentPaths.MOVIES,
  },
  {
    iconClassName: 'grid',
    title: 'Сериалы',
    menuVariant: MenuVariants.SERIALS,
    to: ContentPaths.SERIALS,
  },
  // {
  //   iconClassName: 'icon-sport',
  //   iconClassName: 'ball',
  //   title: 'Спорт',
  //   menuVariant: MenuVariants.SPORT,
  //   to: ContentPaths.SPORT,
  // },
  // {
  //   iconClassName: 'icon-children',
  //   iconClassName: 'balloon',
  //   title: 'Детям',
  //   menuVariant: MenuVariants.CHILDREN,
  //   to: ContentPaths.CHILDREN,
  // },

  {
    iconClassName: 'search',
    title: 'Поиск',
    menuVariant: MenuVariants.SEARCH,
    to: ContentPaths.SEARCH,
  },
  {
    focusKey: 'lastContentMenuItem',
    focusWrapperClassName: 'Notification',
    iconClassName: 'notification',
    title: 'Уведомления',
    menuVariant: MenuVariants.NOTIFICATIONS,
    // to: `${Paths.CABINET}/${CabinetPaths.NOTIFICATIONS}`,
    to: CabinetPaths.NOTIFICATIONS,
  },
]

export const approveMenuItems: MenuItem[] = [
  {
    focusKey: 'firstContentMenuItem',
    focusWrapperClassName: 'User',
    iconClassName: 'account',
    title: 'Пользователь',
    menuVariant: MenuVariants.CABINET,
    to: Paths.CABINET,
  },
  {
    iconClassName: 'tv',
    title: 'ТВ-каналы',
    menuVariant: MenuVariants.TV,
    to: ContentPaths.TV,
  },

  {
    iconClassName: 'ball',
    title: 'Спорт',
    menuVariant: MenuVariants.SPORT,
    to: ContentPaths.SPORT,
  },
  {
    iconClassName: 'balloon',
    title: 'Детям',
    menuVariant: MenuVariants.CHILDREN,
    to: ContentPaths.CHILDREN,
  },
  {
    iconClassName: 'heart',
    title: 'Избранное',
    menuVariant: MenuVariants.FAVORITES,
    to: ContentPaths.FAVORITES,
  },
  {
    focusKey: 'lastContentMenuItem',
    focusWrapperClassName: 'Notification',
    iconClassName: 'notification',
    title: 'Уведомления',
    menuVariant: MenuVariants.NOTIFICATIONS,
    to: `${Paths.CABINET}/${CabinetPaths.NOTIFICATIONS}`,
  },
]
