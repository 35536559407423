import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'
import animations from './amination.module.scss'

import { toast } from '@/components/Notifications'
import { getIcon } from '@/components/Notifications/notifications.helpers'
import { NotificationBaseProps } from '@/components/Notifications/types'
import { useEnterExitAnimations } from "@/components/Notifications/notifications.hooks";

export interface ToastProps extends NotificationBaseProps {
  delay?: number
  progress?: boolean
}

const Toast: FC<ToastProps> = ({
  icon = true,
  iconContainerClassName,
  style,
  className,
  type = 'default',
  theme = 'dark',
  contentContainerClassName,
  titleClassName,
  messageClassName,
  title,
  message,
  preventExitTransition = false,
  delay = 5,
  progress = true,
  id: toastID,
}) => {
  const enterClassName = animations.Enter
  const exitClassName = animations.Exit
  const exitedClassName = animations.Exited

  const { ref, isEntityEntered } = useEnterExitAnimations({
    enterClassName,
    exitClassName,
    exitedClassName,
    delay,
    onExitedCallback: () => {
      if (!toastID) return
      toast.delete(toastID)
    },
    preventExitTransition
  })

  return (
    <div
      ref={ref}
      data-types={type}
      className={classNames(
        className,
        styles.Toast,
        theme === 'colored' && styles.Toast__Colored,
        theme === 'light' && styles.Toast__Light,
      )}
      style={style}
    >
      <div className={classNames(styles.ContentContainer)}>
        <div className={classNames(iconContainerClassName, styles.ContentContainer__Icon)}>
          {!icon ? null : typeof icon === 'function' ? icon() : getIcon(type, theme)}
        </div>
        <div className={classNames(contentContainerClassName, styles.ContentContainer__Content)}>
          {title && (
            <div className={classNames(titleClassName, styles.ContentContainer__Content__Title)}>
              {title}
            </div>
          )}

          <div className={classNames(messageClassName, styles.ContentContainer__Content__Message)}>
            {typeof message === 'function' ? message() : message}
          </div>
        </div>
      </div>
      {progress && (
        <div
          data-types={type}
          style={{ transitionDuration: `${delay}s`, width: isEntityEntered ? 0 : '100%' }}
          data-theme={theme}
          className={classNames(styles.Toast__Progress)}
        />
      )}
    </div>
  )
}

export default Toast
