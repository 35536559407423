import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { MediaViewStatus, SyncMediaViewModelWithResource } from '@/models/sync.media.view.model'
import { ContentWithViewModel } from '@/models/content.model'
import { sortCompareFunction } from '@/core/sort'

interface GetOneContentMediaViewArgs {
  contentId: string
}

export interface SyncOneMediaViewPayload
  extends Omit<
    SyncMediaViewModelWithResource,
    'id' | 'createdAt' | 'updatedAt' | 'status' | 'timestamp'
  > {
  id?: string | null
  status?: MediaViewStatus
  timestamp?: number | null
}

interface SyncOneMediaViewArgs extends GetOneContentMediaViewArgs {
  data: SyncOneMediaViewPayload
}

export const syncMediaViewApi = createApi({
  reducerPath: 'syncMediaViewApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: ['ACTIVE_CONTENTS'],
  endpoints: (builder) => ({
    getOneContentMediaView: builder.query<
      SyncMediaViewModelWithResource | null,
      GetOneContentMediaViewArgs
    >({
      query: ({ contentId }) => ({
        url: `/clients/me/contents/${contentId}/views`,
        method: 'GET',
      }),
    }),

    getAllActiveContentMediaView: builder.query<ContentWithViewModel[], void>({
      query: () => ({
        url: `/clients/me/views/contents`,
        method: 'GET',
        params: {
          // relations: 'posters.verticalImage,posters.horizontalImage,backgrounds.image',
          relations:
            'age,partner,countries,posters.verticalImage,posters.horizontalImage,backgrounds.image',
        },
      }),
      transformResponse: (response: ContentWithViewModel[]) => {
        return response.sort(sortCompareFunction({ sortBy: (val) => val.view.updatedAt }))
      },
    }),

    removeOneMediaView: builder.mutation<void, GetOneContentMediaViewArgs>({
      query: ({ contentId }) => ({
        url: `/clients/me/views/contents/${contentId}`,
        method: 'DELETE',
      }),
    }),

    syncOneMediaView: builder.mutation<SyncMediaViewModelWithResource, SyncOneMediaViewArgs>({
      query: ({ contentId, data }) => ({
        url: `/clients/me/contents/${contentId}/views`,
        method: 'PATCH',
        data,
      }),
    }),
  }),
})

export const {
  useSyncOneMediaViewMutation,
  useLazyGetOneContentMediaViewQuery,
  useGetOneContentMediaViewQuery,

  useGetAllActiveContentMediaViewQuery,
  useRemoveOneMediaViewMutation,
} = syncMediaViewApi
