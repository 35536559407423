import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  setContentId,
  resetContentId,
} from '@/modules/search/slice/actions/contentId/contentId.actions'

export const contentIdExtraReducers = (builder: SearchSliceBuilderType) => {
  builder

    .addCase(setContentId, (state, action) => {
      state[action.payload.pageName].contentId = action.payload.payload
    })

    .addCase(resetContentId, (state, action) => {
      state[action.payload.pageName].contentId = undefined
    })
}
