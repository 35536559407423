import React, { FC } from 'react'

import ContentSlider, { BaseContentSliderProps } from '@/modules/content/components/ContentSlider'

import { SerialModel } from '@/models/serial.model'

export interface SerialsSliderProps extends BaseContentSliderProps<SerialModel> {
  data?: SerialModel[]
  isLoading?: boolean
  withoutNavigationCard?: boolean
  specificAction?: (args: {
    setFocus?: (key: string) => void
    className?: string
  }) => React.ReactElement
}

const SerialsSlider: FC<SerialsSliderProps> = ({
  onCardPress,
  onCardFocus,
  isLoading,
  data,
  ...rest
}) => {
  return (
    <ContentSlider<SerialModel>
      data={data}
      isLoading={isLoading}
      onCardPress={onCardPress}
      onCardFocus={onCardFocus}
      rowProps={{
        title: 'Сериалы',
      }}
      {...rest}
    />
  )
}

export default SerialsSlider
