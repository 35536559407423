import React, { useMemo } from 'react'

import classNames from 'classnames'
import styles from '@/modules/menu/components/ContentMenu/styles.module.scss'

import ApproveMenuItem from '@/modules/menu/components/ApproveMenuItem'
import ContentMenuLogo from '@/modules/menu/components/ContentMenuLogo'

import { motion } from 'framer-motion'

import { FocusContext } from '@noriginmedia/norigin-spatial-navigation'

import { useContentMenu } from '@/modules/menu/hooks/useContentMenu'
import { approveMenuItems } from '@/modules/menu/menu.constants'
import { bgAnimation } from '@/modules/menu/menuBg.animation.variants'

const ApproveMenu = () => {
  const {
    focusKey,
    ref,
    bgRef,
    handleAnimationStart,
    handleAnimationComplete,
    setFocus,
    hasFocusedChild,
    items,
  } = useContentMenu(approveMenuItems)

  const render = useMemo(() => {
    return items.map((el, index) => {
      const { focusWrapperClassName, to, menuVariant, focusKey, title, iconClassName } = el
      return (
        <ApproveMenuItem
          key={index + ' ' + title}
          focusKey={focusKey}
          menuVariant={menuVariant}
          title={title}
          to={to}
          iconClassName={iconClassName}
          setFocus={setFocus}
          focusWrapperClassName={classNames(focusWrapperClassName && styles[focusWrapperClassName])}
        />
      )
    })
  }, [items])

  return (
    <FocusContext.Provider key={'menu-focus-context'} value={focusKey}>
      <div key={'menu-focus-wrapper-container'} ref={ref} className={styles.MenuWrapper}>
        <motion.div
          key={'menu_bg'}
          ref={bgRef}
          initial={'closed'}
          animate={hasFocusedChild ? 'opened' : 'closed'}
          variants={bgAnimation}
          onAnimationStart={handleAnimationStart}
          onAnimationComplete={handleAnimationComplete}
          className={styles.BG}
        />
        <div id={'menu_bgCover'} key={'menu_bgCover'} className={styles.BGCover} />
        <ContentMenuLogo />
        {render}
      </div>
    </FocusContext.Provider>
  )
}

export default ApproveMenu
