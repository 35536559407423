import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { EventManager } from '@/components/Notifications/index'

interface UseRenderListAndQueueArguments {
  limit: number
  newestOnTop?: boolean
}

export const useRenderListAndQueue = <T extends { id?: string }>({
  newestOnTop,
  limit,
}: UseRenderListAndQueueArguments) => {
  const [render, setRender] = useState<T[]>([])
  const [queue, setQueue] = useState<T[]>([])

  useEffect(() => {
    if (queue.length === 0) return

    if (render.length < limit && queue.length > 0) {
      const queueCopy = [...queue]
      const el = queueCopy.shift()
      el && setQueue(queueCopy)
      el && setRender((prevState) => (newestOnTop ? [el, ...prevState] : [...prevState, el]))
    }
  }, [queue.length, render.length])

  const handleAdd = useCallback((manager: typeof EventManager, id: string, props: T) => {
    setQueue((prevState) => {
      return prevState.concat({ ...props, id })
    })
  }, [])

  const handleDelete = useCallback((manager: typeof EventManager, id: string) => {
    setQueue((prevState) => {
      return prevState.filter((el) => el.id !== id)
    })
    setRender((prevState) => {
      return prevState.filter((el) => el.id !== id)
    })
  }, [])

  return { render, handleAdd, handleDelete }
}

const enum AnimationStep {
  Enter,
  Entered,
  Exit,
  Exited,
}
interface UseEnterExitAnimationsArguments {
  enterClassName?: string
  exitClassName?: string
  exitedClassName?: string
  onEnteredCallback?: () => void
  onExitedCallback?: () => void
  preventExitTransition?: boolean
  delay?: number
}

export const useEnterExitAnimations = ({
  delay = 0,
  enterClassName,
  exitClassName,
  exitedClassName,
  onEnteredCallback,
  onExitedCallback,
  preventExitTransition = true,
}: UseEnterExitAnimationsArguments) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isEntityEntered, setIsEntityEntered] = useState(false)
  const animationStep = useRef(AnimationStep.Enter)

  useLayoutEffect(() => {
    const node = ref.current
    if (!node) return

    const onEntered = (e: AnimationEvent) => {
      if (e.target !== node) return

      node.removeEventListener('animationend', onEntered)
      node.removeEventListener('animationcancel', onEntered)

      if (animationStep.current === AnimationStep.Enter && e.type !== 'animationcancel') {
        enterClassName && node.classList.remove(enterClassName)
        setIsEntityEntered(true)
        onEnteredCallback?.()
      }

      animationStep.current = AnimationStep.Entered
    }

    const onEnter = () => {
      enterClassName && node.classList.add(enterClassName)
      node.addEventListener('animationend', onEntered)
      node.addEventListener('animationcancel', onEntered)
    }

    onEnter()
  }, [])

  useEffect(() => {
    const node = ref.current
    if (!node || !isEntityEntered) return

    const onExited = () => {
      exitClassName && node.classList.remove(exitClassName)
      exitedClassName && node.classList.add(exitedClassName)
      node.removeEventListener('animationend', onExited)

      onExitedCallback?.()
      animationStep.current = AnimationStep.Exited
    }

    const onExit = () => {
      animationStep.current = AnimationStep.Exit
      exitClassName && node.classList.add(exitClassName)
      node.addEventListener('animationend', onExited)
    }

    const id = setTimeout(() => {
      preventExitTransition ? onExited() : onExit()
      clearTimeout(id)
    }, delay * 1000)
  }, [isEntityEntered])

  return { ref, isEntityEntered, animationStep }
}
