import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Sidebar from '@/components/Sidebar'
import Button from '@/components/Button'
import SortSidebar from '@/modules/search/components/SortAction/SortSidebar'

import { useAppSelector } from '@/core/store'
import { sortFields } from '@/modules/search/sort.constants'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface SortAction {
  triggerClassName?: string
  disabled?: boolean
  pageName: PagesWithSearch
}

const SortAction: FC<SortAction> = ({ disabled, triggerClassName, pageName }) => {
  const sort = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.sort)

  return (
    <Sidebar
      trigger={(onTrigger) => (
        <Button
          disabled={disabled}
          buttonClassName={classNames(triggerClassName, {
            [styles.FilterActive]: sort,
            [styles.WithoutPrepend]: sort,
          })}
          focusClassName={styles.FilterFocused}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
        >
          <>
            <span>Сортировка</span>

            <span>{sortFields.find((el) => el.value === sort)?.name || 'Не выбрано'}</span>
          </>
        </Button>
      )}
      render={(onClose) => {
        return <SortSidebar pageName={pageName} onClose={onClose} />
      }}
    />
  )
}

export default SortAction
