import React, { FC, ReactElement } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Slider, { SliderProps } from '@/components/Slider'
import { RowProps } from '@/components/Slider/Row'
import { ChannelModel } from '@/models/channel.model'

import TvChannelCard from '@/modules/tv/components/TvChannelCard'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'

type RenderFn = () => ReactElement[]

export interface ChannelsSliderProps extends Omit<SliderProps, 'children'> {
  rowProps?: Omit<RowProps, 'children'>
  children?: RenderFn
  onCardPress?: (item: ChannelModel) => void
  onCardFocus?: OnChildFocus<ChannelModel>
  data?: ChannelModel[]
  isLoading?: boolean
  skeletonItemsLength?: number
  deletable?: boolean
  deleting?: boolean
  deletingId?: string
}

const ChannelsSlider: FC<ChannelsSliderProps> = ({
  rowProps,
  data,
  skeletonItemsLength = 5,
  isLoading,
  children,
  onCardFocus,
  onCardPress,
  deletable,
  deletingId,
  deleting,
  ...sliderProps
}) => {
  return (
    <Slider className={styles.FocusWrapper} {...sliderProps}>
      <Slider.Horizontal
        title={'Белорусские каналы'}
        {...rowProps}
        className={classNames(styles.ScrollingWrapper, rowProps?.className)}
      >
        {children
          ? children()
          : data && data.length > 0 && !isLoading
          ? [
              ...data.map((el, index) => {
                return (
                  <TvChannelCard
                    key={index}
                    card={el}
                    onCardFocus={onCardFocus}
                    onCardPress={onCardPress}
                    wrapperClassName={styles.InnerContainer}
                    deletable={deletable}
                    deleting={deleting && deletingId === el.id}
                  />
                )
              }),
            ]
          : Array(skeletonItemsLength)
              .fill('')
              .map((el, index) => {
                return <TvChannelCard key={index} />
              })}
      </Slider.Horizontal>
    </Slider>
  )
}

export default ChannelsSlider
