import React, { FC, useMemo } from 'react'
import styles from './styles.module.scss'

import { SessionModel } from '@/models/session.model'
import { Platform, PlatformModel } from '@/models/platform.model'
import classNames from 'classnames'
import { DeviceModel } from '@/models/device.model'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/ru'

dayjs.extend(relativeTime)
dayjs.locale('ru')

interface SessionCardInnerProps {
  card?: SessionModel
  focused?: boolean
  platforms?: PlatformModel[]
  disabled?: boolean
  showCloseIcon?: boolean
}

const getPlatform = (session: SessionModel): PlatformModel | undefined => {
  if (!session) return
  if (!session.clientDevice) return
  if (!session.clientDevice.device) return
  if (!session.clientDevice.device.platform) return

  return session.clientDevice.device.platform
}

const getPlatformType = (platform?: PlatformModel): Platform | undefined => {
  if (!platform) return
  if (!platform.type) return

  return platform.type
}

const getPlatformAliasByPlatformType = (platformType?: Platform) => {
  if (!platformType) return

  if (platformType === Platform.WEB_OS) return 'LG'
  if (platformType === Platform.TIZEN) return 'SAMSUNG'
  if (platformType === Platform.ANDROID_TV) return 'ANDROID-TV'
  if (platformType === Platform.ANDROID) return 'ANDROID'
  if (platformType === Platform.IOS) return 'IOS'
  if (platformType === Platform.WEB) return 'WEB'

  if (platformType === Platform.LOCAL) return 'LOCAL'
}

const getDevice = (session: SessionModel): DeviceModel | undefined => {
  if (!session) return
  if (!session.clientDevice) return
  if (!session.clientDevice.device) return

  return session.clientDevice.device
}
//
const getDeviceModel = (device?: DeviceModel): string | undefined => {
  if (!device) return
  if (!device.model) return

  return device.model
}

const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

const SessionCardInner: FC<SessionCardInnerProps> = ({ disabled, showCloseIcon = true, card }) => {
  const platform = useMemo(() => {
    const defaultOutput = 'Неизвестно'
    if (!card) return defaultOutput

    const platform = getPlatform(card)
    if (!platform) return defaultOutput

    const platformType = getPlatformType(platform)
    if (!platformType) return defaultOutput

    const platformAlias = getPlatformAliasByPlatformType(platformType)
    if (!platformAlias) return defaultOutput

    return platformAlias
  }, [card])

  const model = useMemo(() => {
    const defaultOutput = 'Неизвестно'
    if (!card) return defaultOutput

    const device = getDevice(card)
    if (!device) return defaultOutput

    const model = getDeviceModel(device)
    if (!model) return defaultOutput

    return model
  }, [card])
  return (
    <div className={styles.Inner}>
      <div className={styles.DeviceInfo}>
        <div className={classNames(styles.Title, disabled && styles.Title__Disabled)}>
          {`${platform} ${model}`}
        </div>
        <div
          className={classNames(styles.AdditionalInfo, disabled && styles.AdditionalInfo__Disabled)}
        >
          {capitalize(dayjs(card?.updatedAt).fromNow())}
        </div>
      </div>
      {showCloseIcon && (
        <div className={classNames(styles.DeleteIcon, disabled && styles.DeleteIcon__Disabled)}>
          <i className={'icon-fill-close'} />
        </div>
      )}
    </div>
  )
}

export default SessionCardInner
