import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { isNotFoundError } from '@/core/api/api'

import { useLazyGetLiveProgramByChannelIdQuery } from '@/modules/tv/tv.api'
import { resetLiveProgram, setLiveProgram } from '@/modules/tv/tv.actions'

const ChangedChannelStreamsLoader = () => {
  const dispatch = useAppDispatch()

  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)

  // запрос за лайвом для выбранного канала
  const [fetchLive, { data: liveProgram, error: liveError, requestId }] =
    useLazyGetLiveProgramByChannelIdQuery()

  useEffect(() => {
    if (!chosenChannel || !chosenChannel.id) return
    fetchLive({ channelId: chosenChannel.id })
  }, [chosenChannel])

  useEffect(() => {
    if (liveError && isNotFoundError(liveError)) {
      dispatch(resetLiveProgram())
      return
    }
  }, [liveError])

  useEffect(() => {
    if (!liveProgram) return

    dispatch(setLiveProgram(liveProgram))
  }, [liveProgram, requestId]) // <-- requestId в зависимостях иначе не отрабатывае UE после 404 ошибки

  return null
}

export default ChangedChannelStreamsLoader
