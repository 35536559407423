import React, { FC, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Row from '@/components/Slider/Row'

import DefaultFocusWrapper, {
  DefaultFocusWrapperProps,
} from '@/components/Slider/DefaultFocusWrapper'
import { FocusHandler } from '@noriginmedia/norigin-spatial-navigation'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import VirtualColumnScrollingWrapper from '@/components/Slider/VirtualColumnScrollingWrapper'
import VirtualRowScrollingWrapper from '@/components/Slider/VirtualRowScrollingWrapper'
import ColumnScrollingWrapper from '@/components/Slider/ColumnScrollingWrapper'

export interface SliderProps extends DefaultFocusWrapperProps {
  scrollIndex?: number
  onFocus?: OnChildFocus
}

const Slider: FC<SliderProps> = ({
  className,
  style,
  children,
  scrollIndex,
  onFocus,
  focusProps,
}) => {
  const handleOnFocus: FocusHandler = useCallback(
    (...args) => {
      focusProps?.onFocus?.(...args, scrollIndex)
      onFocus?.(...args, scrollIndex)
    },
    [focusProps, onFocus, scrollIndex],
  )

  return (
    <DefaultFocusWrapper
      className={classNames(styles.FocusWrapper, className)}
      style={style}
      focusProps={{
        ...focusProps,
        onFocus: handleOnFocus,
      }}
    >
      {children}
    </DefaultFocusWrapper>
  )
}

export default Object.assign(Slider, {
  Vertical: ColumnScrollingWrapper,
  Horizontal: Row,
  VirtualVertical: VirtualColumnScrollingWrapper,
  VirtualHorizontal: VirtualRowScrollingWrapper,
})
