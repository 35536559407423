import React, { forwardRef, useCallback, useEffect, useRef } from 'react'

import { VideoStreamerBaseProps } from '@/models/streamer.model'

export interface HtmlVideoStreamerProps extends VideoStreamerBaseProps<null, 'html'> {}

const HtmlVideoStreamer = forwardRef<HTMLVideoElement, HtmlVideoStreamerProps>(
  ({ url, onError, onInit, onBeforeReInit, onReInit, ...props }, ref) => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const mockPlayerRef = useRef<boolean | null>(null)

    const handleError = useCallback((event: React.SyntheticEvent<HTMLVideoElement>) => {
      const videoEl = videoRef.current
      if (!videoEl) return

      const target = event.target as HTMLVideoElement & MediaError
      const error = target.error || ({} as MediaError)

      onError?.(videoEl, { code: error.code, message: error.message })
    }, [])

    useEffect(() => {
      const videoEl = videoRef.current

      if (!url || !videoEl) return

      const start = async () => {
        if (mockPlayerRef.current) {
          onBeforeReInit?.(videoEl)
        }

        if (!mockPlayerRef.current) onInit?.(videoEl, null, 'html')
        else onReInit?.(videoEl, null, 'html')

        mockPlayerRef.current = true
      }

      // After url change should trigger loading new video
      videoEl.load()
      start().catch((error) => onError?.(videoEl, error))
    }, [url])

    return (
      <video
        src={url}
        ref={(node: HTMLVideoElement) => {
          videoRef.current = node
          if (typeof ref === 'function') ref(node)
          else if (ref) ref.current = node
        }}
        onError={handleError}
        {...props}
      />
    )
  },
)

HtmlVideoStreamer.displayName = 'HtmlVideoStreamer'

export default HtmlVideoStreamer
