import { useLocation } from 'react-router-dom'
import { useGetResources } from '@/modules/feeds/hooks/useGetResources'
import { resolvePathnameForGetFeedsResources } from '@/modules/content/content.helpers'
import { useMemo } from 'react'

export const useGetContentResourcesByResolvedPathname = () => {
  const { pathname } = useLocation()
  const { resources, loading } = useGetResources(resolvePathnameForGetFeedsResources(pathname))

  return useMemo(() => ({ loading, resources }), [resources, loading])
}
