import React, { forwardRef, memo } from 'react'

import HtmlVideoStreamer, {
  HtmlVideoStreamerProps,
} from '@/modules/player/components/player/VideoStreamer/HtmlVideoStreamer'
import DashVideoStreamer, {
  DashPlayer,
  DashVideoStreamerProps,
} from '@/modules/player/components/player/VideoStreamer/DashVideoStreamer'
import { Platform } from '@/models/platform.model'
import { useAppSelector } from '@/core/store'
import HlsVideoStreamer, {
  HlsVideoStreamerProps,
} from '@/modules/player/components/player/VideoStreamer/HlsVideoStreamer'

export type Player = DashPlayer | null

export type VideoStreamerVariant = 'dash' | 'html' | 'hls'

export type VideoStreamerProps = DashVideoStreamerProps &
  HtmlVideoStreamerProps &
  HlsVideoStreamerProps

export const resolveStreamerByUrl = (url: string, platform?: Platform | null) => {
  if (platform === Platform.LOCAL && url.indexOf('.m3u8') !== -1) return 'hls'

  if (url.indexOf('.mpd') !== -1) return 'dash'

  if (url.indexOf('.m3u8') !== -1) return 'html'

  if (url.indexOf('.mp4') !== -1 && url.indexOf('.mpd') === -1) return 'html'

  return null
}

const VideoStreamer = forwardRef<HTMLVideoElement, VideoStreamerProps>((props, ref) => {
  const platform = useAppSelector((state) => state.app.platform)
  const streamerVariant = resolveStreamerByUrl(props.url, platform)

  if (streamerVariant === 'hls') {
    return <HlsVideoStreamer ref={ref} {...props} />
  }

  if (streamerVariant === 'dash') {
    return <DashVideoStreamer ref={ref} {...props} />
  }

  if (streamerVariant === 'html') {
    return <HtmlVideoStreamer ref={ref} {...props} />
  }

  return null
})

VideoStreamer.displayName = 'VideoStreamer'

export default memo(VideoStreamer)
