import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button, { Button as ButtonProps } from '@/components/Button'

import { useLocation, useNavigate } from 'react-router-dom'

import { MenuVariants } from '@/modules/menu/menu.slice'

export interface MenuButtonProps extends Omit<ButtonProps, 'children'> {
  menuActive?: boolean
  title?: string
  to?: string
  setFocus?: (id: string) => void
  menuVariant?: MenuVariants
  iconClassName?: string
}

const MenuButton: FC<MenuButtonProps> = ({
  focusKey,
  buttonClassName,
  menuVariant,
  to,
  setFocus,
  // menuActive,
  onButtonClick,
  title,
  iconClassName,
  ...props
}) => {
  const { pathname } = useLocation()
  const active = useMemo(() => {
    return (
      (menuVariant && pathname.includes(menuVariant)) ||
      (pathname === '/content' && menuVariant === 'home')
    )
  }, [pathname])

  const navigate = useNavigate()

  const handleOnEnterPress = () => {
    const root = document.getElementById('root')
    if (!root) return

    if (to && menuVariant) {
      navigate(to)
    }
    setFocus?.('Redirect')
    onButtonClick?.()
  }

  const handleArrowPress = useCallback(
    (direction: string) => {
      if (direction === 'right') {
        setFocus?.('Redirect')
      }
      if (focusKey && focusKey === 'firstContentMenuItem' && direction === 'up') {
        setFocus?.('lastContentMenuItem')
        return false
      }
      if (focusKey && focusKey === 'lastContentMenuItem' && direction === 'down') {
        setFocus?.('firstContentMenuItem')
        return false
      }
      if (direction === 'up' || direction === 'down') {
        return true
      }
      return false
    },
    [focusKey, setFocus],
  )

  return (
    <Button
      focusKey={focusKey}
      onArrowPress={handleArrowPress}
      onButtonClick={handleOnEnterPress}
      onEnterPress={handleOnEnterPress}
      buttonClassName={classNames(styles.MenuButton, buttonClassName, {
        [styles.MenuButton__Active]: active,
        active_menu_item: active,
      })}
      focusClassName={styles.MenuButton__Focused}
      shouldHandleClickOnlyInFocus
      {...props}
    >
      <>
        <span id={'menu-title-focus-bg'} className={styles.FocusBG} />
        <i className={`icon-${active ? 'fill' : 'line'}-${iconClassName}`} />
        <span id={'menu-title'} className={styles.MenuTitle}>
          {title}
        </span>
      </>
    </Button>
  )
}

export default MenuButton
