import React, { CSSProperties, forwardRef, useEffect } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { Callback, ContainerType, Events, Toast, type ToastProps } from '@/components/Notifications'
import { containerStartUp } from '@/components/Notifications/notifications.helpers'
import { useRenderListAndQueue } from '@/components/Notifications/notifications.hooks'

export interface ToastsContainerProps {
  id?: string
  className?: string
  newestOnTop?: boolean
  style?: CSSProperties
  toastStyle?: React.CSSProperties
  toastClassName?: string
  limit?: number
}

const ToastsContainer = forwardRef<HTMLDivElement, ToastsContainerProps>(
  ({ limit = 4, newestOnTop = false, id, ...props }, ref) => {
    const { render, handleAdd, handleDelete } = useRenderListAndQueue<ToastProps>({
      limit: limit || 4,
      newestOnTop,
    })
    useEffect(() => {
      const containerId = id ? id : `${Date.now()}`

      const subs: { event: Events; cb: Callback }[] = [
        { event: Events.ADD_TOAST, cb: handleAdd },
        { event: Events.DELETE_TOAST, cb: handleDelete },
      ]

      const emit: Events[] = [Events.TOASTS_CONTAINER_MOUNTED]
      const cleanUpEmit: Events[] = [Events.TOASTS_CONTAINER_UNMOUNTED]

      const cleanUp = containerStartUp(
        containerId,
        ContainerType.TOAST_CONTAINER,
        subs,
        emit,
        cleanUpEmit,
      )
      return () => {
        cleanUp()
      }
    }, [])

    return (
      <div
        ref={ref}
        id={id}
        style={props.style}
        className={classNames(styles.ToastsContainer, props.className)}
      >
        {render.length > 0
          ? render.map((el) => {
              return (
                <div key={el.id} className={styles.ToastsContainer__Inner}>
                  <Toast {...el} />
                </div>
              )
            })
          : null}
      </div>
    )
  },
)

ToastsContainer.displayName = 'ToastsContainer'

export default ToastsContainer
