import React, { FC, useEffect } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import alert from '@/components/Notifications/alert'
import { NotificationBaseProps } from '@/components/Notifications/types'
import { getIcon } from '@/components/Notifications/notifications.helpers'
import { useEnterExitAnimations } from "@/components/Notifications/notifications.hooks";

export interface AlertProps extends NotificationBaseProps {
  show?: boolean
}

const Alert: FC<AlertProps> = ({
  type = 'default',
  theme = 'dark',
  className,
  icon = true,
  iconContainerClassName,
  contentContainerClassName,
  title,
  titleClassName,
  messageClassName,
  message,
  id: alertId,
  preventExitTransition,
  show,
}) => {
  const { ref } = useEnterExitAnimations({
    preventExitTransition
  })

  useEffect(() => {
    if (show) return

    alertId && alert.delete(alertId)
  }, [show])
  return (
    <div
      id={alertId}
      ref={ref}
      data-types={type}
      className={classNames(
        className,
        styles.Alert,
        theme === 'colored' && styles.Alert__Colored,
        theme === 'light' && styles.Alert__Light,
      )}
    >
      <div className={classNames(iconContainerClassName, styles.Alert__Icon)}>
        {!icon ? null : typeof icon === 'function' ? icon() : getIcon(type, theme)}
      </div>
      <div className={classNames(contentContainerClassName, styles.Alert__Content)}>
        {title && (
          <div className={classNames(titleClassName, styles.Alert__Content__Title)}>{title}</div>
        )}
        <div className={classNames(messageClassName, styles.Alert__Content__Message)}>
          {typeof message === 'function' ? message() : message}
        </div>
      </div>
    </div>
  )
}

export default Alert
