import React, { FC } from 'react'

import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import ProgramDatesCardInner from '@/modules/tv/components/ProgramDatesCard/ProgramDatesCardInner'
import { GetDatesByChannelIdResponseItem } from '@/modules/tv/tv.types'

interface ProgramDatesCardProps
  extends Omit<DefaultCardProps<GetDatesByChannelIdResponseItem>, 'children'> {
  isActive?: boolean
  isChosen?: boolean
}

const ProgramDatesCard: FC<ProgramDatesCardProps> = ({ card, isActive, isChosen, ...props }) => {
  return (
    <DefaultCard
      {...props}
      card={card}
      className={styles.TabFocusWrapper}
      wrapperClassName={styles.TabWrapper}
      focusClassName={styles.TabFocusWrapperFocused}
    >
      <ProgramDatesCardInner card={card} isActive={isActive} isChosen={isChosen} />
    </DefaultCard>
  )
}

export default ProgramDatesCard
