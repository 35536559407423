import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import SearchResultsList from '@/modules/search/components/SearchResultsList'

import { useSearchByPage } from '@/modules/search/hooks/useSearchByPage'

const SearchResults = () => {
  const {
    searchResult,
    handleLoadMore,
    handleOnCardPress,
    shouldShowLoadMore,
    isSearchQueryCalledRef,
    setActions,
    searchLoading,
  } = useSearchByPage('search')

  if (searchLoading) return <div className={classNames(styles.Results, styles.Results__Empty)} />

  if (!isSearchQueryCalledRef.current && !searchResult.length)
    return (
      <div className={classNames(styles.Results, styles.Results__Empty)}>
        Введите название или измените фильтры, чтобы начать поиск
      </div>
    )

  if (isSearchQueryCalledRef.current && (!searchResult || !(searchResult.length > 0)))
    return (
      <div className={classNames(styles.Results, styles.Results__Empty)}>
        По запросу ничего не найдено
      </div>
    )

  return (
    <div className={styles.Results}>
      <SearchResultsList
        searchResult={searchResult}
        shouldShowLoadMore={shouldShowLoadMore}
        onInit={setActions}
        onCardPress={handleOnCardPress}
        onLoadMorePress={handleLoadMore}
      />
    </div>
  )
}

export default SearchResults
