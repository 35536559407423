import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { PackageModel } from '@/models/package.model'

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllPackages: builder.query<PackageModel[], void>({
      query: () => ({
        url: 'payments/packages',
        method: 'GET',
      }),
    }),

    getAllClientMePackages: builder.query<PackageModel[], void>({
      query: () => ({
        url: 'clients/me/payments/packages',
        method: 'GET',
      }),
    }),
    getClientMePackageById: builder.query<PackageModel, { packageId: string }>({
      query: ({ packageId }) => ({
        url: `clients/me/payments/packages/${packageId}`,
        method: 'GET',
      }),
    }),
    getPackageById: builder.query<PackageModel, { packageId: string }>({
      query: ({ packageId }) => ({
        url: `payments/packages/${packageId}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetAllPackagesQuery,
  useGetPackageByIdQuery,
  useGetAllClientMePackagesQuery,
  useGetClientMePackageByIdQuery,
} = subscriptionApi
