import React from 'react'
import { ContentModel } from '@/models/content.model'
import Info from '@/modules/content/components/Info'

interface FreeInfoProps {
  card?: ContentModel
  className?: string
}

const FreeInfo = ({ className, card }: FreeInfoProps) => {
  const isFree = card?.isFree
  if (!isFree) return null
  return <Info infoText={'Бесплатно'} className={className} />
}

export default FreeInfo
