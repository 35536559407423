import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import {
  PlayerReducerActions,
  PlayerState,
  ProgressState,
  setProgressStateAC,
  setVideoStateAC,
  VideoState,
} from '@/modules/player/player.reducer'
import { useHotReload } from '@/core/store/hooks'

export interface PlayerActions {
  videoEl?: HTMLVideoElement | null
  play: () => Promise<void>
  pause: () => void
  togglePlayPause: () => Promise<void>
}

export type CommonPlayerEventsCallback<T = HTMLVideoElement> = (videoEl: T) => void

export interface PlayerEventsProps {
  onPlay?: CommonPlayerEventsCallback
  onPause?: CommonPlayerEventsCallback
  onManualPlay?: CommonPlayerEventsCallback<HTMLVideoElement | null>
  onManualPause?: CommonPlayerEventsCallback<HTMLVideoElement | null>
  onStop?: CommonPlayerEventsCallback
  onTimeUpdate?: CommonPlayerEventsCallback
  onTimeChange?: (
    videoEl: HTMLVideoElement,
    prevCurrentTime: number,
    nextCurrentTime: number,
  ) => void
  onSeeking?: CommonPlayerEventsCallback
  onSeeked?: CommonPlayerEventsCallback
  onWaiting?: CommonPlayerEventsCallback
  onCanPlay?: CommonPlayerEventsCallback
  onEnded?: CommonPlayerEventsCallback
  onChangeUrl?: CommonPlayerEventsCallback
  onError?: (videoEl: HTMLVideoElement, error: unknown) => void
  onActionsReady?: (actions: PlayerActions) => void
  onStartRewind?: CommonPlayerEventsCallback
  onEndRewind?: CommonPlayerEventsCallback
}

interface ProviderRenderParameters {
  onVideoRef: (element: HTMLVideoElement) => void
  togglePlayPause: () => Promise<void>
  changeProgress: (value: number | string) => void
}

export interface PlayerVideoManagerProps extends PlayerEventsProps {
  videoState?: Partial<VideoState>
  // screenState?: Partial<ScreenState>
}

interface FullPlayerVideoManagerProps extends PlayerVideoManagerProps {
  children: (params: ProviderRenderParameters) => React.ReactNode
  progressState?: Partial<ProgressState>
}

const PlayerVideoManager: FC<FullPlayerVideoManagerProps> = ({
  children,
  progressState: progressStateProp,
  onActionsReady,
  videoState,

  ...props
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const shouldChangeProgressStateRef = useRef(!!progressStateProp?.currentTime)

  const previousUrl = useRef<string | undefined>(videoState?.url)

  useEffect(() => {
    if (!videoRef.current || !videoState?.url) return
    if (previousUrl.current && previousUrl.current !== videoState.url) {
      props.onChangeUrl?.(videoRef.current)
    }

    return () => {
      previousUrl.current = videoState?.url
    }
  }, [videoState?.url])

  const playerId = usePlayerIdContext()
  const { usePlayerDispatch, usePlayerStateSelector } = PlayerManager.getPlayer<
    PlayerState,
    PlayerReducerActions
  >(playerId)

  const dispatch = usePlayerDispatch()

  const isPaused = usePlayerStateSelector((state) => state.video.isPaused)
  const isLive = usePlayerStateSelector((state) => state.video.isLive)

  useEffect(() => {
    dispatch(setVideoStateAC({ isLive: videoState?.isLive }))
  }, [videoState?.isLive])

  const togglePlayPause = useCallback(async () => {
    if (isPaused) {
      props.onManualPlay && props.onManualPlay(videoRef.current)
      await play()
      return
    } else {
      props.onManualPause && props.onManualPause(videoRef.current)
      pause()
    }
  }, [isPaused, isLive])

  const play = async () => {
    const videoEl = videoRef?.current

    if (!videoEl || !videoEl.paused || !isPaused || isLive) return

    try {
      await videoEl.play()
      // dispatch(setVideoStateAC({ isPaused: false }))
      props.onPlay?.(videoEl)
    } catch (error) {
      console.log(error)
      props.onError?.(videoEl, error)
    }
  }

  const pause = () => {
    const videoEl = videoRef?.current

    if (!videoEl || videoEl.paused || isPaused || isLive) return

    videoEl.pause()
  }

  const handleCanPlay = useCallback(() => {
    const videoEl = videoRef?.current
    dispatch(setVideoStateAC({ isBuffering: false }))
    if (videoEl) props.onCanPlay?.(videoEl)
  }, [props.onCanPlay])

  const handleEnded = useCallback(() => {
    const videoEl = videoRef?.current
    if (!videoEl) return

    videoEl.currentTime = 0
    dispatch(setVideoStateAC({ isPaused: true }))
    props.onEnded?.(videoEl)
  }, [props.onEnded])

  const handleWaiting = useCallback(() => {
    const videoEl = videoRef?.current
    dispatch(setVideoStateAC({ isBuffering: true }))
    if (videoEl) props.onWaiting?.(videoEl)
  }, [props.onWaiting])

  const handleSeeked = useCallback(() => {
    const videoEl = videoRef?.current
    if (videoEl) props.onSeeked?.(videoEl)
  }, [props.onSeeked])

  const handleSeeking = useCallback(() => {
    const videoEl = videoRef?.current
    if (videoEl) props.onSeeking?.(videoEl)
  }, [props.onSeeking])

  const handleTimeUpdate = useCallback(() => {
    const videoEl = videoRef.current
    if (!videoEl) return

    const currentTime = videoEl.currentTime
    const duration = videoEl.duration
    dispatch(setProgressStateAC({ currentTime, duration }))
    props.onTimeUpdate?.(videoEl)
  }, [props.onTimeUpdate])

  const handleError = useCallback(
    (error?: unknown) => {
      const videoEl = videoRef?.current
      if (!videoEl) return
      console.log(error)
      props.onError?.(videoEl, error)
    },
    [props.onError],
  )

  const changeProgress = useCallback(
    (value: number | string) => {
      const videoEl = videoRef.current
      if (!videoEl) return

      props.onStartRewind?.(videoEl)

      const preCurrentTime = Number(videoEl.currentTime)
      const newCurrentTime = Number(value)

      videoEl.currentTime = newCurrentTime
      dispatch(setProgressStateAC({ currentTime: newCurrentTime }))
      props.onTimeChange?.(videoEl, preCurrentTime, newCurrentTime)

      props.onEndRewind?.(videoEl)
    },
    [props.onEndRewind, props.onStartRewind, props.onTimeChange],
  )

  const handlePlay = useCallback(
    (e: Event) => {
      const videoEl = e.target as HTMLVideoElement
      props.onPlay?.(videoEl)
      dispatch(setVideoStateAC({ isPaused: false }))
    },
    [props.onPlay],
  )

  const handlePause = useCallback(
    (e: Event) => {
      const videoEl = e.target as HTMLVideoElement
      props.onPause?.(videoEl)
      dispatch(setVideoStateAC({ isPaused: true }))
    },
    [props.onPause],
  )

  const hotReload = useHotReload()
  const onVideoRef = useCallback((videoEl: HTMLVideoElement | null) => {
    if (!videoEl) return

    videoRef.current = videoEl
    onActionsReady?.({ play, pause, togglePlayPause, videoEl: videoRef.current })

    hotReload()

    if (shouldChangeProgressStateRef.current) {
      const currentTime = progressStateProp?.currentTime || 0
      dispatch(setProgressStateAC({ currentTime }))
      videoEl.currentTime = currentTime

      shouldChangeProgressStateRef.current = false
    }
  }, [])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      videoEl?.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [videoRef.current, handleTimeUpdate])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('canplay', handleCanPlay)
    return () => {
      videoEl?.removeEventListener('canplay', handleCanPlay)
    }
  }, [videoRef.current, handleCanPlay])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('play', handlePlay)
    return () => {
      videoEl?.removeEventListener('play', handlePlay)
    }
  }, [videoRef.current, handlePlay])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('pause', handlePause)
    return () => {
      videoEl?.removeEventListener('pause', handlePause)
    }
  }, [videoRef.current, handlePause])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return

    videoEl.addEventListener('seeking', handleSeeking)
    return () => {
      videoEl?.removeEventListener('seeking', handleSeeking)
    }
  }, [videoRef.current, handleSeeking])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('seeked', handleSeeked)

    return () => {
      videoEl?.removeEventListener('seeked', handleSeeked)
    }
  }, [videoRef.current, handleSeeked])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('waiting', handleWaiting)

    return () => {
      videoEl?.removeEventListener('waiting', handleWaiting)
    }
  }, [videoRef.current, handleWaiting])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('ended', handleEnded)

    return () => {
      videoEl?.removeEventListener('ended', handleEnded)
    }
  }, [videoRef.current, handleEnded])

  useEffect(() => {
    const videoEl = videoRef.current
    if (!videoEl) return
    videoEl.addEventListener('error', handleError)

    return () => {
      videoEl?.removeEventListener('error', handleError)
    }
  }, [videoRef.current, handleError])

  useEffect(() => {
    onActionsReady?.({ play, pause, togglePlayPause, videoEl: videoRef.current })
  }, [togglePlayPause, videoRef.current])

  const params = useMemo(() => {
    return { onVideoRef, togglePlayPause, changeProgress }
  }, [onVideoRef, togglePlayPause, changeProgress])

  return children(params)
}

export default PlayerVideoManager
