import React, { FC } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'

import SkeletonView from '@/components/SkeletonView'

import dayjs from 'dayjs'

import { ProgramBaseModel } from '@/models/program.model'

interface ShortProgramListItemProps {
  program?: ProgramBaseModel
  isActive: boolean
}

const ShortProgramListItem: FC<ShortProgramListItemProps> = ({ program, isActive }) => {
  return (
    <div className={styles.ShortProgramListItem}>
      <div className={classNames(styles.ItemContainer, isActive && styles.ItemContainer__Active)}>
        <div className={styles.Title}>{(program && program.title) || <SkeletonView />}</div>
        <div className={styles.Time}>
          {(program && dayjs(program.startTime).format('HH:mm')) || <SkeletonView />}
        </div>
      </div>
    </div>
  )
}

export default ShortProgramListItem
