import { MutableRefObject, useCallback, useMemo } from 'react'

import { useCommonStatisticCounterLogic } from '@/modules/player/hooks/useCommonStatisticCounterLogic'

export enum EventTypes {
  MANUAL_PAUSE = 'MANUAL_PAUSE',
  MANUAL_PLAY = 'MANUAL_PLAY',

  MANUAL_STOP = 'MANUAL_STOP',
  HB = 'HB',
}

export interface SendHBOptions {
  totalCountRef: MutableRefObject<number>
  counterRef: MutableRefObject<number>
  isViewPointAchievedRef: MutableRefObject<boolean>
  resetCounter: () => void
  type?: EventTypes
}

interface UseSendStatisticConfig {
  sendHBBeforeCallback?: (options: SendHBOptions) => void
  sendHBCallback?: (options: SendHBOptions) => void
  sendViewCallback?: (options: SendHBOptions) => void
  hbBefore: number
  viewPoint: number
  hb: number
  counterStep?: number
}

export const useSendStatistic = ({
  hb,
  sendHBCallback,
  sendHBBeforeCallback,
  sendViewCallback,
  viewPoint,
  hbBefore,
  counterStep = 5,
}: UseSendStatisticConfig) => {
  const {
    totalCountRef,
    isViewPointAchievedRef,
    counterRef,
    intervalIdRef,
    resetIsViewPointAchieved,
    resetCounterInterval,
    resetTotalCount,
    resetCounter,
  } = useCommonStatisticCounterLogic()

  const startCount = useCallback(() => {
    resetCounterInterval()

    intervalIdRef.current = setInterval(() => {
      counterRef.current = counterRef.current + counterStep
      totalCountRef.current = totalCountRef.current + counterStep

      if (!isViewPointAchievedRef.current) {
        if (hbBefore && hbBefore > 0 && counterRef.current === hbBefore) {
          sendHBBeforeCallback &&
            sendHBBeforeCallback({
              totalCountRef,
              isViewPointAchievedRef,
              counterRef,
              resetCounter,
              type: EventTypes.HB,
            })
          return
        }

        if (totalCountRef.current < viewPoint) return

        sendViewCallback &&
          sendViewCallback({
            totalCountRef,
            isViewPointAchievedRef,
            counterRef,
            resetCounter,
            type: EventTypes.HB,
          })
        isViewPointAchievedRef.current = true

        return
      }

      if (counterRef.current === hb) {
        sendHBCallback &&
          sendHBCallback({
            totalCountRef,
            isViewPointAchievedRef,
            counterRef,
            resetCounter,
            type: EventTypes.HB,
          })
      }
    }, counterStep * 1000)
  }, [])

  const send = useCallback((type?: EventTypes) => {
    sendHBCallback &&
      sendHBCallback({ totalCountRef, isViewPointAchievedRef, counterRef, resetCounter, type })
  }, [])

  return useMemo(() => {
    return {
      resetCounter,
      resetTotalCount,
      resetIsViewPointAchieved,
      resetCounterInterval,
      startCount,
      send,
    }
  }, [])
}
