import { configureStore, combineReducers, Dispatch } from '@reduxjs/toolkit'

import { reducer } from '@/core/store/reducer'
import { middleware } from '@/core/store/middleware'
import SocketClient from '@/core/sockets/client'

export const resetAction = (dispatch: Dispatch) => {
  const socketClient = SocketClient.getInstance()
  const sockets = [...socketClient.sockets.keys()]

  sockets.forEach((socket) => {
    socketClient.disconnect(socket)
  })

  dispatch(internalResetAction)
}

export const internalResetAction = {
  type: 'RESET_ALL_STATE',
} as const

const appReducer = combineReducers(reducer)

const reducerWithReset: typeof appReducer = (state, action) => {
  if (action.type === 'RESET_ALL_STATE') {
    return appReducer(undefined, { type: undefined })
  }

  return appReducer(state, action)
}

export const store = configureStore({
  reducer: reducerWithReset,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleware),
})

export type AppStore = typeof store
export type AppState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.getState = store.getState
