import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'

// import { useAppSelector } from '@/core/store'

import {
  // formatDateToDayMonthYearString,
  getProgramDateCardTitle,
} from '@/modules/tv/components/ProgramDatesCard/programDatesCard.helper'

import { GetDatesByChannelIdResponseItem } from '@/modules/tv/tv.types'
import { useAppSelector } from '@/core/store'

interface ProgramDatesCardInnerProps {
  card?: GetDatesByChannelIdResponseItem
  isActive?: boolean
  isChosen?: boolean
}

const ProgramDatesCardInner: FC<ProgramDatesCardInnerProps> = ({ card, isChosen, isActive }) => {
  const currentDate = useAppSelector((state) => state.tvProgram.currentDate)

  return (
    <div
      className={classNames(
        styles.ProgramDatesCardInner,
        isActive && styles.ProgramDatesCardInner__Active,
        isChosen && styles.ProgramDatesCardInner__Chosen,
      )}
    >
      {!card && <SkeletonView />}
      {card && getProgramDateCardTitle(card, currentDate ? currentDate : undefined)}
    </div>
  )
}

export default ProgramDatesCardInner
