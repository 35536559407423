import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  resetTypesFilter,
  setTypesFilter,
} from '@/modules/search/slice/actions/searchState/searchState.contentTypes.actions'

export const contentTypesExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ CONTENT_TYPES
  builder

    .addCase(setTypesFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.type = action.payload.payload
    })
    .addCase(resetTypesFilter, (state, action) => {
      state[action.payload.pageName].searchState.filters.type = null
    })
  //-----------------------------------------------------------
}
