import { appSlice } from '@/modules/app/app.slice'
import { authSlice } from '@/modules/auth/auth.slice'
import { networkSlice } from '@/modules/network/network.slice'
import { menuSlice } from '@/modules/menu/menu.slice'
import { genreApi } from '@/modules/genre/genre.api'
import { contentApi } from '@/modules/content/content.api'
import { contentSlice } from '@/modules/content/content.slice'
import { profileSlice } from '@/modules/profile/profile.slice'
import { serialsSlice } from '@/modules/serials/serials.slice'
import { tvSlice } from '@/modules/tv/tv.slice'
import { categoryApi } from '@/modules/category/category.api'
import { tvApi } from '@/modules/tv/tv.api'
import { tvProgramSlice } from '@/modules/tv/tv.program.slice'
import { streamsApi } from '@/modules/streams/streams.api'
import { favoritesApi } from '@/modules/favorites/favorites.api'
import { countriesApi } from '@/modules/countries/countries.api'
import { partnerApi } from '@/modules/partner/partner.api'
import { profileApi } from '@/modules/profile/profile.api'
import { subscriptionApi } from '@/modules/subscriptions/subscriptions.api'
import { paymentsApi } from '@/modules/payments/paymentsApi'
import { feedsApi } from '@/modules/feeds/feeds.api'
import { tagsApi } from '@/modules/tags/tags.api'
import { promotesApi } from '@/modules/promotes/promotes.api'
import { syncMediaViewApi } from '@/modules/syncMediaView/syncMediaView.api'
import { searchByPageSlice } from '@/modules/search/slice/search.slice'
import { notificationsApi } from '@/modules/notifications/notifications.api'
import { notificationsSlice } from '@/modules/notifications/notifications.slice'
import { devicesApi } from '@/modules/devices/devices.api'

export const reducer = {
  app: appSlice.reducer,
  auth: authSlice.reducer,
  network: networkSlice.reducer,
  menu: menuSlice.reducer,
  content: contentSlice.reducer,
  profile: profileSlice.reducer,
  serial: serialsSlice.reducer,
  tv: tvSlice.reducer,
  tvProgram: tvProgramSlice.reducer,
  searchByPageSlice: searchByPageSlice.reducer,
  notifications: notificationsSlice.reducer,
  [tvApi.reducerPath]: tvApi.reducer,
  [genreApi.reducerPath]: genreApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [streamsApi.reducerPath]: streamsApi.reducer,
  [favoritesApi.reducerPath]: favoritesApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [partnerApi.reducerPath]: partnerApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,
  [feedsApi.reducerPath]: feedsApi.reducer,
  [tagsApi.reducerPath]: tagsApi.reducer,
  [promotesApi.reducerPath]: promotesApi.reducer,
  [syncMediaViewApi.reducerPath]: syncMediaViewApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [devicesApi.reducerPath]: devicesApi.reducer,
}
