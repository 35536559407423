import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'

import PromoSlider from '@/modules/promotes/components/PromoSlider'
import PromoCard from '@/modules/promotes/components/PromoCard'
import Spinner from '@/components/Loaders/Spinner'
import Loader from '@/components/Loader'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/core/store'

import { setChosenCard } from '@/modules/content/content.actions'

import { ContentModel, ContentType, ContentWithPromoteModel } from '@/models/content.model'
import { ContentPaths, Paths } from '@/core/router/router.paths'

interface Props {
  promos?: ContentWithPromoteModel[]
  loading?: boolean
  shouldAutoFocusPromoCard?: boolean
  onAutoFocus?: () => void
}

const RenderPromosSlider: FC<Props> = ({
  promos,
  loading,
  shouldAutoFocusPromoCard = false,
  onAutoFocus,
}) => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const shouldAutoFocusPromoCardRef = useRef(shouldAutoFocusPromoCard)

  useEffect(() => {
    shouldAutoFocusPromoCardRef.current = shouldAutoFocusPromoCard
  }, [shouldAutoFocusPromoCard])

  const handleCardPress = useCallback((item: ContentModel) => {
    if (!item) return

    if (item.type === ContentType.MOVIE) {
      dispatch(setChosenCard(item))
      navigate(`${Paths.CONTENT}/${ContentPaths.MOVIES}/${item.id}`)
      return
    }

    if (item.type === ContentType.SERIAL) {
      dispatch(setChosenCard(item))
      navigate(`${Paths.CONTENT}/${ContentPaths.SERIALS}/${item.id}`)
    }
  }, [])

  const children = useMemo(() => {
    if (!promos) return [0, 0, 0].map((_, index) => <PromoCard key={index} />)

    return promos.map((promo, index) => {
      return (
        <PromoCard<ContentWithPromoteModel>
          autoFocus={shouldAutoFocusPromoCardRef.current && index === 0}
          key={promo.id}
          card={promo}
          onCardPress={handleCardPress}
          onCardFocus={
            onAutoFocus && shouldAutoFocusPromoCardRef.current && index === 0
              ? onAutoFocus
              : undefined
          }
        />
      )
    })


    // return [
    //   <BannerPromoCard
    //     key={'banner'}
    //     autoFocus={shouldAutoFocusPromoCardRef.current}
    //     onCardFocus={onAutoFocus && shouldAutoFocusPromoCardRef.current ? onAutoFocus : undefined}
    //   />,
    //   ...preparedPromos,
    // ]
  }, [promos, onAutoFocus])
  return (
    <>
      {loading && (
        <Loader>
          <Spinner />
        </Loader>
      )}
      <PromoSlider>{children}</PromoSlider>
    </>
  )
}

export default memo(RenderPromosSlider)
