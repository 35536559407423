import React, { FC } from 'react'

import styles from './styles.module.scss'

import Button, { type Button as ButtonProps } from '@/components/Button'
import classNames from 'classnames'

interface SearchKeyboardButton extends ButtonProps {
  doubleSize?: boolean
  tripleSize?: boolean
  sixSize?: boolean
}

const KeyboardButton: FC<SearchKeyboardButton> = ({
  buttonClassName,
  focusClassName,
  focusWrapperClassName,
  ...props
}) => {
  return (
    <Button
      focusWrapperClassName={classNames(focusWrapperClassName, styles.FocusWrapper, {
        [styles.FocusWrapper__DoubleSize]: props.doubleSize,
        [styles.FocusWrapper__TripleSize]: props.tripleSize,
        [styles.FocusWrapper__SixSize]: props.sixSize,
      })}
      buttonClassName={classNames(buttonClassName, styles.KeyButton)}
      focusClassName={classNames(focusClassName, styles.FocusedButton)}
      {...props}
    />
  )
}

export default KeyboardButton
