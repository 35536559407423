import React from 'react'

import styles from './styles.module.scss'
import { cabinetMenuItems } from '@/modules/cabinet/cabinet.menu.constants'
import CabinetMenuItem from '@/modules/cabinet/components/CabinetMenuItem'

const CabinetMenu = () => {
  return (
    <div className={styles.CabinetMenu}>
      {cabinetMenuItems.map(({ title, navigatePath }, index) => {
        return <CabinetMenuItem key={index} title={title} navigatePath={navigatePath} />
      })}
    </div>
  )
}

export default CabinetMenu
