import axios from 'axios'
import {
  PREMIER_STATISTICS_API_URL,
  PREMIER_STATISTICS_HB_LG_PATH,
  PREMIER_STATISTICS_HB_SAMSUNG_PATH,
  PREMIER_STATISTICS_VIEW_SAMSUNG_PATH,
  PREMIER_STATISTICS_VIEW_LG_PATH,
} from '@/core/config'
import PlatformService from '@/core/services/platform.service'
import { Platform } from '@/models/platform.model'

const instance = axios.create({ baseURL: `${PREMIER_STATISTICS_API_URL}/` })

interface PremierStatViewDTO {
  content_id: string
  user_id: string
  view_id: string
  user_agent_id: string | null
}

interface PremierStatHBDTO {
  view_id: string
  position: number
  secs: number
}

export const premierStatisticsApi = {
  sendView: async (data: PremierStatViewDTO) => {
    if (PlatformService.platform === Platform.TIZEN) {
      if (!PREMIER_STATISTICS_VIEW_SAMSUNG_PATH) return
      return instance.post(PREMIER_STATISTICS_VIEW_SAMSUNG_PATH, data).catch(console.log)
    }
    if (PlatformService.platform === Platform.WEB_OS) {
      if (!PREMIER_STATISTICS_VIEW_LG_PATH) return
      return instance.post(PREMIER_STATISTICS_VIEW_LG_PATH, data).catch(console.log)
    }
    if (PlatformService.platform === Platform.LOCAL) {
      console.log('hb info:')
      console.table(data)
    }
  },
  sendHB: async (data: PremierStatHBDTO) => {
    if (PlatformService.platform === Platform.TIZEN) {
      if (!PREMIER_STATISTICS_HB_SAMSUNG_PATH) return
      return instance.post(PREMIER_STATISTICS_HB_SAMSUNG_PATH, data).catch(console.log)
    }
    if (PlatformService.platform === Platform.WEB_OS) {
      if (!PREMIER_STATISTICS_HB_LG_PATH) return
      return instance.post(PREMIER_STATISTICS_HB_LG_PATH, data).catch(console.log)
    }
    if (PlatformService.platform === Platform.LOCAL) {
      console.log('hb info:')
      console.table(data)
    }
  },
}
