import React from 'react'

import Info from '@/modules/content/components/Info'

import { getAgeFromContentCard } from '@/modules/content/content.helpers'

import { ContentModel } from '@/models/content.model'

interface AgeInfoProps {
  card?: ContentModel
  className?: string
}

const AgeInfo = ({ card, className }: AgeInfoProps) => {
  const age = getAgeFromContentCard(card)
  if (!age) return null
  return <Info infoText={age} className={className} />
}

export default AgeInfo
