import React, { FC, useCallback, useRef, useState } from 'react'

import DefaultScrollingWrapper, {
  DefaultScrollingWrapperProps,
  OnChildFocus,
} from '@/components/Slider/DefaultScrollingWrapper'

export type OmittedDefaultScrollingWrapperProps = Omit<DefaultScrollingWrapperProps, 'onChildFocus'>

interface LoopRowScrollingWrapperProps extends OmittedDefaultScrollingWrapperProps {
  setFocus?: (focusKey: string) => void
  getCurrentFocusKey?: () => string
}

const LoopRowScrollingWrapper: FC<LoopRowScrollingWrapperProps> = ({
  className,
  contentWrapperClassName,
  onChildPress,
  children,
}) => {
  const [currentIndex, setCurrentIndex] = useState(2)
  const scrollingRef = useRef<HTMLDivElement | null>(null)
  const firstRender = useRef<boolean>(true)

  const handleChildFocus: OnChildFocus = useCallback(
    (...args) => {
      const layout = args[0]
      const details = args[2]
      const scrollIndex = args[3]
      //
      const {
        /*x*/
        node,
      } = layout

      const scrollNode = scrollingRef.current
      if (!scrollNode) return

      const { event } = details
      const typedEvent = event as KeyboardEvent

      if (firstRender.current) {
        firstRender.current = false
        scrollIndex !== undefined && setCurrentIndex(scrollIndex)
        scrollNode.scrollLeft = node.clientWidth * 2
        return
      }

      if (!(scrollIndex !== undefined && currentIndex !== scrollIndex)) return

      setCurrentIndex(scrollIndex)

      // if (typedEvent && typedEvent.key && typedEvent.key === 'ArrowRight') {
      //   scrollNode.scrollLeft = node.clientWidth
      //   scrollNode.scrollTo({
      //     left: node.clientWidth * 2,
      //     behavior: 'smooth',
      //   })
      //   return
      // }

      if (typedEvent && typedEvent.key && typedEvent.key === 'ArrowLeft') {
        scrollNode.scrollLeft = node.clientWidth * 3
        scrollNode.scrollTo({
          left: node.clientWidth * 2,
          behavior: 'smooth',
        })
        return
      }

      scrollNode.scrollLeft = node.clientWidth
      scrollNode.scrollTo({
        left: node.clientWidth * 2,
        behavior: 'smooth',
      })
    },
    [scrollingRef.current, currentIndex],
  )

  const childrenForRender = React.useMemo(() => {
    let renderList = children
    if (children.length < 5) {
      const multiply = Math.ceil(5 / children.length)
      renderList = new Array(multiply)
        .fill(children)
        .flat()
        .map((el, index) => {
          return { ...el, key: index }
        })
    }
    const prev = +currentIndex - 1 < 0 ? renderList.length - 1 : +currentIndex - 1
    const first = prev - 1 < 0 ? renderList.length - 1 : prev - 1
    const next = +currentIndex + 1 > renderList.length - 1 ? 0 : +currentIndex + 1
    const last = next + 1 > renderList.length - 1 ? 0 : next + 1
    return [
      renderList[first],
      renderList[prev],
      renderList[currentIndex],
      renderList[next],
      renderList[last],
    ]
  }, [children, currentIndex])

  return (
    <DefaultScrollingWrapper
      ref={scrollingRef}
      className={className}
      contentWrapperClassName={contentWrapperClassName}
      onChildFocus={handleChildFocus}
      onChildPress={onChildPress}
    >
      {childrenForRender}
    </DefaultScrollingWrapper>
  )
}

export default LoopRowScrollingWrapper
