export enum NotificationModalEvents {
  OPENED = 'notificationOpened',
  CLOSED = 'notificationClosed',
}

const notificationModalOpenedEvent = new Event(NotificationModalEvents.OPENED)
export const dispatchNotificationModalOpenedEvent = () => {
  document.dispatchEvent(notificationModalOpenedEvent)
}

const notificationModalClosedEvent = new Event(NotificationModalEvents.CLOSED)
export const dispatchNotificationModalClosedEvent = () => {
  document.dispatchEvent(notificationModalClosedEvent)
}
