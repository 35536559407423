import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  addSymbolToSearchTerm,
  removeLastSymbolFromSearchTerm,
  resetSearchTerm,
  setSearchTerm,
} from '@/modules/search/slice/actions/searchState/searchState.term.actions'

export const searchTermExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ SEARCH TERM
  builder
    .addCase(addSymbolToSearchTerm, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.searchTerm = state[
          action.payload.pageName
        ].searchState.searchTerm.concat(action.payload.payload)
    })
    .addCase(removeLastSymbolFromSearchTerm, (state, action) => {
      state[action.payload.pageName].searchState.searchTerm = state[
        action.payload.pageName
      ].searchState.searchTerm.slice(
        0,
        state[action.payload.pageName].searchState.searchTerm.length - 1,
      )
    })
    .addCase(setSearchTerm, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.searchTerm = action.payload.payload
    })
    .addCase(resetSearchTerm, (state, action) => {
      state[action.payload.pageName].searchState.searchTerm = ''
    })

  //-----------------------------------------------------------
}
