import Manager, { ContainerType, Events } from './event.manager'
import { AlertProps } from '@/components/Notifications/components/Alerts/Alert'
import { TypeOptions } from '@/components/Notifications/types'

let queue: { id: string; props: AlertProps }[] = []

const getDefaultAlertProps = (): AlertProps => {
  return {
    theme: 'dark',
    type: 'default',
    preventExitTransition: false,
    message: '',
  }
}

const onContainerMountCallback = () => {
  queue.forEach(({ id, props }) => {
    Manager?.setToast(id, props).emit(Events.ADD_ALERT, id, props)
  })
  queue = []
}

Manager?.on(Events.ALERTS_CONTAINER_MOUNTED, onContainerMountCallback)

const dispatchAlert = (alertProps: Partial<AlertProps> = {}) => {
  const props = { ...getDefaultAlertProps(), ...alertProps }
  const id = `${Date.now()}`
  const length = Manager?.getContainersLengthByType(ContainerType.ALERT_CONTAINER)
  if (length === 0) {
    queue.push({ id, props })
  }
  Manager?.setToast(id, props).emit(Events.ADD_ALERT, id, props)
}

const createAlertByType = (type: TypeOptions) => {
  return (alertProps: Partial<AlertProps> = {}) => {
    const props = { ...alertProps, type }
    dispatchAlert(props)
  }
}

const deleteAlertByID = (id: string) => {
  Manager?.deleteToast(id)?.emit(Events.DELETE_ALERT, id)
}

function alert(alertProps: Partial<AlertProps>) {
  return dispatchAlert(alertProps)
}

const info = createAlertByType('info')
const success = createAlertByType('success')
const warning = createAlertByType('warning')
const error = createAlertByType('error')

export default Object.assign(alert, { info, success, warning, error, delete: deleteAlertByID })
