import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { SessionModel } from '@/models/session.model'

export const devicesApi = createApi({
  reducerPath: 'devicesApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllSessions: builder.query<SessionModel[], void>({
      query: () => ({
        url: '/auth/client/me/sessions',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllSessionsQuery } = devicesApi
