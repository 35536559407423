import React, { memo, useCallback, useEffect, useRef } from 'react'

import FetchAndRenderAllTags from '@/modules/tags/components/FetchAndRenderAllTags'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { TagModel } from '@/models/tag.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  addTagToTagsFilter,
  removeTagFromTagsFilter,
} from '@/modules/search/slice/actions/searchState/searchState.tags.actions'

const FilterByTags = ({ pageName = 'search' }: { pageName?: PagesWithSearch }) => {
  const dispatch = useAppDispatch()

  const tags = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.filters.tags)

  const tagsRef = useRef(tags)

  useEffect(() => {
    tagsRef.current = tags
  }, [tags])

  const handlePressTag = useCallback((card: TagModel) => {
    if (!card) return
    const tags = tagsRef.current
    if (!tags) return

    const isCardInTags = !!tags.find((el) => el.id === card.id)

    if (isCardInTags) {
      dispatch(removeTagFromTagsFilter({ pageName, payload: card }))
      return
    }

    dispatch(addTagToTagsFilter({ pageName, payload: card }))
  }, [])

  return <FetchAndRenderAllTags onCardPress={handlePressTag} />
}

export default memo(FilterByTags)
