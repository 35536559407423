import React from 'react'

import styles from './styles.module.scss'

import SpinnerImage from '@/assets/images/loaders/loader-0.svg'

const Spinner = () => {
  return (
    <div className={styles.Spinner}>
      <img src={SpinnerImage} alt='Indicator' />
    </div>
  )
}

export default Spinner
