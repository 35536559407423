import {
  AppInfo,
  NetworkInfo,
  Platform,
  PlatformInfo,
  ProductInfo,
  SystemInfo,
} from '@/models/platform.model'
import PlatformService from '@/core/services/platform.service'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AppSlice {
  platform: Platform | null
  info: {
    app?: Partial<AppInfo> | null
    product?: Partial<ProductInfo> | null
    system?: Partial<SystemInfo> | null
    network?: Partial<NetworkInfo> | null
  }
  application: {
    type: 'ITV_3'
  }
}

const initialState: AppSlice = {
  platform: PlatformService.platform,
  info: {
    app: null,
    network: null,
    product: null,
    system: null,
  },
  application: {
    type: 'ITV_3',
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppState: (
      state,
      action: PayloadAction<{ platform?: Platform; info: Partial<PlatformInfo> }>,
    ) => {
      state.info = action.payload.info
      if (action.payload.platform) state.platform = action.payload.platform
    },
  },
  extraReducers: () => {},
})

export const { setAppState } = appSlice.actions
