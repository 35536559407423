import React, { FC } from 'react'

import { getActorsFromContent, getGenresFromContent } from '@/modules/content/content.helpers'

import { useAppSelector } from '@/core/store'

interface ActorsAndGenresProps {
  className?: string
}

const ActorsAndGenres: FC<ActorsAndGenresProps> = ({ className }) => {
  const card = useAppSelector((state) => state.content.chosenCard)
  return (
    <>
      {getActorsFromContent(card) && (
        <div className={className}>
          Актерский состав: <br /> {getActorsFromContent(card)}
        </div>
      )}
      {getGenresFromContent(card) && (
        <div className={className}>
          Жанры: <br /> {getGenresFromContent(card)}
        </div>
      )}
    </>
  )
}

export default ActorsAndGenres
