import { MutableRefObject, useEffect, useRef } from 'react'

interface Props<R extends HTMLElement = HTMLElement> {
  observedRef: MutableRefObject<R | null>
  focused: boolean
  hasFocusedChild: boolean
  focusSelf: () => void
}

export const useMutationObserverForListsWithDynamicLength = <R extends HTMLElement>({
  focused,
  hasFocusedChild,
  observedRef,
  focusSelf,
}: Props<R>) => {
  const hasFocusedChildRef = useRef(hasFocusedChild)
  const focusedRef = useRef(focused)

  useEffect(() => {
    hasFocusedChildRef.current = hasFocusedChild
  }, [hasFocusedChild])

  useEffect(() => {
    focusedRef.current = focused
  }, [focused])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (!focusedRef.current) return
      if (hasFocusedChildRef.current) return
      if (!observedRef.current) return

      const target = observedRef.current

      const childrenLength = target.childNodes.length

      if (!(childrenLength > 0)) return

      focusSelf()
    })

    observedRef.current && observer.observe(observedRef.current, { childList: true })

    return () => {
      observer.disconnect()
    }
  }, [])
}
