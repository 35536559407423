import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { CountryBaseModel } from '@/models/country.model'
import { UnionContentType } from '@/modules/promotes/promotes.api'

export const countriesApi = createApi({
  reducerPath: 'countriesApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAllCountries: builder.query<CountryBaseModel[], void>({
      query: () => ({
        url: '/countries',
        method: 'GET',
      }),
    }),
    getCountriesHasContentByType: builder.query<CountryBaseModel[], { type?: UnionContentType }>({
      query: ({ type = 'contents' }) => ({
        url: `/countries/has/${type}`,
        method: 'GET',
      }),
    }),

    getClientsCountriesHasContentByType: builder.query<
      CountryBaseModel[],
      { type?: UnionContentType }
    >({
      query: ({ type = 'contents' }) => ({
        url: `/clients/countries/has/${type}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAllCountriesQuery, useGetClientsCountriesHasContentByTypeQuery } = countriesApi
