import React, { useCallback, useEffect } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'

import Button from '@/components/Button'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { NotificationTypes } from '@/models/notification.model'
import { resetFilter, setFilter } from '@/modules/notifications/notifications.slice'

interface Props {
  disabled?: boolean
}

const NotificationsFilters = ({ disabled }: Props) => {
  const dispatch = useAppDispatch()

  const handleSetCurrentFilter = useCallback((curr: null | NotificationTypes) => {
    dispatch(setFilter(curr))
  }, [])

  const currentFilter = useAppSelector((state) => state.notifications.currentFilter)
  const filters = useAppSelector((state) => state.notifications.filters)

  useEffect(() => {
    return () => {
      dispatch(resetFilter())
    }
  }, [])

  return (
    <div className={styles.Filters}>
      {filters.map((el, index) => {
        return (
          <Button
            key={index}
            disabled={disabled}
            buttonClassName={classNames(styles.FilterAction, {
              [styles.FilterAction__Active]: currentFilter === el.value,
            })}
            focusClassName={styles.FilterAction__Focused}
            onButtonClick={() => handleSetCurrentFilter(el.value)}
            onEnterPress={() => handleSetCurrentFilter(el.value)}
          >
            {el.label}
          </Button>
        )
      })}
    </div>
  )
}

export default NotificationsFilters
