import React, { FC, useEffect, useRef } from 'react'

import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'

interface LoadMoreResourcesProps {
  onLoadMorePress?: () => void
}

const LoadMoreResources: FC<LoadMoreResourcesProps> = ({ onLoadMorePress }) => {
  const onLoadMoreRef = useRef<() => void>()

  useEffect(() => {
    onLoadMoreRef.current = onLoadMorePress
  }, [onLoadMorePress])

  useEffect(() => {
    const load = document.getElementById('load-more-resources-button')
    const footer = document.getElementById('footer-container')

    if (!footer || !load) return

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.length === 0) return

        const entry = entries[0]

        if (!(entry.intersectionRatio > 0)) return
        if (!onLoadMoreRef.current) return

        onLoadMoreRef.current()
      },
      { root: footer, threshold: [0.1] },
    )
    // начать наблюдение
    intersectionObserver.observe(load)

    return () => {
      intersectionObserver.unobserve(load)
      intersectionObserver.disconnect()
    }
  }, [])
  return (
    <div id={'load-more-resources-button'} className={styles.LoadMoreButtonWrapper}>
      <div className={styles.SkeletonWrapper}>
        <div className={styles.SkeletonTitle}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonBody}>
          <div className={styles.SkeletonCard}>
            <SkeletonView />
          </div>
          <div className={styles.SkeletonCard}>
            <SkeletonView />
          </div>
          <div className={styles.SkeletonCard}>
            <SkeletonView />
          </div>
          <div className={styles.SkeletonCard}>
            <SkeletonView />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadMoreResources
