import React, { FC } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'
import FavoritesAction from '@/modules/favorites/components/FavoritesAction'
import { ContentType } from '@/models/content.model'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'
import PlayerManager from '@/modules/player/player.manager'
import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

interface MoviePlayerOverlayActionsProps {
  changeProgress?: (value: string | number) => void
  togglePlayPause?: () => Promise<void>
  isPaused: boolean
}

const MoviePlayerOverlayActions: FC<MoviePlayerOverlayActionsProps> = ({
  togglePlayPause,
  isPaused,
  changeProgress,
}) => {
  const handleReplay = () => {
    changeProgress?.(0)
  }
  const handlePlayPause = () => {
    togglePlayPause?.()
  }

  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isUserActive = usePlayerStateSelector((state) => state.userActivity.isUserActive)
  return (
    <div className={styles.Actions}>
      <Button
        disabled={!isUserActive && !isPaused}
        onButtonClick={handlePlayPause}
        onEnterPress={handlePlayPause}
        buttonClassName={styles.Button}
      >
        <>
          <i className={isPaused ? 'icon-fill-play' : 'icon-fill-pause'} />
          <span data-title={'title'}>{isPaused ? 'Продолжить' : 'Остановить' + ''}</span>
        </>
      </Button>
      <Button
        disabled={!isUserActive && !isPaused}
        onButtonClick={handleReplay}
        onEnterPress={handleReplay}
        buttonClassName={styles.Button}
      >
        <>
          <i className='icon-fill-at-first' />
          <span data-title={'title'}>Смотреть сначала</span>
        </>
      </Button>
      <FavoritesAction
        disabled={!isUserActive && !isPaused}
        withText
        favoritesContentType={ContentType.MOVIE}
        className={styles.Button}
      />
    </div>
  )
}

export default MoviePlayerOverlayActions
