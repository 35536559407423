import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import ImageLoader from '@/components/Image'
import SkeletonView from '@/components/SkeletonView'
import ShortProgramList from '@/modules/tv/components/ShortProgramList'
import FavoritesChannelsAction from '@/modules/favorites/components/FavoritesChannelsAction'
import ProgramListAction from '@/modules/tv/components/ProgramListAction'

import { useAppSelector } from '@/core/store'

import { getChannelLogoUrl, getChannelTitle } from '@/modules/tv/tv.helpers'
import ChannelsListSidebarAction from '@/modules/tv/components/ChannelsListSidebarAction'

const ProgramContainer = () => {
  const focusedChannel = useAppSelector((state) => state.tv.focusedChannel)
  return (
    <div className={styles.ProgramContainer}>
      <div className={styles.ChannelTitle}>
        <div className={styles.ChannelLogo}>
          <ImageLoader src={getChannelLogoUrl(focusedChannel)} skeleton />
        </div>
        <h1>{getChannelTitle(focusedChannel) || <SkeletonView />}</h1>
      </div>
      <ShortProgramList />
      <div className={styles.Actions}>
        <ProgramListAction triggerClassName={styles.ActionButton} />
        <ChannelsListSidebarAction
          triggerClassName={classNames(styles.ActionButton, styles.ActionButton__Icon)}
        />
        <FavoritesChannelsAction
          className={classNames(styles.ActionButton, styles.ActionButton__Icon)}
          withText={false}
        />
      </div>
    </div>
  )
}

export default ProgramContainer
