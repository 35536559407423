import { getInitialPageState, SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  updatePageState,
  partialUpdatePageState,
  resetPageState,
} from '@/modules/search/slice/actions/page/page.actions'

export const pageExtraReducers = (builder: SearchSliceBuilderType) => {
  builder

    .addCase(updatePageState, (state, action) => {
      if (action.payload.payload) state[action.payload.pageName] = action.payload.payload
    })

    .addCase(partialUpdatePageState, (state, action) => {
      state[action.payload.pageName] = {
        ...state[action.payload.pageName],
        ...action.payload.payload,
      }
    })
    .addCase(resetPageState, (state, action) => {
      state[action.payload.pageName] = getInitialPageState()
    })
}
