import { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'

import SocketClient from '@/core/sockets/client'
import { useAppSelector } from '@/core/store'
import { getFromLocalStorage } from '@/core/utils'
import { MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY, SOCKET_MEDIA_EVENT_URL } from '@/core/config'

import { EventTypes, useSendStatistic } from '@/modules/player/hooks/useSendStatistics'
import { useSendStatisticsCallbacks } from '@/modules/player/hooks/useSendStatisticsCallbacks'

import { ClientMediaEventSocketMessage } from '@/core/sockets/messages'

import type { ContentModel } from '@/models/content.model'
import type { MediaEventsOptions } from '@/core/sockets/connect.subsciptions'
import type { SendHBOptions } from '@/modules/player/hooks/useSendStatistics'
import type { ClientViewContentDto } from '@/core/sockets/dto/clientViewContent.dto'
import type { EventsRenderParameters } from '@/modules/player/Player/EventsRenderParams.type'

export interface PlayerMediaEventManagerProps extends Partial<EventsRenderParameters> {
  disabled?: boolean

  content: ContentModel | null

  children: (params: Partial<EventsRenderParameters>) => ReactElement
}

const socketClient = SocketClient.getInstance()

const OPTIONS = getFromLocalStorage<MediaEventsOptions>(MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY)

const HB_BEFORE_VIEW_POINT = OPTIONS?.beforeViewContentHb || 30
const VIEW_POINT = OPTIONS?.viewContentPoint || 60
const HB = OPTIONS?.afterViewContentHb || 300

export const PlayerMediaEventManager = ({
  disabled: extDisabled,
  content,
  ...props
}: PlayerMediaEventManagerProps) => {
  const disabled = useMemo(() => !!extDisabled, [extDisabled])
  const disabledRef = useRef(disabled)
  disabledRef.current = disabled

  const client = useAppSelector((state) => state.auth.auth.data.loginData?.user?.client)
  const clientRef = useRef(client)
  clientRef.current = client

  const contentRef = useRef(content)
  contentRef.current = content

  const videoElRef = useRef<HTMLVideoElement | null>(null)

  const groupIdRef = useRef<string | null>(null)

  const sendHB = useCallback(
    ({ totalCountRef, isViewPointAchievedRef, resetCounter, counterRef, type }: SendHBOptions) => {
      if (disabledRef.current) return

      if (totalCountRef.current >= VIEW_POINT && !isViewPointAchievedRef.current) {
        isViewPointAchievedRef.current = true
      }

      const client = clientRef.current
      if (!client) return

      const content = contentRef.current
      if (!content) return

      const duration = counterRef.current
      if (
        duration === 0 &&
        type !== EventTypes.MANUAL_PLAY &&
        type !== EventTypes.MANUAL_STOP &&
        type !== EventTypes.MANUAL_PAUSE
      )
        return

      if (duration < HB_BEFORE_VIEW_POINT && totalCountRef.current < HB_BEFORE_VIEW_POINT) return

      socketClient.emit<ClientViewContentDto>(
        SOCKET_MEDIA_EVENT_URL,
        ClientMediaEventSocketMessage.VIEW_CONTENT,
        {
          client,
          content,
          event: { id: groupIdRef.current || undefined, duration, type },
        },
      )

      resetCounter()
    },
    [],
  )

  const {
    resetCounterInterval,
    resetTotalCount,
    resetIsViewPointAchieved,
    startCount,
    resetCounter,
    send,
  } = useSendStatistic({
    sendHBCallback: sendHB,
    sendHBBeforeCallback: sendHB,
    sendViewCallback: sendHB,
    hb: HB,
    hbBefore: HB_BEFORE_VIEW_POINT,
    viewPoint: VIEW_POINT,
  })

  const callbacks = useSendStatisticsCallbacks({
    videoElRef,
    send,
    startCount,
    resetCounterInterval,
    resetCounter,
    resetIsViewPointAchieved,
    resetTotalCount,
    ...props,
  })

  const handleViewContent = useCallback((res: { id: string }) => {
    if (!res) return
    if (!res.id) return

    groupIdRef.current = res.id
  }, [])

  // const handleReconnect = useCallback(() => {
  //   socketClient.emit(SOCKET_MEDIA_EVENT_URL, ClientMediaEventSocketMessage.JOIN_CONTENT, {
  //     content: contentRef.current,
  //   })
  // }, [])

  // useEffect(() => {
  //   socketClient.on(SOCKET_MEDIA_EVENT_URL, 'reconnect', handleReconnect)
  //
  //   return () => {
  //     socketClient.off(SOCKET_MEDIA_EVENT_URL, 'reconnect', handleReconnect)
  //   }
  // }, [])

  useEffect(() => {
    if (!content) return

    // socketClient.emit(SOCKET_TV_EVENT_URL, ClientMediaEventSocketMessage.JOIN_CONTENT, { content })
    socketClient.on(
      SOCKET_MEDIA_EVENT_URL,
      ClientMediaEventSocketMessage.VIEW_CONTENT,
      handleViewContent,
    )

    // reset before start count
    groupIdRef.current = null
    resetCounter()
    resetTotalCount()
    resetIsViewPointAchieved()

    return () => {
      resetCounterInterval()
      send(EventTypes.MANUAL_STOP)

      // socketClient.emit(SOCKET_MEDIA_EVENT_URL, ClientMediaEventSocketMessage.LEAVE_CONTENT, { content })
      socketClient.off(
        SOCKET_MEDIA_EVENT_URL,
        ClientMediaEventSocketMessage.VIEW_CONTENT,
        handleViewContent,
      )
    }
  }, [content])

  const renderParams: Partial<EventsRenderParameters> = useMemo(() => {
    return {
      ...props,
      ...callbacks,
    }
  }, [callbacks])

  return props.children(renderParams)
}
