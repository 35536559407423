import React, { ChangeEvent, FC, memo, MouseEvent, useCallback, useEffect, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useFocusable, UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation'

import { EnterPressHandler } from '@noriginmedia/norigin-spatial-navigation/dist/useFocusable'

export interface CheckboxProps extends UseFocusableConfig {
  name?: string

  className?: string
  checkboxClassName?: string
  labelClassName?: string

  checked?: boolean
  disabled?: boolean

  onClick?: (e?: MouseEvent<HTMLLabelElement>) => void
  onChange?: (checked: boolean) => void

  autoFocus?: boolean
  rounded?: boolean
  // focusProps?: UseFocusableConfig
}

const Checkbox: FC<CheckboxProps> = ({
  className,
  labelClassName,
  checkboxClassName,
  disabled,
  name,
  onClick,
  checked,
  onChange,
  autoFocus,
  rounded,
  ...focusProps
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleEnterPress: EnterPressHandler = useCallback(
    (...args) => {
      focusProps?.onEnterPress?.(...args)
      const input = inputRef.current
      if (!input) return

      input.click()
    },
    [focusProps.onEnterPress],
  )

  const { ref, focusKey, focused, focusSelf } = useFocusable({
    ...focusProps,
    onEnterPress: handleEnterPress,
    focusable: !disabled && focusProps.focusable,
  })

  useEffect(() => {
    if (!autoFocus) return
    focusSelf()
  }, [autoFocus])

  const handleOnClick = useCallback(
    (e?: MouseEvent<HTMLLabelElement>) => {
      !disabled && focusSelf()
      onClick?.(e)
    },
    [onClick, disabled],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked
      onChange?.(checked)
    },
    [onChange],
  )
  return (
    <div
      ref={ref}
      id={focusKey}
      className={classNames(className, styles.Checkbox, {
        [styles.Checkbox__Focused]: focused,
        [styles.Checkbox__Disabled]: disabled,
      })}
    >
      <label
        className={classNames(labelClassName, styles.Label, {
          [styles.Rounded]: rounded,
        })}
        onClick={handleOnClick}
      >
        <input
          ref={inputRef}
          type='checkbox'
          className={classNames(checkboxClassName, styles.Input)}
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
        />
        {name && <span>{name}</span>}
      </label>
    </div>
  )
}

export default memo(Checkbox)
