import React, { cloneElement, forwardRef, ReactElement, useCallback } from 'react'

import { VariableSizeList as List } from 'react-window'
import { OnChildFocus, OnChildPress } from '@/components/Slider/DefaultScrollingWrapper'

export interface VirtualScrollingWrapperProps {
  children: ReactElement[]
  className?: string
  contentWrapperClassName?: string

  onChildPress?: OnChildPress
  onChildFocus?: OnChildFocus

  layout?: 'vertical' | 'horizontal'

  height?: number
  width?: number
  overscanCount?: number
}

const VirtualScrollingWrapper = forwardRef<HTMLDivElement, VirtualScrollingWrapperProps>(
  (
    { className, overscanCount, height, width, layout, children, onChildFocus, onChildPress },
    ref,
  ) => {
    const onChildFocusLocal: OnChildFocus = useCallback(
      (layout, props, details, scrollIndex) => {
        onChildFocus?.(layout, props, details, scrollIndex)
      },
      [onChildFocus],
    )

    const onChildLocalPress: OnChildPress = useCallback(
      (props, details) => {
        onChildPress?.(props, details)
      },
      [onChildPress],
    )

    const getItemSize = useCallback(
      (index: number): number => {
        if (layout && layout === 'vertical') {
          return children[index].props.height
        }
        return children[index].props.width
      },
      [children, layout],
    )
    if (children.length < 2) return

    return (
      <List
        outerRef={ref}
        className={className}
        overscanCount={overscanCount || 1}
        layout={layout || 'horizontal'}
        height={height || 500}
        width={width || 1920}
        itemSize={getItemSize}
        itemCount={children.length}
        itemData={children}
      >
        {({ index, data, style }) => {
          const child = data[index] as ReactElement
          return cloneElement(child, {
            ...child.props,
            onFocus: onChildFocusLocal,
            onPress: onChildLocalPress,
            scrollIndex: index,
            style: { ...style, margin: (!layout || layout === 'horizontal') && '0 0 0 170px' },
          })
        }}
      </List>
    )
  },
)

VirtualScrollingWrapper.displayName = 'VirtualScrollingWrapper'

export default VirtualScrollingWrapper
