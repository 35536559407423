import React from 'react'

import styles from './styles.module.scss'

import CategorySlider from '@/modules/category/components/CategorySlider'

import { useAppDispatch } from '@/core/store'

import { resetChosenCategory, setChosenCategory } from '@/modules/tv/tv.actions'

import { CategoryModel } from '@/models/category.model'

const FilterByCategory = () => {
  const dispatch = useAppDispatch()

  const handleCardPress = (category?: CategoryModel) => {
    if (!category) return
    if (!category.id) {
      dispatch(resetChosenCategory())
      return
    }

    dispatch(setChosenCategory(category))
  }
  return (
    <div className={styles.FilterByCategory}>
      <CategorySlider onCardPress={handleCardPress} />
    </div>
  )
}

export default FilterByCategory
