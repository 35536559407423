import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { FocusDetails } from '@noriginmedia/norigin-spatial-navigation'

const THROTTLE_SCROLL_DELAY = 150

export const useHandleWheelScroll = (
  virtualScrollingRef: MutableRefObject<HTMLDivElement | null>,
  navigateByDirection?: (direction: string, focusDetails: FocusDetails) => void,
  scrollDelay?: number,
): void => {
  const disableScrollRef = useRef<boolean>(false)

  const handleWheelScroll: React.WheelEventHandler<HTMLDivElement> = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    if (!navigateByDirection) return
    if (disableScrollRef.current) return

    // UP
    if (e.deltaY < 0) {
      navigateByDirection('up', {})
    }

    // DOWN
    if (e.deltaY > 0) {
      navigateByDirection('down', {})
    }

    disableScrollRef.current = true

    const id = setTimeout(() => {
      disableScrollRef.current = false
      clearTimeout(id)
    }, scrollDelay || THROTTLE_SCROLL_DELAY)
  }, [])

  useEffect(() => {
    const outer = virtualScrollingRef.current
    if (!outer) return

    outer.addEventListener('mousewheel', handleWheelScroll as unknown as EventListener)

    return () => {
      outer.removeEventListener('mousewheel', handleWheelScroll as unknown as EventListener)
    }
  }, [virtualScrollingRef.current, handleWheelScroll])
}
