import React, { FC, memo } from 'react'

import styles from './styles.module.scss'

import ChannelsSlider, { ChannelsSliderProps } from '@/modules/tv/components/ChannelsSlider'

import { FeedsResourceBaseModel } from '@/models/feeds.resource.model'
import { ChannelModel } from '@/models/channel.model'

interface Props extends ChannelsSliderProps {
  resource: FeedsResourceBaseModel
  channels: ChannelModel[]
}

const ChannelsFromResource: FC<Props> = ({ resource, channels, ...props }) => {
  if (!channels || !channels.length) return null

  return (
    <ChannelsSlider
      className={styles.HeightWrapper}
      data={channels}
      // isLoading={loading}
      rowProps={{ title: resource.title }}
      {...props}
    />
  )
}

export default memo(ChannelsFromResource)
