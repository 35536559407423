import React, { useMemo } from 'react'

import styles from './styles.module.scss'

import Filters from '@/modules/search/components/Filters'
import { useParams } from 'react-router-dom'
import { useGetGenreQuery } from '@/modules/genre/genre.api'

const GenreFilters = () => {
  const { id } = useParams()

  const { data: genre } = useGetGenreQuery(
    { genreId: id || '' },
    {
      skip: !id,
    },
  )

  const chosenGenres = useMemo(() => {
    if (!id || !genre) return

    return [genre]
  }, [id, genre])

  return <Filters pageName={'genres'} className={styles.Filters} chosenGenres={chosenGenres} />
}

export default GenreFilters
