import React from 'react'

import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'

const Skeleton = ({ height }: { height?: string }) => {
  return (
    <div className={styles.SkeletonWrapper} style={{ height }}>
      <div className={styles.SkeletonTitle}>
        <SkeletonView />
      </div>
      <div className={styles.SkeletonBody}>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
      </div>
    </div>
  )
}

export default Skeleton
