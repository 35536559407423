import React, { lazy } from 'react'

import { RouteObject } from 'react-router-dom'

import EmptyLayout from '@/layouts/empty'
import SuspenseWithLoaderFallback from '@/components/SuspenseWithLoaderFallback'
// import Login from '@/modules/auth/views/Login'
const Login = lazy(() => import(/* webpackChunkName: "Login" */ '@/modules/auth/views/Login'))
// import Signup from '@/modules/auth/views/Signup'
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ '@/modules/auth/views/Signup'))
// import Greeting from '@/modules/auth/views/Greeting'
const Greeting = lazy(
  () => import(/* webpackChunkName: "Greeting" */ '@/modules/auth/views/Greeting'),
)
// import Agreement from '@/modules/auth/views/Agreement'
const Agreement = lazy(
  () => import(/* webpackChunkName: "Agreement" */ '@/modules/auth/views/Agreement'),
)
// import LoginChoice from '@/modules/auth/views/LoginChoice'
const LoginChoice = lazy(
  () => import(/* webpackChunkName: "LoginChoice" */ '@/modules/auth/views/LoginChoice'),
)
// import ProfileChoice from '@/modules/auth/views/ProfileChoice'
const ProfileChoice = lazy(
  () => import(/* webpackChunkName: "ProfileChoice" */ '@/modules/auth/views/ProfileChoice'),
)

import { AuthPaths, Paths } from '@/core/router/router.paths'
import ErrorBoundary from '@/modules/errorBoundary'
import Sessions from '@/modules/auth/views/Sessions'

export const authRoute: RouteObject = {
  path: Paths.AUTH,
  element: <EmptyLayout />,
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: (
        <SuspenseWithLoaderFallback>
          <Greeting />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.GREETING,
      element: (
        <SuspenseWithLoaderFallback>
          <Greeting />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.LOGIN_CHOICE,
      element: (
        <SuspenseWithLoaderFallback>
          <LoginChoice />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.PROFILE_CHOICE,
      element: (
        <SuspenseWithLoaderFallback>
          <ProfileChoice />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.LOGIN,
      element: (
        <SuspenseWithLoaderFallback>
          <Login />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.SIGNUP,
      element: (
        <SuspenseWithLoaderFallback>
          <Signup />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.AGREEMENT,
      element: (
        <SuspenseWithLoaderFallback>
          <Agreement />
        </SuspenseWithLoaderFallback>
      ),
    },
    {
      path: AuthPaths.SESSIONS,
      element: (
        <SuspenseWithLoaderFallback>
          <Sessions />
        </SuspenseWithLoaderFallback>
      ),
    },
  ],
}
