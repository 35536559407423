import React, { ReactElement } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'

interface PromoSliderProps {
  children: ReactElement[]
}

const PromoSlider = ({ children }: PromoSliderProps) => {
  return (
    <Slider className={styles.FocusWrapper}>
      <Slider.Horizontal
        className={styles.ScrollingWrapper}
        contentWrapperClassName={styles.ContentWrapper}
      >
        {children}
      </Slider.Horizontal>
    </Slider>
  )
}

export default PromoSlider
