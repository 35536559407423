import React, { FC } from 'react'
import styles from './styles.module.scss'

interface Props {
  children?: React.ReactNode
}

const NotificationsLayout: FC<Props> = ({ children }) => {
  return (
    <div className={styles.NotificationsLayout}>
      <h2 className={styles.Title}>Уведомления</h2>
      <div className={styles.Content}>{children}</div>
    </div>
  )
}

export default NotificationsLayout
