import React from 'react'

import styles from './styles.module.scss'

import { useParams } from 'react-router-dom'

import FavoritesContentsListOrEmpty from '@/modules/favorites/components/FavoritesContentsListOrEmpty'

import { ContentType } from '@/models/content.model'

const FavoritesContent = () => {
  const { type } = useParams()
  return (
    <div className={styles.Favorites}>
      <h1>Избранные {type && type === ContentType.MOVIE ? 'фильмы' : 'сериалы'}</h1>
      <FavoritesContentsListOrEmpty
        type={type as ContentType}
        containerClassName={styles.Container}
        className={styles.Container}
        scrollWrapperClassName={styles.List}
      />
    </div>
  )
}

export default FavoritesContent
