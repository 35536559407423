import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { GenreModel } from '@/models/genre.model'

// ------------------------ GENRES
export const addGenreToGenresFilter = createAction(
  'searchSlice/addGenreToYearsFilter',
  withPayloadType<PageUpdatePayload<GenreModel>>(),
)
export const removeGenreFromGenresFilter = createAction(
  'searchSlice/removeGenreFromGenresFilter',
  withPayloadType<PageUpdatePayload<GenreModel>>(),
)

export const setGenresFilter = createAction(
  'searchSlice/setGenresFilter',
  withPayloadType<PageUpdatePayload<GenreModel[]>>(),
)

export const resetGenresFilter = createAction(
  'searchSlice/resetGenresFilter',
  withPayloadType<PageUpdatePayload>(),
)
// ------------------------------------
