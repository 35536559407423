import { ImageModel } from './poster.model'

export enum BackgroundType {
  PARTNER = 'PARTNER',
  CUSTOM = 'CUSTOM',
}

export interface BackgroundModel {
  id: string
  type: BackgroundType
  contentId: string
  createdAt: string
  updatedAt: string
  image?: ImageModel
}
