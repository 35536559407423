import { SyncMediaViewModelWithResource } from '@/models/sync.media.view.model'
import { PlayerProps } from '@/modules/player/Player'

export const getProgressState = (
  view?: SyncMediaViewModelWithResource | null,
): PlayerProps['progressState'] | undefined => {
  if (!view) return

  return {
    currentTime: view.duration - view.currentTime < 30 ? view.duration - 30 : view.currentTime,
    duration: view.duration,
  }
}
