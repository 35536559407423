import { io, ManagerOptions, Socket, SocketOptions } from 'socket.io-client'

export default class SocketClient {
  static _instance: SocketClient | null = null

  _sockets: Map<string, Socket> = new Map()

  static getInstance() {
    if (!SocketClient._instance) {
      SocketClient._instance = new SocketClient()
    }

    return SocketClient._instance
  }

  get sockets() {
    return this._sockets
  }

  connect(
    url: string,
    config: Partial<ManagerOptions & SocketOptions> = {},
    connectSubscription: (socket: Socket) => Promise<void>,
  ) {
    if (!url) throw new Error('URL field must be defined')
    const socket = io(url, config)

    this._sockets.set(url, socket)

    return connectSubscription(socket)
  }

  disconnect(socketKey: string): Promise<void> {
    const socket = this._sockets.get(socketKey)
    if (!socket) return Promise.resolve()

    return new Promise((resolve) => {
      socket.disconnect()
      this._sockets.delete(socketKey)
      resolve()
    })
  }

  emit<T = Any>(socketKey: string, event: string, data: T) {
    return new Promise((resolve, reject) => {
      const socket = this._sockets.get(socketKey)

      if (!socket) return reject('No socket connection.')

      return socket.emit(event, data, (response: { type: string; payload: Any }) => {
        if (response.type === 'ERROR') {
          return reject(response.payload)
        }

        return resolve(response.payload)
      })
    })
  }

  on<T = unknown>(socketKey: string, event: string, cb: (payload: T) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      const socket = this._sockets.get(socketKey)
      if (!socket) return reject('No socket connection.')

      socket.on(event, cb)
      resolve()
    })
  }

  off<T = unknown>(socketKey: string, event: string, cb: (payload: T) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      const socket = this._sockets.get(socketKey)
      if (!socket) return reject('No socket connection.')

      socket.off(event, cb)
      resolve()
    })
  }
}
