import React, { memo, useRef, useState } from 'react'

import styles from './styles.module.scss'

import BottomBar from '@/components/Bottombar'
import ControlledMaskedInput from '@/components/ControlledMaskedInput'
import SearchKeyboard from '@/modules/search/components/SearchKeyboard'

import { useAppSelector } from '@/core/store'
import CircleButton from '@/components/CircleButton'
import classNames from 'classnames'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

const SearchInput = ({ pageName = 'search' }: { pageName?: PagesWithSearch }) => {
  const [active, setActive] = useState(false)
  const value = useAppSelector((state) => state.searchByPageSlice[pageName].searchState.searchTerm)

  const iconRef = useRef<HTMLElement | null>(null)

  return (
    <BottomBar
      trigger={(onTrigger) => (
        <ControlledMaskedInput
          active={active}
          name={'searchInput'}
          value={value}
          placeholder={'Название фильма или сериала'}
          className={styles.Input}
          wrapperClassName={styles.SearchInput}
          appendChild={() => (
            <i
              ref={iconRef}
              className={classNames(styles.KeyboardIcon, {
                ['icon-fill-keyboard']: !active,
                ['icon-fill-keyboard-no']: active,
                [styles.Active]: active,
              })}
            />
          )}
          onFocusedInputClick={() => {
            onTrigger()
            setActive(true)
          }}
          focusProps={{
            onEnterPress: () => {
              onTrigger()
              setActive(true)
            },
            onFocus: () => {
              if (!iconRef.current || active) return
              iconRef.current.classList.add(styles.Active)
            },
            onBlur: () => {
              if (!iconRef.current || active) return
              iconRef.current.classList.remove(styles.Active)
            },
          }}
        />
      )}
      onClose={() => setActive(false)}
      render={(onClose) => (
        <div className={styles.RenderContainer}>
          <CircleButton
            onEnterPress={onClose}
            onButtonClick={onClose}
            onFocus={onClose}
            buttonClassName={styles.CloseButton}
            focusClassName={styles.FocusedCloseButton}
          >
            <i className={'icon-fill-chevron-up'} />
          </CircleButton>
          <SearchKeyboard className={styles.Keyboard} />
        </div>
      )}
    />
  )
}

export default memo(SearchInput)
