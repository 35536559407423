import React, { FC, ReactNode } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Image from '@/components/Image'
import Button from '@/components/Button'

import questionMark from '@/assets/images/question.png'

interface QuestionWithConfirmAndCancelActionsProps {
  headerText: string | ReactNode
  additionalText: string | ReactNode
  onCancel: () => void
  onConfirm: () => void
  className?: string
  actionClassName?: string
  confirmText?: string
}

const QuestionWithConfirmAndCancelActions: FC<QuestionWithConfirmAndCancelActionsProps> = ({
  headerText,
  additionalText,
  onCancel,
  onConfirm,
  className,
  actionClassName,
  confirmText,
}) => {
  return (
    <div className={classNames(className, styles.Container)}>
      <div className={styles.ImageContainer}>
        <Image src={questionMark} skeleton roundedSkeleton />
      </div>
      <hgroup>
        <h2>{headerText}</h2>
        <p>{additionalText}</p>
      </hgroup>

      <div className={styles.ActionsContainer}>
        <Button
          onButtonClick={onCancel}
          onEnterPress={onCancel}
          buttonClassName={classNames(actionClassName, styles.ActionButton)}
        >
          Отмена
        </Button>
        <Button
          onButtonClick={onConfirm}
          onEnterPress={onConfirm}
          buttonClassName={classNames(actionClassName, styles.ActionButton)}
        >
          {confirmText || 'Выйти'}
        </Button>
      </div>
    </div>
  )
}

export default QuestionWithConfirmAndCancelActions
