import { createAsyncThunk } from '@reduxjs/toolkit'

import api, { IApiErrorResponse } from '@/core/api/api'
import ApiUtils from '@/core/api/api.utils'

import { saveToLocalStorage, getFromLocalStorage } from '@/core/utils'

import { LoginDTO, LoginResponse, SetSessionProfile, SignupDTO } from '@/modules/auth/auth.service'
import { UserModel } from '@/models/user.model'
import { SessionModel } from '@/models/session.model'
import SocketClient from '@/core/sockets/client'
import { ACCESS_TOKEN_KEY } from '@/core/config'
import { AuthSessionsSocketsEvents } from '@/core/sockets/events'
import { AppState, resetAction } from '@/core/store/store'
import { toast } from '@/components/Notifications'
import { SocketUrls } from '@/core/sockets/soketUrls'
import { subscribeToSessionsSocketConnection } from '@/core/sockets/connect.subsciptions'

export const checkPhone = createAsyncThunk<void, string, { rejectValue: IApiErrorResponse }>(
  'auth/checkPhone',
  async (login, thunkAPI) => {
    try {
      await api.services.auth.checkPhone(login)
    } catch (e) {
      return thunkAPI.rejectWithValue(api.resolveError(e))
    }
  },
)

export const login = createAsyncThunk<
  LoginResponse,
  LoginDTO,
  { rejectValue: IApiErrorResponse; state: AppState }
>('auth/login', async (attr, thunkAPI) => {
  try {
    const { data } = await api.services.auth.login(attr)

    const { accessToken, refreshToken } = data

    thunkAPI.dispatch(meSession())
    saveToLocalStorage(ApiUtils.accessTokenKey, accessToken)
    saveToLocalStorage(ApiUtils.refreshTokenKey, refreshToken)

    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const logout = createAsyncThunk<void, void, { rejectValue: IApiErrorResponse }>(
  'auth/logout',
  async (attr, thunkAPI) => {
    try {
      const sessionId = getFromLocalStorage<string>(ApiUtils.sessionId) ?? ''

      const { data } = await api.services.auth.logout({ sessionId })

      saveToLocalStorage(ApiUtils.accessTokenKey, '')
      saveToLocalStorage(ApiUtils.refreshTokenKey, '')
      saveToLocalStorage(ApiUtils.sessionId, '')

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(api.resolveError(e))
    }
  },
)

export const signup = createAsyncThunk<void, SignupDataAttrs, { rejectValue: IApiErrorResponse }>(
  'auth/signup',
  async (attr, thunkAPI) => {
    const { phone } = attr
    try {
      await api.services.auth.signup({ credentials: { phone } })
    } catch (e) {
      return thunkAPI.rejectWithValue(api.resolveError(e))
    }
  },
)

export const me = createAsyncThunk<UserModel, void, { rejectValue: IApiErrorResponse }>(
  'auth/me',
  async (attr, thunkAPI) => {
    try {
      const { data } = await api.services.auth.me()
      // thunkAPI.dispatch(meSession())
      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(api.resolveError(e))
    }
  },
)

export const refresh = createAsyncThunk<void, void, { rejectValue: IApiErrorResponse }>(
  'auth/refresh',
  async (attr, thunkAPI) => {
    try {
      const refresh = getFromLocalStorage<string>(ApiUtils.refreshTokenKey)
      const sessionId = getFromLocalStorage<string>(ApiUtils.sessionId)

      if (!refresh || !sessionId) return

      const { data } = await api.services.auth.refresh({ sessionId, refresh })
      saveToLocalStorage(ApiUtils.accessTokenKey, data.accessToken)
      saveToLocalStorage(ApiUtils.refreshTokenKey, data.refreshToken)
      return
    } catch (e) {
      return thunkAPI.rejectWithValue(api.resolveError(e))
    }
  },
)

export const meSession = createAsyncThunk<
  SessionModel,
  void,
  { rejectValue: IApiErrorResponse; state: AppState }
>('auth/meSession', async (attr, thunkAPI) => {
  try {
    const { data } = await api.services.auth.meSession()

    saveToLocalStorage(ApiUtils.sessionId, data.id)

    const socketClient = SocketClient.getInstance()
    await socketClient.connect(
      SocketUrls.SESSIONS,
      {
        extraHeaders: { Authorization: `${getFromLocalStorage(ACCESS_TOKEN_KEY)}` },
        transports: ['polling', 'websocket'],
        closeOnBeforeunload: false,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 10000,
        reconnectionAttempts: 5000,
      },
      subscribeToSessionsSocketConnection,
    )

    socketClient.on<SessionModel>(
      SocketUrls.SESSIONS,
      AuthSessionsSocketsEvents.DELETE_SESSION,
      (payload) => {
        const currentSessionId = thunkAPI.getState().auth.auth.data.session?.id
        if (!payload) return
        if (!payload.id) return
        if (currentSessionId !== payload.id) return

        thunkAPI.dispatch(resetAction)
        toast.warning({
          title: 'Завершение сессии',
          message:
            'Сессия была принудительно завершена с другого устройства, так как был превышен допустимый лимит подключенных устройств.',
          theme: 'dark',
          delay: 15,
        })
      },
    )

    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const removeSessionById = createAsyncThunk<
  void,
  { sessionId: string },
  { rejectValue: IApiErrorResponse }
>('auth/removeSessionById', async (attr, thunkAPI) => {
  try {
    await api.services.auth.removeSessionById(attr)

    return
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const updateSessionById = createAsyncThunk<
  void,
  { sessionId: string; data: Omit<LoginDTO, 'credentials'> },
  { rejectValue: IApiErrorResponse }
>('auth/updateSessionById', async (attr, thunkAPI) => {
  try {
    await api.services.auth.updateSessionById(attr)

    return
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const setSessionProfile = createAsyncThunk<
  Any,
  SetSessionProfile,
  { rejectValue: IApiErrorResponse }
>('auth/setSessionProfile', async (attr, thunkAPI) => {
  try {
    const { data } = await api.services.auth.setSessionProfile(attr)

    return data
  } catch (e) {
    return thunkAPI.rejectWithValue(api.resolveError(e))
  }
})

export const refreshPassword = createAsyncThunk<Any, SignupDTO, { rejectValue: IApiErrorResponse }>(
  'auth/refreshPassword',
  async (attr, thunkAPI) => {
    try {
      const { data } = await api.services.auth.refreshPassword(attr)

      return data
    } catch (e) {
      return thunkAPI.rejectWithValue(api.resolveError(e))
    }
  },
)

interface SignupDataAttrs {
  phone: string
}
