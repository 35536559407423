import React, { FC, useEffect, useState } from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

interface CommonFilterSidebarProps {
  onClose?: () => void
  title: string
  children: React.ReactNode
}

const CommonFilterSidebar: FC<CommonFilterSidebarProps> = ({ onClose, children, title }) => {
  return (
    <div className={styles.SideBar}>
      <CloseButton onClose={onClose} />
      <div className={styles.ListAndTitleContainer}>
        <h2>{title}</h2>

        {children}
      </div>
    </div>
  )
}

interface CloseButtonProps {
  onClose?: () => void
}

const CloseButton = ({ onClose }: CloseButtonProps) => {
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const id = setTimeout(() => {
      setDisabled(false)
      clearTimeout(id)
    }, 150)
  }, [])

  return (
    <Button
      focusWrapperClassName={styles.CloseButtonFocusWrapper}
      buttonClassName={styles.SearchButton}
      focusClassName={styles.FocusedSearchButton}
      onButtonClick={onClose}
      onEnterPress={onClose}
      onFocus={onClose}
      disabled={disabled}
    >
      <i className='icon-fill-chevron-left' />
    </Button>
  )
}

export default CommonFilterSidebar
