import React, { FC } from 'react'

import Button from '@/components/Button'
import ChoseSeasonEpisodeModal from '@/modules/serials/component/ChoseSeasonEpisodeModal'

import { useAppSelector } from '@/core/store'

import { getSeasonEpisodeTitle } from '@/modules/serials/serials.helpers'

import { SerialModel } from '@/models/serial.model'

interface ChoseSeasonEpisodeActionProps {
  disabled?: boolean
  className?: string
  withText?: boolean
  appendToId?: string
}

const ChoseSeasonEpisodeAction: FC<ChoseSeasonEpisodeActionProps> = ({
  disabled,
  className,
  withText = false,
  appendToId,
}) => {
  const episode = useAppSelector((state) => state.serial.episode)
  const serial = useAppSelector((state) => state.content.chosenCard) as SerialModel

  return (
    <>
      <ChoseSeasonEpisodeModal
        appendToId={appendToId}
        trigger={(onTrigger) => {
          return (
            <Button
              disabled={disabled}
              onButtonClick={onTrigger}
              onEnterPress={onTrigger}
              buttonClassName={className}
            >
              <>
                <i className='icon-fill-catalog' />
                {withText && (
                  <span data-title={'title'}>{getSeasonEpisodeTitle(episode, serial)}</span>
                )}
              </>
            </Button>
          )
        }}
      />
    </>
  )
}

export default ChoseSeasonEpisodeAction
