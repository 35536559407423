import React, { cloneElement, CSSProperties, FC, ReactElement, useCallback, useEffect } from 'react'

import styles from './styles.module.scss'

import { useFocusable, UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation'
import classNames from 'classnames'

export interface DefaultFocusButtonProps extends UseFocusableConfig {
  autoFocus?: boolean
  children: ReactElement
  className?: string
  style?: CSSProperties
}

const DefaultFocusButton: FC<DefaultFocusButtonProps> = ({
  children,
  autoFocus,
  className,
  style,
  ...restProps
}) => {
  const { ref, focusKey, focused, focusSelf } = useFocusable(restProps)

  useEffect(() => {
    if (!autoFocus) return
    focusSelf()
  }, [autoFocus])

  const handleOnClick = useCallback(() => {
    !focused && focusSelf()
  }, [focused])

  return (
    <div
      id={focusKey}
      onClick={handleOnClick}
      ref={ref}
      style={style}
      className={classNames(className, styles.DefaultFocusButton)}
    >
      {cloneElement(children, {
        ...children.props,
        focused,
      })}
    </div>
  )
}

export default DefaultFocusButton
