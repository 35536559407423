import React, { Fragment, ReactElement } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Slider, { SliderProps } from '@/components/Slider'

import ContentCard from '@/modules/content/components/ContentCard'
import NavigationCard from '@/modules/content/components/NavigationCard'

import { RowProps } from '@/components/Slider/Row'
import { ContentModel } from '@/models/content.model'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'

type RenderFn = () => ReactElement[]

export interface BaseContentSliderProps<T> extends Omit<SliderProps, 'children'> {
  rowProps?: Omit<RowProps, 'children'>
  children?: RenderFn
  onCardPress?: (item: T) => void
  onCardFocus?: OnChildFocus<T>
}

export interface ContentSliderProps<T> extends BaseContentSliderProps<T> {
  data?: T[]
  isLoading?: boolean
  skeletonItemsLength?: number
  withoutNavigationCard?: boolean
  specificAction?: (args: {
    setFocus?: (key: string) => void
    className?: string
  }) => React.ReactElement
}

function ContentSlider<T extends ContentModel = ContentModel>({
  data,
  isLoading,
  rowProps,
  onCardPress,
  onCardFocus,
  skeletonItemsLength = 6,
  children,
  withoutNavigationCard = false,
  specificAction,
  ...sliderProps
}: ContentSliderProps<T>) {
  return (
    <>
      {!isLoading && (!data || data.length === 0) ? null : (
        <Slider {...sliderProps}>
          <Slider.Horizontal
            title={'Контент'}
            {...rowProps}
            className={classNames(styles.ScrollingWrapper, rowProps?.className)}
          >
            {children
              ? children()
              : data && data.length > 0 && !isLoading
              ? [
                  ...data.map((el, index) => {
                    return (
                      <ContentCard<T>
                        key={index}
                        card={el}
                        onCardFocus={onCardFocus}
                        onCardPress={onCardPress}
                      />
                    )
                  }),
                  !withoutNavigationCard ? (
                    <NavigationCard key={data.length} specificAction={specificAction} />
                  ) : (
                    <Fragment key={data.length} />
                  ),
                ]
              : Array(skeletonItemsLength)
                  .fill('')
                  .map((el, index) => {
                    return (
                      <ContentCard
                        autoFocus={index === 0 && sliderProps.scrollIndex === 0}
                        key={index}
                      />
                    )
                  })}
          </Slider.Horizontal>
        </Slider>
      )}
    </>
  )
}

export default ContentSlider
