import React, { FC } from 'react'

import styles from './styles.module.scss'

import ContentTypesList from '@/modules/search/components/ContentTypesList'
import CommonFilterSidebar from '@/modules/search/components/CommonFilterSidebar'

import { ContentType } from '@/models/content.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface ContentTypesFilterSidebarProps {
  chosenType?: ContentType
  disabledTypes?: ContentType[]
  onClose?: () => void
  pageName: PagesWithSearch
}

const ContentTypesFilterSidebar: FC<ContentTypesFilterSidebarProps> = ({
  onClose,
  disabledTypes,
  chosenType,
  pageName,
}) => {
  return (
    <CommonFilterSidebar onClose={onClose} title={'Тип контента'}>
      <ContentTypesList
        className={styles.ItemsList}
        chosenType={chosenType}
        disabledTypes={disabledTypes}
        throttleScrollDelay={20}
        pageName={pageName}
      />
    </CommonFilterSidebar>
  )
}

export default ContentTypesFilterSidebar
