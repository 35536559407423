import { useAppSelector } from '@/core/store'
import { useMemo } from 'react'

//  todo: можно пепеписать на простой селектор??? хз, что лучше читаться будет
export const useShouldUpdateCurrentProgram = (): boolean => {
  const currentProgramId = useAppSelector((state) => state.tv.currentProgram?.id)
  const liveProgramId = useAppSelector((state) => state.tv.liveProgram?.id)

  return useMemo(() => {
    if (!currentProgramId || !liveProgramId) return false

    return currentProgramId === liveProgramId
  }, [currentProgramId, liveProgramId])
}
