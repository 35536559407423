import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard from '@/components/DefaultCard'
import CardInner from '@/modules/syncMediaView/components/ViewCard/CardInner'
import DeleteCardActionInner from '@/components/DeleteCardActionInner'

import { ContentWithViewModel } from '@/models/content.model'
import { ContentCardProps } from '@/modules/syncMediaView/components/ViewCard'

interface DeletableViewCard extends ContentCardProps<ContentWithViewModel> {
  deletable: boolean
  deleting?: boolean
}

const DeletableViewCard: FC<DeletableViewCard> = ({ deletable, deleting, ...props }) => {
  return (
    <DefaultCard
      wrapperClassName={styles.DeletableCardWrapper}
      focusClassName={classNames(styles.FocusWrapper, {
        [styles.FocusWrapper__DeleteMode]: deletable,
      })}
      {...props}
    >
      <>
        <div
          className={classNames({
            [styles.OpacityWrapper]: deletable,
          })}
        >
          <CardInner card={props.card} />
        </div>
        <DeleteCardActionInner
          deleting={deleting}
          deletable={deletable}
          notDeletingClassName={classNames(styles.DeleteButton, styles.DeleteButton__NotDeleting)}
          deletingClassName={classNames(styles.DeleteButton, styles.DeleteButton__Deleting)}
        />
      </>
    </DefaultCard>
  )
}

export default DeletableViewCard
