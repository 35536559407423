import Manager, { ContainerType, Events } from './event.manager'
import { NotificationsModalProps } from '@/components/Notifications/components/Modals/Modal'

let queue: { id: string; props: NotificationsModalProps }[] = []

const onContainerMountCallback = () => {
  queue.forEach(({ id, props }) => {
    Manager?.setModal(id, props).emit(Events.ADD_MODAL, id, props)
  })
  queue = []
}

Manager?.on(Events.MODAL_CONTAINER_MOUNTED, onContainerMountCallback)

const dispatchModal = (modalProps: NotificationsModalProps) => {
  const props = { ...modalProps }
  const id = `${modalProps.notification.id}`
  const length = Manager?.getContainersLengthByType(ContainerType.MODAL_CONTAINER)
  if (length === 0) {
    queue.push({ id, props })
  }
  Manager?.setModal(id, props).emit(Events.ADD_MODAL, id, props)
}

const deleteModalByID = (id: string) => {
  Manager?.deleteModal(id)?.emit(Events.ADD_MODAL, id)
}

function modal(modalProps: NotificationsModalProps) {
  return dispatchModal(modalProps)
}

export default Object.assign(modal, { delete: deleteModalByID })
