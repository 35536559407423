import { AxiosError, AxiosResponse, RawAxiosRequestHeaders, isAxiosError } from 'axios'
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, SESSION_ID } from '../config'

export interface IApiError {
  code: number
  message: string
}

const INTERNAL_SERVER_ERROR: IApiError = {
  code: 500,
  message: 'Internal Server Error',
}

abstract class ApiUtils {
  static get sessionId(): string {
    return SESSION_ID
  }

  static get accessTokenKey(): string {
    return ACCESS_TOKEN_KEY
  }

  static get refreshTokenKey(): string {
    return REFRESH_TOKEN_KEY
  }

  static normalizeHeaders(headers: RawAxiosRequestHeaders | undefined) {
    if (!headers) return {}

    return Object.keys(headers).reduce((acc, key) => ({ ...acc, [key]: headers[key] }), {})
  }

  static resolveError(e: unknown): IApiError {
    if (isAxiosError(e)) {
      const error = e as AxiosError<IApiError>
      const response = error.response as AxiosResponse<IApiError>
      return response.data || INTERNAL_SERVER_ERROR
    }

    return INTERNAL_SERVER_ERROR
  }

  // static stringifyParams(rowParams: IApiFetchAllQueryParams = {}) {
  //   const params = { ...rowParams }
  //   if (params.paginate && typeof params.paginate !== 'string') {
  //     // params.paginate = this.stringifyPaginateParam(params.paginate)
  //     // если в пагинации приходит оба нулл убираем из квери параметров
  //     const afterStringify = stringifyPaginateParamUtil(params.paginate)
  //     params.paginate = afterStringify !== 'null:null' ? afterStringify : undefined
  //   }
  //   if (params.where && typeof params.where !== 'string') {
  //     // params.where = this.stringifyWhereParam(params.where)
  //     // если получается пустая строка убираем из параметров
  //     params.where = stringifyOrderAndWhereParamUtil(params.where)
  //       ? stringifyOrderAndWhereParamUtil(params.where)
  //       : undefined
  //   }
  //   if (params.order && typeof params.order !== 'string') {
  //     params.order = stringifyOrderAndWhereParamUtil(params.order)
  //       ? stringifyOrderAndWhereParamUtil(params.order)
  //       : undefined
  //   }
  //
  //   return params
  // }
}

export default ApiUtils
