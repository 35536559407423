import React from 'react'

import styles from './styles.module.scss'

import Button from '@/components/Button'

import { useNavigate, useRouteError } from 'react-router-dom'

import { Paths } from '@/core/router/router.paths'

const ErrorBoundary = () => {
  const navigate = useNavigate()
  const error = useRouteError() as Error

  const handleErrorButton = () => {
    navigate(Paths.ROOT)
  }

  return (
    <div className={styles.ErrorBoundary}>
      <div className={styles.ErrorContent}>
        <hgroup>
          <h1>Произошла ошибка</h1>
          {error?.stack && (
            <p>
              <pre>{error.stack}</pre>
            </p>
          )}
          <p>При возможности сфотографируйте ошибку, и свяжитесь с техподдержкой.</p>
          <p>В ближайших версиях мы это исправим.</p>
        </hgroup>
        <Button
          autoFocus
          buttonClassName={styles.ErrorButton}
          onButtonClick={handleErrorButton}
          onEnterPress={handleErrorButton}
        >
          Вернуться
        </Button>
      </div>
    </div>
  )
}

export default ErrorBoundary
