import React from 'react'

import styles from './styles.module.scss'

import Image from '@/components/Image'
import SkeletonView from '@/components/SkeletonView'
import ResolveAndRenderPartner from '@/modules/content/components/ResolveAndRenderPartner'

import {
  getContentPosterUrl,
  getKeywordFromCard,
  PosterExtension,
  PosterOrientation,
} from '@/modules/content/content.helpers'

import { ContentModel } from '@/models/content.model'

interface ContentCardInnerProps<T = ContentModel> {
  card?: T
}

const contentCardQuery = {
  w: 410,
  q: 50,
}
const contentCardExtension = PosterExtension.WebP

function ContentCardInner<T extends ContentModel>({ card }: ContentCardInnerProps<T>) {
  return (
    <div className={styles.ContentCardInner}>
      <div className={styles.Image}>
        <Image
          src={getContentPosterUrl<T>({
            content: card,
            orientation: PosterOrientation.HORIZONTAL,
            extension: contentCardExtension,
            query: contentCardQuery,
          })}
        />
        <Image
          src={getContentPosterUrl<T>({
            content: card,
            orientation: PosterOrientation.HORIZONTAL,
            extension: contentCardExtension,
            query: contentCardQuery,
          })}
          alt='Content card'
          fallbackSrc={
            card &&
            getContentPosterUrl<T>({
              content: card,
              orientation: PosterOrientation.VERTICAL,
              extension: contentCardExtension,
              query: contentCardQuery,
            })
          }
          skeleton
        />
      </div>
      <div className={styles.ContentDescription}>
        <div className={styles.Title}>
          {card && card.title}
          {!card && <SkeletonView />}
        </div>
        <div className={styles.Description}>
          <div className={styles.Description__Text}>
            {getKeywordFromCard(card)}
            {!card && <SkeletonView />}
          </div>
          <ResolveAndRenderPartner card={card} className={styles.Description__PartnerLogo} />
        </div>
      </div>
    </div>
  )
}

export default ContentCardInner
