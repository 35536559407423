import React, { ReactNode, useCallback } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { Controller, ControllerProps } from 'react-hook-form'

import InputMask, { Props as MaskedInputProps } from 'react-input-mask'

import { useFocusable, UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation'
import Input from '@/components/Input'

export interface ControlledMaskedInputProps<T extends Record<string, string>>
  extends Omit<ControllerProps<T>, 'defaultValue' | 'render'>,
    Omit<MaskedInputProps, 'name' | 'mask'> {
  focusProps?: UseFocusableConfig
  appendChild?: (focusInput?: () => void) => ReactNode
  wrapperClassName?: string
  label?: ReactNode
  labelClassName?: string
  onFocusedInputClick?: () => void
  active?: boolean
  mask?: string
}

const ControlledMaskedInput = <T extends Record<string, string>>({
  control,
  name,
  rules,
  shouldUnregister,
  className,
  disabled,
  focusProps,
  appendChild,
  wrapperClassName,
  label,
  labelClassName,
  onFocusedInputClick,
  active,
  ...restProps
}: ControlledMaskedInputProps<T>) => {
  const { focusKey, ref, focused, focusSelf } = useFocusable(focusProps)
  const handleFocusSelf = useCallback(() => {
    if (focusProps && focusProps.focusable === false) return

    focusSelf()

    if (!focused || !onFocusedInputClick) return

    onFocusedInputClick()
  }, [focusProps, focused])
  return (
    <label
      className={classNames(wrapperClassName, styles.FocusWrapper)}
      id={focusKey}
      ref={ref}
      htmlFor={name}
      onClick={handleFocusSelf}
    >
      {label && <div className={classNames(labelClassName, styles.Label)}>{label}</div>}

      {control && (
        <Controller
          name={name}
          control={control}
          rules={rules}
          shouldUnregister={shouldUnregister}
          render={({ field }) => {
            const { value, /*ref: refCb,*/ ...rest } = field
            return !restProps.mask ? (
              <Input
                type={'text'}
                className={classNames(className, styles.Input, {
                  [styles.Disabled]: disabled,
                  [styles.Focused]: focused,
                  [styles.Active]: active,
                })}
                {...restProps}
                value={value as unknown as string}
                {...rest}
              />
            ) : (
              <InputMask
                type={'text'}
                maskChar={'●'}
                className={classNames(className, styles.Input, {
                  [styles.Disabled]: disabled,
                  [styles.Focused]: focused,
                  [styles.Active]: active,
                })}
                mask={restProps.mask}
                alwaysShowMask={true}
                value={value}
                disabled={disabled}
                {...restProps}
                {...rest}
              />
            )
          }}
        />
      )}
      {!control &&
        (!restProps.mask ? (
          <Input
            type={'text'}
            className={classNames(className, styles.Input, {
              [styles.Disabled]: disabled,
              [styles.Focused]: focused,
              [styles.Active]: active,
            })}
            {...restProps}
            value={restProps.value as string}
            // value={value}
          />
        ) : (
          <InputMask
            type={'text'}
            maskChar={'●'}
            className={classNames(className, styles.Input, {
              [styles.Disabled]: disabled,
              [styles.Focused]: focused,
              [styles.Active]: active,
            })}
            alwaysShowMask={true}
            disabled={disabled}
            mask={restProps.mask}
            {...restProps}
          />
        ))}
      {appendChild && appendChild(handleFocusSelf)}
    </label>
  )
}

export default ControlledMaskedInput
