import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageUpdatePayload } from '@/modules/search/slice/search.slice'
import { ContentModel } from '@/models/content.model'

// _____________________SEARCH RESULTS

export const setSearchResults = createAction(
  'searchSlice/setSearchResults',
  withPayloadType<PageUpdatePayload<ContentModel[]>>(),
)

export const addManyToEndSearchResults = createAction(
  'searchSlice/addManyToEndSearchResults',
  withPayloadType<PageUpdatePayload<ContentModel[]>>(),
)

export const addManyToStartSearchResults = createAction(
  'searchSlice/addManyToStartSearchResults',
  withPayloadType<PageUpdatePayload<ContentModel[]>>(),
)

export const removeOneFromSearchResults = createAction(
  'searchSlice/removeOneFromSearchResults',
  withPayloadType<PageUpdatePayload<ContentModel>>(),
)

export const resetSearchResults = createAction(
  'searchSlice/resetSearchResults',
  withPayloadType<PageUpdatePayload>(),
)
