import { AppInfo, NetworkInfo, ProductInfo, SystemInfo } from '@/models/platform.model'

interface AppInfoResponse {
  id: string
  title: string
  type: string
  main: string
  icon: string
  version: string
}

interface DeviceInfoResponse {
  modelName: string
  version: string
  versionMajor: number
  versionMinor: number
  versionDot: number
  sdkVersion: string
  screenWidth: number
  screenHeight: number
  uhd: boolean
  uhd8K: boolean
  oled?: boolean
  ddrSize?: string
  hdr10: string
  dolbyVision: boolean
  dolbyAtmos: boolean
  brandName: string
  manufacturer: string
  mainboardMaker?: string
  platformBizType: string
  tuner: boolean
}

type LGUDIDResponse = {
  id: string
}

type LGUDIDError = {
  errorCode: string
  errorText: string
}

interface WebOsConnectionWired {
  state: string
  interfaceName: string
  ipAddress: string
  netmaks: string
  gateway: string
  dns1: string
  dns2: string
  dns3: string
  method: string
  onInternet: string
}

interface WebOsConnectionWifi {
  state: string
  interfaceName: string
  ipAddress: string
  netmaks: string
  gateway: string
  dns1: string
  dns2: string
  dns3: string
  method: string
  ssid: string
  isWakeOnWiFiEnabled: string
  onInternet: string
}

export interface WebOsConnectionStatusInfoResponse {
  returnValue: boolean
  errorText?: string
  subscribed?: boolean
  isInternetConnectionAvailable: boolean
  wired: WebOsConnectionWired
  wifi: WebOsConnectionWifi
}

export interface WebOsSubscription {
  cancelled: boolean
  subscribe: boolean
  cancel: () => void
}

export interface WebOsConnectionInfoResponse {
  isInternetConnectionAvailable: boolean
  subscribed: boolean
  wifiInfo: { macAddress: string }
  wiredInfo: { macAddress: string }
  returnValue: boolean
}

type WebOsConnectionInfoError = {
  errorCode: string
  errorText: string
}

export default class WebOsService {
  static setup() {}

  static volumeUp() {}

  static volumeDown() {}

  static exit() {
    window.webOS.platformBack()
  }

  static disconnect() {}

  static fetchAppInfo(): Promise<AppInfo> {
    return new Promise((resolve, reject) => {
      const path = window.webOS.fetchAppRootPath()
      const app = {} as AppInfo

      if (path.length !== 0) {
        window.webOS.fetchAppInfo((info: AppInfoResponse) => {
          if (!info) reject(new Error('Error occurs while getting appinfo.json.'))

          app.id = info.id
          app.title = info.title
          app.icon = info.icon
          app.version = info.version

          resolve(app)
        }, `${path}appinfo.json`)
      } else {
        reject(new Error('Getting application root path failed.'))
      }
    })
  }

  static fetchProductInfo(): Promise<ProductInfo> {
    return new Promise((resolve, reject) => {
      const product = {} as ProductInfo

      window.webOS.deviceInfo((info: DeviceInfoResponse) => {
        window.webOSDev.LGUDID({
          onSuccess: (response: LGUDIDResponse) => {
            product.uid = response.id
            product.model = info.modelName
            product.isOled = info.oled
            product.ddrSize = info.ddrSize
            product.isUhd = info.uhd
            product.isUhd8K = info.uhd8K
            product.isDolbyVision = info.dolbyVision
            product.isDolbyAtmos = info.dolbyAtmos
            product.isHdr = info.hdr10

            resolve(product)
          },
          onFailure: (error: LGUDIDError) => reject(error),
        })
      })
    })
  }

  static fetchSystemInfo(): Promise<SystemInfo> {
    return new Promise((resolve) => {
      const system = {} as SystemInfo

      window.webOS.deviceInfo((info: DeviceInfoResponse) => {
        system.version = info.version

        resolve(system)
      })
    })
  }

  private static resolveWebOsConnectionInfo(
    connectionStatus: WebOsConnectionStatusInfoResponse,
    connectionInfo: WebOsConnectionInfoResponse,
  ) {
    const { wifi, wired } = connectionStatus
    const { wifiInfo, wiredInfo } = connectionInfo

    if (wifi.state === 'connected') {
      return {
        mac: wifiInfo.macAddress,
        dns: wifi.dns1 || wifi.dns2 || wifi.dns3,
        ip: wifi.ipAddress,
        wifi: {
          ssid: wifi.ssid,
          signalStrengthLevel: undefined,
        },
      }
    }

    if (wired.state === 'connected') {
      return {
        mac: wiredInfo.macAddress,
        dns: wired.dns1 || wired.dns2 || wired.dns3,
        ip: wired.ipAddress,
        wifi: undefined,
      }
    }

    return null
  }

  static async fetchNetworkInfo(): Promise<NetworkInfo> {
    const getStatus: Promise<WebOsConnectionStatusInfoResponse> = new Promise((resolve, reject) => {
      window.webOS.service.request('luna://com.palm.connectionmanager', {
        method: 'getStatus',
        onSuccess: (info: WebOsConnectionStatusInfoResponse) => resolve(info),
        onFailure: (error: WebOsConnectionInfoError) => reject(error),
      })
    })

    const getInfo: Promise<WebOsConnectionInfoResponse> = new Promise((resolve, reject) => {
      window.webOS.service.request('luna://com.palm.connectionmanager', {
        method: 'getinfo',
        onSuccess: (info: WebOsConnectionInfoResponse) => resolve(info),
        onFailure: (error: WebOsConnectionInfoError) => reject(error),
      })
    })

    const [connectionStatus, connectionInfo] = await Promise.all([getStatus, getInfo])

    const network = WebOsService.resolveWebOsConnectionInfo(connectionStatus, connectionInfo)
    return network ?? {}
  }

  static subscribeToNetworkInfo(
    onSuccess?: (info: WebOsConnectionStatusInfoResponse) => void,
    onFailure?: (error: WebOsConnectionInfoError) => void,
  ): WebOsSubscription {
    return window.webOS.service.request('luna://com.palm.connectionmanager', {
      method: 'getStatus',
      parameters: { subscribe: true },
      onSuccess,
      onFailure,
    })
  }

  static async fetchInfo() {
    const results = await Promise.allSettled([
      WebOsService.fetchAppInfo(),
      WebOsService.fetchProductInfo(),
      WebOsService.fetchSystemInfo(),
      WebOsService.fetchNetworkInfo(),
    ])

    const [app, product, system, network] = results.reduce(
      (accumulator, currentValue) => {
        if (currentValue.status === 'fulfilled') accumulator.push(currentValue.value)
        else accumulator.push({})

        return accumulator
      },
      [] as unknown as [
        Partial<AppInfo>,
        Partial<ProductInfo>,
        Partial<SystemInfo>,
        Partial<NetworkInfo>,
      ],
    )

    return { app, product, system, network }
  }
}
