import React from 'react'
import { useGetContentResourcesByResolvedPathname } from '@/modules/content/hooks/useGetContentResourcesByResolvedPathname'
import ResourcesList from '@/modules/feeds/components/ResourcesList'

const ContentResourceListWithResolvedPathname = () => {
  const { resources, loading } = useGetContentResourcesByResolvedPathname()

  return <ResourcesList resources={resources} loading={loading} />
}

export default ContentResourceListWithResolvedPathname
