import { memo, useEffect, useRef } from 'react'

import { useGetAllChannelsQuery } from '@/modules/tv/tv.api'
import { setChosenChannel } from '@/modules/tv/tv.actions'

import { isChannelUp } from '@/core/keys/isChannelUp'
import { isChannelDown } from '@/core/keys/isChannelDown'
import { useAppDispatch, useAppSelector } from '@/core/store'

const SwitchChannelByChannelUpDownKeys = () => {
  const dispatch = useAppDispatch()

  const chosenChannel = useAppSelector((state) => state.tv.chosenChannel)

  const { data: channels } = useGetAllChannelsQuery({})

  const channelRef = useRef(chosenChannel)
  channelRef.current = chosenChannel

  const channelsRef = useRef(channels)
  channelsRef.current = channels

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (!e) return

      const channel = channelRef.current
      if (!channel) return
      if (!channelsRef.current) return

      if (!isChannelUp(e) && !isChannelDown(e)) return

      e.preventDefault()

      if (isChannelUp(e)) {
        const index = channelsRef.current.findIndex((el) => el.id === channel.id)

        if (index === -1) return
        if (index + 1 >= channelsRef.current.length) {
          dispatch(setChosenChannel(channelsRef.current[0]))
          return
        }

        dispatch(setChosenChannel(channelsRef.current[index + 1]))
        return
      }

      const index = channelsRef.current.findIndex((el) => el.id === channel.id)

      if (index === -1) return
      if (index - 1 <= 0) {
        dispatch(setChosenChannel(channelsRef.current[channelsRef.current.length - 1]))
        return
      }

      dispatch(setChosenChannel(channelsRef.current[index - 1]))
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return null
}

export default memo(SwitchChannelByChannelUpDownKeys)
