import { MutableRefObject, useCallback, useMemo } from 'react'
import { EventTypes, useSendStatistic } from '@/modules/player/hooks/useSendStatistics'

import type { EventsRenderParameters } from '@/modules/player/Player/EventsRenderParams.type'
import type { CommonPlayerEventsCallback } from '@/modules/player/Player/PlayerVideoManager'

export const useSendStatisticsCallbacks = ({
  send,
  resetCounterInterval,
  startCount,
  videoElRef,
  ...props
}: Partial<EventsRenderParameters> &
  ReturnType<typeof useSendStatistic> & {
    videoElRef: MutableRefObject<HTMLVideoElement | null>
  }) => {
  const onChangeUrl: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl

      props.onChangeUrl && props.onChangeUrl(videoEl)

      resetCounterInterval()
      send(EventTypes.HB)
    },
    [props.onChangeUrl],
  )

  const onPlay: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onPlay?.(videoEl)

      startCount()
    },
    [props.onPlay],
  )

  const onManualPlay: (videoEl: HTMLVideoElement | null) => void = useCallback(
    (videoEl) => {
      videoEl && (videoElRef.current = videoEl)
      props.onManualPlay?.(videoEl)

      send(EventTypes.MANUAL_PLAY)
    },
    [props.onManualPlay],
  )

  const onManualPause: (videoEl: HTMLVideoElement | null) => void = useCallback(
    (videoEl) => {
      videoEl && (videoElRef.current = videoEl)
      props.onManualPause?.(videoEl)

      send(EventTypes.MANUAL_PAUSE)
    },
    [props.onManualPause],
  )

  const onCanPlay: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onCanPlay?.(videoEl)

      startCount()
    },
    [props.onCanPlay],
  )

  const onPause: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onPause?.(videoEl)

      resetCounterInterval()
      send(EventTypes.HB)
    },
    [props.onPause],
  )

  const onStop: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onStop?.(videoEl)

      resetCounterInterval()
      send(EventTypes.MANUAL_STOP)
    },
    [props.onStop],
  )

  const onWaiting: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onWaiting?.(videoEl)

      resetCounterInterval()
      send(EventTypes.HB)
    },
    [props.onWaiting],
  )

  const onEndRewind: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onEndRewind?.(videoEl)
    },
    [props.onEndRewind],
  )

  const onStartRewind: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onStartRewind?.(videoEl)

      resetCounterInterval()
      send(EventTypes.HB)
    },
    [props.onStartRewind],
  )

  const onTimeUpdate: CommonPlayerEventsCallback = useCallback(
    (videoEl) => {
      videoElRef.current = videoEl
      props.onTimeUpdate?.(videoEl)
    },
    [props.onTimeUpdate],
  )

  return useMemo(
    () => ({
      onTimeUpdate,
      onPause,
      onPlay,
      onWaiting,
      onCanPlay,
      onStartRewind,
      onChangeUrl,
      onEndRewind,
      onStop,
      onManualPlay,
      onManualPause,
    }),
    [
      onTimeUpdate,
      onPause,
      onPlay,
      onWaiting,
      onCanPlay,
      onStartRewind,
      onChangeUrl,
      onEndRewind,
      onStop,
      onManualPlay,
      onManualPause,
      // onEnded,
    ],
  )
}
