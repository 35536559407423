import React, { FC, useCallback, useRef } from 'react'

import styles from './styles.module.scss'

import Sidebar from '@/components/Sidebar'
import ModalInner from '@/components/Notifications/components/Modals/ModalInner'

import { useViewNotificationByIdMutation } from '@/modules/notifications/notifications.api'

import { NotificationModel } from '@/models/notification.model'
import { EventManager, Events } from '@/components/Notifications'
import { dispatchNotificationModalClosedEvent } from '@/core/customEvents/notifications.custom.events'

export interface NotificationsModalProps {
  id?: string
  notification: NotificationModel
}

const emitCloseEventForPlayer = () => {
  const modalContainer = document.getElementById('modal')
  const playerOverlay = document.getElementById('player_overlay')

  if (!modalContainer || !playerOverlay) return
  if (playerOverlay.childNodes.length > 3) return
  if (modalContainer.childNodes.length > 1) return

  dispatchNotificationModalClosedEvent()
}

const NotificationsModal: FC<NotificationsModalProps> = ({ notification }) => {
  const [viewNotification, { isLoading: viewing }] = useViewNotificationByIdMutation()

  const deletedRef = useRef(false)

  const handleClose = useCallback(() => {
    emitCloseEventForPlayer()
    EventManager?.deleteModal(notification.id)?.emit(Events.DELETE_MODAL, notification.id)
    if (deletedRef.current || !notification || notification.clientNotification?.readedAt) return

    viewNotification({ notificationId: notification.id })
  }, [notification])
  return (
    <Sidebar
      isOpen
      backgroundClassName={styles.Background}
      onClose={handleClose}
      trigger={() => <></>}
      render={(onClose) => {
        return (
          <ModalInner
            notification={notification}
            onClose={onClose}
            onRemove={() => {
              deletedRef.current = true
            }}
            disabled={viewing}
          />
        )
      }}
    />
  )
}

export default NotificationsModal
