import { useAppDispatch, useAppSelector } from '@/core/store'
import { useLazyGetProgramsByChannelIdAndDateQuery } from '@/modules/tv/tv.api'
import { useEffect, useMemo } from 'react'
import { setCurrentProgram } from '@/modules/tv/tv.actions'

export const useGetNextProgram = () => {
  const dispatch = useAppDispatch()
  const currentProgram = useAppSelector((state) => state.tv.currentProgram)
  const liveProgram = useAppSelector((state) => state.tv.liveProgram)

  const [getPrograms, { data: programs, isLoading }] = useLazyGetProgramsByChannelIdAndDateQuery({
    selectFromResult: ({ data, isLoading }) => ({ data, isLoading }),
  })

  useEffect(() => {
    if (!liveProgram) return
    if (currentProgram && currentProgram.channelId === liveProgram.channelId) return
    dispatch(setCurrentProgram(liveProgram))
  }, [liveProgram])

  useEffect(() => {
    if (!currentProgram) return
    const currentDate = currentProgram?.startTime.split('T')[0]
    getPrograms({ date: currentDate, channelId: currentProgram.channelId }, true)
  }, [currentProgram])

  const nextProgram = useMemo(() => {
    if (!currentProgram || !programs || !liveProgram) return
    if (currentProgram.id === liveProgram.id) return

    const currentProgramIndex = programs.findIndex((el) => {
      return el.id === currentProgram.id
    })

    if (currentProgramIndex === -1 || currentProgramIndex === programs.length - 1) return

    return programs[currentProgramIndex + 1]
  }, [currentProgram, liveProgram, programs])

  return useMemo(() => {
    return {
      currentProgram,
      liveProgram,
      nextProgram,
      programsLoading: isLoading,
    }
  }, [currentProgram, liveProgram, nextProgram, isLoading])
}
