import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Loader from '@/components/Loader'
import ContentPlayer from '@/modules/content/components/ContentPlayer'
import ContentPlayerTitle from '@/modules/content/components/ContentPlayerTitle'
import SerialsPlayerOverlayActions from '@/modules/serials/component/SerialsPlayerOverlayActions'

import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/core/store'
import { DRM_CONFIG } from '@/core/drm.config'

import { getNextEpisode, getSeasonEpisodeTitle } from '@/modules/serials/serials.helpers'
import { setEpisode, setSeason } from '@/modules/serials/serials.actions'

import { useLazyContentRightsCheckQuery } from '@/modules/content/content.api'
// import { useGetStreamsBySerialIdAndByEpisodeIdQuery } from '@/modules/streams/streams.api'
import { setChosenCard } from '@/modules/content/content.actions'
import { useContentPlayerRequestExitFullscreen } from '@/modules/content/hooks/useContentPlayerRequestFullscreen'

import { LeftSlotRenderFn } from '@/modules/player/components/player/Overlay'
import { PlayerProps } from '@/modules/player/Player'
import { EpisodeBaseModel, SeasonBaseModel, SerialModel } from '@/models/serial.model'
import { useLazyGetOneContentMediaViewQuery } from '@/modules/syncMediaView/syncMediaView.api'
import { getProgressState } from '@/modules/syncMediaView/helpers'
import { PartnerValue } from '@/models/partner.model'
import StreamsErrorFallback from '@/components/StreamsErrorFallback'
import { isObject } from '@/models/rightsError.model'
import { useGetStreams } from '@/modules/serials/hooks/useGetStreams'

interface SerialPlayerLocationState {
  serial: SerialModel
  episode: EpisodeBaseModel
  season: SeasonBaseModel
}

const SerialPlayerFullscreen = () => {
  const dispatch = useAppDispatch()
  const {
    state: { serial, episode: initialEpisode, season: initialSeason },
  }: { state: SerialPlayerLocationState } = useLocation()

  // ??? duplicate????
  useEffect(() => {
    dispatch(setEpisode(initialEpisode))
    dispatch(setSeason(initialSeason))
    dispatch(setChosenCard(serial))
  }, [initialEpisode])

  const episode = useAppSelector((state) => state.serial.episode)

  const episodeRef = useRef(episode)

  const rightsParams = useMemo(() => {
    return {
      contentId: serial.id,
    }
  }, [episode])

  const [
    contentRightsCheck,
    { isLoading: loadingRights, isFetching: fetchingRights, error: rightsError, originalArgs },
  ] = useLazyContentRightsCheckQuery()

  const [rightsChecked, setRights] = useState<{ streamAccessToken: string } | undefined>(undefined)

  useEffect(() => {
    setRights(undefined)
  }, [episode])

  useEffect(() => {
    if (!episode || !episode.id) return

    contentRightsCheck(rightsParams)
      .unwrap()
      .then((res) => {
        setRights(res)
      })
      .catch(console.error)
  }, [episode])

  const [
    getContentMediaView,
    { data: viewInfo, isLoading: contentMediaViewLoading, isFetching: contentMediaViewFetching },
  ] = useLazyGetOneContentMediaViewQuery()

  useEffect(() => {
    getContentMediaView({ contentId: serial.id })
  }, [serial.id])

  const isContentMediaLoading = useMemo(() => {
    return contentMediaViewFetching || contentMediaViewLoading
  }, [contentMediaViewLoading, contentMediaViewFetching])

  const progressState = useMemo(() => {
    if (isContentMediaLoading) return
    if (!viewInfo) return
    if (episodeRef.current?.id !== viewInfo.resourceId) return

    return getProgressState(viewInfo)
  }, [viewInfo, isContentMediaLoading])

  const { streams, streamsLoading, streamsFetching, streamsError, partnerPlaybackPath } =
    useGetStreams({
      serial,
      episode,
      token: rightsChecked?.streamAccessToken,
      disabledByRights:
        loadingRights ||
        !!rightsError ||
        !rightsChecked ||
        originalArgs?.contentId !== serial.id ||
        !rightsChecked.streamAccessToken,
    })

  const resolved404Error = useMemo(() => {
    if (!streamsError) return false

    if (!isObject(streamsError)) return false

    if (!('status' in streamsError && streamsError.status === 404)) return false

    return true
  }, [streamsError])

  useEffect(() => {
    episodeRef.current = episode
  }, [episode])

  const { navigateToPreviousPage, navigateByDelta } = useContentPlayerRequestExitFullscreen()

  const handleOnBackButton = useCallback(() => {
    if (resolved404Error) {
      navigateByDelta(2)
      return
    }

    navigateToPreviousPage()
  }, [streamsError, resolved404Error])

  const handleOnEnded = useCallback(() => {
    const episode = episodeRef.current
    const nextEpisode = getNextEpisode(serial, episode)

    if (nextEpisode) {
      dispatch(setEpisode(nextEpisode))
      return
    }

    navigateToPreviousPage()
  }, [serial])

  const leftSlotRenderFn: LeftSlotRenderFn = useCallback(
    (isPaused, togglePlayPause, changeProgress) => {
      return (
        <SerialsPlayerOverlayActions
          isPaused={isPaused}
          togglePlayPause={togglePlayPause}
          changeProgress={changeProgress}
        />
      )
    },
    [],
  )

  const headerSlotRenderFn = useCallback(() => {
    return (
      <ContentPlayerTitle
        title={
          <>
            <div>{serial.title}</div>
            <div className={styles.SerialSubTitle}>{`${getSeasonEpisodeTitle(
              episode,
              serial,
            )}`}</div>
          </>
        }
      />
    )
  }, [episode, serial])

  const overlayProps = useMemo(() => {
    return {
      leftSlotRenderFn: leftSlotRenderFn,
      headerSlotRenderFn: headerSlotRenderFn,
    }
  }, [headerSlotRenderFn])

  const loading = useMemo(() => {
    return (
      loadingRights || fetchingRights || streamsLoading || streamsFetching || isContentMediaLoading
    )
  }, [loadingRights, fetchingRights, streamsLoading, streamsFetching, isContentMediaLoading])

  const playerProperties: Partial<PlayerProps> = useMemo(() => {
    return {
      playerId: 'SERIAL_PLAYER',
      className: classNames(styles.SerialsPlayer),
      overlayProps,
      videoManagerProps: {
        onEnded: handleOnEnded,
      },
      progressState,
      fullscreenState: { status: true },
      videoStreamerProps: {
        autoPlay: true,
        url: '',
      },
    }
  }, [overlayProps, loading, progressState])

  return (
    <div className={styles.SerialsPlayerPage}>
      <StreamsErrorFallback error={!!streamsError} onBack={handleOnBackButton} />
      {loading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      {!isContentMediaLoading && streams && (
        <ContentPlayer
          url={streams && !streamsLoading && !streamsFetching ? streams : undefined}
          playerId={'SERIAL_PLAYER'}
          drm={serial?.partner?.type === PartnerValue.AMEDIATEKA ? DRM_CONFIG : undefined}
          playerProps={playerProperties}
          content={serial}
          episode={episode || undefined}
          viewInfo={viewInfo}
          partnerPlaybackPath={partnerPlaybackPath}
        />
      )}
    </div>
  )
}

export default SerialPlayerFullscreen
