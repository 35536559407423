export enum PosterType {
  PARTNER = 'PARTNER',
  CUSTOM = 'CUSTOM',
}

export interface BasePosterModel {
  contentId: string
  createdAt: string
  id: string
  type: PosterType
  updatedAt: string
  horizontalImage?: ImageModel
  verticalImage?: ImageModel
}

export interface ImageModel {
  createdAt: string
  updatedAt: string
  id: string
  hash: string
  folder: string
  sourceBaseUrl: string
  sourcePath: string
}
