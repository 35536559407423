export interface ScreenState {
  isLoading: boolean
  isControls: boolean
}

export interface ProgressState {
  duration: number
  currentTime: number
}

export interface FullscreenState {
  status: boolean
}

export interface VideoState {
  url: string
  volume: number
  isPaused: boolean
  isMuted: boolean
  isBuffering: boolean
  isLive: boolean
}

export const getDefaultVideoState = (payload?: Partial<VideoState>) => ({
  url: '',
  volume: 0,
  isMuted: false,
  isPaused: false,
  isBuffering: true,
  isLive: false,
  ...payload,
})

export const getDefaultProgressState = (payload?: Partial<ProgressState>) => ({
  duration: 0,
  currentTime: 0,
  ...payload,
})

export const getDefaultScreenState = (payload?: Partial<ScreenState>) => ({
  isLoading: false,
  isControls: true,
  ...payload,
})

export const getDefaultFullScreenState = (payload?: Partial<FullscreenState>) => ({
  status: false,
  ...payload,
})

export interface PlayerState {
  fullscreen: FullscreenState
  video: VideoState
  progress: ProgressState
  screen: ScreenState
  userActivity: {
    isUserActive: boolean
  }
}

interface GetDefaultStatePayload {
  videoState?: Partial<VideoState>
  progressState?: Partial<ProgressState>
  screenState?: Partial<ScreenState>
  fullscreenState?: Partial<FullscreenState>
}

export const getDefaultState = (payload: GetDefaultStatePayload): PlayerState => {
  const { videoState, screenState, fullscreenState, progressState } = payload
  return {
    fullscreen: getDefaultFullScreenState(fullscreenState),
    video: getDefaultVideoState(videoState),
    progress: getDefaultProgressState(progressState),
    screen: getDefaultScreenState(screenState),
    userActivity: {
      isUserActive: true,
    },
  }
}

export const playerReducer = (
  state = getDefaultState({}),
  action: PlayerReducerActions,
): PlayerState => {
  switch (action.type) {
    case 'PLAYER/setFullscreenStatus':
      return {
        ...state,
        fullscreen: {
          ...state.fullscreen,
          status: action.payload.status,
        },
      }
    case 'PLAYER/setVideoState':
      return {
        ...state,
        video: {
          // ...state.video,
          ...getDefaultVideoState({ ...state.video, ...action.payload }),
        },
      }

    case 'PLAYER/setProgressState':
      return {
        ...state,
        progress: {
          ...getDefaultProgressState({ ...state.progress, ...action.payload }),
        },
      }
    case 'PLAYER/setScreenState':
      return {
        ...state,
        screen: {
          ...getDefaultScreenState({ ...state.screen, ...action.payload }),
        },
      }
    case 'PLAYER/setUserActivity':
      return {
        ...state,
        userActivity: {
          ...state.userActivity,
          isUserActive: action.payload.status,
        },
      }
    case 'PLAYER/resetState':
      return getDefaultState({})
    default:
      return state
  }
}

export const resetStateAC = () => {
  return {
    type: 'PLAYER/resetState',
  } as const
}

type ResetState = ReturnType<typeof resetStateAC>

export const setUserActivityAC = (status: boolean) => {
  return {
    type: 'PLAYER/setUserActivity',
    payload: {
      status,
    },
  } as const
}

type SetUserActivity = ReturnType<typeof setUserActivityAC>

export const setScreenStateAC = (payload: Partial<ScreenState>) => {
  return {
    type: 'PLAYER/setScreenState',
    payload,
  } as const
}

type SetScreenState = ReturnType<typeof setScreenStateAC>

export const setProgressStateAC = (payload: Partial<ProgressState>) => {
  return {
    type: 'PLAYER/setProgressState',
    payload,
  } as const
}

type SetProgressState = ReturnType<typeof setProgressStateAC>

export const setVideoStateAC = (payload: Partial<VideoState>) => {
  return {
    type: 'PLAYER/setVideoState',
    payload,
  } as const
}

type SetVideoState = ReturnType<typeof setVideoStateAC>

export const setFullscreenStatusAC = (status: boolean) => {
  return {
    type: 'PLAYER/setFullscreenStatus',
    payload: {
      status,
    },
  } as const
}

type SetFullscreenStatus = ReturnType<typeof setFullscreenStatusAC>

export type PlayerReducerActions =
  | SetFullscreenStatus
  | SetVideoState
  | SetProgressState
  | SetScreenState
  | SetUserActivity
  | ResetState
