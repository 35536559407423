import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { isBackButton } from '@/core/keys/specific.keys.codes'
import { AuthPaths } from '@/core/router/router.paths'
import PlatformService from '@/core/services/platform.service'
import Modal from '@/components/Modal'
import QuestionWithConfirmAndCancelActions from '@/components/QuestionWithConfirmAndCancelActions'
import { Platform } from '@/models/platform.model'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'

interface EmptyLayoutProps {
  children?: ReactNode
}

const EmptyLayout: FC<EmptyLayoutProps> = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const path = useRef('')

  const { ref, focusKey, focusSelf } = useFocusable()

  useEffect(() => {
    path.current = pathname
  }, [pathname])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return

      e.preventDefault()
      e.stopPropagation()

      if (path.current.includes(AuthPaths.GREETING) || path.current === '/auth') {
        if (
          PlatformService.platform === Platform.TIZEN ||
          PlatformService.platform === Platform.LOCAL
        ) {
          setOpen(true)
          return
        }
        PlatformService.exit()
        return
      }
      navigate(-1)
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const { 0: isExitModalOpen, 1: setOpen } = useState(false)

  const handleModalClose = useCallback(() => {
    setOpen(false)
    focusSelf()
  }, [])

  const handleCancelExit = useCallback((onClose?: () => void) => {
    return () => {
      setOpen(false)
      onClose?.()
      focusSelf()
    }
  }, [])

  const render = useCallback((onClose?: () => void) => {
    return (
      <QuestionWithConfirmAndCancelActions
        headerText={'Выход из приложения'}
        additionalText={'Вы уверены, что хотите выйти?'}
        onCancel={handleCancelExit(onClose)}
        onConfirm={PlatformService.exit}
        confirmText={'Выйти'}
      />
    )
  }, [])

  const trigger = useCallback(() => {
    return <></>
  }, [])
  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} id={focusKey} className={styles.EmptyLayout}>
        <div className={styles.Content}>
          <Outlet />
        </div>
        <Modal
          isOpen={isExitModalOpen}
          closeByClickBackground={false}
          hideCloseButton
          onClose={handleModalClose}
          trigger={trigger}
          render={render}
        />
      </div>
    </FocusContext.Provider>
  )
}

export default EmptyLayout
