import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import ImageLoader from '@/components/Image'
import SkeletonView from '@/components/SkeletonView'
import DeleteCardActionInner from '@/components/DeleteCardActionInner'

import { getChannelLogoUrl, getChannelTitle } from '@/modules/tv/tv.helpers'

import { useAppSelector } from '@/core/store'

import { ArchiveStatuses, ChannelModel } from '@/models/channel.model'

interface TvChannelCardInnerProps {
  focused?: boolean
  card?: ChannelModel
  deletable?: boolean
  deleting?: boolean
}

const TvChannelCardInner: FC<TvChannelCardInnerProps> = ({
  card,
  focused,
  deleting = true,
  deletable,
}) => {
  const chosenChannelId = useAppSelector((state) => state.tv.chosenChannel?.id)
  return (
    <>
      <div
        className={classNames(styles.TvChannelCardInner, {
          [styles.OpacityWrapper]: deletable,
        })}
      >
        <div
          className={classNames(styles.LogoContainer, {
            [styles.Focused]: focused && !deletable,
            [styles.FocusedDeletable]: focused && deletable,
            [styles.LogoContainer__Chosen]: chosenChannelId && card && chosenChannelId === card?.id,
          })}
        >
          <ImageLoader src={getChannelLogoUrl(card)} alt={getChannelTitle(card)} skeleton />
        </div>
        <div className={styles.Title}>
          <h4>{getChannelTitle(card) || <SkeletonView />}</h4>
          {card && card.archiveStatus === ArchiveStatuses.AVAILABLE && (
            <i className={'icon-fill-archive'} />
          )}
        </div>
      </div>
      <DeleteCardActionInner
        deleting={deleting}
        deletable={deletable}
        notDeletingClassName={classNames(styles.DeleteButton, styles.DeleteButton__NotDeleting)}
        deletingClassName={classNames(styles.DeleteButton, styles.DeleteButton__Deleting)}
      />
    </>
  )
}

export default TvChannelCardInner
