import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { getTimeDisplay } from '@/modules/player/helpers/common.helper'

export type PlayMode = 'ondemand' | 'live' | 'livedvr'

interface Props {
  /** ⇘︎ For previewing live clock time positions, this should contain the date/time corresponding to the start of the timeline.. */
  absoluteStartPosition?: Date
  /** ⇘︎ Used for selecting relative position or clock time display. */
  playMode?: PlayMode
  /** ⇘︎ Used for computing the tooltip position. */
  maxValue?: number
  /** The relative preview position, passed automatically from a Index parent. */
  previewValue?: number
  /** The Index parent manages mouse pointer state, and passes to this component. If true, the tooltip is displayed. */
  isPointerInside?: boolean
  /** Passed from the Index parent. When dragging, it is set to true, and the tooltip displays. */
  isDragging?: boolean
}
const TimelineTooltip: FC<Props> = ({
  absoluteStartPosition,
  previewValue,
  // isDragging,
  // isPointerInside,
  playMode = 'ondemand',
  maxValue,
}) => {
  const timeDisplay = getTimeDisplay(playMode, absoluteStartPosition, previewValue)

  const left = (((previewValue || 0) / (maxValue || 1)) * 100).toFixed(2)

  // const tooltipVisibleClassName = styles.TimelineTooltipVisible

  const prefixedTooltipClassNames = classNames(styles.TimelineTooltip)

  return (
    <div className={classNames(styles.TimelineInformation)}>
      {timeDisplay && (
        <div className={prefixedTooltipClassNames} style={{ left: `${left}%` }}>
          {timeDisplay}
        </div>
      )}
    </div>
  )
}

export default TimelineTooltip
