import Manager, { ContainerType, Events } from './event.manager'
import { ToastProps } from '@/components/Notifications/components/Toasts/Toast'
import { TypeOptions } from '@/components/Notifications/types'

let queue: { id: string; props: ToastProps }[] = []

const getDefaultToastProps = (): ToastProps => {
  return {
    progress: true,
    theme: 'dark',
    type: 'default',
    preventExitTransition: false,
    delay: 5,
    message: '',
  }
}

const onContainerMountCallback = () => {
  queue.forEach(({ id, props }) => {
    Manager?.setToast(id, props).emit(Events.ADD_TOAST, id, props)
  })
  queue = []
}

Manager?.on(Events.TOASTS_CONTAINER_MOUNTED, onContainerMountCallback)

const dispatchToast = (toastProps: Partial<ToastProps> = {}) => {
  const props = { ...getDefaultToastProps(), ...toastProps }
  const id = `${Date.now()}`
  const length = Manager?.getContainersLengthByType(ContainerType.TOAST_CONTAINER)
  if (length === 0) {
    queue.push({ id, props })
  }
  Manager?.setToast(id, props).emit(Events.ADD_TOAST, id, props)
}

const createToastByType = (type: TypeOptions) => {
  return (toastProps: Partial<ToastProps> = {}) => {
    const props = { ...toastProps, type }
    dispatchToast(props)
  }
}

const deleteToastByID = (id: string) => {
  Manager?.deleteToast(id)?.emit(Events.DELETE_TOAST, id)
}

function toast(toastProps: Partial<ToastProps>) {
  return dispatchToast(toastProps)
}

const info = createToastByType('info')
const success = createToastByType('success')
const warning = createToastByType('warning')
const error = createToastByType('error')

export default Object.assign(toast, { info, success, warning, error, delete: deleteToastByID })
