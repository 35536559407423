import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import DefaultCard from '@/components/DefaultCard'
import ContentCardInner from '@/modules/content/components/ContentCard/ContentCardInner'
import DeleteCardActionInner from '@/components/DeleteCardActionInner'

import { ContentModel } from '@/models/content.model'
import { ContentCardProps } from '@/modules/content/components/ContentCard'

interface DeletableFavoritesCard extends ContentCardProps<ContentModel> {
  deletable?: boolean
  deleting?: boolean
}

const DeletableFavoritesCard: FC<DeletableFavoritesCard> = ({
  card,
  className,
  wrapperClassName,
  onCardFocus,
  deletable,
  deleting,
  ...props
}) => {
  return (
    <DefaultCard
      card={card}
      className={classNames(className, styles.ContentCardFocusWrapper)}
      wrapperClassName={classNames(wrapperClassName, styles.ContentCardWrapper)}
      focusClassName={classNames(styles.FocusWrapper, {
        [styles.FocusWrapper__DeleteMode]: deletable,
      })}
      onCardFocus={onCardFocus}
      {...props}
    >
      <>
        <div
          className={classNames({
            [styles.OpacityWrapper]: deletable,
          })}
        >
          <ContentCardInner card={card} />
        </div>
        <DeleteCardActionInner
          deleting={deleting}
          deletable={deletable}
          notDeletingClassName={classNames(styles.DeleteButton, styles.DeleteButton__NotDeleting)}
          deletingClassName={classNames(styles.DeleteButton, styles.DeleteButton__Deleting)}
        />
      </>
    </DefaultCard>
  )
}

export default DeletableFavoritesCard
