import React, { FC } from 'react'
import styles from './styles.module.scss'

interface ContentPlayerTitleProps {
  title?: string | React.ReactNode
}

const ContentPlayerTitle: FC<ContentPlayerTitleProps> = ({ title }) => {
  if (!title) return null
  return <div className={styles.ContentPlayerTitle}>{title}</div>
}

export default ContentPlayerTitle
