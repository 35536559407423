export interface RightsErrorMessage {
  days: string
  description: string
  discountAmount: string
  discountPercent: string
  expireDate: null | string
  id: number
  image: string
  name: string
  price: string
  promoImage: string
  title: string
  utExpireDate: null | string
}

interface RightsErrorData {
  error: string
  statusCode: number
  message: RightsErrorMessage[]
}

export interface RightsErrorModel {
  status: number
  data: RightsErrorData
}

export const isObject = (e: unknown): e is object => {
  return !!e && typeof e === 'object'
}

export const checkIsRightsErrorMessage = (message: unknown): message is RightsErrorMessage => {
  if (!isObject(message)) return false
  if (!('name' in message && typeof message.name === 'string')) return false
  if (!('price' in message && typeof message.price === 'string')) return false
  if (!('title' in message && typeof message.title === 'string')) return false
  if (!('id' in message && typeof message.id === 'number')) return false
  if (!('description' in message && typeof message.description === 'string')) return false
  if (!('days' in message && typeof message.days === 'string')) return false
  return true
}

const checkIsRightsErrorData = (e: unknown): e is RightsErrorMessage => {
  if (!isObject(e)) return false
  if (!('error' in e && typeof e.error === 'string')) return false
  if (!('statusCode' in e && typeof e.statusCode === 'number')) return false
  if (!('message' in e && isObject(e.message))) return false
  if (!(Array.isArray(e.message) && e.message.length > 0)) return false

  const message = e.message[0]

  if (!checkIsRightsErrorMessage(message)) return false

  return true
}

export const checkIsRightsErrorModel = (e: unknown): e is RightsErrorModel => {
  if (!isObject(e)) return false
  if (!('status' in e && typeof e.status === 'number')) return false
  if (!('data' in e && checkIsRightsErrorData(e.data))) return false

  return true
}
