import { ApiHelper } from '@/core/api/api'
import { UserModel } from '@/models/user.model'
import { ClientProfileModel } from '@/models/profile.model'
import { SessionModel } from '@/models/session.model'

const authApiUrl = 'auth/client'

const AuthService = (api: ApiHelper) => ({
  checkPhone: async (phone: string) => {
    return api.post('users/check', { data: { phone } })
  },
  login: async (data: LoginDTO) => {
    return api.post<LoginResponse>(`${authApiUrl}/sign-in`, { data })
  },
  logout: async ({ sessionId }: { sessionId: string }) => {
    return api.get<void>(`${authApiUrl}/sign-out`, {
      headers: {
        SessionId: sessionId,
      },
    })
  },
  signup: async (data: SignupDTO) => {
    return api.post<UserModel>(`${authApiUrl}/sign-up`, { data })
  },
  me: async () => {
    return api.get<UserModel>(`${authApiUrl}/me`)
  },
  refresh: async ({ sessionId, refresh }: { sessionId: string; refresh: string }) => {
    return api.get<{ accessToken: string; refreshToken: string }>(`${authApiUrl}/refresh/tokens`, {
      headers: {
        SessionId: sessionId,
        Authorization: `Bearer ${refresh}`,
      },
    })
  },
  meSession: async () => {
    // return api.get<SessionModel>(`${authApiUrl}/me/session`)
    return api.get<SessionModel>(`${authApiUrl}/me/sessions/access`)
  },
  removeSessionById: async ({ sessionId }: { sessionId: string }) => {
    return api.delete<void>(`${authApiUrl}/me/sessions/${sessionId}`)
  },
  updateSessionById: async ({
    sessionId,
    data,
  }: {
    sessionId: string
    data: Omit<LoginDTO, 'credentials'>
  }) => {
    return api.patch<void>(`${authApiUrl}/me/sessions/${sessionId}`, { data })
  },
  setSessionProfile: async ({ clientProfileId }: SetSessionProfile) => {
    return api.patch<Any>(`clients/me/sessions/profiles/${clientProfileId}`)
  },
  refreshPassword: async (data: SignupDTO) => {
    return api.patch(`${authApiUrl}/refresh/password`, { data })
  },
})

export interface SetSessionProfile {
  clientProfileId: string
}

export interface SignupDTO {
  credentials: {
    phone: string
    password?: string
  }
}

export interface LoginDTO {
  credentials: {
    phone: string
    password: string
  }
  device: {
    uid: string
    model: string
  }
  platform: {
    type: 'TIZEN' | 'WEB_OS' | 'UNKNOWN' | 'SANDBOX'
    version: string
  }
  application: {
    type: 'ITV_3'
    version: string
  }
}

export interface LoginResponse {
  accessToken: string
  refreshToken: string
  user: UserModel
}

export interface GetMeSessionResponse {
  createdAt: string
  updatedAt: string
  id: string
  accessToken: string
  refreshToken: string
  refreshTokenExpiredAt: string
  clientProfile?: ClientProfileModel
  clientId: string
  clientDeviceId: string
  clientApplicationId: string
  clientProfileId?: string | null
  tokenId: string | null
}

export default AuthService
