import { Socket } from 'socket.io-client'
import {
  ClientNotificationSocketEvent,
  TvEventSessionsSocketsEvents,
  AuthSessionsSocketsEvents,
  MediaEventSessionsSocketsEvents,
} from '@/core/sockets/events'
import { saveToLocalStorage } from '@/core/utils'
import {
  MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY,
  TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY,
} from '@/core/config'

const getSuccessConnectionLogText = (value: string) => {
  return `connect to ${value} sockets`
}

const getFailedConnectionLogText = (value: string) => {
  return `connection to ${value} sockets failed`
}

interface TVConnectSuccessResponse {
  options: TVEventsOptions
}

interface MediaConnectSuccessResponse {
  options: MediaEventsOptions
}

export interface TVEventsOptions {
  afterViewChannelHb: number
  beforeViewChannelHb: number
  viewChannelPoint: number
}

export interface MediaEventsOptions {
  afterViewContentHb: number
  beforeViewContentHb: number
  viewContentPoint: number
}

export const subscribeToTvEventSocketConnection = (socket: Socket) => {
  return new Promise((resolve, reject) => {
    socket.on(TvEventSessionsSocketsEvents.CONNECT, (res: TVConnectSuccessResponse) => {
      saveToLocalStorage<TVEventsOptions>(TV_EVENT_OPTIONS_LOCAL_STORAGE_KEY, res.options)
      resolve(res)
    })
    socket.on(TvEventSessionsSocketsEvents.CONNECT_ERROR, reject)
    socket.on('connect_error', reject)
  })
    .then(() => {
      console.log(getSuccessConnectionLogText('tv events'))
    })
    .catch(() => console.log(getFailedConnectionLogText('tv events')))
}

export const subscribeToMediaEventSocketConnection = (socket: Socket) => {
  return new Promise((resolve, reject) => {
    socket.on(MediaEventSessionsSocketsEvents.CONNECT, (res: MediaConnectSuccessResponse) => {
      saveToLocalStorage<MediaEventsOptions>(MEDIA_EVENT_OPTIONS_LOCAL_STORAGE_KEY, res.options)
      resolve(res)
    })
    socket.on(MediaEventSessionsSocketsEvents.CONNECT_ERROR, reject)
    socket.on('connect_error', reject)
  })
    .then(() => {
      console.log(getSuccessConnectionLogText('media events'))
    })
    .catch(() => console.log(getFailedConnectionLogText('media events')))
}

export const subscribeToNotificationSocketConnection = (socket: Socket) => {
  return new Promise((resolve, reject) => {
    socket.on(ClientNotificationSocketEvent.CONNECT, resolve)
    socket.on(ClientNotificationSocketEvent.CONNECT_ERROR, reject)
    socket.on('connect_error', reject)
  })
    .then(() => {
      console.log(getSuccessConnectionLogText('notifications'))
    })
    .catch(() => console.log(getFailedConnectionLogText('notifications')))
}

export const subscribeToSessionsSocketConnection = (socket: Socket) => {
  return new Promise((resolve, reject) => {
    socket.on(AuthSessionsSocketsEvents.CONNECT, resolve)
    socket.on(AuthSessionsSocketsEvents.CONNECT_ERROR, reject)
    socket.on('connect_error', reject)
  })
    .then(() => {
      console.log(getSuccessConnectionLogText('auth sessions'))
    })
    .catch(() => console.log(getFailedConnectionLogText('auth sessions')))
}
