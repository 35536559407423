import React, { FC } from 'react'

import styles from './styles.module.scss'

import DefaultCard, { DefaultCardProps } from '@/components/DefaultCard'
import NeedPackageCardInner from '@/modules/content/components/NeedPackageCard/NeedPackageCardInner'

import { RightsErrorMessage } from '@/models/rightsError.model'

interface NeedPackageCardProps extends Omit<DefaultCardProps<RightsErrorMessage>, 'children'> {}

export const NeedPackageCard: FC<NeedPackageCardProps> = ({ card, ...props }) => {
  return (
    <DefaultCard<RightsErrorMessage>
      card={card}
      wrapperClassName={styles.FocusWrapper}
      {...props}
      border
    >
      <NeedPackageCardInner card={card} />
    </DefaultCard>
  )
}

export default NeedPackageCard
