import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  addGenreToGenresFilter,
  removeGenreFromGenresFilter,
  resetGenresFilter,
  setGenresFilter,
} from '@/modules/search/slice/actions/searchState/searchState.genres.actions'

export const genresExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ GENRES
  builder
    .addCase(addGenreToGenresFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.genres.push(action.payload.payload)
    })
    .addCase(removeGenreFromGenresFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.genres = state[
          action.payload.pageName
        ].searchState.filters.genres.filter((el) => {
          return el.id !== action.payload.payload?.id
        })
    })
    .addCase(setGenresFilter, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.filters.genres = action.payload.payload
    })
    .addCase(resetGenresFilter, (state, action) => {
      state[action.payload.pageName].searchState.filters.genres = []
    })

  //-----------------------------------------------------------
}
