import React, { forwardRef, useCallback, useEffect, useRef } from 'react'

import dash from 'dashjs'

import { Drm, DrmTypes, VideoStreamerBaseProps } from '@/models/streamer.model'

export type DashPlayer = dash.MediaPlayerClass

const getProtectionData = (drm?: Drm | null) => {
  if (!drm) return {}

  const protectionData = {} as dash.ProtectionDataSet

  const { servers } = drm
  if (servers?.[DrmTypes.PLAYREADY]) {
    protectionData[DrmTypes.PLAYREADY] = {
      serverURL: servers[DrmTypes.PLAYREADY],
    }
  }

  if (servers?.[DrmTypes.WIDEVINE]) {
    protectionData[DrmTypes.WIDEVINE] = {
      serverURL: servers[DrmTypes.WIDEVINE],
      priority: 0,
    }
  }

  return protectionData
}

const createPlayer = (
  videoEl: HTMLVideoElement,
  url: string,
  drm?: Drm | null,
  autoplay?: boolean,
): Promise<dash.MediaPlayerClass> => {
  return new Promise((resolve) => {
    const player = dash.MediaPlayer().create()

    player.updateSettings({ streaming: { text: { defaultEnabled: false } } })
    player.initialize(videoEl, url, autoplay, videoEl.currentTime)

    const protectionData = getProtectionData(drm)
    player.setProtectionData(protectionData)

    player.setInitialMediaSettingsFor('audio', { lang: 'ru' })

    resolve(player)
  })
}

const destroyPlayer = (player: DashPlayer) => {
  player.destroy()
}

export interface DashVideoStreamerProps extends VideoStreamerBaseProps<DashPlayer, 'dash'> {}

const DashVideoStreamer = forwardRef<HTMLVideoElement, DashVideoStreamerProps>(
  ({ url, drm, onInit, onBeforeReInit, onReInit, onError, ...props }, ref) => {
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const playerRef = useRef<dash.MediaPlayerClass | null>(null)

    const handleEventError = useCallback((event: dash.ErrorEvent) => {
      const videoEl = videoRef.current
      if (!videoEl) return

      const error = event.error as dash.DashJSError
      onError?.(videoEl, { code: error.code, message: error.message })
    }, [])

    useEffect(() => {
      return () => {
        if (playerRef.current) destroyPlayer(playerRef.current)
      }
    }, [])

    useEffect(() => {
      const videoEl = videoRef.current
      if (!url || !videoEl) return

      const start = async () => {
        if (playerRef.current) {
          onBeforeReInit?.(videoEl)

          playerRef.current.off(dash.MediaPlayer.events.ERROR, handleEventError)
          destroyPlayer(playerRef.current)
        }

        const player = await createPlayer(videoEl, url, drm, props.autoPlay)

        player.on(dash.MediaPlayer.events.ERROR, handleEventError)

        if (!playerRef.current) onInit?.(videoEl, player, 'dash')
        else onReInit?.(videoEl, player, 'dash')

        playerRef.current = player
      }

      start().catch((error) => onError?.(videoEl, error))
    }, [url])

    return (
      <video
        ref={(node: HTMLVideoElement) => {
          videoRef.current = node
          if (typeof ref === 'function') ref(node)
          else if (ref) ref.current = node
        }}
        {...props}
      />
    )
  },
)

DashVideoStreamer.displayName = 'DashVideoStreamer'

export default DashVideoStreamer
