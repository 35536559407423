import React, { useCallback, useEffect, useState } from 'react'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import { ArrowPressHandler } from '@noriginmedia/norigin-spatial-navigation/dist/useFocusable'
import styles from './styles.module.scss'
import Button from '@/components/Button'
import { ListTopEvents } from '@/components/FirstLineOfGridWithObserver'

interface Props {
  firstElement: { id: string }
  listFocusKey: string
}

const NavigateToFirstElementOfListButton = ({ firstElement, listFocusKey }: Props) => {
  const { 0: isListTop, 1: setIsListTop } = useState(true)

  const { ref, focusKey, focused, setFocus } = useFocusable({ focusable: !isListTop })
  if (focused) {
    setFocus('to-list-start')
  }

  const handleOnArrowPress: ArrowPressHandler = useCallback((direction) => {
    if (direction === 'left') {
      setFocus(listFocusKey)
      return false
    }

    return true
  }, [])

  const handleOnPress = useCallback(() => {
    setFocus(firstElement.id)
  }, [firstElement])

  useEffect(() => {
    const handleTop = () => {
      setIsListTop(true)
    }
    const handleNotTop = () => {
      setIsListTop(false)
    }

    document.addEventListener(ListTopEvents.TOP, handleTop)
    document.addEventListener(ListTopEvents.NOT_TOP, handleNotTop)

    return () => {
      document.removeEventListener(ListTopEvents.TOP, handleTop)
      document.removeEventListener(ListTopEvents.NOT_TOP, handleNotTop)
    }
  }, [])

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} style={{ height: '100%' }} />
      {!isListTop && (
        <div className={styles.Up}>
          <Button
            focusKey={'to-list-start'}
            onArrowPress={handleOnArrowPress}
            onEnterPress={handleOnPress}
            onButtonClick={handleOnPress}
            buttonClassName={styles.UpButton}
            shouldHandleClickOnlyInFocus
          >
            <i className={'icon-fill-chevron-up'} />
          </Button>
        </div>
      )}
    </FocusContext.Provider>
  )
}

export default NavigateToFirstElementOfListButton
