import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Checkbox, { CheckboxProps } from '@/components/Checkbox'
import CommonListWrapperWithLoader from '@/modules/search/components/CommonListWrapperWithLoader'
import ListWithScrollbar from '@/components/ListWithScrollbar'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { sortFields } from '@/modules/search/sort.constants'

import { ListProps } from '@/components/List'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  resetSort,
  setSort,
} from '@/modules/search/slice/actions/searchState/searchState.sort.actions'

interface SortList extends Omit<ListProps, 'children'> {
  pageName: PagesWithSearch
}

const SortList: FC<SortList> = ({ className, pageName, ...rest }) => {
  const items = useMemo(() => {
    return [
      <DefaultSortCheckBox
        key={'chooseAllGenres'}
        pageName={pageName}
        className={styles.DefaultCheckbox}
        autoFocus={true}
      />,
      ...sortFields.map((field) => {
        return (
          <SortCheckBox
            key={field.value}
            pageName={pageName}
            value={field.value}
            name={field.name}
          />
        )
      }),
    ]
  }, [])

  return (
    <CommonListWrapperWithLoader isLoading={false}>
      <ListWithScrollbar className={classNames(styles.SortList, className)} {...rest}>
        {items}
      </ListWithScrollbar>
    </CommonListWrapperWithLoader>
  )
}

interface DefaultSortCheckBox extends CheckboxProps {
  pageName: PagesWithSearch
}

const DefaultSortCheckBox = (props: DefaultSortCheckBox) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) => !state.searchByPageSlice[props.pageName].searchState.sort,
  )

  const handleChange = useCallback((checked: boolean) => {
    if (checked) {
      dispatch(resetSort({ pageName: props.pageName }))
      return
    }
  }, [])

  return (
    <Checkbox name={'По умолчанию'} rounded checked={checked} onChange={handleChange} {...props} />
  )
}

interface PartnerCheckBox extends CheckboxProps {
  value: string
  pageName: PagesWithSearch
}

const SortCheckBox = ({ value, ...props }: PartnerCheckBox) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) => state.searchByPageSlice[props.pageName].searchState.sort === value,
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        dispatch(setSort({ pageName: props.pageName, payload: value }))
        return
      }
    },
    [value],
  )

  return <Checkbox rounded checked={checked} onChange={handleChange} {...props} />
}

export default SortList
