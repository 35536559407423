import React, { FC, useCallback } from 'react'

import styles from './styles.module.scss'

import CircleButton from '@/components/CircleButton'

import PlayerManager from '@/modules/player/player.manager'
import { usePlayerIdContext } from '@/modules/player/PlayerIDProvider'

import { PlayerReducerActions, PlayerState } from '@/modules/player/player.reducer'

import { dispatchRequestPlayerExitFromFullscreenEvent } from '@/core/customEvents/player.custom.events'

import { checkNativeFullscreen } from '@/modules/player/helpers/fullscreen.helper'

interface BackButtonProps {
  toggleFullscreen?: () => void
}

const BackButton: FC<BackButtonProps> = ({ toggleFullscreen }) => {
  const playerId = usePlayerIdContext()
  const { usePlayerStateSelector } = PlayerManager.getPlayer<PlayerState, PlayerReducerActions>(
    playerId,
  )

  const isUserActive = usePlayerStateSelector((state) => state.userActivity.isUserActive)

  const handlePress = useCallback(() => {
    if (!isUserActive) return

    dispatchRequestPlayerExitFromFullscreenEvent()

    if (!checkNativeFullscreen() || !toggleFullscreen) return
    toggleFullscreen()
  }, [isUserActive, toggleFullscreen])

  return (
    <CircleButton
      buttonClassName={styles.Button}
      onButtonClick={handlePress}
      onEnterPress={handlePress}
    >
      <i className='icon-fill-arrow-left' />
    </CircleButton>
  )
}

export default BackButton
