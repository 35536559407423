import React, { FC, useCallback } from 'react'

import ContentPlayerInteractOverlayActions from '@/modules/content/components/ContentPlayerInteractOverlayActions'
import Player, { PlayerProps } from '@/modules/player/Player'
import PlayerSourceManager, {
  PlayerSourceRenderParameters,
} from '@/modules/player/Player/PlayerSourceManager' // PlayerSourceRenderParameters,

import { StreamsModel } from '@/models/streams.model'

import { DrmLicenceServers } from '@/models/streamer.model'

import { OverlayProps } from '@/modules/player/components/player/Overlay'
import PlayerSyncViewManager from '@/modules/player/Player/PlayerSyncViewManager'
import { ContentModel } from '@/models/content.model'
import { EpisodeBaseModel } from '@/models/serial.model'
import { SyncMediaViewModelWithResource } from '@/models/sync.media.view.model'
import { MovieBaseModel } from '@/models/movie.model'
import { PlayerMediaEventManager } from '@/modules/player/Player/PlayerMediaEventManager'
import { PlayerPremierEventManager } from '@/modules/player/Player/PlayerPremierEventManager'

export type OmitSourceState = Omit<PlayerSourceRenderParameters, 'url' | 'drm'>

interface ContentPlayerProps {
  url?: string | StreamsModel[]
  drm?: DrmLicenceServers
  playerId: string
  playerProps: Partial<PlayerProps>

  content: ContentModel
  movie?: MovieBaseModel
  episode?: EpisodeBaseModel
  partnerPlaybackPath?: string

  viewInfo?: SyncMediaViewModelWithResource | null
}

const ContentPlayer: FC<ContentPlayerProps> = ({
  url: urls,
  drm,
  playerId,
  content,
  movie,
  episode,
  partnerPlaybackPath,
  playerProps,
  viewInfo,
}) => {
  const { videoStreamerProps, videoState, overlayProps, videoManagerProps, ...restPlayerProps } =
    playerProps

  const overrideOverlayProps = useCallback(
    (state: OmitSourceState): Partial<OverlayProps> => {
      return {
        ...overlayProps,
        rightSlotRenderFn: (setUserActivity, changeDisableOverlay) => {
          return (
            <ContentPlayerInteractOverlayActions
              setUserActivity={setUserActivity}
              changeDisableOverlay={changeDisableOverlay}
              sourceState={state}
            />
          )
        },
      }
    },
    [overlayProps],
  )

  return (
    <PlayerSourceManager urls={urls} drm={drm} preferredLanguage={viewInfo?.language}>
      {({ url, drm, ...sourceState }) => {
        return (
          <PlayerPremierEventManager
            content={content}
            movie={movie}
            episode={episode}
            partnerPlaybackPath={partnerPlaybackPath}
          >
            {(params) => (
              <PlayerMediaEventManager content={content} {...params}>
                {(params) => (
                  <PlayerSyncViewManager
                    url={url}
                    content={content}
                    movie={movie}
                    episode={episode}
                    preferredLanguage={sourceState.language?.value}
                    viewInfo={viewInfo}
                    {...params}
                  >
                    {({ onTimeUpdate, onPause, onEndRewind, onStop, ...rest }) => (
                      <Player
                        playerId={playerId}
                        videoStreamerProps={{
                          ...videoStreamerProps,
                          url,
                          drm,
                        }}
                        overlayProps={overrideOverlayProps(sourceState)}
                        videoManagerProps={{
                          ...videoManagerProps,
                          ...rest,
                          onTimeUpdate,
                          onPause,
                          onEndRewind,
                          onStop,
                        }}
                        videoState={{
                          ...videoState,
                          url,
                        }}
                        {...restPlayerProps}
                      />
                    )}
                  </PlayerSyncViewManager>
                )}
              </PlayerMediaEventManager>
            )}
          </PlayerPremierEventManager>
        )
      }}
    </PlayerSourceManager>
  )
}

export default ContentPlayer
