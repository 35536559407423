import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import SidebarCloseButton from '@/modules/notifications/components/SidebarCloseButton'
import Button from '@/components/Button'
import RenderFrame from '@/components/Notifications/components/Modals/RenderFrame'

import { useRemoveNotificationByIdMutation } from '@/modules/notifications/notifications.api'

import { NotificationModel } from '@/models/notification.model'
import { dispatchNotificationModalOpenedEvent } from '@/core/customEvents/notifications.custom.events'

interface Props {
  onClose?: () => void
  notification: NotificationModel
  disabled?: boolean
  onRemove?: () => void
}

const ModalInner: FC<Props> = ({ onClose, notification, onRemove, disabled }) => {
  const [removeNotification, { isLoading: removing }] = useRemoveNotificationByIdMutation()

  const deletedRef = useRef(false)

  const height = useMemo(() => {
    const fullAvailableHeight = 1030
    const actionWithMarginHeight = 80

    const actionsLength = (notification.actions?.length || 0) + 1

    return fullAvailableHeight - actionWithMarginHeight * actionsLength
  }, [notification])

  const handleRemove = useCallback(
    (onClose?: () => void) => {
      if (deletedRef.current || !notification) return

      removeNotification({ notificationId: notification.id })
        .unwrap()
        .then(() => {
          onRemove?.()
          onClose?.()
        })
    },
    [notification, onRemove],
  )

  const handleAction = useCallback(
    (action: string) => {
      console.log(`try do action ${action}`)
    },
    [notification],
  )

  useEffect(() => {
    dispatchNotificationModalOpenedEvent()
  }, [])

  return (
    <div className={styles.Sidebar}>
      <SidebarCloseButton onClose={onClose} />
      <div className={styles.Content}>
        <div className={styles.FrameContainer}>
          <RenderFrame notification={notification} height={height} width={640} onClose={onClose} />
        </div>
        <div className={styles.ActionsContainer}>
          {notification.actions?.map((action, index) => {
            return (
              <Button
                key={index}
                disabled={disabled || removing}
                focusWrapperClassName={styles.ActionWrapper}
                buttonClassName={classNames(styles.Action)}
                onButtonClick={() => handleAction(action.action)}
                onEnterPress={() => handleAction(action.action)}
              >
                {action.label}
              </Button>
            )
          })}

          <Button
            disabled={disabled || removing}
            focusWrapperClassName={styles.ActionWrapper}
            buttonClassName={classNames(styles.Action, styles.DeleteAction)}
            focusClassName={styles.DeleteAction__Focused}
            onButtonClick={() => handleRemove(onClose)}
            onEnterPress={() => handleRemove(onClose)}
          >
            Удалить
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModalInner
