import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { resetChosenCard, setChosenCard } from '@/modules/content/content.actions'
import { resetChosenChannel, setChosenChannel, setFocusedChannel } from '@/modules/tv/tv.actions'
import {
  isChannel,
  isContent,
  isGenre,
  isMovie,
} from '@/modules/feeds/components/ResourcesList/helpers'

import { ContentPaths, Paths } from '@/core/router/router.paths'

import { ChannelModel } from '@/models/channel.model'
import { ContentModel } from '@/models/content.model'
import { GenreModel } from '@/models/genre.model'
import { OnChildFocus } from '@/components/Slider/DefaultScrollingWrapper'
import { dispatchRequestPlayerEnterToFullscreenEvent } from '@/core/customEvents/player.custom.events'
import { isBackButton } from '@/core/keys/specific.keys.codes'
import { hasModalNodeOwnChild } from '@/core/helpers'
import {
  checkCustomFullscreen,
  checkNativeFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'

export const useHandleCardPressAndCardFocus = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const cardIdRef = useRef<string>()
  const cardId = useAppSelector((state) => state.tv.chosenChannel?.id)

  useEffect(() => {
    cardIdRef.current = cardId
  }, [cardId])

  const postersChangeBlockedRef = useRef<boolean>(false)

  const timoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const channelTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleOnChannelFocus = useCallback((card: ChannelModel) => {
    dispatch(resetChosenCard())
    if (timoutIdRef.current) {
      clearTimeout(timoutIdRef.current)
      timoutIdRef.current = null
    }
    if (channelTimerRef.current) {
      clearTimeout(channelTimerRef.current)
    }
    channelTimerRef.current = setTimeout(() => {
      dispatch(setFocusedChannel(card))
      if (channelTimerRef.current) {
        clearTimeout(channelTimerRef.current)
        channelTimerRef.current = null
      }
    }, 1000)
  }, [])
  const handleOnChannelPress = useCallback((card: ChannelModel) => {
    if (card.id === cardIdRef.current) {
      dispatchRequestPlayerEnterToFullscreenEvent()

      // dispatch(resetChosenChannel())
      return
    }
    dispatch(setChosenChannel(card))
  }, [])

  const handleOnContentFocus = useCallback((card: ContentModel) => {
    // dispatch(resetChosenChannel())

    if (timoutIdRef.current) {
      clearTimeout(timoutIdRef.current)
      timoutIdRef.current = null
    }

    if (postersChangeBlockedRef.current) return

    const id = setTimeout(() => {
      dispatch(setChosenCard(card))
      clearTimeout(id)
      timoutIdRef.current = null
    }, 1000)

    timoutIdRef.current = id
  }, [])

  const handleOnContentPress = useCallback((card: ContentModel) => {
    if (cardIdRef.current) {
      dispatch(setChosenCard(card))
      dispatch(resetChosenChannel())
      return
    }

    if (timoutIdRef.current) {
      clearTimeout(timoutIdRef.current)
      timoutIdRef.current = null
    }
    dispatch(setChosenCard(card))

    navigate(
      `${Paths.CONTENT}/${isMovie(card) ? ContentPaths.MOVIES : ContentPaths.SERIALS}/${card.id}`,
      { replace: true },
    )
  }, [])

  const handleOnGenreFocus = useCallback(() => {
    if (cardIdRef.current) {
      dispatch(resetChosenCard())
    }

    if (timoutIdRef.current) {
      clearTimeout(timoutIdRef.current)
      timoutIdRef.current = null
    }

    if (channelTimerRef.current) {
      clearTimeout(channelTimerRef.current)
    }
  }, [])

  const handleOnGenrePress = useCallback((card: GenreModel) => {
    if (cardIdRef.current) {
      dispatch(resetChosenChannel())
      return
    }

    navigate(`${Paths.CONTENT}/${ContentPaths.GENRES}/${card.id}`)
  }, [])

  const handleOnCardFocus: OnChildFocus<ChannelModel | ContentModel | GenreModel> = useCallback(
    (...args) => {
      const card = args[4]
      if (!card) return

      if (isChannel(card)) {
        handleOnChannelFocus(card)
        return
      }

      if (isContent(card)) {
        handleOnContentFocus(card)
        return
      }

      if (isGenre(card)) {
        handleOnGenreFocus()
        return
      }

      return
    },
    [],
  )

  const handleCardPress = useCallback((card: ChannelModel | ContentModel | GenreModel) => {
    if (!card) return

    if (isChannel(card)) {
      handleOnChannelPress(card)
      return
    }

    if (isContent(card)) {
      handleOnContentPress(card)

      return
    }

    if (isGenre(card)) {
      handleOnGenrePress(card)
      return
    }
  }, [])

  useEffect(() => {
    const handleBlockPostersChange = () => {
      postersChangeBlockedRef.current = true

      if (!timoutIdRef.current) return

      clearTimeout(timoutIdRef.current)
      timoutIdRef.current = null
    }
    const handleUnBlockPostersChange = () => {
      postersChangeBlockedRef.current = false
    }

    const handleKeyPress = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return

      if (!cardIdRef.current) return

      if (hasModalNodeOwnChild()) return
      if (checkNativeFullscreen() || checkCustomFullscreen()) return

      e.preventDefault()
      e.stopPropagation()

      dispatch(resetChosenChannel())
    }

    document.addEventListener('blockContentPosterChangeEvent', handleBlockPostersChange)
    document.addEventListener('unBlockContentPosterChangeEvent', handleUnBlockPostersChange)
    window.addEventListener('keydown', handleKeyPress, { capture: true })

    return () => {
      document.removeEventListener('blockContentPosterChangeEvent', handleBlockPostersChange)
      document.removeEventListener('unBlockContentPosterChangeEvent', handleUnBlockPostersChange)
      window.removeEventListener('keydown', handleKeyPress, { capture: true })
    }
  }, [])

  return useMemo(() => {
    return {
      handleOnCardFocus,
      handleCardPress,
    }
  }, [handleOnCardFocus, handleCardPress])
}
