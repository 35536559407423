import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import {
  resetSort,
  resetSortAsc,
  setSort,
  setSortAsc,
} from '@/modules/search/slice/actions/searchState/searchState.sort.actions'

export const sortExtraReducers = (builder: SearchSliceBuilderType) => {
  // ------------------------ SORT
  builder
    .addCase(setSort, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.sort = action.payload.payload
    })
    .addCase(resetSort, (state, action) => {
      state[action.payload.pageName].searchState.sort = ''
    })

    //-----------------------------------------------------------

    // ------------------------ SORT ASC

    .addCase(setSortAsc, (state, action) => {
      if (action.payload.payload)
        state[action.payload.pageName].searchState.asc = action.payload.payload
    })
    .addCase(resetSortAsc, (state, action) => {
      state[action.payload.pageName].searchState.asc = false
    })

  //-----------------------------------------------------------
}
