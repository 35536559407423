import React, { useCallback, useEffect, useRef } from 'react'

import CircleButton from '@/components/CircleButton'

import { useLocation, useNavigate } from 'react-router-dom'

import { isBackButton } from '@/core/keys/specific.keys.codes'
import { getPrevPathFromRouterHistoryFromLocalStorage, hasModalNodeOwnChild } from '@/core/helpers'
import { CabinetPaths, Paths } from '@/core/router/router.paths'

interface CabinetNavigationButton {
  className?: string
}

const shouldNavigateToContentLayout = (path: string): boolean => {
  const prev = getPrevPathFromRouterHistoryFromLocalStorage()
  return (
    !prev ||
    path === `${Paths.CABINET}` ||
    path === `${Paths.CABINET}/${CabinetPaths.PROFILE}` ||
    path === `${Paths.CABINET}/${CabinetPaths.SUBSCRIPTIONS}` ||
    path === `${Paths.CABINET}/${CabinetPaths.PAYMENT}` ||
    path === `${Paths.CABINET}/${CabinetPaths.NOTIFICATIONS}` ||
    path === `${Paths.CABINET}/${CabinetPaths.SUPPORT}` ||
    path === `${Paths.CABINET}/${CabinetPaths.STATISTICS}` ||
    path === `${Paths.CABINET}/${CabinetPaths.LEARN_MASTER}`
  )
}

const CabinetNavigationBackButton = ({ className }: CabinetNavigationButton) => {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const pathNameRef = useRef('')

  useEffect(() => {
    pathNameRef.current = pathname
  }, [pathname])

  const handleBackButton = useCallback(() => {
    const path = pathNameRef.current
    if (shouldNavigateToContentLayout(path)) {
      navigate(`${Paths.CONTENT}`)
      return
    }
    navigate(-1)
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return

      e.preventDefault()
      e.stopPropagation()

      if (hasModalNodeOwnChild()) return

      handleBackButton()
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <CircleButton
      autoFocus
      onButtonClick={handleBackButton}
      onEnterPress={handleBackButton}
      buttonClassName={className}
    >
      <i className={'icon-fill-arrow-left'} />
    </CircleButton>
  )
}

export default CabinetNavigationBackButton
