import React from 'react'

import styles from './styles.module.scss'

import Image from '@/components/Image'

import {
  getContentPosterUrl,
  PosterExtension,
  PosterOrientation,
} from '@/modules/content/content.helpers'

import { ContentModel, ContentType, ContentWithViewModel } from '@/models/content.model'
import SkeletonView from '@/components/SkeletonView'

interface ContentCardInnerProps<T = ContentWithViewModel> {
  card?: T
}

const contentCardQuery = {
  w: 410,
  q: 50,
}
const contentCardExtension = PosterExtension.WebP

function CardInner<T extends ContentModel = ContentWithViewModel>({
  card: untyped,
}: ContentCardInnerProps<T>) {
  const card = untyped as unknown as ContentWithViewModel
  return (
    <div className={styles.ContentCardInner}>
      <div className={styles.Image}>
        <Image
          src={getContentPosterUrl<T>({
            content: untyped,
            orientation: PosterOrientation.HORIZONTAL,
            extension: contentCardExtension,
            query: contentCardQuery,
          })}
        />
        <Image
          src={getContentPosterUrl<T>({
            content: untyped,
            orientation: PosterOrientation.HORIZONTAL,
            extension: contentCardExtension,
            query: contentCardQuery,
          })}
          alt='Content card'
          fallbackSrc={
            card &&
            getContentPosterUrl<T>({
              content: untyped,
              orientation: PosterOrientation.VERTICAL,
              extension: contentCardExtension,
              query: contentCardQuery,
            })
          }
          skeleton
        />
      </div>

      <div className={styles.BoxShadow} />

      <div className={styles.Description}>
        {card &&
          card.type === ContentType.SERIAL &&
          card.view &&
          card.view.resource &&
          card.view.resource.episode &&
          card.view.resource.season && (
            <div
              className={styles.Info}
            >{`${card.view.resource.season} сезон ${card.view.resource.episode} серия `}</div>
          )}
        <div className={styles.Title}>
          {card && card.title}
          {!card && <SkeletonView />}
        </div>
        <div
          className={styles.Progress}
          style={{
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            '--progress':
              card && card.view && card.view.duration && card.view.currentTime
                ? `${(+card.view.currentTime / +card.view.duration) * 100}%`
                : undefined,
          }}
        />
      </div>
    </div>
  )
}

export default CardInner
