import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import IntersectedElement from '@/components/IntersectedElement'

import { useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import { NotificationModel } from '@/models/notification.model'
import { isObject } from '@/models/rightsError.model'
import Loader from '@/components/Loader'
import { isBackButton } from '@/core/keys/specific.keys.codes'

interface Props {
  height: number
  width: number
  className?: string
  notification: NotificationModel
  onClose?: () => void
}

const RenderFrame: FC<Props> = ({ notification, className, onClose, height, width }) => {
  const { 0: focusable, 1: setFocusable } = useState(false)

  const { ref: focusRef, focused, focusSelf } = useFocusable({ focusable })

  const ref = useRef<HTMLDivElement>(null)
  const titleDescriptionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!titleDescriptionRef.current) return

    const selfHeight = titleDescriptionRef.current.offsetHeight

    if (ref.current && selfHeight > height) {
      ref.current.classList.add(styles.FrameContainer__Scrollable)
      setFocusable(true)
      // setLoaded(true)
      focusSelf()
    }
  }, [titleDescriptionRef.current])

  const frameRef = useRef<HTMLIFrameElement>(null)

  const blockFocusNavigationRef = useRef(false)

  const focusedRef = useRef(focused)

  focusedRef.current = focused

  const handleScrollInEndPosition = useCallback((isVisible: boolean) => {
    blockFocusNavigationRef.current = !isVisible
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (!frameRef.current) return

      if (!event.data || typeof event.data !== 'string') return

      try {
        const data = JSON.parse(event.data)

        if (!isObject(data) || !('height' in data && typeof data.height === 'number')) {
          frameRef.current.height = `800px`
          return
        }

        frameRef.current.height = `${data.height}`

        if (ref.current && data.height > height) {
          ref.current.classList.add(styles.FrameContainer__Scrollable)
          setFocusable(true)
          focusSelf()
        }
      } catch (e) {
        console.log(e)
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (isBackButton(e)) {
        e.preventDefault()
        e.stopPropagation()

        onClose && onClose()
      }

      if (!focusedRef.current) return
      if (!ref.current) return

      if (e.code === 'ArrowDown') {
        if (!blockFocusNavigationRef.current) return
        e.preventDefault()
        e.stopPropagation()

        ref.current.scroll({
          behavior: 'smooth',
          top: ref.current.scrollTop + ref.current.offsetHeight * 0.2,
        })
        return
      }

      if (e.code === 'ArrowUp') {
        e.preventDefault()
        e.stopPropagation()

        ref.current.scroll({
          behavior: 'smooth',
          top: ref.current.scrollTop - ref.current.offsetHeight * 0.2,
        })
        return
      }

      if (e.code === 'ArrowLeft') {
        if (!blockFocusNavigationRef.current) return
        e.preventDefault()
        e.stopPropagation()
        return
      }

      if (e.code === 'ArrowRight') {
        if (!blockFocusNavigationRef.current) return
        e.preventDefault()
        e.stopPropagation()
        return
      }
    }

    window.addEventListener('keydown', handleKeydown, { capture: true })

    return () => {
      window.removeEventListener('keydown', handleKeydown, { capture: true })
    }
  }, [])
  const [loaded, setLoaded] = useState(false)
  const [loadError, setLoadError] = useState(false)

  useEffect(() => {
    if (!notification) return
    if (
      !notification.template ||
      !notification.template.sourceBaseUrl ||
      !notification.template.sourcePath
    ) {
      setLoaded(true)
    }
  }, [notification])

  return (
    <div ref={focusRef} className={classNames({ [styles.FocusWrapper]: focused })}>
      <div
        ref={ref}
        className={classNames(className, styles.FrameContainer)}
        style={{ height: `${height}px` }}
      >
        {!loaded && !loadError && (
          <Loader>
            <Loader.Spinner />
          </Loader>
        )}
        <div className={styles.ScrollingContainer}>
          <IntersectedElement rootRef={ref} />

          {notification &&
            notification.template &&
            notification.template.sourcePath &&
            !loadError && (
              <>
                <iframe
                  ref={frameRef}
                  src={`${notification.template.sourceBaseUrl}${notification.template.sourcePath}`}
                  width={`${width}px`}
                  className={classNames(styles.Frame, {
                    [styles.Hidden]: !loaded,
                  })}
                  scrolling={'no'}
                  onLoad={() => {
                    // console.log(e.currentTarget.innerHTML)
                    setLoaded(true)
                  }}
                  onError={() => {
                    setLoadError(true)
                  }}
                />
              </>
            )}

          {((notification &&
            (!notification.template ||
              !notification.template.sourceBaseUrl ||
              !notification.template.sourcePath)) ||
            loadError) && (
            <div
              ref={titleDescriptionRef}
              style={{ width }}
              className={styles.TitleDescriptionContainer}
            >
              <h2 className={styles.Title}>{notification.title}</h2>
              <div className={styles.Description}>{notification.description}</div>
            </div>
          )}
          <IntersectedElement rootRef={ref} onVisibilityChange={handleScrollInEndPosition} />
        </div>
      </div>
    </div>
  )
}

export default RenderFrame
