import React from 'react'

import NetworkChangesSubscription from '@/modules/network/components/NetworkChangesSubscription'

import router from '@/core/router'

import { RouterProvider } from 'react-router-dom'
import { ToastsContainer } from '@/components/Notifications'
import { FocusContext, useFocusable } from '@noriginmedia/norigin-spatial-navigation'
import ApplicationSetUp from '@/modules/app/components/ApplicationSetUp'

function App() {
  const { focusKey, ref } = useFocusable()

  return (
    <FocusContext.Provider value={focusKey}>
      <div ref={ref} className='App' style={{ position: 'relative' }}>
        <ToastsContainer />
        <RouterProvider router={router} />
        <NetworkChangesSubscription />
      </div>
      <ApplicationSetUp />
    </FocusContext.Provider>
  )
}

export default App
