import React, { useEffect, useMemo } from 'react'

import styles from './styles.module.scss'

import { useLocation, useParams } from 'react-router-dom'

import SkeletonView from '@/components/SkeletonView'
import ImageLoader from '@/components/Image'

import { useGetGenreQuery } from '@/modules/genre/genre.api'

import { useAppDispatch, useAppSelector } from '@/core/store'

import {
  dispatchSearchLoadingTurnOffEvent,
  dispatchSearchLoadingTurnOnEvent,
} from '@/core/customEvents/search.custom.events'

import fallbackLogo from '@/assets/images/genres/genre-fallback.svg'
import { ContentType } from '@/models/content.model'
import { setTypesFilter } from '@/modules/search/slice/actions/searchState/searchState.contentTypes.actions'
import { setGenresFilter } from '@/modules/search/slice/actions/searchState/searchState.genres.actions'

const GenreHeader = () => {
  const dispatch = useAppDispatch()
  const genres = useAppSelector(
    (state) => state.searchByPageSlice['genres'].searchState.filters.genres,
  )
  const { id } = useParams()
  const { state } = useLocation()

  const {
    data: genre,
    isLoading: genreLoading,
    isFetching: genreFetching,
  } = useGetGenreQuery(
    { genreId: id || '' },
    {
      skip: !id,
    },
  )

  const genreLogoUrl = useMemo(() => {
    if (!genre) return
    if (!genre.logo) return

    const sourceBaseUrl = genre.logo.sourceBaseUrl
    const sourcePath = genre.logo.sourcePath

    return sourceBaseUrl + sourcePath
  }, [genre])

  const fromType = useMemo(() => {
    if (!state) return
    const { movies, serials } = state
    if (!movies && !serials) return

    if (movies) return ContentType.MOVIE

    return ContentType.SERIAL
  }, [state])

  useEffect(() => {
    if (!fromType) return
    dispatch(setTypesFilter({ pageName: 'genres', payload: fromType }))
  }, [fromType])

  useEffect(() => {
    if (genreLoading || genreFetching) {
      dispatchSearchLoadingTurnOnEvent()
      return
    }
    dispatchSearchLoadingTurnOffEvent()
  }, [genreLoading, genreFetching])

  useEffect(() => {
    if (!genre) return
    if (genres.length > 0) return

    dispatch(setGenresFilter({ pageName: 'genres', payload: [genre] }))
  }, [genre])

  return (
    <div className={styles.GenreHeader}>
      <div className={styles.GenreTitle}>
        {genre && genre.pluralTitle ? genre.pluralTitle : <SkeletonView />}
      </div>
      <div className={styles.GenreLogo}>
        <ImageLoader
          src={genreLogoUrl || fallbackLogo}
          fallbackSrc={fallbackLogo}
          skeleton={true}
          width={80}
          roundedSkeleton
        />
      </div>
    </div>
  )
}

export default GenreHeader
