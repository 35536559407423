import React from 'react'

import styles from './styles.module.scss'

import RemoveAllNotificationsAction from '@/modules/notifications/components/RemoveAllNotificationsAction'
import ViewAllNotificationsAction from '@/modules/notifications/components/ViewAllNotificationsAction'

interface Props {
  disabled?: boolean
}
const Actions = ({ disabled }: Props) => {
  return (
    <div className={styles.Actions}>
      <ViewAllNotificationsAction
        disabled={disabled}
        className={styles.Action}
        focusClassName={styles.Focused}
      />
      <RemoveAllNotificationsAction
        triggerClassName={styles.Action}
        focusedTriggerClassName={styles.Focused}
      />
    </div>
  )
}

export default Actions
