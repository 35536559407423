import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '@/core/store/helpers'
import { PageState, PageUpdatePayload } from '@/modules/search/slice/search.slice'

// _____________________PAGE STATE UPDATE___________

export const updatePageState = createAction(
  'searchSlice/updatePageState',
  withPayloadType<PageUpdatePayload<PageState>>(),
)

export const partialUpdatePageState = createAction(
  'searchSlice/partialUpdatePageState',
  withPayloadType<PageUpdatePayload<Partial<PageState>>>(),
)

export const resetPageState = createAction(
  'searchSlice/resetPageState',
  withPayloadType<PageUpdatePayload>(),
)
