import React, { FC, Fragment, memo } from 'react'

import styles from './styles.module.scss'

import Slider from '@/components/Slider'
import Skeleton from '@/modules/feeds/components/Skeleton'
import LoadMoreResources from '@/modules/feeds/components/LoadMoreResources'

import { usePrepareResources } from '@/modules/feeds/hooks/usePrepareResources'

import { FeedsResourceBaseModel } from '@/models/feeds.resource.model'

interface ResourcesListProps {
  resources?: FeedsResourceBaseModel[]
  loading?: boolean
}

const ResourcesList: FC<ResourcesListProps> = ({ resources, loading }) => {
  const { sliders, shouldShowLoadMore, loadMoreChunksContents } = usePrepareResources(resources)

  return (
    <Slider className={styles.FocusWrapper} focusProps={{ focusable: !(!resources && loading) }}>
      <Slider.Vertical>
        {!resources && loading
          ? [<Skeleton key={1} height={'300px'} />, <Skeleton key={2} />]
          : [
              ...sliders,
              shouldShowLoadMore ? (
                <LoadMoreResources
                  key={'load-more-resources'}
                  onLoadMorePress={loadMoreChunksContents}
                />
              ) : (
                <Fragment key={'empty'} />
              ),
            ]}
      </Slider.Vertical>
    </Slider>
  )
}

export default memo(ResourcesList)
