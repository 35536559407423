import React from 'react'

import styles from './styles.module.scss'

import FavoritesChannelsListOrEmpty from '@/modules/favorites/components/FavoritesChannelsListOrEmpty'
import FavoritesContentsListOrEmpty from '@/modules/favorites/components/FavoritesContentsListOrEmpty'
import { useAppSelector } from '@/core/store'

const Favorites = () => {
  return (
    <div className={styles.Favorites}>
      <h1>Избранное</h1>
      <FavoritesChannelsListOrEmpty />
      <ResolveShouldShowFavoritesContentsListOrEmpty />
    </div>
  )
}

const ResolveShouldShowFavoritesContentsListOrEmpty = () => {
  const user = useAppSelector((state) => state.auth.auth.data.loginData.user)

  const roles = user?.roles

  const role = roles?.find((role) => role.type === 'SELLER_APPROVE')

  if (!user || !roles || role) return null

  return (
    <FavoritesContentsListOrEmpty
      containerClassName={styles.FavoritesContentsListOrEmptyContainer}
      className={styles.FavoritesContentsListContainer}
      scrollWrapperClassName={styles.FavoritesContentsListScrollingWrapper}
    />
  )
}

export default Favorites
