import React, { memo } from 'react'

import styles from './styles.module.scss'

import SkeletonView from '@/components/SkeletonView'
import { useAppSelector } from '@/core/store'

const ChosenContentSkeleton = () => {
  const card = useAppSelector((state) => state.content.chosenCard)
  return (
    <div className={styles.SkeletonWrapper}>
      <div className={styles.ActorsSkeletonWrapper}>
        <div className={styles.Title}>
          <SkeletonView />
        </div>
        <div className={styles.Actors}>
          <SkeletonView />
        </div>
      </div>
      <div className={styles.GenresSkeletonWrapper}>
        <div className={styles.Title}>
          <SkeletonView />
        </div>
        <div className={styles.Genres}>
          <SkeletonView />
        </div>
      </div>
      <div className={styles.ActionsSkeletonWrapper}>
        <div>
          <SkeletonView />
        </div>

        {card && card.trailer && card.trailer.url && (
          <div style={{ width: '155px' }}>
            <SkeletonView />
          </div>
        )}

        <div>
          <SkeletonView />
        </div>
      </div>
      <div className={styles.GenreSliderSkeletonWrapper}>
        <div className={styles.GenreTitle}>
          <SkeletonView />
        </div>
        <div className={styles.GenreCards}>
          <div className={styles.GenreCard}>
            <SkeletonView />
          </div>
          <div className={styles.GenreCard}>
            <SkeletonView />
          </div>
          <div className={styles.GenreCard}>
            <SkeletonView />
          </div>
          <div className={styles.GenreCard}>
            <SkeletonView />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(ChosenContentSkeleton)
