import React from 'react'

import styles from './styles.module.scss'

import Modal from '@/components/Modal'
import Redirect from '@/components/Redirect'
import ApproveMenu from '@/modules/menu/components/ApproveMenu'

import { Outlet } from 'react-router-dom'

import { FocusContext } from '@noriginmedia/norigin-spatial-navigation'

import { useDefaultLayoutLogic } from '@/layouts/commonLogicForApproveAndDefaultLayouts/useDefaultLayoutLogic'

const ApproveLayout = () => {
  const { ref, focusKey, render, isExitModalOpen, handleModalClose } = useDefaultLayoutLogic()
  return (
    <div className={styles.ApproveLayout}>
      <div className={styles.MenuContainer}>
        <ApproveMenu />
      </div>
      <div className={styles.ContentRedirectContainer}>
        <div className={styles.Content}>
          <Redirect focusKey={'Redirect'} />

          <FocusContext.Provider value={focusKey}>
            <div id={focusKey} ref={ref} className={styles.ContentContainer}>
              <Outlet />
            </div>
          </FocusContext.Provider>
        </div>
      </div>
      <Modal
        isOpen={isExitModalOpen}
        closeByClickBackground={false}
        hideCloseButton
        onClose={handleModalClose}
        trigger={() => <></>}
        render={render}
      />
    </div>
  )
}

export default ApproveLayout
