import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useFocusable } from '@noriginmedia/norigin-spatial-navigation'

import { useAppSelector } from '@/core/store'
import { hasModalNodeOwnChild } from '@/core/helpers'
import { isBackButton } from '@/core/keys/specific.keys.codes'

import { MenuItem } from '@/modules/menu/menu.constants'
import {
  checkNativeFullscreen,
  checkCustomFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'
import { animate } from '@/core/animations/animate'
import timingFunctions from '@/core/animations/timing.functions'
import { drawClosedMenu, drawOpenedMenu } from '@/modules/menu/draw.menu.function'
import { useIsFirstRender } from '@/core/store/hooks'

const getPreferredChild = (): string | undefined => {
  const childCollection = document.getElementsByClassName('active_menu_item')

  if (!childCollection || childCollection.length === 0) return

  return childCollection.item(0)!.parentElement!.id
}

export const useContentMenu = (menuItems: MenuItem[]) => {
  const bgRef = useRef<HTMLDivElement | null>(null)

  const profile = useAppSelector((state) => state.profile.data.profile)

  const { ref, hasFocusedChild, focusKey, setFocus } = useFocusable({
    focusable: true,
    saveLastFocusedChild: true,
    trackChildren: true,
    autoRestoreFocus: true,
    isFocusBoundary: true,
    focusKey: 'MENU',
    onFocus: () => {
      const root = document.getElementById('root')
      root && (root.dataset.location = 'MENU')
    },
  })

  const items = useMemo(() => {
    if (!profile) return menuItems

    const mapped = menuItems.map((el) =>
      el.title === 'Пользователь' ? { ...el, title: profile.name } : el,
    )
    if (profile.profile.type === 'CHILD') {
      return mapped.filter((el) => el.title !== 'Спорт')
    }
    return mapped
  }, [profile])

  useEffect(() => {
    if (!hasFocusedChild) return

    const id = getPreferredChild()
    if (!id) return
    setFocus(id)
  }, [hasFocusedChild])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isBackButton(e)) return
      const root = document.getElementById('root')

      if (!root) return
      if (root.dataset.location === 'CONTENT') return
      e.preventDefault()
      e.stopPropagation()

      if (hasModalNodeOwnChild()) return
      if (checkNativeFullscreen() || checkCustomFullscreen()) return

      setFocus('Redirect')
    }

    document.addEventListener('keydown', handleKeyDown, { capture: true })

    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true })
    }
  }, [])

  const isFirstRender = useIsFirstRender()

  useEffect(() => {
    if (isFirstRender) return

    const bgCover = document.getElementById('menu_bgCover')
    const menuTitleElements = document.querySelectorAll<HTMLSpanElement>(`#menu-title`)
    const menuTitleFocusBgElements =
      document.querySelectorAll<HTMLSpanElement>(`#menu-title-focus-bg`)
    let timerId: ReturnType<typeof setTimeout> | null = null
    let openId: number | null = null
    let closeId: number | null = null

    if (hasFocusedChild) {
      timerId = setTimeout(() => {
        openId = animate({
          timing: timingFunctions.easeInOut,
          draw: drawOpenedMenu(menuTitleFocusBgElements, menuTitleElements, bgCover),
          duration: 300,
        })
      }, 200)
    } else {
      timerId = setTimeout(() => {
        closeId = animate({
          timing: timingFunctions.easeInOut,
          draw: drawClosedMenu(menuTitleFocusBgElements, menuTitleElements, bgCover),
          duration: 300,
        })
      }, 0)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
        timerId = null
      }

      if (closeId) {
        cancelAnimationFrame(closeId)
        closeId = null
      }

      if (openId) {
        cancelAnimationFrame(openId)
        openId = null
      }
    }
  }, [hasFocusedChild])

  const handleAnimationStart = useCallback(() => {
    const bg = bgRef.current
    if (!bg) return

    bg.style.left = '0px'
  }, [])

  const handleAnimationComplete = useCallback((definition: unknown) => {
    const bg = bgRef.current
    if (!bg) return
    if (definition === 'closed') {
      bg.style.left = '-1920px'
      return
    }
  }, [])

  return useMemo(() => {
    return {
      ref,
      bgRef,
      items,
      focusKey,
      setFocus,
      hasFocusedChild,
      handleAnimationStart,
      handleAnimationComplete,
    }
  }, [items, hasFocusedChild])
}
