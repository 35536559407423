import React, { FC, memo, useEffect } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import ContentTypesFilterAction from '@/modules/search/components/ContentTypesFilterAction'
import GenresFilterAction from '@/modules/search/components/GenresFilterAction'
import PartnerFilterAction from '@/modules/search/components/PartnerFilterAction'
import ResetFiltersAction from '@/modules/search/components/ResetFiltersAction'
import SortAction from '@/modules/search/components/SortAction'
import YearsFilterAction from '@/modules/search/components/YearsFilterAction'
import CountriesFilterAction from '@/modules/search/components/CountriesFilterAction'

import { GenreModel } from '@/models/genre.model'
import { PartnerBaseModel } from '@/models/partner.model'
import { ContentType } from '@/models/content.model'
import { CountryBaseModel } from '@/models/country.model'
import { setThrottle } from '@noriginmedia/norigin-spatial-navigation'
import { TagModel } from '@/models/tag.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface FiltersProps {
  className?: string
  chosenGenres?: GenreModel[]
  disabledGenres?: string[]
  chosenPartners?: PartnerBaseModel[]
  disabledPartners?: string[]
  chosenType?: ContentType
  chosenYears?: string[]
  chosenCountries?: CountryBaseModel[]
  chosenTags?: TagModel[]
  pageName: PagesWithSearch
}

const Filters: FC<FiltersProps> = ({
  className,
  chosenGenres,
  disabledGenres,
  chosenPartners,
  disabledPartners,
  chosenType,
  chosenYears,
  chosenCountries,
  chosenTags,
  pageName,
}) => {
  useEffect(() => {
    setThrottle({ throttleKeypresses: false, throttle: 300 })
    return () => {
      setThrottle({ throttleKeypresses: true, throttle: 300 })
    }
  }, [])
  return (
    <div className={classNames(className, styles.Filters)}>
      <SortAction
        pageName={pageName}
        triggerClassName={classNames(styles.FilterButton, styles.FilterButton__Sort)}
      />
      <ContentTypesFilterAction
        pageName={pageName}
        triggerClassName={styles.FilterButton}
        chosenType={chosenType}
      />
      <GenresFilterAction
        pageName={pageName}
        chosenGenres={chosenGenres}
        disabledGenres={disabledGenres}
        triggerClassName={styles.FilterButton}
      />
      <YearsFilterAction
        pageName={pageName}
        triggerClassName={styles.FilterButton}
        chosenYears={chosenYears}
        // disabledYears={}
      />
      <CountriesFilterAction
        pageName={pageName}
        triggerClassName={styles.FilterButton}
        chosenCountries={chosenCountries}
      />
      <PartnerFilterAction
        pageName={pageName}
        chosenPartners={chosenPartners}
        disabledPartners={disabledPartners}
        triggerClassName={styles.FilterButton}
      />
      <ResetFiltersAction
        pageName={pageName}
        chosenGenres={chosenGenres}
        chosenPartners={chosenPartners}
        chosenTags={chosenTags}
        className={classNames(styles.FilterButton, styles.FilterButton__Reset)}
      />
    </div>
  )
}

export default memo(Filters)
