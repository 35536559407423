import React, { FC } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'

interface EmptyFavoritesProps {
  text?: string
  className?: string
}

const DEFAULT_EMPTY_CONTENT_TEXT = 'Здесь будет отображаться Ваш избранный контент'

const EmptyFavorites: FC<EmptyFavoritesProps> = ({ text, className }) => {
  return (
    <div className={classNames(className, styles.EmptyFavorites)}>
      {text || DEFAULT_EMPTY_CONTENT_TEXT}
    </div>
  )
}

export default EmptyFavorites
