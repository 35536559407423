import React, { FC, useCallback } from 'react'

import styles from './styles.module.scss'
import Sidebar from '@/components/Sidebar'
import CircleButton from '@/components/CircleButton'
import Settings from '@/modules/content/components/ContentPlayerInteractOverlayActions/Settings'
import { OmitSourceState } from '@/modules/content/components/ContentPlayer'

interface ContentPlayerInteractOverlayActionsProps {
  setUserActivity: (status: boolean) => void
  changeDisableOverlay: (value: boolean) => void
  sourceState: OmitSourceState
}

const ContentPlayerInteractOverlayActions: FC<ContentPlayerInteractOverlayActionsProps> = ({
  setUserActivity,
  changeDisableOverlay,
  sourceState,
}) => {
  const handleTrigger = useCallback((onTrigger: () => void) => {
    return () => {
      onTrigger()
      setUserActivity(false)
      changeDisableOverlay(true)
    }
  }, [])

  const handleOnClose = useCallback(() => {
    changeDisableOverlay(false)
    setUserActivity(true)
  }, [])
  return (
    <div className={styles.Actions}>
      <Sidebar
        appendToId={'player_overlay'}
        onClose={handleOnClose}
        trigger={(onTrigger) => (
          <CircleButton
            buttonClassName={styles.CircleButtonAction}
            onButtonClick={handleTrigger(onTrigger)}
            onEnterPress={handleTrigger(onTrigger)}
          >
            <i className={'icon-fill-settings2'} />
          </CircleButton>
        )}
        render={(closeMainOverlay) => {
          return <Settings sourceState={sourceState} closeMainOverlay={closeMainOverlay} />
        }}
      />
    </div>
  )
}

export default ContentPlayerInteractOverlayActions
