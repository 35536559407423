export class PlayerStore<T, R> {
  private subscriptions: (() => void)[] = []
  private state: T
  private reducer: (state: T, action: R) => T

  constructor(initialState: T, reducer: (state: T, action: R) => T) {
    this.state = initialState
    this.reducer = reducer
  }

  getState = () => {
    return this.state
  }

  update = (partialState: Partial<T>) => {
    this.state = { ...this.state, ...partialState }

    this.subscriptions.forEach((cb) => cb())
  }

  dispatch = (action: R) => {
    this.state = this.reducer(this.state, action)

    this.subscriptions.forEach((cb) => cb())
  }

  subscribe = (cb: () => void) => {
    this.subscriptions.push(cb)

    return () => {
      const index = this.subscriptions.indexOf(cb)

      if (index === -1) {
        return
      }

      this.subscriptions.splice(index, 1)
    }
  }
}

export default PlayerStore
