import React, { FC } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { NotificationModel, NotificationVariants } from '@/models/notification.model'

interface Props {
  card?: NotificationModel
  focused?: boolean
}

const NotificationCardInner: FC<Props> = ({ card, focused }) => {
  return (
    <div
      className={classNames(styles.CardInner, {
        [styles.CardInner__Focused]: focused,
        [styles.CardInner__Readed]: card?.clientNotification.readedAt,
      })}
    >
      <div
        className={classNames(styles.ReadIndicator, {
          [styles.ReadIndicator__Active]: !card?.clientNotification.readedAt,
        })}
      />
      <div className={styles.Title}>{card?.title}</div>
      <div
        className={classNames(styles.Variant, {
          [styles.Variant__Info]: card?.variant === NotificationVariants.INFO,
          [styles.Variant__Success]: card?.variant === NotificationVariants.SUCCESS,
          [styles.Variant__Warning]: card?.variant === NotificationVariants.WARNING,
          [styles.Variant__Danger]: card?.variant === NotificationVariants.DANGER,
        })}
      >
        {!card ? (
          <i className={'icon-line-check'} style={{ fontSize: '20px' }} />
        ) : card.variant === NotificationVariants.INFO ? (
          'i'
        ) : card.variant === NotificationVariants.WARNING ||
          card.variant === NotificationVariants.DANGER ? (
          '!'
        ) : card.variant === NotificationVariants.SUCCESS ? (
          <i className={'icon-line-check'} style={{ fontSize: '20px' }} />
        ) : null}
      </div>
      <div className={styles.Description}>{card?.description}</div>
      <div className={styles.Action}>
        <i className={'icon-line-chevron-right'} />
      </div>
    </div>
  )
}

export default NotificationCardInner
