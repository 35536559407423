import React from 'react'

import styles from './styles.module.scss'

const ActiveViewsHeader = () => {
  return (
    <div className={styles.ViewsHeader}>
      <div className={styles.Title}>Я смотрю</div>
    </div>
  )
}

export default ActiveViewsHeader
