import React, { ReactNode, Suspense } from 'react'
import Loader from '@/components/Loader'

interface SuspenseWithLoaderFallback {
  children: ReactNode
}

const SuspenseWithLoaderFallback = ({ children }: SuspenseWithLoaderFallback) => {
  return (
    <Suspense
      fallback={
        <Loader>
          <Loader.Spinner />
        </Loader>
      }
    >
      {children}
    </Suspense>
  )
}

export default SuspenseWithLoaderFallback
