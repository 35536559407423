import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import Button from '@/components/Button'
import List from '@/components/List'
import Checkbox from '@/components/Checkbox'
import ActiveViewsHeader from '@/modules/syncMediaView/components/ActiveViewsHeader'
import DeletableViewCard from '@/modules/syncMediaView/components/DeletableViewCard'

import { useNavigate } from 'react-router-dom'
import { setChosenCard } from '@/modules/content/content.actions'
import { useAppDispatch } from '@/core/store'

import {
  useGetAllActiveContentMediaViewQuery,
  useRemoveOneMediaViewMutation,
} from '@/modules/syncMediaView/syncMediaView.api'

import warning from '@/assets/images/warning.png'

import { ContentPaths, Paths } from '@/core/router/router.paths'
import { ContentType, ContentWithViewModel } from '@/models/content.model'

const ActiveViews = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const { 0: isEditMode, 1: setEditMode } = useState(false)

  const { data, isLoading, isFetching, refetch } = useGetAllActiveContentMediaViewQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  const itemsLoading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  const [removeContentFromActiveViews, { isLoading: deleting, originalArgs }] =
    useRemoveOneMediaViewMutation()

  const handlePress = useCallback(
    (card: ContentWithViewModel) => {
      if (!card) return

      if (!isEditMode) {
        if (card.type === ContentType.MOVIE) {
          dispatch(setChosenCard(card))
          navigate(`${Paths.CONTENT}/${ContentPaths.MOVIES}/${card.id}`)
          return
        }

        if (card.type === ContentType.SERIAL) {
          dispatch(setChosenCard(card))
          navigate(`${Paths.CONTENT}/${ContentPaths.SERIALS}/${card.id}`)
        }
        return
      }

      removeContentFromActiveViews({ contentId: card.id }).unwrap().then(refetch)
    },
    [isEditMode, removeContentFromActiveViews],
  )

  useEffect(() => {
    if (!data || data.length === 0) {
      setEditMode(false)
    }
  }, [data])

  const handleToMain = useCallback(() => {
    navigate(`${Paths.CONTENT}`)
  }, [])

  return (
    <div className={styles.ActiveViews}>
      <ActiveViewsHeader />
      <div className={styles.EditMode}>
        <Checkbox
          checked={isEditMode}
          onChange={setEditMode}
          name={`${!isEditMode ? 'Включить' : 'Выключить'} режим удаления`}
          className={styles.Checkbox}
          disabled={itemsLoading || (data && data.length === 0)}
        />
      </div>
      {data && !itemsLoading && data.length === 0 && (
        <div className={classNames(styles.ListWrapper, styles.ListWrapper__Empty)}>
          <img className={styles.Icon} src={warning} alt={'Внимание'} />
          <div className={styles.EmptyText}>Здесь будет отображаться Ваша история просмотров</div>
          <Button
            buttonClassName={styles.ToMainAction}
            autoFocus
            onButtonClick={handleToMain}
            onEnterPress={handleToMain}
          >
            На главную
          </Button>
        </div>
      )}
      {data && data.length > 0 && (
        <div className={styles.ListWrapper}>
          <List className={styles.List} scrollWrapperClassName={styles.ContentWrapper}>
            {data.map((viewedContent, index) => (
              <DeletableViewCard
                autoFocus={index === 0}
                key={viewedContent.id}
                card={viewedContent}
                deletable={isEditMode}
                onCardPress={handlePress}
                onFocusedCardClick={handlePress}
                deleting={deleting && originalArgs && originalArgs.contentId === viewedContent.id}
              />
            ))}
          </List>
        </div>
      )}
    </div>
  )
}

export default ActiveViews
