import React, { FC } from 'react'

import styles from './styles.module.scss'

import Checkbox from '@/components/Checkbox'
import Sidebar from '@/components/Sidebar'
import Button from '@/components/Button'
import ListWithScrollbar from '@/components/ListWithScrollbar'

import { PreparedQuality } from '@/modules/player/player.url.manager'
import { QualityValue } from '@/models/streams.model'

interface QualitiesProps {
  quality: PreparedQuality | null
  qualities: PreparedQuality[]
  changeResolution: (quality: PreparedQuality) => void
  closeMainOverlay?: () => void
}

const Qualities: FC<QualitiesProps> = ({
  quality,
  qualities,
  closeMainOverlay,
  changeResolution,
}) => {
  return (
    <Sidebar
      appendToId={'player_overlay'}
      backgroundClassName={styles.BgTransparent}
      trigger={(onTrigger) => (
        <Button
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
          buttonClassName={styles.ActionButton}
          focusClassName={styles.ActionButton__Focused}
          onArrowPress={(direction) => {
            if (direction === 'right') {
              onTrigger && onTrigger()
              return false
            }
            if (direction === 'left') {
              closeMainOverlay && closeMainOverlay()
              return false
            }
            return true
          }}
        >
          <>
            <span>Качество</span>
            <span>{`${quality?.label} ${
              quality?.value === QualityValue.AUTO ? '' : quality?.value
            }`}</span>
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <div className={styles.Settings}>
            <h4>Качество</h4>
            <ListWithScrollbar className={styles.List}>
              {qualities.map((qualityInMap) => {
                const handleClick = () => {
                  changeResolution(qualityInMap)
                  // onClose && onClose()
                }
                return (
                  <Checkbox
                    rounded
                    key={qualityInMap.id}
                    name={`${qualityInMap?.label} ${
                      qualityInMap?.value === QualityValue.AUTO ? '' : qualityInMap?.value
                    }`}
                    checked={qualityInMap.id === quality?.id}
                    onEnterPress={handleClick}
                    onClick={handleClick}
                    onArrowPress={(direction) => {
                      if (direction === 'left') {
                        onClose && onClose()
                        return false
                      }
                      return true
                    }}
                  />
                )
              })}
            </ListWithScrollbar>
          </div>
        )
      }}
    />
  )
}

export default Qualities
