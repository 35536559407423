import React from 'react'
import { ContentModel } from '@/models/content.model'
import ContentRating from '@/modules/content/components/ContentRating'

import kinopoisk from '@/assets/images/partners/kinopoisk.mini.svg'

interface KinopoiskRatingProps {
  card?: ContentModel
  className?: string
}

const KinopoiskRating = ({ className, card }: KinopoiskRatingProps) => {
  if (!card || !card.ratingKinopoisk) return null
  return <ContentRating rating={card.ratingKinopoisk} logo={kinopoisk} className={className} />
}

export default KinopoiskRating
