import React, { FC } from 'react'

import styles from './styles.module.scss'
import classNames from 'classnames'

import ImageLoader from '@/components/Image'

import { getChannelLogoUrl, getChannelTitle } from '@/modules/tv/tv.helpers'

import { ChannelModel } from '@/models/channel.model'
import { useAppSelector } from '@/core/store'

interface Props {
  focused?: boolean
  card?: ChannelModel
  index: number
}

const ChannelListItemInner: FC<Props> = ({ card, index, focused }) => {
  const currentChannelId = useAppSelector((state) => state.tv.chosenChannel?.id)

  return (
    <div
      className={classNames(styles.Inner, {
        [styles.Inner__Focused]: focused,
        [styles.Inner__Chosen]: !!currentChannelId && !!card && currentChannelId === card?.id,
      })}
    >
      <div className={styles.ChannelIndex}>{index + 1}</div>
      <div className={styles.ChannelLogo}>
        <ImageLoader src={getChannelLogoUrl(card)} alt={getChannelTitle(card)} skeleton />
      </div>
      <div className={styles.ChannelTitle}>{getChannelTitle(card)}</div>
    </div>
  )
}

export default ChannelListItemInner
