import React, { FC, useCallback, useRef, useState } from 'react'

import SkeletonView from '@/components/SkeletonView'

export interface ImageLoaderProps {
  src?: string
  alt?: string
  skeleton?: boolean
  roundedSkeleton?: boolean
  className?: string
  fallbackSrc?: string
  height?: number
  width?: number
}

const ImageLoader: FC<ImageLoaderProps> = ({
  fallbackSrc,
  src,
  alt,
  skeleton,
  roundedSkeleton,
  className,
  width,
  height,
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const shouldGetFallback = useRef(true)

  const loadHandler = useCallback(
    (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const image = ev.currentTarget
      if (image && image.complete && image.naturalHeight) {
        setImageLoaded(true)
        error && setError(false)
      }
    },
    [error],
  )

  const loadErrorHandler = useCallback(
    (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
      console.log('Cannot load image, ', ev.currentTarget.src)
      setError(true)
      const image = ev.currentTarget
      if (!fallbackSrc || !image || !shouldGetFallback.current) return

      image.src = fallbackSrc
      shouldGetFallback.current = false
    },
    [fallbackSrc],
  )

  return (
    <>
      {skeleton && !imageLoaded && <SkeletonView rounded={roundedSkeleton} />}
      <img
        src={src?.includes('undefined') ? undefined : src}
        alt={alt}
        loading={'lazy'}
        className={className}
        onLoad={loadHandler}
        onError={loadErrorHandler}
        height={height}
        width={width}
        style={{
          position: !imageLoaded ? 'absolute' : 'unset',
          // display: !imageLoaded ? 'none' : 'unset',
          opacity: !imageLoaded ? 0 : 1,
        }}
      />
    </>
  )
}

export default ImageLoader
