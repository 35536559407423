import React, { memo, useEffect, useState } from 'react'

import { useAppSelector } from '@/core/store'

import { Navigate } from 'react-router-dom'
import { ContentPaths } from '@/core/router/router.paths'

const ApproveHomeViewResolver = () => {
  const [resolved, setResolved] = useState(false)
  const user = useAppSelector((state) => state.auth.auth.data.loginData.user)

  const roles = user?.roles

  const role = roles?.find((role) => role.type === 'SELLER_APPROVE')

  useEffect(() => {
    if (!user) return
    if (!roles) return
    if (role) return

    setResolved(true)
    return () => {
      setResolved(false)
    }
  }, [user])
  if (!user || role) return null
  return resolved ? <Navigate to={ContentPaths.HOME} /> : null
}

export default memo(ApproveHomeViewResolver)
