import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useAppDispatch } from '@/core/store'

import { useFocusable, UseFocusableConfig } from '@noriginmedia/norigin-spatial-navigation'

import Loader from '@/components/Loader'
import Player, { PlayerProps } from '@/modules/player/Player'
import PlayerSourceManager, {
  SourceManagerChild,
} from '@/modules/player/Player/PlayerSourceManager'
import ChannelPlayerOverlayActions from '@/modules/tv/components/ChannelPlayerOverlayActions'
import ChannelNeedPackage from '@/modules/tv/components/ChannelNeedPackage'
import ChannelPlayerTitle from '@/modules/tv/components/ChannelPlayerTitle'
import SwitchChannelByChannelUpDownKeys from '@/modules/tv/components/SwitchChannelByChannelUpDownKeys'
import SwitchChannelByNumericKeys from '@/modules/tv/components/SwitchChannelByNumericKeys'
import { PlayerTvEventManager } from '@/modules/player/Player/PlayerTvEventManager'

import {
  checkNativeFullscreen,
  checkCustomFullscreen,
} from '@/modules/player/helpers/fullscreen.helper'
import { setCurrentProgram } from '@/modules/tv/tv.actions'

import { checkIsRightsErrorModel } from '@/models/rightsError.model'
import { useGetChannelStreams } from '@/modules/tv/hooks/useGetChannelStreams'

import { LeftSlotRenderFn } from '@/modules/player/components/player/Overlay'
import { ProgramBaseModel } from '@/models/program.model'
import { PlayerCustomEvents } from '@/core/customEvents/player.custom.events'

export interface TvPlayerProps extends Partial<Omit<PlayerProps, 'className'>> {
  url?: string
  className?: string
  playerClassName?: string
  focusProps?: UseFocusableConfig
}

const TvPlayer: FC<TvPlayerProps> = ({ className, focusProps, playerClassName, ...props }) => {
  const dispatch = useAppDispatch()
  const { 0: playerFS, 1: setPlayerFS } = useState(false)

  const nextProgramRef = useRef<ProgramBaseModel | undefined>()

  const { isLive, nextProgram, rightsError, loading, streams, channel } = useGetChannelStreams()

  useEffect(() => {
    nextProgramRef.current = nextProgram
  }, [nextProgram])

  const goToFullscreen = useCallback(() => {
    const player = document.getElementById(props.playerId || 'TV-PLAYER') as HTMLDivElement | null
    if (!player) return

    const node = document.getElementById('content_redirect_container')

    if (!node) return

    setPlayerFS(true)
    node.style.zIndex = '2'
    player.classList.add(styles.TvPlayer__Fullscreen)
  }, [])

  const { ref, focusKey, focused, focusSelf } = useFocusable({
    ...focusProps,
    focusable: !rightsError,
    onEnterPress: (...args) => {
      focusProps?.onEnterPress?.(...args)
      goToFullscreen()
    },
  })

  const goFromFullscreen = useCallback(() => {
    const node = document.getElementById('content_redirect_container')

    if (!node) return

    node.style.zIndex = '0'
  }, [])

  useEffect(() => {
    const handleRequestExitFS = () => {
      const player = document.getElementById(props.playerId || 'TV-PLAYER') as HTMLDivElement | null
      if (!player) return

      goFromFullscreen()

      setPlayerFS(false)
      player.classList.remove(styles.TvPlayer__Fullscreen)
    }

    document.addEventListener(PlayerCustomEvents.REQUEST_PLAYER_OUT_FS, handleRequestExitFS)

    return () => {
      document.removeEventListener(PlayerCustomEvents.REQUEST_PLAYER_OUT_FS, handleRequestExitFS)

      goFromFullscreen()
    }
  }, [])

  const handleClick = useCallback(() => {
    if (checkNativeFullscreen() || checkCustomFullscreen()) return
    if (rightsError) return
    if (focused) {
      goToFullscreen()
      return
    }
    focusSelf()
  }, [focused, rightsError])

  const handleDoubleClick: React.MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleOnEnded = useCallback(
    (/*videoEl: HTMLVideoElement*/) => {
      if (!nextProgramRef.current) return

      dispatch(setCurrentProgram(nextProgramRef.current))
    },
    [],
  )

  const overlayProps = useMemo(() => {
    const renderLeftSlot: LeftSlotRenderFn = (
      isPaused,
      togglePlayPause,
      changeProgress,
      isLive,
      setUserActivity,
      changeDisableOverlay,
    ) => {
      return (
        <ChannelPlayerOverlayActions
          isPaused={isPaused}
          togglePlayPause={togglePlayPause}
          changeProgress={changeProgress}
          isLive={isLive}
          setUserActivity={setUserActivity}
          changeDisableOverlay={changeDisableOverlay}
        />
      )
    }

    const renderTopSlot = () => {
      return <ChannelPlayerTitle />
    }

    return {
      headerSlotRenderFn: renderTopSlot,
      leftSlotRenderFn: renderLeftSlot,
    }
  }, [])

  const videoState = useMemo(() => {
    return {
      isMuted: false,
      isLive,
    }
  }, [isLive])

  const renderPlayer: SourceManagerChild = useCallback(
    ({ url }) => {
      return (
        <PlayerTvEventManager channel={channel} {...props}>
          {(params) => (
            <>
              <Player
                {...params}
                playerId={props.playerId || 'TV-PLAYER'}
                hideOverlay={!playerFS}
                // showBufferLoaderInUnFullScreen={!checkNativeFullscreen() && !checkCustomFullscreen()}
                videoState={videoState}
                overlayProps={overlayProps}
                fullscreenState={{ status: true }}
                videoStreamerProps={{
                  muted: false,
                  autoPlay: props.videoStreamerProps?.autoPlay || true,
                  url: url,
                }}
                videoManagerProps={{
                  ...params,
                  onEnded: (videoEl) => {
                    params.onEnded?.(videoEl)
                    handleOnEnded()
                  },
                }}
                className={classNames(styles.Player, playerClassName)}
              />
              <SwitchChannelByChannelUpDownKeys />
              <SwitchChannelByNumericKeys />
            </>
          )}
        </PlayerTvEventManager>
      )
    },
    [videoState, playerFS, channel],
  )

  useEffect(() => {
    const handleRequestPlayerToFullscreen = () => {
      if (checkNativeFullscreen() || checkCustomFullscreen()) return

      goToFullscreen()
    }

    document.addEventListener(
      PlayerCustomEvents.REQUEST_PLAYER_TO_FS,
      handleRequestPlayerToFullscreen,
    )

    return () => {
      document.removeEventListener(
        PlayerCustomEvents.REQUEST_PLAYER_TO_FS,
        handleRequestPlayerToFullscreen,
      )
    }
  }, [])

  return (
    <div
      id={focusKey}
      ref={ref}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      className={classNames(className, styles.TvPlayer, focused && styles.TvPlayer__Focused)}
    >
      {loading && (
        <Loader>
          <Loader.Spinner />
        </Loader>
      )}
      {!!rightsError && !!checkIsRightsErrorModel(rightsError) && (
        <ChannelNeedPackage packages={rightsError.data.message} />
      )}
      {!rightsError && <PlayerSourceManager urls={streams}>{renderPlayer}</PlayerSourceManager>}
    </div>
  )
}

export default TvPlayer
