import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'
import { PaymentsHistoryModel } from '@/models/payments.history.model'
import { PaymentCardModel } from '@/models/payment.card.model'

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/clients/me/payments/` }),
  tagTypes: ['CARDS'],
  endpoints: (builder) => ({
    generateEripCode: builder.mutation<{ code: number }, { packageId: number }>({
      query: (data) => {
        return {
          url: 'erip',
          method: 'POST',
          data,
        }
      },
    }),
    sendSmsWithEripCode: builder.mutation<void, { eripCode: number }>({
      query: ({ eripCode }) => {
        return {
          url: `erip/${eripCode}/sms`,
          method: 'POST',
        }
      },
    }),
    getPaymentsHistory: builder.query<PaymentsHistoryModel[], void>({
      query: () => ({
        url: 'history',
        method: 'GET',
      }),
    }),

    getPaymentsCards: builder.query<PaymentCardModel[], void>({
      query: () => ({
        url: 'cards',
        method: 'GET',
      }),
      providesTags: ['CARDS'],
    }),
    setPaymentsCardAsForPay: builder.mutation<void, { cardId: string }>({
      query: ({ cardId }) => ({
        url: `cards/${cardId}/for-pay`,
        method: 'PATCH',
      }),
      invalidatesTags: ['CARDS'],
    }),

    payWithChosenPaymentCard: builder.mutation<PayResponse, { cardId: string; packageId: string }>({
      query: ({ cardId, packageId }) => ({
        url: `cards/${cardId}/packages/${packageId}/pay`,
        method: 'POST',
      }),
    }),
    deletePaymentsCard: builder.mutation<void, { cardId: string }>({
      query: ({ cardId }) => ({
        url: `cards/${cardId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CARDS'],
    }),

    createPaymentsCard: builder.mutation<
      void,
      { holder: string; number: string; expiryMonth: number; expiryYear: number }
    >({
      query: (data) => ({
        url: `cards`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['CARDS'],
    }),
  }),
})

interface PayResponse {
  data: {
    // message: 'Для подтверждения оплаты пройдите по ссылке: https://desktop.itv.by/transaction.php?user=220040'
    message: string
    packageId?: string
    phone: string
    transactionId?: string
    // url: 'https://desktop.itv.by/transaction.php?user=220040'
    url?: string
    userId?: number
  }
  status: 'OK'
  type: '3D_SECURE' | 'NO_3D_SECURE'
}

export const {
  useGetPaymentsCardsQuery,
  useGenerateEripCodeMutation,
  useSendSmsWithEripCodeMutation,
  useGetPaymentsHistoryQuery,
  useDeletePaymentsCardMutation,
  useSetPaymentsCardAsForPayMutation,
  useCreatePaymentsCardMutation,
  usePayWithChosenPaymentCardMutation,
} = paymentsApi
