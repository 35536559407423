import React, { useMemo } from 'react'

import styles from './styles.module.scss'

import Filters from '@/modules/search/components/Filters'
import { useParams } from 'react-router-dom'
import { useGetAllPartnersQuery } from '@/modules/partner/partner.api'

const PartnerFilters = () => {
  const { id } = useParams()

  const { data: partners } = useGetAllPartnersQuery(undefined, { skip: !id })

  const chosenPartners = useMemo(() => {
    if (!partners || !id) return

    const partner = partners.find((partner) => partner.id === id)

    if (!partner) return

    return [partner]
  }, [partners, id])

  return (
    <Filters pageName={'partners'} className={styles.Filters} chosenPartners={chosenPartners} />
  )
}

export default PartnerFilters
