import { SearchSliceBuilderType } from '@/modules/search/slice/search.slice'

import { countriesExtraReducers } from '@/modules/search/slice/extraReducers/searchState/countries'
import { yearsExtraReducers } from '@/modules/search/slice/extraReducers/searchState/years'
import { genresExtraReducers } from '@/modules/search/slice/extraReducers/searchState/genres'
import { contentTypesExtraReducers } from '@/modules/search/slice/extraReducers/searchState/contentTypes'
import { partnersExtraReducers } from '@/modules/search/slice/extraReducers/searchState/partners'
import { tagsExtraReducers } from '@/modules/search/slice/extraReducers/searchState/tags'
import { searchTermExtraReducers } from '@/modules/search/slice/extraReducers/searchState/searchTerm'
import { sortExtraReducers } from '@/modules/search/slice/extraReducers/searchState/sort'

export const searchStateExtraReducers = (builder: SearchSliceBuilderType) => {
  countriesExtraReducers(builder)
  yearsExtraReducers(builder)
  genresExtraReducers(builder)
  contentTypesExtraReducers(builder)
  partnersExtraReducers(builder)
  tagsExtraReducers(builder)
  searchTermExtraReducers(builder)
  sortExtraReducers(builder)
}
