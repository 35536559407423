import React from 'react'

import styles from './styles.module.scss'

import NotificationsFilters from '@/modules/notifications/components/NotificationsFilters'
import Actions from '@/modules/notifications/components/Actions'

interface Props {
  disabled?: boolean
}

const Header = ({ disabled }: Props) => {
  return (
    <div className={styles.Header}>
      <NotificationsFilters />
      <Actions disabled={disabled} />
    </div>
  )
}

export default Header
