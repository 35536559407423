import React, { FC, useCallback, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { ListProps } from '@/components/List'
import Checkbox, { CheckboxProps } from '@/components/Checkbox'
import ListWithScrollbar from '@/components/ListWithScrollbar'
import CommonListWrapperWithLoader from '@/modules/search/components/CommonListWrapperWithLoader'

import { useAppDispatch, useAppSelector } from '@/core/store'

import { useGetAllPartnersQuery } from '@/modules/partner/partner.api'

import { PartnerBaseModel } from '@/models/partner.model'
import { useLocation } from 'react-router-dom'
import { ContentPaths } from '@/core/router/router.paths'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'
import {
  addPartnerToPartnersFilter,
  removePartnerFromPartnersFilter,
  resetPartnersFilter,
  setPartnersFilter,
} from '@/modules/search/slice/actions/searchState/searchState.partners.actions'

interface PartnersListProps extends Omit<ListProps, 'children'> {
  chosenPartners?: PartnerBaseModel[]
  disabledPartners?: string[]
  pageName: PagesWithSearch
}

const PartnersList: FC<PartnersListProps> = ({
  className,
  chosenPartners,
  disabledPartners,
  pageName,
  ...rest
}) => {
  const { pathname } = useLocation()
  const { partners, loading } = useGetAllPartnersQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching }) => {
      return {
        partners: data,
        loading: isLoading || isFetching,
      }
    },
  })

  const items = useMemo(() => {
    if (!partners) return []
    return [
      <DefaultPartnerCheckBox
        key={'chooseAllGenres'}
        className={styles.DefaultCheckbox}
        autoFocus={true}
        chosenPartners={chosenPartners}
        pageName={pageName}
      />,
      ...partners.map((partner) => {
        return (
          <PartnerCheckBox
            disabled={
              (pathname.includes(ContentPaths.PARTNERS) &&
                chosenPartners &&
                !!chosenPartners.find((el) => el.id === partner.id)) ||
              (disabledPartners && !!disabledPartners.find((el) => el === partner.id))
            }
            key={partner.id}
            pageName={pageName}
            partner={partner}
          />
        )
      }),
    ]
  }, [partners])

  if (!partners && !loading) return null

  return (
    <CommonListWrapperWithLoader isLoading={loading}>
      <ListWithScrollbar className={classNames(styles.PartnersList, className)} {...rest}>
        {items}
      </ListWithScrollbar>
    </CommonListWrapperWithLoader>
  )
}

interface DefaultPartnerCheckBox extends CheckboxProps {
  chosenPartners?: PartnerBaseModel[]
  pageName: PagesWithSearch
}

const DefaultPartnerCheckBox = (props: DefaultPartnerCheckBox) => {
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) =>
      state.searchByPageSlice[props.pageName].searchState.filters.partners.length === 0 ||
      !!(
        pathname.includes(ContentPaths.PARTNERS) &&
        props.chosenPartners &&
        props.chosenPartners.length ===
          state.searchByPageSlice[props.pageName].searchState.filters.partners.length
      ),
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (props.chosenPartners && pathname.includes(ContentPaths.PARTNERS)) {
        dispatch(setPartnersFilter({ pageName: props.pageName, payload: props.chosenPartners }))
        return
      }

      if (checked) {
        dispatch(resetPartnersFilter({ pageName: props.pageName }))
        return
      }
    },
    [props.chosenPartners],
  )

  return <Checkbox name={'Выбрать всё'} checked={checked} onChange={handleChange} {...props} />
}

interface PartnerCheckBox extends CheckboxProps {
  partner: PartnerBaseModel
  pageName: PagesWithSearch
}

const PartnerCheckBox = ({ partner, ...props }: PartnerCheckBox) => {
  const dispatch = useAppDispatch()

  const checked = useAppSelector(
    (state) =>
      !!state.searchByPageSlice[props.pageName].searchState.filters.partners.find(
        (el) => el.id === partner.id,
      ),
  )

  const handleChange = useCallback(
    (checked: boolean) => {
      if (!partner) return

      if (checked) {
        dispatch(addPartnerToPartnersFilter({ pageName: props.pageName, payload: partner }))
        return
      }

      dispatch(removePartnerFromPartnersFilter({ pageName: props.pageName, payload: partner }))
    },
    [partner],
  )

  return <Checkbox name={partner.type} checked={checked} onChange={handleChange} {...props} />
}

export default PartnersList
