import React, { FC, useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import SkeletonView from '@/components/SkeletonView'

interface LoadMoreProps {
  onLoadMorePress?: () => void
}

const LoadMoreSearchResults: FC<LoadMoreProps> = ({ onLoadMorePress }) => {
  const onLoadMoreRef = useRef<() => void>()

  useEffect(() => {
    onLoadMoreRef.current = onLoadMorePress
  }, [onLoadMorePress])

  useEffect(() => {
    const list = document.getElementById('search-results-list')
    const load = document.getElementById('load-more-button')

    if (!list || !load) return

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.length === 0) return

        const entry = entries[0]

        if (!(entry.intersectionRatio > 0)) return
        if (!onLoadMoreRef.current) return

        onLoadMoreRef.current()
      },
      { root: list, threshold: [0.1] },
    )
    // начать наблюдение
    intersectionObserver.observe(load)

    return () => {
      intersectionObserver.unobserve(load)
      intersectionObserver.disconnect()
    }
  }, [])

  return (
    <div id={'load-more-button'} className={styles.LoadMoreButtonWrapper}>
      <div className={styles.SkeletonBody}>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
        <div className={styles.SkeletonCard}>
          <SkeletonView />
        </div>
      </div>
    </div>
  )
}

export default LoadMoreSearchResults
