import React, { FC, useMemo } from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

import { useAppSelector } from '@/core/store'

import Sidebar from '@/components/Sidebar'
import Button from '@/components/Button'
import PartnersFilterSidebar from '@/modules/search/components/PartnerFilterAction/PartnersFilterSidebar'

import { PartnerBaseModel } from '@/models/partner.model'
import { PagesWithSearch } from '@/modules/search/slice/search.slice'

interface PartnerFilterAction {
  chosenPartners?: PartnerBaseModel[]
  disabledPartners?: string[]
  triggerClassName?: string
  disabled?: boolean
  pageName: PagesWithSearch
}

const PartnerFilterAction: FC<PartnerFilterAction> = ({
  disabledPartners,
  chosenPartners,
  disabled,
  triggerClassName,
  pageName,
}) => {
  const partners = useAppSelector(
    (state) => state.searchByPageSlice[pageName].searchState.filters.partners,
  )

  const mappedPartners = useMemo(() => {
    return partners.map((el) => el.type)
  }, [partners])

  return (
    <Sidebar
      trigger={(onTrigger) => (
        <Button
          disabled={disabled}
          buttonClassName={classNames(triggerClassName, {
            [styles.FilterActive]:
              (chosenPartners && mappedPartners.length >= chosenPartners.length) ||
              (!chosenPartners && mappedPartners.length > 0),
          })}
          focusClassName={styles.FilterFocused}
          onButtonClick={onTrigger}
          onEnterPress={onTrigger}
        >
          <>
            <span>Партнеры</span>

            <span>
              {mappedPartners && mappedPartners.length > 0
                ? mappedPartners.join(', ')
                : 'Не выбрано'}
            </span>
          </>
        </Button>
      )}
      render={(onClose) => {
        return (
          <PartnersFilterSidebar
            chosenPartners={chosenPartners}
            disabledPartners={disabledPartners}
            onClose={onClose}
            pageName={pageName}
          />
        )
      }}
    />
  )
}

export default PartnerFilterAction
