import { createApi } from '@reduxjs/toolkit/query/react'

import { API_URL } from '@/core/config'
import { apiBaseQuery } from '@/core/api/api.query'

import { StreamsModel } from '@/models/streams.model'
import { Platform } from '@/models/platform.model'

export const streamsApi = createApi({
  reducerPath: 'streamsApi',
  baseQuery: apiBaseQuery({ baseUrl: `${API_URL}/clients/me/streams` }),
  tagTypes: [],
  endpoints: (builder) => ({
    getStreamsByMovieId: builder.query<
      StreamsModel[],
      { movieId: string; platform?: Platform | null; token?: string }
    >({
      query: ({ movieId, platform, token }) => {
        return {
          url: `/movies/${movieId}`,
          method: 'GET',
          params: {
            platform: platform && platform !== 'LOCAL' ? platform : 'TIZEN',
          },
          headers: {
            StreamAccessToken: token,
          },
        }
      },
    }),

    getStreamsBySerialIdAndByEpisodeId: builder.query<
      StreamsModel[],
      { serialId: string; episodeId: string; platform?: Platform | null; token?: string }
    >({
      query: ({ serialId, episodeId, platform, token }) => {
        return {
          url: `/serials/${serialId}/episodes/${episodeId}`,
          method: 'GET',
          params: {
            platform: platform && platform !== 'LOCAL' ? platform : 'TIZEN',
          },
          headers: {
            StreamAccessToken: token,
          },
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.serialId}-${queryArgs.episodeId}`
      },
    }),

    getPartnersPathBySerialIdAndByEpisodeId: builder.query<
      {
        partnerPlaybackPath: string
        partnerResourcePath: string
      },
      { serialId: string; episodeId: string; platform?: Platform | null; token?: string }
    >({
      query: ({ serialId, episodeId, token }) => {
        return {
          url: `/serials/${serialId}/episodes/${episodeId}/partners/paths`,
          method: 'GET',
          headers: {
            StreamAccessToken: token,
          },
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.serialId}-${queryArgs.episodeId}`
      },
    }),

    getPartnersPathByMovieId: builder.query<
      {
        partnerPlaybackPath: string
        partnerResourcePath: string
      },
      { movieId: string; platform?: Platform | null; token?: string }
    >({
      query: ({ movieId, token }) => {
        return {
          url: `/movies/${movieId}/partners/paths`,
          method: 'GET',
          headers: {
            StreamAccessToken: token,
          },
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.movieId}`
      },
    }),

    getStreamsByChannelId: builder.query<StreamsModel[], { channelId: string; token?: string }>({
      query: ({ channelId, token }) => {
        return {
          url: `/channels/${channelId}`,
          method: 'GET',
          headers: {
            StreamAccessToken: token,
          },
        }
      },
    }),

    getStreamsByChannelIdAndProgramId: builder.query<
      StreamsModel[],
      { channelId: string; programId: string; token?: string }
    >({
      query: ({ channelId, programId, token }) => {
        return {
          url: `/channels/${channelId}/programs/${programId}`,
          method: 'GET',
          headers: {
            StreamAccessToken: token,
          },
        }
      },
    }),
  }),
})

export const {
  useLazyGetStreamsByChannelIdQuery,
  useGetStreamsByMovieIdQuery,
  useGetStreamsByChannelIdAndProgramIdQuery,
  useLazyGetStreamsByChannelIdAndProgramIdQuery,
  useGetStreamsBySerialIdAndByEpisodeIdQuery,
  useGetPartnersPathBySerialIdAndByEpisodeIdQuery,
  useGetPartnersPathByMovieIdQuery,
} = streamsApi
